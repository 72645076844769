import * as React from 'react'
import { useRoute, RouteProp } from '@react-navigation/native'
import { Planet, planetPath } from '@mv/api/lib/src/schema/planets'
import { logger } from '../../../logger'
import { Text } from '../..'
import { PlanetsStackParamList } from '../../../navigation/types'
import { Screen, Heading } from '../../Screen'
import { CreateOrEditPlanetForm } from '../CreateOrEditPlanetForm'
import { Loading } from '../../Loading'
import { db, doc, getDoc, DocumentReference } from '../../../firebase/firestore'
import { PLANET_NOT_FOUND } from '../../../constants/messages'

export function EditPlanetScreen(): JSX.Element {
  const route = useRoute<RouteProp<PlanetsStackParamList, 'PlanetsEdit'>>()

  const [loading, setLoading] = React.useState(true)
  const [planet, setPlanet] = React.useState<Planet | null>(null)

  React.useEffect(() => {
    getDoc(
      doc(db, planetPath(route.params.planetId)) as DocumentReference<Planet>
    )
      .then((planetSnapshot) => {
        setLoading(false)
        if (planetSnapshot.exists()) {
          setPlanet(planetSnapshot.data() as Planet)
        }
      })
      .catch((e) => logger.warn('planet document failed to load', e))
  }, [route.params.planetId])

  if (loading) {
    return <Loading />
  }
  const testID = 'screen-planet-edit'
  if (!planet) {
    return (
      <Screen testID={testID}>
        <Heading>Edit Planet</Heading>
        <Text>{PLANET_NOT_FOUND}</Text>
      </Screen>
    )
  }
  return (
    <Screen testID={testID}>
      <Heading>Edit Planet</Heading>
      <CreateOrEditPlanetForm planet={planet} />
    </Screen>
  )
}
