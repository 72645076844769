import React from 'react'
import {
  StyleSheet,
  ActivityIndicator,
  StyleProp,
  ViewStyle,
} from 'react-native'
import { View } from '.'
import { useThemeColors } from '../constants/colors'

type Props = {
  style?: StyleProp<ViewStyle>
  size?: 'large' | 'small'
}
export const Loading = ({ style, size }: Props): JSX.Element => {
  const { activityIndicator: activityIndicatorColor } = useThemeColors()
  const viewColorStyles = { backgroundColor: 'transparent' }
  return (
    <View style={[styles.container, viewColorStyles, style]}>
      <ActivityIndicator size={size} color={activityIndicatorColor} />
    </View>
  )
}
Loading.defaultProps = {
  style: undefined,
  size: 'large',
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
})
