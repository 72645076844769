/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialState, ContextMenu } from './contextMenuTypes'

export const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState: initialState(),
  reducers: {
    setContextMenu(state, action: PayloadAction<ContextMenu>) {
      if (
        state.contextMenu.parentUID === action.payload.parentUID ||
        action.payload.menu === null
      ) {
        return initialState()
      }
      state.contextMenu = action.payload
      state.show = true
      return state
    },
    hideContextMenu() {
      return initialState()
    },
  },
})

export const contextMenuActions = contextMenuSlice.actions
