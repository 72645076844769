import * as React from 'react'
import { Platform, Linking, StyleProp, TextStyle } from 'react-native'
import { Button } from './Button'
import { useThemeColors } from '../constants/colors'
import { Weight } from '.'
import { TextPreset } from './Text'

type Props = {
  testID?: string
  label: string
  labelWeight?: Weight
  labelStyle?: StyleProp<TextStyle>
  labelPreset?: TextPreset
  url?: string
  onPress?: () => void
}
export function LinkButton({
  testID,
  label,
  labelWeight,
  labelStyle,
  labelPreset,
  url,
  onPress,
}: Props): JSX.Element {
  const colorStyles = useThemeColors().link
  return (
    <Button
      testID={testID}
      onPress={async () => {
        if (onPress) onPress()
        if (url) {
          if (Platform.OS === 'web' && window) {
            window.open(url, '_blank')
          } else {
            await Linking.openURL(url)
          }
        }
      }}
      label={label}
      labelWeight={labelWeight}
      labelStyle={[colorStyles, labelStyle]}
      labelPreset={labelPreset}
    />
  )
}
LinkButton.defaultProps = {
  url: undefined,
  labelStyle: undefined,
  onPress: undefined,
  labelPreset: undefined,
}
