/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import {
  StyleSheet,
  ScrollView,
  LayoutChangeEvent,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native'
import { useThemeColors } from '../constants/colors'
import { View, Text } from '.'
import { useScreenMargins } from '../hooks/useScreenMargins'
import { useTheme } from '../hooks/useTheme'
import { useDispatch } from '../state/store'
import { contextMenuActions } from '../state/contextMenuSlice'

export function Screen({
  onLayout,
  children,
  scrollable = true,
  noMargins = false,
  style = undefined,
  testID = undefined,
}: {
  onLayout?: (event: LayoutChangeEvent) => void
  children: React.ReactNode | null
  scrollable?: boolean
  noMargins?: boolean
  style?: StyleProp<ViewStyle>
  testID?: string
}): JSX.Element {
  const colors = useThemeColors()
  const { theme } = useTheme()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(contextMenuActions.hideContextMenu())
  }, [dispatch])

  const styles = themedStyles[theme]

  const backgroundColorStyle = {
    backgroundColor: colors.background,
  }

  const screenViewMarginStyleSheet = useScreenMargins().styleSheet

  const screenViewStyles = noMargins ? undefined : screenViewMarginStyleSheet

  const screenTestID = testID?.startsWith('screen-')
    ? testID
    : `screen-${testID}`
  const innerScreen = (
    <View
      nativeID={screenTestID}
      style={[styles.screenView, screenViewStyles, style]}
      onLayout={onLayout}
    >
      {children}
    </View>
  )
  const screenRender = scrollable ? (
    <ScrollView style={[styles.screenScrollView, backgroundColorStyle]}>
      {innerScreen}
    </ScrollView>
  ) : (
    <View style={[styles.screenScrollView, backgroundColorStyle]}>
      {innerScreen}
    </View>
  )

  return screenRender
}
Screen.defaultProps = {
  onLayout: undefined,
}

export function Heading({
  children,
  style = undefined,
  textStyle = undefined,
}: {
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}): JSX.Element {
  const { theme } = useTheme()
  const styles = themedStyles[theme]

  return (
    <View style={[styles.headingView, style]}>
      <Text
        preset="displayXLarge"
        weight={theme === 'hadron' ? 'bold' : undefined}
        style={[styles.headingText, textStyle]}
      >
        {children}
      </Text>
    </View>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    headingText: {
      fontSize: 36,
      lineHeight: 36 * 1.25,
    },
    headingView: {
      flexDirection: 'column',
      marginBottom: 20,
    },
    screenScrollView: {
      flex: 1,
    },
    screenView: {
      flex: 1,
    },
  }),
  multiverse2022: StyleSheet.create({
    headingText: {},
    headingView: {
      flexDirection: 'column',
      marginBottom: 40,
    },
    screenScrollView: {
      flex: 1,
    },
    screenView: {
      flex: 1,
    },
  }),
}
