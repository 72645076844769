import React from 'react'
import { StatusBar } from 'expo-status-bar'
import { connect, Provider } from 'react-redux'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { useFonts } from './hooks/useFonts'
import { Loading } from './components/Loading'
import useCachedResources from './hooks/useCachedResources'
import { useTheme } from './hooks/useTheme'
import Navigation from './navigation'
import { store, useSelector } from './state/store'
import { Modal } from './modals/Modal'
import { UserAuthentication } from './components/UserAuthentication'
import { BuildVersionChecker } from './components/BuildVersionChecker'
import { Notifications } from './components/Notifications'
import { ContextMenu } from './components/ContextMenu'
import { ImpersonationStatusBar } from './components/admin/ImpersonationStatusBar'

function UnconnectedApp() {
  const isLoadingComplete = useCachedResources()
  const { colorScheme } = useTheme()
  const [fontLoaded, fontError] = useFonts()

  const userReady = useSelector((state) => state.user.ready)

  React.useEffect(() => {
    if (document) {
      // if web, defeat the Inter font's contextual ligature replacement
      const style = document.createElement('style')
      style.appendChild(
        document.createTextNode(
          'html,body,div,span { font-variant-ligatures: no-contextual !important; }'
        )
      )
      document.head.appendChild(style)
    }
  }, [])

  let appBody: React.ReactNode
  if (!(isLoadingComplete && (fontLoaded || fontError) && userReady)) {
    appBody = <Loading />
  } else {
    appBody = (
      <>
        <ImpersonationStatusBar />
        <Navigation colorScheme={colorScheme} />
        <StatusBar style={colorScheme} />
        <ContextMenu />
        <Modal />
      </>
    )
  }
  return (
    <SafeAreaProvider>
      <Notifications />
      <BuildVersionChecker />
      <UserAuthentication />
      {appBody}
    </SafeAreaProvider>
  )
}

export default function App(): JSX.Element | null {
  const ConnectedApp = connect()(UnconnectedApp)
  return (
    <Provider store={store}>
      <ConnectedApp />
    </Provider>
  )
}
