import * as React from 'react'
import { Image, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { Screen, Heading } from '../../Screen'
import { MyWalletTabNavigator } from '../../../navigation/MyWalletTabNavigator'
import { useScreenMargins } from '../../../hooks/useScreenMargins'
import { View } from '../../View'
import { useMobileLayout } from '../../../hooks/useMobileLayout'

const MAX_IMAGE_WIDTH = 800
const MAX_IMAGE_HEIGHT = 200
const BACKGROUND_IMAGE_WIDTH = 9504
const BACKGROUND_IMAGE_HEIGHT = 5367

export function MyWalletScreen(): JSX.Element {
  const [imageWidth, setImageWidth] = React.useState(0)
  const screenMargins = useScreenMargins().style
  const mobileLayout = useMobileLayout()

  const imageHeight =
    imageWidth * (BACKGROUND_IMAGE_HEIGHT / BACKGROUND_IMAGE_WIDTH)

  const verticalMarginStyle = {
    marginTop: screenMargins.marginTop,
    marginBottom: screenMargins.marginBottom,
  }
  const horizontalMarginStyle = {
    marginLeft: screenMargins.marginLeft,
    marginRight: screenMargins.marginRight,
  }

  const imageViewStyle: ViewStyle = mobileLayout
    ? {
        height: Math.min(imageHeight, MAX_IMAGE_HEIGHT),
        // backgroundColor: 'black',
        position: 'absolute',
      }
    : { height: Math.min(imageHeight, MAX_IMAGE_HEIGHT) }
  const imageStyle = mobileLayout
    ? {
        width: imageWidth,
        height: imageHeight,
        // opacity: 0.5,
      }
    : { width: imageWidth, height: imageHeight }
  const headingStyle: ViewStyle | undefined = mobileLayout
    ? {
        maxWidth: MAX_IMAGE_WIDTH,
        height: MAX_IMAGE_HEIGHT,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'flex-end',
      }
    : undefined
  const headingTextStyle: TextStyle | undefined = mobileLayout
    ? { padding: 16, color: 'white' }
    : undefined

  return (
    <Screen
      testID="screen-wallet"
      scrollable={false}
      noMargins
      style={verticalMarginStyle}
    >
      <View
        style={horizontalMarginStyle}
        onLayout={(event) => {
          setImageWidth(
            Math.min(event.nativeEvent.layout.width, MAX_IMAGE_WIDTH)
          )
        }}
      >
        <View style={[styles.backgroundImageView, imageViewStyle]}>
          <Image
            source={{
              uri: require('../../../assets/images/spaceman_beer/spacebeer.jpg'),
            }}
            style={imageStyle}
            resizeMode="cover"
          />
        </View>

        <Heading
          style={headingStyle}
          textStyle={mobileLayout ? headingTextStyle : undefined}
        >
          My Multiverse Wallet
        </Heading>
      </View>
      <MyWalletTabNavigator />
    </Screen>
  )
}

const styles = StyleSheet.create({
  backgroundImageView: {
    marginBottom: 32,
    maxHeight: 200,
    overflow: 'hidden',
  },
})
