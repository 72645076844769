import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { View } from '..'
import { MenuButton } from '../MenuButton'
import { PlanetsNavigationProps } from '../../navigation/types'
import { useEarlyAccess } from '../../hooks/useEarlyAccess'

export function CreatePlanetButton(): JSX.Element | null {
  const navigation = useNavigation<PlanetsNavigationProps>()
  const hasCreatePlanetAccess = useEarlyAccess('createPlanet')

  if (!hasCreatePlanetAccess) {
    return null
  }

  return (
    <View style={styles.buttonRow}>
      <MenuButton
        label="Create a Planet"
        onPress={() => {
          navigation.navigate('PlanetsCreate', {})
        }}
      />
    </View>
  )
}
const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
    marginBottom: 20,
  },
})
