/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addresses, forums } from '@mv/api/lib/src/schema'
import {
  initialState,
  DISPLAY_NAME_CACHE_EXPIRATION_TIME_IN_MS,
  FullPostId,
  stringifyFullPostId,
  PostSummary,
} from './cacheTypes'
import { RootState } from './state'
import { db, doc, getDoc, DocumentReference } from '../firebase/firestore'
import { logger } from '../logger'

const thunks = {
  getDisplayName: createAsyncThunk<
    string,
    { address: string; forceLoad?: boolean }
  >('cache/getDisplayName', async (args, { dispatch, getState }) => {
    let { cache } = getState() as RootState
    const displayNameCache = cache.displayNames
    const cachedDisplayName = displayNameCache[args.address]
    if (
      !args.forceLoad &&
      cachedDisplayName &&
      cachedDisplayName.lastRetrieved.getTime() >
        Date.now() - DISPLAY_NAME_CACHE_EXPIRATION_TIME_IN_MS
    ) {
      return cachedDisplayName.data
    }

    // immediately update with current value so lastRetrieved timestamp updates
    // preventing subsequent calls from triggering additional updates
    dispatch(
      cacheSlice.actions.setDisplayName({
        address: args.address,
        displayName: cachedDisplayName?.data || '',
      })
    )

    // load from firestore
    try {
      const displayDocSnapshot = await getDoc(
        doc(
          db,
          addresses.pub.publicUserProfileDocPath(args.address)
        ) as DocumentReference<addresses.pub.PublicProfileDoc>
      )
      const displayDoc = displayDocSnapshot.data()
      dispatch(
        cacheSlice.actions.setDisplayName({
          address: args.address,
          displayName: displayDoc?._?.displayName || '',
        })
      )
    } catch (e) {
      logger.error('Failed to retrieve display name and update state:', e)
      // Since state was not changed, no need to take further action - previous
      // value still stands until next cache expiration
    }
    cache = (getState() as RootState).cache
    return cache.displayNames[args.address]?.data || ''
  }),
  getPostSummary: createAsyncThunk<
    PostSummary,
    {
      fullPostId: FullPostId
      forceLoad?: boolean
    }
  >('cache/getPostSummary', async (args, { dispatch, getState }) => {
    let { cache } = getState() as RootState
    const postSummariesCache = cache.postSummaries
    const cachedPostSummary = postSummariesCache[args.fullPostId.postId]?.data
    const cachedPostAuthor = cachedPostSummary?.authorAddress
    const cachedPostText = cachedPostSummary?.text
    if (!args.forceLoad && cachedPostSummary) {
      return cachedPostSummary
    }

    // immediately update with current value so lastRetrieved timestamp updates
    // preventing subsequent calls from triggering additional updates
    dispatch(
      cacheSlice.actions.setPostSummaries([
        {
          fullPostId: args.fullPostId,
          authorAddress: cachedPostAuthor || '...',
          summaryText: cachedPostText || '',
        },
      ])
    )

    // load from firestore
    try {
      const postDocSnapshot = await getDoc(
        doc(
          db,
          forums.topicPostPath(
            args.fullPostId.planetId,
            args.fullPostId.topicId,
            args.fullPostId.postId
          )
        ) as DocumentReference<forums.PostDoc>
      )
      const postDoc = postDocSnapshot.data()
      if (postDoc) {
        dispatch(
          cacheSlice.actions.setPostSummariesFromPosts([
            {
              fullPostId: args.fullPostId,
              post: postDoc,
            },
          ])
        )
      }
    } catch (e) {
      logger.error('Failed to retrieve post  and update state:', e)
      // Since state was not changed, no need to take further action - previous
      // value still stands until next cache expiration
    }
    cache = (getState() as RootState).cache
    return (
      cache.postSummaries[stringifyFullPostId(args.fullPostId)]?.data || {
        authorAddress: '',
        text: '',
      }
    )
  }),
}

export const cacheSlice = createSlice({
  name: 'cache',
  initialState: initialState(),
  reducers: {
    setDisplayName(
      state,
      action: PayloadAction<{ address: string; displayName: string }>
    ) {
      state.displayNames[action.payload.address] = {
        data: action.payload.displayName,
        lastRetrieved: new Date(),
      }
    },
    setPostSummaries(
      state,
      action: PayloadAction<
        { fullPostId: FullPostId; authorAddress: string; summaryText: string }[]
      >
    ) {
      action.payload.forEach((payload) => {
        state.postSummaries[stringifyFullPostId(payload.fullPostId)] = {
          data: {
            authorAddress: payload.authorAddress,
            text: payload.summaryText,
          },
          lastRetrieved: new Date(),
        }
      })
    },
    setPostSummariesFromPosts(
      state,
      action: PayloadAction<
        {
          fullPostId: FullPostId
          post: forums.Post | forums.PostDoc
        }[]
      >
    ) {
      action.payload.forEach((payload) => {
        const summaryText =
          payload.post.bodyType === 'text'
            ? payload.post._.body.text.substring(0, 80)
            : ''
        state.postSummaries[stringifyFullPostId(payload.fullPostId)] = {
          data: {
            authorAddress: payload.post.authorAddress,
            text: summaryText,
          },
          lastRetrieved: new Date(),
        }
      })
    },
  },
})

export const cacheActions = {
  ...cacheSlice.actions,
  ...thunks,
}
