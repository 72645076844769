import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
  FunctionsError,
} from 'firebase/functions'
import {
  CloudFunctionName,
  CloudFunctionRequestAndResponse,
} from '@mv/api/lib/src/requests'
import { constantsExtra } from '../constants/constantsExtra'
import { app } from './init'

// Firebase Functions uses the modular v9 style to support custom domains.
// Other Firebase services should eventually be migrated as well, to take
// advantage of the resulting smaller js bundles via tree-shaking.
const functions = getFunctions(app, constantsExtra.config.cloudFunctionsDomain)

type ErrorWithCode = Error & {
  code: string | undefined
}
export function makeCallableFunction<F extends CloudFunctionName>(
  functionName: F
): (request: CloudFunctionRequestAndResponse<F>['req']) => Promise<{
  readonly data: CloudFunctionRequestAndResponse<F>['res']
}> {
  const callableFunction = httpsCallable(functions, functionName) as (
    request: CloudFunctionRequestAndResponse<F>['req']
  ) => Promise<HttpsCallableResult<CloudFunctionRequestAndResponse<F>['res']>>

  async function callableWithStackFunction(
    request: CloudFunctionRequestAndResponse<F>['req']
  ) {
    const { stack } = Error()
    try {
      return await callableFunction(request)
    } catch (_e) {
      const e = _e as ErrorWithCode
      e.stack = stack
      e.code = e.code?.replace(/^functions\//, '')
      const error = e as FunctionsError
      throw error
    }
  }

  return callableWithStackFunction
}

export type { FunctionsError } from 'firebase/functions'
