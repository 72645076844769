import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { useThemeColors } from '../../../constants/colors'
import { View } from '../../View'

import {
  DEFAULT_THUMB_WIDTH,
  DEFAULT_MARKER_WIDTH,
  DEFAULT_MARKER_HEIGHT,
} from './constants'

type Props = {
  style?: StyleProp<ViewStyle>
  thumbWidth?: number
  markerWidth?: number
  markerHeight?: number
}
export function SliderTrackMark({
  style,
  thumbWidth = DEFAULT_THUMB_WIDTH,
  markerWidth = DEFAULT_MARKER_WIDTH,
  markerHeight = DEFAULT_MARKER_HEIGHT,
}: Props): JSX.Element {
  const markerColor = useThemeColors().slider.marker

  const markerStyle = {
    borderRightColor: markerColor,
    borderRightWidth: markerWidth,
    height: markerHeight,
    width: (thumbWidth + markerWidth) / 2,
  }
  return <View style={[styles.trackMark, markerStyle, style]} />
}

const styles = StyleSheet.create({
  trackMark: {},
})
