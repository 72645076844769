export type Coordinates = {
  top?: number
  left?: number
  bottom?: number
  right?: number
}

export type ContextMenu = {
  coordinates: Coordinates
  menu: JSX.Element | null
  parentUID: string | null
}

export type ContextMenuState = {
  contextMenu: ContextMenu
  show: boolean
}

export function initialState(): ContextMenuState {
  return {
    contextMenu: {
      coordinates: {
        top: 0,
        left: 0,
      },
      menu: null,
      parentUID: null,
    },
    show: false,
  }
}
