import { useSelector } from '../state/store'
import { useIsImpersonating } from './useIsImpersonating'

export function useSuperAdminClaim(): boolean {
  const user = useSelector((state) => state.user)
  const isImpersonating = useIsImpersonating()
  if (isImpersonating) {
    return false
  }
  return !!user.user?.claims.prodDebug
}
