import * as React from 'react'
import { StyleSheet } from 'react-native'
import { TwoFactorAuth } from '@mv/api/lib/src/requests/account'
import { logger } from '../../logger'
import { SubmitButton } from './SubmitButton'
import { Item } from './Item'
import { Label, SubLabel } from './Label'
import { TextInput } from './TextInput'
import { Error as FormError } from './Error'
import { Text, View } from '..'
import { LinkButton } from '../LinkButton'
import { RootState } from '../../state/state'
import { useSelector } from '../../state/store'
import {
  ERROR_OOPS,
  ERROR_VERIFICATION_LOCK_OUT,
  ERROR_USER_NOT_LOGGED_IN,
} from '../../constants/messages'
import { FunctionsError, makeCallableFunction } from '../../firebase/functions'
import { useThemeColors } from '../../constants/colors'

type ParentFormError = {
  generic?: string
}

type Props = {
  setAuth: React.Dispatch<React.SetStateAction<TwoFactorAuth>>
  setFormError: React.Dispatch<React.SetStateAction<ParentFormError>>
  children: React.ReactNode
  formLabelPrefix?: string
  continueDisabled?: boolean
  continueLabel?: string
  errorMessage?: string
}

export function TwoFactorStep({
  setFormError,
  formLabelPrefix = '',
  continueDisabled = false,
  continueLabel = 'Continue to Verification',
  setAuth,
  children,
  errorMessage,
}: Props): JSX.Element {
  const { user, totp } = useSelector((state) => ({
    user: state.user,
    totp: !!state.user.user?.account?.twoFactor?.totp,
  }))

  const [code, setCode] = React.useState<string | null>(null)
  const [error, setError] = React.useState<string | undefined>(undefined)

  React.useEffect(() => {
    if (totp) {
      setAuth({ totpToken: !code ? '' : code })
    } else {
      setAuth({ emailCode: !code ? '' : code })
    }
  }, [code, setAuth, totp])

  React.useEffect(() => {
    setError(errorMessage)
  }, [errorMessage])

  if (!totp) {
    if (code === null) {
      return (
        <SubmitButton
          testID="two-factor-continue-verification"
          label={continueLabel}
          onPress={async () => {
            try {
              await requestVerification(user)
              setCode('')
            } catch (_e) {
              const e = _e as FunctionsError
              if (e.code === 'permission-denied') {
                setFormError({
                  generic: ERROR_VERIFICATION_LOCK_OUT,
                })
                logger.warn('Request Verification failed', e)
              } else if (e.code === 'internal') {
                setFormError({
                  generic: `There was a problem sending the verification code. Please try again later.`,
                })
                logger.error('Request Verification failed', e)
              } else {
                setFormError({ generic: ERROR_OOPS })
                logger.error('Request Verification failed', e)
              }
              setCode(null)
            }
            return false
          }}
          disabled={continueDisabled}
        />
      )
    }
    return (
      <>
        <Item testID="two-factor-verification-code">
          <View style={styles.verificationLabelView}>
            <Label>
              {formLabelPrefix && `${formLabelPrefix} `}Verification Code
            </Label>
            <ResendVerificationCodeLink
              onPress={async () => {
                setError(undefined)
                try {
                  await requestVerification(user)
                  setCode('')
                } catch (_e) {
                  const e = _e as FunctionsError
                  logger.warn('Request Verification failed:', e.code)
                  if (e.code === 'permission-denied') {
                    setError(ERROR_VERIFICATION_LOCK_OUT)
                  } else {
                    setError(ERROR_OOPS)
                  }
                }
                return false
              }}
            />
          </View>
          <TextInput
            value={code}
            onChangeText={(textValue) => {
              setCode(textValue)
            }}
          />
          <SubLabel>
            A verification code has been sent to this account&apos;s email
            address.
          </SubLabel>
          <FormError error={error} />
        </Item>
        {children}
      </>
    )
  }
  return (
    <>
      <Item testID="two-factor-authentication-code">
        <Label>
          {formLabelPrefix && `${formLabelPrefix} `}Authentication Code
        </Label>
        <TextInput
          testID="totpInput"
          value={code || ''}
          onChangeText={(textValue) => {
            setCode(textValue)
          }}
        />
        <SubLabel>Enter the code from your authentication app</SubLabel>
        <FormError error={error} />
      </Item>
      {children}
    </>
  )
}

const styles = StyleSheet.create({
  verificationLabelView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

async function requestVerification(user: RootState['user']): Promise<boolean> {
  if (!user.user) {
    throw new Error(ERROR_USER_NOT_LOGGED_IN)
  }
  const sendVerification = makeCallableFunction('auth-code')
  await sendVerification({ id: user.user.fuid })

  return true
}

function ResendVerificationCodeLink({
  onPress,
}: {
  onPress: () => Promise<boolean>
}): JSX.Element | null {
  const colors = useThemeColors()

  const [sent, setSent] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const [resetTimeout, setResetTimeout] = React.useState<NodeJS.Timeout | null>(
    null
  )

  // cleanup when unmounting
  React.useEffect(
    () => () => {
      if (resetTimeout) clearTimeout(resetTimeout)
    },
    [resetTimeout]
  )

  const textColor = {
    color: colors.medium,
  }

  if (sent) {
    return null
  }
  if (disabled) {
    return (
      <Text style={[verificationLinkStyles.linkText, textColor]}>
        Resending...
      </Text>
    )
  }
  return (
    <LinkButton
      testID="two-factor-resend-verification-code"
      label="Resend"
      onPress={async () => {
        setDisabled(true)
        const response = await onPress()
        setDisabled(false)
        setSent(true)
        setResetTimeout(
          setTimeout(() => {
            setSent(false)
            setDisabled(false)
            setResetTimeout(null)
          }, 5000)
        )
        return response
      }}
      labelStyle={verificationLinkStyles.linkText}
    />
  )
}
const verificationLinkStyles = StyleSheet.create({
  linkText: {
    fontSize: 14,
    lineHeight: 14,
  },
})

export function getTwoFactorFormError(
  code: string,
  message: string,
  auth: TwoFactorAuth
): string | undefined {
  let errorString: string | undefined
  switch (code) {
    case 'resource-exhausted':
      if (/Account locked/i.test(message)) {
        errorString = 'This account has been locked for security.'
      }
      break
    case 'permission-denied':
      if (/authentication/i.test(message)) {
        if (auth.totpToken) {
          errorString =
            'The authentication code you entered is invalid.\nPlease try again.'
        } else {
          errorString = `Verification code did not match.\nPlease try again.`
        }
      }
      break
    case 'internal':
      if (/Server terminates connection.*smtp/i.test(message)) {
        errorString = `There was a problem sending the verification code. Please try again later.`
      }
      break
    default:
      errorString = undefined
  }
  return errorString
}

export function disableTwoFactoredSubmit(auth: TwoFactorAuth): boolean {
  return (!auth.totpToken || auth.totpToken.length !== 6) && !auth.emailCode
}
