import * as React from 'react'
import { StyleSheet } from 'react-native'
import * as staking from '@mv/api/lib/src/schema/staking'
import { Text, View } from '..'

type Props = {
  earlyUnstakingFeeInBasisPoints: number | undefined
  earlyUnstakingPeriod: staking.EarlyUnstakingPeriod | undefined
}
export function MyWalletStakingSummaryEarlyUnstakeNotice({
  earlyUnstakingFeeInBasisPoints,
  earlyUnstakingPeriod,
}: Props): JSX.Element | null {
  if (earlyUnstakingFeeInBasisPoints === undefined) {
    return null
  }
  if (earlyUnstakingPeriod === undefined) {
    return null
  }

  return (
    <View style={styles.noticeView}>
      <Text preset="paragraphXLarge" weight="bold" style={[]}>
        Current early unstaking fee: {earlyUnstakingFeeInBasisPoints / 100}% AI
      </Text>
      <Text preset="paragraphXSmall" style={[]}>
        Early unstaking fee applied if unstaking before {earlyUnstakingPeriod}
      </Text>
      {earlyUnstakingFeeInBasisPoints === 0 &&
        earlyUnstakingPeriod === '1 year' && (
          <View style={styles.promoView}>
            <Text>
              During this promotional time period, there is no early unstaking
              fee.
            </Text>
          </View>
        )}
    </View>
  )
}

const styles = StyleSheet.create({
  noticeView: {
    marginBottom: 24,
  },
  promoView: {
    marginTop: 10,
  },
})
