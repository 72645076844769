/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet } from 'react-native'
import { schema, requests } from '@mv/api'
import { logger } from '../logger'
import { Modal, Heading } from './ModalElements'
import * as Form from '../components/form'
import { RootState } from '../state/state'
import { useDispatch, useSelector } from '../state/store'
import { modalsActions } from '../state/modalsSlice'
import { userActions } from '../state/userSlice'
import { makeCallableFunction, FunctionsError } from '../firebase/functions'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../constants/messages'
import { NoRefresh } from '../components/NoRefresh'
import { AccountEditProfileDisplayNamePicker } from './AccountEditProfileDisplayNamePicker'
import { useTheme } from '../hooks/useTheme'

type UserProfileVisible = schema.accounts.userProfile.UserProfileVisible
const { UserProfileVisibleMaxLengths } = schema.accounts.userProfile
const MODAL_HEADING = 'Edit Profile Details'

type FormError = {
  firstName?: string
  middleName?: string
  lastName?: string
  displayName?: string
  company?: string
  ethereumAddress?: string
  referrerEmail?: string
  notUSCheckbox?: string
  generic?: string
  verificationCode?: string
}

export function AccountEditProfileModal(): JSX.Element {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const prefillData = useSelector(
    (state) => state.modals.data
  ) as UserProfileVisible
  const { theme } = useTheme()

  const [firstName, setFirstName] = React.useState(prefillData?.firstName ?? '')
  const [middleName, setMiddleName] = React.useState(
    prefillData?.middleName ?? ''
  )
  const [lastName, setLastName] = React.useState(prefillData?.lastName ?? '')
  const [displayNameSelection, setDisplayNameSelection] = React.useState(
    prefillData?.displayNameFields ?? []
  )
  const [company, setCompany] = React.useState(prefillData?.company ?? '')
  const [ethereumAddress, setEthereumAddress] = React.useState(
    prefillData?.ethereumAddress ?? ''
  )
  const [auth, setAuth] = React.useState<requests.account.TwoFactorAuth>({})
  const [fieldLengthsOkay, setFieldLengthsOkay] = React.useState<boolean>(true)
  const [formError, setFormError] = React.useState({} as FormError)

  React.useEffect(() => {
    const fields = {
      firstName,
      middleName,
      lastName,
      company,
      ethereumAddress,
    }
    checkFieldLengths(fields, setFormError, setFieldLengthsOkay)
  }, [firstName, middleName, lastName, company, ethereumAddress])

  const styles = themedStyles[theme]
  return (
    <Modal testID="profile-edit">
      <Heading>{MODAL_HEADING}</Heading>
      <NoRefresh>
        <Form.Item testID="first-name">
          <Form.Label>First Name</Form.Label>
          <Form.TextInput
            value={firstName}
            onChangeText={(textValue) => {
              setFirstName(textValue)
            }}
          />
          <Form.Error error={formError.firstName} />
        </Form.Item>
        <Form.Item testID="middle-name">
          <Form.Label>Middle Name</Form.Label>
          <Form.TextInput
            value={middleName}
            onChangeText={(textValue) => {
              setMiddleName(textValue)
            }}
          />
          <Form.Error error={formError.middleName} />
        </Form.Item>
        <Form.Item testID="last-name">
          <Form.Label>Last Name</Form.Label>
          <Form.TextInput
            value={lastName}
            onChangeText={(textValue) => {
              setLastName(textValue)
            }}
          />
          <Form.Error error={formError.lastName} />
        </Form.Item>
        <Form.Item testID="display-name">
          <Form.Label>Name to Display to Public</Form.Label>
          <AccountEditProfileDisplayNamePicker
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            selectedValue={displayNameSelection}
            onValueChange={(itemValue) => {
              setDisplayNameSelection(itemValue)
            }}
          />
          <Form.Error error={formError.displayName} />
        </Form.Item>
        <Form.Item testID="company-name">
          <Form.Label>Company</Form.Label>
          <Form.TextInput
            value={company}
            onChangeText={(textValue) => {
              setCompany(textValue)
            }}
          />
          <Form.Error error={formError.company} />
        </Form.Item>
        <Form.Item testID="ethereum-address">
          <Form.Label>Ethereum Address</Form.Label>
          <Form.TextInput
            value={ethereumAddress}
            onChangeText={(textValue) => {
              setEthereumAddress(textValue)
            }}
          />
          <Form.Error error={formError.ethereumAddress} />
        </Form.Item>
        <Form.TwoFactorStep
          setFormError={setFormError}
          setAuth={setAuth}
          errorMessage={formError.verificationCode}
        >
          <Form.SubmitButton
            testID="save-profile"
            label="Save"
            onPress={async () => {
              setFormError({})
              const result = await submitProfile(
                firstName,
                middleName,
                lastName,
                company,
                ethereumAddress,
                displayNameSelection,
                user,
                setFormError,
                auth
              )
              if (result) {
                return async () => {
                  dispatch(
                    modalsActions.showGenericModal({
                      title: MODAL_HEADING,
                      messages: ['User profile updated successfully.'],
                      addCloseLink: true,
                    })
                  )
                  await dispatch(userActions.loadUserProfile())
                }
              }
              return false
            }}
            disabled={Form.disableTwoFactoredSubmit(auth) || !fieldLengthsOkay}
          />
        </Form.TwoFactorStep>
        <Form.Error error={formError.generic} />
      </NoRefresh>
      <Form.Alternative.Row style={styles.column}>
        <Form.Alternative.Link
          style={styles.marginBottom}
          label="Change your password"
          onPress={() => {
            dispatch(modalsActions.showChangePasswordModal())
          }}
        />
        <Form.Alternative.Link
          label={`${
            user.user?.account?.twoFactor?.totp ? 'Update' : 'Set up'
          } 2-factor auth with authentication app`}
          onPress={() => {
            dispatch(modalsActions.showTotpSetupModal())
          }}
        />
      </Form.Alternative.Row>
    </Modal>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    column: {
      flexDirection: 'column',
    },
    marginBottom: {
      marginBottom: 10,
    },
  }),
  multiverse2022: StyleSheet.create({
    column: {
      alignItems: 'center',
      flexDirection: 'column',
    },
    marginBottom: {
      marginBottom: 16,
    },
  }),
}

async function submitProfile(
  firstName: string,
  middleName: string,
  lastName: string,
  company: string,
  ethereumAddress: string,
  displayNameFields: requests.account.ProfileEditRequest['displayNameFields'],
  user: RootState['user'],
  setFormError: React.Dispatch<React.SetStateAction<FormError>>,
  auth: requests.account.TwoFactorAuth
): Promise<boolean> {
  if (!user.user) {
    setFormError({
      generic: ERROR_USER_NOT_LOGGED_IN,
    })
    return false
  }

  const profile: Partial<UserProfileVisible> = {
    firstName,
    middleName,
    lastName,
    company,
    ethereumAddress,
  }

  const profileEdit = makeCallableFunction('account-profileEdit')

  try {
    await profileEdit({
      id: user.user.fuid,
      profile,
      displayNameFields,
      auth,
    })
  } catch (_e) {
    const e = _e as FunctionsError
    const errors: FormError = {}
    errors.verificationCode = Form.getTwoFactorFormError(
      e.code,
      e.message,
      auth
    )
    if (!errors.verificationCode) {
      logger.error('Failed to edit profile', e)
      errors.generic = ERROR_OOPS
    } else {
      logger.warn('Failed to edit profile', e)
    }
    setFormError(errors)
    return false
  }
  return true
}

function checkFieldLengths(
  fields: {
    firstName: string
    middleName: string
    lastName: string
    company: string
    ethereumAddress: string
  },
  setFormError: React.Dispatch<React.SetStateAction<FormError>>,
  setFieldLengthsOkay: React.Dispatch<React.SetStateAction<boolean>>
): void {
  const prettyName = {
    firstName: 'first name',
    middleName: 'middle name',
    lastName: 'last name',
    company: 'company name',
    ethereumAddress: 'address',
  }
  const newError: FormError = {}
  let okay = true
  Object.entries(fields).forEach(([k, value]) => {
    const key = k as keyof typeof fields
    if (value.length > UserProfileVisibleMaxLengths[key]) {
      newError[key] = `Please enter a shorter ${prettyName[key] || key}`
      okay = false
    }
  })
  setFormError(newError)
  setFieldLengthsOkay(okay)
}
