/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { View, Text } from '..'
import { testIdToNativeId } from '../../helpers/testId'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { useTheme } from '../../hooks/useTheme'
import { LinkButton } from '../LinkButton'

export function Row({
  style,
  children,
  testID,
}: {
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
  testID?: string
}): JSX.Element {
  const isMobile = useMobileLayout()
  const { theme } = useTheme()
  const styles = themedStyles[theme]

  return (
    <View
      nativeID={testIdToNativeId(testID, 'form-alternative')}
      testID={testID}
      style={[
        isMobile ? styles.alternativeRowMobile : styles.alternativeRow,
        style,
      ]}
    >
      {children}
    </View>
  )
}
Row.defaultProps = {
  style: undefined,
  children: undefined,
}

export function Link({
  textLabel,
  label,
  onPress,
  style,
  textLabelStyle,
  labelStyle,
  testID,
}: {
  textLabel?: string
  label: string
  onPress: () => void
  style?: StyleProp<ViewStyle>
  textLabelStyle?: StyleProp<TextStyle>
  labelStyle?: StyleProp<TextStyle>
  testID?: string
}): JSX.Element {
  const isMobile = useMobileLayout()
  const { theme } = useTheme()
  const styles = themedStyles[theme]
  return (
    <View
      style={[
        isMobile ? styles.alternativeRowViewMobile : styles.alternativeRowView,
        style,
      ]}
    >
      {!!textLabel && (
        <Text
          preset="paragraph"
          style={[styles.alternativeRowTextLabel, textLabelStyle]}
        >
          {textLabel}
        </Text>
      )}
      <LinkButton
        testID={testID}
        label={label}
        labelPreset="paragraph"
        labelStyle={[styles.alternativeRowText, labelStyle]}
        onPress={() => {
          onPress()
        }}
      />
    </View>
  )
}
Link.defaultProps = {
  textLabel: undefined,
  style: undefined,
  textLabelStyle: undefined,
  labelStyle: undefined,
}

export const themedStyles = {
  hadron: StyleSheet.create({
    alternativeRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
      marginTop: 20,
    },
    alternativeRowMobile: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
      marginTop: 20,
    },
    alternativeRowText: {
      fontSize: 16,
    },
    alternativeRowTextLabel: {
      fontSize: 16,
      marginRight: 6,
    },
    alternativeRowView: {
      flexDirection: 'row',
    },
    alternativeRowViewMobile: {
      flexDirection: 'row',
    },
  }),
  multiverse2022: StyleSheet.create({
    alternativeRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 0,
      marginTop: 40,
    },
    alternativeRowMobile: {
      flexDirection: 'column',
      marginBottom: 10,
      marginTop: 20,
    },
    alternativeRowText: {
      textAlign: 'center',
    },
    alternativeRowTextLabel: {
      marginRight: 6,
    },
    alternativeRowView: {
      flexDirection: 'row',
    },
    alternativeRowViewMobile: {
      flexDirection: 'row',
      marginBottom: 10,
    },
  }),
}

export default Row
