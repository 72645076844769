import * as React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import {
  UnlockSchedule,
  Schedule as ScheduleDoc,
  locksCollectionPath,
} from '@mv/api/lib/src/schema/accounts/locks'
import { balance } from '@mv/api/lib/src/types'
import { Text, View } from '..'
import { useSelector } from '../../state/store'
import { humanizeBalance } from '../../helpers'
import { Schedule } from './types'
import {
  db,
  collection,
  CollectionReference,
  query,
  where,
  onSnapshot,
} from '../../firebase/firestore'
import { MyWalletBalanceLockedSchedules } from './MyWalletBalanceLockedSchedules'
import { LinkButton } from '../LinkButton'
import { useThemeColors } from '../../constants/colors'
import { AnimateDownFadeIn } from '../animations/AnimateDownFadeIn'

export function MyWalletBalanceLocked(): JSX.Element | null {
  const isMounted = React.useRef(true)
  const user = useSelector((state) => state.user)
  const lockedTokenColors = useThemeColors().lockedTokens
  const tableCellTextColorStyle = {
    color: lockedTokenColors.summaryColor,
  }
  const borderColorStyle = {
    borderColor: lockedTokenColors.borderColor,
  }
  const headingColorStyle = {
    color: lockedTokenColors.headingColor,
  }
  const [userAiLockSchedules, setUserAiLockSchedules] = React.useState<
    Schedule[] | null
  >(null)
  const [showSchedule, setShowSchedule] = React.useState<boolean>(true)

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    if (user.user?.fuid) {
      const locksQuery = query(
        collection(
          db,
          locksCollectionPath(user.user.fuid)
        ) as CollectionReference<ScheduleDoc>,
        where('token', '==', 'AI')
      )
      return onSnapshot(locksQuery, (snap) => {
        if (isMounted.current) {
          const lockSchedule = snap.docs
            .map((doc) => {
              const scheduleDoc = doc.data()
              if (!scheduleDoc) {
                return scheduleDoc
              }
              return { ...scheduleDoc, key: doc.id }
            })
            .filter((schedule) => schedule.nextUnlock)

          setUserAiLockSchedules(lockSchedule)
        }
      })
    }
    return () => {}
  }, [user.user?.fuid])

  if (!(user.user?.balances?.AI && user.user?.account)) {
    return null
  }

  const balanceAI = user.user.balances.AI

  if (!userAiLockSchedules || userAiLockSchedules.length === 0) {
    return null
  }

  let nextUnlockSchedule: Schedule | undefined
  userAiLockSchedules.forEach((schedule) => {
    if (
      !nextUnlockSchedule ||
      (schedule.nextUnlock &&
        nextUnlockSchedule.nextUnlock &&
        schedule.nextUnlock.toMillis() <
          nextUnlockSchedule.nextUnlock.toMillis())
    )
      nextUnlockSchedule = schedule
  })
  if (!nextUnlockSchedule) {
    return null
  }

  let nextUnlockInSchedule: UnlockSchedule | undefined
  nextUnlockSchedule._.unlocks.forEach((unlock) => {
    if (
      !nextUnlockInSchedule ||
      (unlock.next &&
        nextUnlockInSchedule.next &&
        unlock.next.toMillis() < nextUnlockInSchedule.next.toMillis())
    ) {
      nextUnlockInSchedule = unlock
    }
  })

  if (!nextUnlockInSchedule) {
    return null
  }

  const rendered = (
    <ScrollView horizontal style={styles.scrollView}>
      <View style={styles.container}>
        <View style={[styles.tableHeader]}>
          <View style={[styles.lockMainRowCellToken]}>
            <Text
              style={[styles.tableHeaderText, headingColorStyle]}
              weight="semiBold"
            >
              Token
            </Text>
          </View>
          <View style={[styles.lockMainRowCellAmount]}>
            <Text
              style={[styles.tableHeaderText, headingColorStyle]}
              weight="semiBold"
            >
              Available
            </Text>
          </View>
          <View style={[styles.lockMainRowCellAmount]}>
            <Text
              style={[styles.tableHeaderText, headingColorStyle]}
              weight="semiBold"
            >
              Locked
            </Text>
          </View>
          <View style={[styles.lockMainRowCellAmount]}>
            <Text
              style={[styles.tableHeaderText, headingColorStyle]}
              weight="semiBold"
            >
              Total
            </Text>
          </View>
          <View style={[styles.lockMainRowCellShowDetails]} />
        </View>
        <View style={[styles.lockRectangle, borderColorStyle]}>
          <View style={styles.lockMainRow}>
            <View style={[styles.lockMainRowCellToken]}>
              <Text style={[styles.tableCellText, tableCellTextColorStyle]}>
                AI
              </Text>
            </View>
            <View style={[styles.lockMainRowCellAmount]}>
              <Text style={[styles.tableCellText, tableCellTextColorStyle]}>
                {humanizeBalance(balanceAI._.available, 2, undefined, 2)}
                &nbsp;AI
              </Text>
            </View>
            <View style={[styles.lockMainRowCellAmount]}>
              <Text style={[styles.tableCellText, tableCellTextColorStyle]}>
                {humanizeBalance(balanceAI._.locked, 2, undefined, 2)}
                &nbsp;AI
              </Text>
            </View>
            <View style={[styles.lockMainRowCellAmount]}>
              <Text style={[styles.tableCellText, tableCellTextColorStyle]}>
                {humanizeBalance(balance.total(balanceAI._), 2, undefined, 2)}
                &nbsp;AI
              </Text>
            </View>
            <View style={[styles.lockMainRowCellShowDetails]}>
              {balanceAI._.locked !== '0' && (
                <>
                  <View style={[styles.lockMainRowCellShowDetailsSpacer]}>
                    <LinkButton
                      label={showSchedule ? 'Hide Details' : 'Show Details'}
                      labelStyle={styles.tableCellText}
                      onPress={() => {
                        setShowSchedule(!showSchedule)
                      }}
                    />
                  </View>
                </>
              )}
            </View>
          </View>
          <AnimateDownFadeIn>
            {showSchedule ? (
              <MyWalletBalanceLockedSchedules schedules={userAiLockSchedules} />
            ) : null}
          </AnimateDownFadeIn>
        </View>
      </View>
    </ScrollView>
  )

  return rendered
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    maxWidth: 800,
    minWidth: 500,
  },
  lockMainRow: {
    flexDirection: 'row',
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 16,
  },
  lockMainRowCellAmount: {
    flex: 2,
    flexDirection: 'row',
  },
  lockMainRowCellShowDetails: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  lockMainRowCellShowDetailsSpacer: {
    paddingRight: 8,
  },
  lockMainRowCellToken: { flex: 1 },
  lockRectangle: {
    borderRadius: 25,
    borderWidth: 1,
    overflow: 'hidden',
  },
  scrollView: { flexDirection: 'column', flex: 1, marginBottom: 32 },
  tableCellText: {
    fontSize: 12,
    lineHeight: 12,
  },
  tableHeader: {
    flexDirection: 'row',
    marginBottom: 8,
    paddingLeft: 32,
    paddingRight: 32,
  },
  tableHeaderText: {
    fontSize: 11,
    textTransform: 'uppercase',
  },
  tableSubTable: {
    flex: 1,
  },
})
