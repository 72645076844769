import * as React from 'react'
import { StyleProp, TextStyle } from 'react-native'
import { total } from '@mv/api/lib/src/types/balance'
import { BalancesCollection } from '@mv/api/lib/src/schema/accounts/balances'
import { balance } from '@mv/api/lib/src/types'
import { View, Text, TextProps } from '..'
import { TextPreset } from '../Text'
import { humanizeBalance } from '../../helpers'
import { useSelector } from '../../state/store'

export function LoggedInNavBalancesDisplay({
  balances,
  labelStyle,
  weight,
  titleWeight,
  preset,
  verticalSpaceBetween = 0,
}: {
  balances: BalancesCollection | undefined
  labelStyle?: StyleProp<TextStyle>
  weight?: TextProps['weight']
  titleWeight?: TextProps['weight']
  preset?: TextPreset
  verticalSpaceBetween?: number
}): JSX.Element | null {
  const user = useSelector((state) => state.user)

  if (!balances || !user.user) {
    return null
  }
  const { AI, ...otherBalances } = balances
  if (!AI) {
    return null
  }

  const spaceStyle = verticalSpaceBetween
    ? {
        marginTop: verticalSpaceBetween,
      }
    : undefined

  const totalAIBalance = balance.total(AI._)
  const decimalPlaces = Math.max(Math.min(30 - totalAIBalance.length, 5), 0)
  const renderedBalances = [
    <Text
      key="AI"
      weight={weight}
      style={[labelStyle, spaceStyle]}
      preset={preset}
    >
      AI: {humanizeBalance(totalAIBalance, decimalPlaces)}
    </Text>,
  ]
  Object.entries(otherBalances).forEach(([symbol, symbolBalance]) => {
    if (symbolBalance && BigInt(symbolBalance._.available) > 0n) {
      const totalBalance = total(symbolBalance._)
      if (BigInt(totalBalance) > 0n) {
        renderedBalances.push(
          <Text key="{symbol}" style={[labelStyle, spaceStyle]} preset={preset}>
            {symbol}: {humanizeBalance(totalBalance)}
          </Text>
        )
      }
    }
  })
  return (
    <View>
      <Text preset={preset} weight={titleWeight || weight} style={labelStyle}>
        Balance{renderedBalances.length > 1 ? 's' : ''}:
      </Text>
      {renderedBalances}
    </View>
  )
}
