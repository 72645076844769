/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet } from 'react-native'
import { View } from '..'
import { useTheme } from '../../hooks/useTheme'
import { ExternalLinkButton } from './ExternalLinkButton'
import { CustomDrawerContentProps } from './types'

export function ExternalLinksDrawerItems(
  props: CustomDrawerContentProps
): JSX.Element {
  const { theme } = useTheme()
  const { inactiveTintColor, navColors } = props

  const styles = themedStyles[theme]

  const navItemColors = navColors.item
  const navHorizontalRuleColorStyle = {
    borderTopColor: navColors.horizontalRule,
    borderBottomColor: navColors.horizontalRule,
  }
  return (
    <View style={[styles.externalLinksView, navHorizontalRuleColorStyle]}>
      <ExternalLinkButton
        testID="discord"
        color={inactiveTintColor || navItemColors.inactiveTintColor}
        label="Discord"
        labelStyle={styles.labelExternalLink}
        labelWeight="bold"
        style={styles.drawerItemExternalLink}
        url="https://discord.gg/8pzqarSQtZ"
      />
      <ExternalLinkButton
        testID="twitter"
        color={inactiveTintColor || navItemColors.inactiveTintColor}
        label="Twitter"
        labelStyle={styles.labelExternalLink}
        labelWeight="bold"
        style={styles.drawerItemExternalLink}
        url="https://twitter.com/multiverse_labs"
      />
      <ExternalLinkButton
        testID="reddit"
        color={inactiveTintColor || navItemColors.inactiveTintColor}
        label="Reddit"
        labelStyle={styles.labelExternalLink}
        labelWeight="bold"
        style={styles.drawerItemExternalLink}
        url="https://www.reddit.com/r/Multiverselabs/"
      />
    </View>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    drawerItemExternalLink: {
      margin: 0,
      padding: 0,
    },
    externalLinksView: {
      borderBottomWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 10,
      paddingTop: 10,
    },
    labelExternalLink: {
      fontSize: 14,
      lineHeight: 14 * 1.5,
    },
  }),
  multiverse2022: StyleSheet.create({
    drawerItemExternalLink: {
      margin: 0,
      padding: 0,
      paddingLeft: 16,
    },
    externalLinksView: {
      borderBottomWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 10,
      paddingTop: 10,
    },
    labelExternalLink: {
      fontSize: 14,
      lineHeight: 14 * 1.5,
    },
  }),
}
