import * as React from 'react'
import { StyleSheet } from 'react-native'
import { requests } from '@mv/api'
import { token } from '@mv/api/lib/src/types'
import { Account, accountPath } from '@mv/api/lib/src/schema/accounts'
import { Prices, Withdrawal, pricesFor } from '@mv/api/lib/src/schema/prices'
import { logger } from '../logger'
import { Modal, Heading } from './ModalElements'
import * as Form from '../components/form'
import { RootState } from '../state/state'
import { useDispatch, useSelector } from '../state/store'
import { modalsActions } from '../state/modalsSlice'
import { db, doc, DocumentReference, getDoc } from '../firebase/firestore'
import { logEvent, analytics } from '../firebase/analytics'
import { makeCallableFunction, FunctionsError } from '../firebase/functions'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../constants/messages'
import { NoRefresh } from '../components/NoRefresh'

import { LinkButton } from '../components/LinkButton'
import { Text, View } from '../components'
import { useThemeColors } from '../constants/colors'

const GAS_PRICE_INVALID_TIME_MS = 1000 * 60 * 4 // 4 minutes

type GasPrice = {
  AI: string
  USD: string
}

type FormError = {
  externalAddress?: string
  amount?: string
  verificationCode?: string
  generic?: string
}

type Props = {
  gasPriceInvalidTimeMs?: number
}
export function WithdrawalModal({
  gasPriceInvalidTimeMs = GAS_PRICE_INVALID_TIME_MS,
}: Props): JSX.Element {
  const isMounted = React.useRef(true)

  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const colors = useThemeColors()

  const [externalAddress, setExternalAddress] = React.useState('')
  const [amountFieldValue, setAmountFieldValue] = React.useState('')
  const [gas, setGas] = React.useState<null | GasPrice>(null)
  const [gasValid, setGasValid] = React.useState<boolean>(false)
  const [formError, setFormError] = React.useState({} as FormError)
  const [continueDisabled, setContinueDisabled] = React.useState(true)
  const [withdrawalDisabled, setWithdrawalDisabled] = React.useState(true)
  const [auth, setAuth] = React.useState<requests.account.TwoFactorAuth>({})

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    // fetches initial gas price and whenever gas is reset to null
    if (gas === null) {
      setGasValid(false)
      getCurrentGasPrice()
        .then((price) => {
          if (isMounted.current) {
            setGas(price)
            setGasValid(true)
          }
        })
        .catch((e) => {
          logger.warn('unable to get current gas price: ', e)
        })
    }
  }, [gas])

  React.useEffect(() => {
    // when gas price is changed to valid, start the timeout countdown
    let timeout: NodeJS.Timeout
    if (gasValid) {
      timeout = setTimeout(() => {
        if (isMounted.current) {
          setGasValid(false)
        }
      }, gasPriceInvalidTimeMs)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [gasValid, gasPriceInvalidTimeMs])

  React.useEffect(() => {
    const validAmount = checkValidity(amountFieldValue)
    if (externalAddress && validAmount && gasValid) {
      setContinueDisabled(false)
      setWithdrawalDisabled(false)
    } else {
      setContinueDisabled(true)
      setWithdrawalDisabled(true)
    }
  }, [externalAddress, amountFieldValue, gasValid])

  async function getNonce(): Promise<number> {
    if (!user.user) {
      throw new Error('User logged out')
    }
    const userDoc = await getDoc(
      doc(db, accountPath(user.user.fuid)) as DocumentReference<Account>
    ).catch(() => {
      throw new Error('Failed to load user account information')
    })
    const currentUserAccount = userDoc.data()
    if (!currentUserAccount) {
      throw new Error('Failed to load user account information')
    }
    return currentUserAccount._.nonce
  }

  async function getCurrentGasPrice(): Promise<GasPrice> {
    const priceDoc = await getDoc(
      doc(db, pricesFor(Withdrawal)) as DocumentReference<Prices>
    ).catch(() => {
      throw new Error('Failed to retrieve gas price')
    })
    const currentWithdrawalPrices = priceDoc.data()
    if (!currentWithdrawalPrices) {
      throw new Error('Failed to retrieve gas price')
    }
    return {
      AI: currentWithdrawalPrices._[token.AI][0].value,
      USD: currentWithdrawalPrices._.usd[0].value,
    }
  }

  const validAmount = checkValidity(amountFieldValue)
  let transactionFeeMessage = (
    <View>
      <Form.LabelText testID="gasLabel">
        Ethereum Gas: Calculating...
      </Form.LabelText>
      <Form.LabelText style={styles.totalText}>&nbsp;</Form.LabelText>
    </View>
  )
  if (gas) {
    if (gasValid) {
      const roundedUpGasInAI = roundUpFee(gas.AI, 0)
      transactionFeeMessage = (
        <View>
          <Form.LabelText testID="gasLabel">
            Ethereum Gas: {roundedUpGasInAI}&nbsp;AI (approx. $
            {roundUpFee(gas.USD, 2)})
          </Form.LabelText>
          {validAmount ? (
            <Form.LabelText style={styles.totalText}>
              Total: {parseFloat(validAmount) + roundedUpGasInAI}&nbsp;AI
            </Form.LabelText>
          ) : (
            <Form.LabelText style={styles.totalText}>&nbsp;</Form.LabelText>
          )}
        </View>
      )
    } else {
      transactionFeeMessage = (
        <View>
          <View style={styles.feeView}>
            <Form.LabelText>Ethereum Gas: </Form.LabelText>
            <LinkButton
              labelStyle={styles.feeRefreshLink}
              label="Refresh"
              onPress={() => {
                setGas(null)
              }}
            />
          </View>
          <Form.LabelText style={styles.totalText}>&nbsp;</Form.LabelText>
        </View>
      )
    }
  }

  const warningColorStyles = colors.textError
  return (
    <Modal testID="wallet-withdraw">
      <Heading>Withdraw</Heading>
      <Text weight="bold" style={[styles.warning, warningColorStyles]}>
        Entering an Ethereum address that is invalid or does not accept AI may
        result in lost tokens.
      </Text>
      <NoRefresh>
        <Form.Item>
          <Form.Label>Ethereum Address</Form.Label>
          <Form.TextInput
            testID="addressInput"
            value={externalAddress}
            onChangeText={(textValue) => {
              setExternalAddress(textValue)
            }}
          />
          <Form.Error error={formError.externalAddress} />
        </Form.Item>
        <Form.Item>
          <Form.Label>AI Amount</Form.Label>
          <Form.TextInput
            testID="amountInput"
            value={amountFieldValue}
            onChangeText={(textValue) => {
              setAmountFieldValue(textValue)
            }}
          />
          <Form.Error error={formError.amount} />
        </Form.Item>
        <Form.Item>
          <Form.LabelView>{transactionFeeMessage}</Form.LabelView>
        </Form.Item>
        <Form.TwoFactorStep
          setFormError={setFormError}
          setAuth={setAuth}
          continueDisabled={continueDisabled}
          errorMessage={formError.verificationCode}
        >
          <Form.SubmitButton
            testID="submitButton"
            label={validAmount ? `Withdraw ${validAmount} AI` : 'Withdraw'}
            onPress={async () => {
              setFormError({})
              const nonce = await getNonce().catch((e) => {
                logger.warn('Failed to retrieve nonce', e)
                setFormError({
                  generic: ERROR_OOPS,
                })
              })
              if (nonce != null && !!gas) {
                const result = await submitWithdrawal(
                  user,
                  externalAddress,
                  validAmount,
                  gas.AI,
                  nonce,
                  setFormError,
                  auth
                )
                if (result) {
                  return () => {
                    dispatch(
                      modalsActions.showGenericModal({
                        title: 'Withdrawal Requested',
                        messages: [
                          `Multiverse has received the request to withdraw ${validAmount} AI to Ethereum Address ${externalAddress}.`,
                          `Your withdrawal will be processed shortly.`,
                          `Transfers will be completed after 35 Ethereum block confirmations, which can take up to approximately 10 minutes.`,
                        ],
                      })
                    )
                    logEvent(analytics(), 'transfer_out', {
                      amount: validAmount,
                      to: externalAddress,
                      from: user.user?.fuid,
                    })
                  }
                }
              }
              return false
            }}
            disabled={withdrawalDisabled || Form.disableTwoFactoredSubmit(auth)}
          />
        </Form.TwoFactorStep>
        <Form.Error error={formError.generic} />
      </NoRefresh>
    </Modal>
  )
}
const styles = StyleSheet.create({
  feeRefreshLink: {
    fontSize: 14,
    lineHeight: 14,
  },
  feeView: {
    flexDirection: 'row',
  },
  totalText: {
    marginTop: 4,
  },
  warning: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
})

async function submitWithdrawal(
  user: RootState['user'],
  externalAddress: string,
  amount: string,
  gas: string,
  nonce: number,
  setFormError: React.Dispatch<React.SetStateAction<FormError>>,
  auth: requests.account.TwoFactorAuth
): Promise<boolean> {
  if (!user.user) {
    setFormError({
      generic: ERROR_USER_NOT_LOGGED_IN,
    })
    return false
  }

  const withdraw = makeCallableFunction('token-withdraw')
  try {
    await withdraw({
      id: user.user.fuid,
      to: externalAddress,
      gas,
      amount,
      nonce,
      auth,
      maxFee: '0', // TODO: Plumb from user input.
    })
    return true
  } catch (_e) {
    const e = _e as FunctionsError
    const errors: FormError = {}
    errors.verificationCode = Form.getTwoFactorFormError(
      e.code,
      e.message,
      auth
    )
    if (!errors.verificationCode) {
      switch (e.code) {
        case 'already-exists':
          logger.error('withdraw error', e)
          errors.generic = 'This transaction may have already been processed.'
          break
        case 'invalid-argument':
          logger.warn('withdraw error', e)
          if (/token/i.test(e.message)) {
            errors.amount = 'Invalid amount entered.'
          } else if (/address/i.test(e.message)) {
            errors.externalAddress = e.message
          } else {
            errors.generic = ERROR_OOPS
          }
          break
        case 'failed-precondition':
          logger.warn('withdraw error', e)
          errors.amount = 'Insufficient funds. Please enter a smaller amount.'
          break
        default:
          logger.error('withdraw error', e)
          errors.generic = ERROR_OOPS
      }
    }
    setFormError(errors)
    return false
  }
}

function roundUpFee(feeString: string, decimals: number): number {
  if (!/\./.test(feeString)) {
    return parseFloat(feeString)
  }
  if (parseFloat(feeString) >= 1) {
    return Math.ceil(parseFloat(feeString) * 10 ** decimals) / 10 ** decimals
  }
  const fractionalString = feeString.match(
    RegExp(`\\.(0*[1-9][0-9]{${decimals + 1}})`)
  )?.[1]
  if (fractionalString) {
    return (
      Math.ceil(parseFloat(fractionalString) / 10) /
      10 ** (fractionalString.length - 1)
    )
  }
  return parseFloat(feeString)
}

function checkValidity(amountString: string): string {
  let trimmedAmountString = amountString.trim()
  if (!/^[0-9.,]+$/.test(trimmedAmountString)) {
    return ''
  }
  if (trimmedAmountString.includes('.')) {
    if (!/^[0-9,]*\.[0-9]*$/.test(trimmedAmountString)) {
      return ''
    }
  }

  trimmedAmountString = trimmedAmountString
    .replace(/,/g, '') // remove commas
    .replace(/^0+/, '') // remove leading zeroes
    .replace(/^\./, '0.') // add 0 if decimal point at beginning
    .replace(/(\.[0-9]*?)0+$/, '$1') // remove all trailing 0 if decimal point
    .replace(/\.$/, '') // remove decimal point at end

  return trimmedAmountString
}
