import * as React from 'react'
import { StyleSheet } from 'react-native'
import { View } from '.'
import { useSelector } from '../state/store'

export function ContextMenu(): JSX.Element | null {
  const contextMenuState = useSelector((state) => state.contextMenu)

  if (!contextMenuState.show || !contextMenuState.contextMenu.menu) {
    return null
  }

  const locationStyle = {
    ...contextMenuState.contextMenu.coordinates,
  }

  return (
    <View style={[styles.container, locationStyle]}>
      {contextMenuState.contextMenu.menu}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
  },
})
