/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet } from 'react-native'
import { View } from '..'
import { ExternalLinkButton } from './ExternalLinkButton'
import { useThemeColors } from '../../constants/colors'
import { useTheme } from '../../hooks/useTheme'

export function NavFooterLinks(): JSX.Element {
  const { nav: navColors } = useThemeColors()
  const { theme } = useTheme()

  const navItemColors = navColors.footerItem
  const navHorizontalRuleColorStyle = {
    borderTopColor: navColors.horizontalRule,
  }

  const styles = themedStyles[theme]

  if (theme === 'hadron') {
    return (
      <View style={[styles.container, navHorizontalRuleColorStyle]}>
        <ExternalLinkButton
          testID="terms"
          color={navItemColors.activeTintColor}
          label="Terms of Use"
          labelStyle={styles.linkLabel}
          labelWeight="semiBold"
          style={styles.link}
          containerStyle={styles.linkContainer}
          url="https://multiverse.ai/terms-of-use"
        />
        <ExternalLinkButton
          testID="privacy"
          color={navItemColors.activeTintColor}
          label="Privacy Policy"
          labelStyle={styles.linkLabel}
          labelWeight="semiBold"
          style={styles.link}
          containerStyle={styles.linkContainer}
          url="https://multiverse.ai/privacy-policy"
        />
      </View>
    )
  }

  return (
    <View style={[styles.container, navHorizontalRuleColorStyle]}>
      <ExternalLinkButton
        testID="terms"
        color={navItemColors.activeTintColor}
        label="Terms of Use"
        labelPreset="paragraphXSmall"
        style={styles.link}
        containerStyle={styles.linkContainer}
        url="https://multiverse.ai/terms-of-use"
      />
      <ExternalLinkButton
        testID="privacy"
        color={navItemColors.activeTintColor}
        label="Privacy Policy"
        labelPreset="paragraphXSmall"
        style={styles.link}
        containerStyle={styles.linkContainer}
        url="https://multiverse.ai/privacy-policy"
      />
    </View>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
    },
    link: {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
    linkContainer: {
      marginLeft: 0,
      marginRight: 0,
    },
    linkLabel: {
      fontSize: 11,
      lineHeight: 12 * 1.5,
    },
  }),
  multiverse2022: StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 8,
    },
    link: {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
    linkContainer: {
      marginLeft: 0,
      marginRight: 0,
    },
    linkLabel: {
      fontSize: 11,
      lineHeight: 12 * 1.5,
    },
  }),
}
