import * as React from 'react'
import { Impersonation } from '../Impersonation'
import { Screen, Heading } from '../../Screen'

export function AdminScreen(): JSX.Element {
  return (
    <Screen testID="screen-admin">
      <Heading>Admin Tools</Heading>
      <Impersonation />
    </Screen>
  )
}
