import { logger as reactNativeLogs, consoleTransport } from 'react-native-logs'
import StackdriverErrorReporter from 'stackdriver-errors-js'
import Constants from 'expo-constants'
import { logEvent, analytics } from '../firebase/analytics'
import { Build } from '../build'

const DISABLE_LOGGING_IN_DEV = true

// Having a StackdriverErrorReporter will catch any uncaught promise errors
// (which may be undesirable to send when developing on localhost)
export const stackdriver =
  !Constants.debugMode || !DISABLE_LOGGING_IN_DEV
    ? new StackdriverErrorReporter()
    : undefined

stackdriver?.start({
  key: Constants.manifest.extra?.firebaseConfig.apiKey,
  projectId: Constants.manifest.extra?.firebaseConfig.projectId,
  service: 'portal',
  version: Build.currentVersion(),
})

const { createLogger } = reactNativeLogs
type ConfigLoggerType = Parameters<typeof createLogger>[0]
type TransportFunctionType = NonNullable<ConfigLoggerType>['transport']

const googleAnalyticsTransport: TransportFunctionType = (props) => {
  if (props.level.severity === 3) {
    logEvent(analytics(), 'exception', {
      description: props.rawMsg,
      fatal: false,
    })
  } else if (props.level.severity === 2) {
    logEvent(analytics(), 'warning', {
      description: props.rawMsg,
    })
  }
}

const stackdriverTransport: TransportFunctionType = async (props) => {
  if (stackdriver && props.level.severity === 3) {
    if (Array.isArray(props.rawMsg)) {
      const error = props.rawMsg.find(
        (elem) => elem instanceof Error || (elem.message && elem.stack)
      )
      if (error) {
        error.message = props.msg
        await stackdriver.report(error)
        return
      }
    } else if (props.rawMsg instanceof Error) {
      const error = props.rawMsg
      error.message = props.msg
      await stackdriver.report(error)
      return
    }
    // should never happen since all errors should have an Error as one of the arguments
    await stackdriver.report(props.msg)
  }
}

const defaultConfig: ConfigLoggerType = {
  severity: 'debug',
  transport: (props) => {
    consoleTransport(props)
    if (!Constants.debugMode || !DISABLE_LOGGING_IN_DEV) {
      stackdriverTransport(props)
      googleAnalyticsTransport(props)
    }
  },
  transportOptions: {
    colors: 'web', // custom option that color consoleTransport logs
  },
  levels: {
    log: 0,
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  async: true,
  dateFormat: 'time',
  printLevel: true,
  printDate: true,
  enabled: true,
}

const actualLogger = createLogger(defaultConfig)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logger: Record<string, (...args: any[]) => void> = {}

if (defaultConfig.levels) {
  Object.keys(defaultConfig.levels).forEach((level) => {
    if (defaultConfig.levels?.[level] === 3) {
      // For better stack traces, always attach an Error to error level logs
      logger[level] = (...args) => {
        if (!args.find((elem) => elem instanceof Error)) {
          const localError = new Error()
          actualLogger.error(...args, localError)
          return
        }
        actualLogger.error(...args)
      }
    } else {
      logger[level] = (...args) => {
        actualLogger[level](...args)
      }
    }
  })
}
