import * as React from 'react'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Post, PostId, TopicId } from '@mv/api/lib/src/schema/forums'
import { planet } from '@mv/api/lib/src/types'
import { logger } from '../../logger'
import { Button } from '../Button'
import { useSelector } from '../../state/store'
import { FunctionsError, makeCallableFunction } from '../../firebase/functions'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../../constants/messages'
import { RootState } from '../../state/state'
import { useThemeColors } from '../../constants/colors'

type Props = {
  post: Post
  handleError: (errorMessage: string | null) => void
  labelStyle: StyleProp<TextStyle>
  style: StyleProp<ViewStyle>
}
export function PlanetForumPostActionsDeleteButton({
  post,
  handleError,
  labelStyle,
  style,
}: Props): JSX.Element {
  const user = useSelector((state) => state.user)
  const colors = useThemeColors()

  const [deleting, setDeleting] = React.useState(false)

  const labelColorStyle = deleting
    ? { color: colors.activityIndicator }
    : undefined
  const label = deleting ? 'deleting...' : 'delete'
  return (
    <Button
      testID="forumPostActionsDeleteButton"
      label={label}
      labelStyle={[labelStyle, labelColorStyle]}
      labelWeight="extraLight"
      style={style}
      onPress={async () => {
        handleError(null)
        setDeleting(true)
        await deletePost(
          post.planet,
          post.topic,
          post.postId,
          user,
          handleError
        )
        setDeleting(false)
      }}
    />
  )
}

async function deletePost(
  planetId: planet.Id,
  topicId: TopicId,
  postId: PostId,
  user: RootState['user'],
  handleError: (errorMessage: string | null) => void
): Promise<boolean> {
  if (!user.user || !user.user.account) {
    handleError(ERROR_USER_NOT_LOGGED_IN)
    return false
  }

  const deletePostFunction = makeCallableFunction('forums-deletePost')
  try {
    await deletePostFunction({
      id: user.user.fuid,
      planetId,
      topicId,
      postId,
    })
  } catch (e) {
    const error = e as FunctionsError
    logger.error('Failed to delete planet forum post', error)
    handleError(ERROR_OOPS)
    return false
  }
  return true
}
