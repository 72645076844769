import * as React from 'react'
import { StyleSheet } from 'react-native'
import { View, Text } from '..'
import { SubmitButton } from '../form'
import { useDispatch } from '../../state/store'
import { modalsActions } from '../../state/modalsSlice'

export function MyWalletTransferOut(): JSX.Element {
  const dispatch = useDispatch()

  return (
    <View>
      <Text style={styles.text}>
        You can swap your Multiverse AI coins for ERC20 AI tokens. Ethereum gas
        fees will apply.
      </Text>
      <View style={styles.buttonRow}>
        <SubmitButton
          onPress={async () => {
            dispatch(modalsActions.showWithdrawalModal())
            return false
          }}
          label="Withdraw from Multiverse"
          limitWidth
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
  },
  text: {
    paddingBottom: 8,
  },
})
