import * as React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { View } from '.'
import { Button } from './Button'
import { useThemeColors } from '../constants/colors'

type Props = {
  showPrev: boolean
  showNext: boolean
  prevLabel?: string
  nextLabel?: string
  prevOnPress: () => Promise<void>
  nextOnPress: () => Promise<void>
  containerStyle?: StyleProp<ViewStyle>
}
export function PaginationButtons({
  showPrev,
  showNext,
  prevLabel,
  nextLabel,
  prevOnPress,
  nextOnPress,
  containerStyle,
}: Props): JSX.Element {
  const colors = useThemeColors()
  const buttonBackgroundStyle = {
    backgroundColor: colors.link.color,
  }
  const buttonColorStyle = {
    color: colors.background,
  }
  return (
    <View style={[styles.pagination, containerStyle]}>
      {showPrev ? (
        <Button
          style={[styles.paginationButton, buttonBackgroundStyle]}
          labelStyle={[buttonColorStyle]}
          label={prevLabel}
          onPress={prevOnPress}
        />
      ) : (
        <View />
      )}
      {showNext && (
        <Button
          style={[styles.paginationButton, buttonBackgroundStyle]}
          labelStyle={[buttonColorStyle]}
          label={nextLabel}
          onPress={nextOnPress}
        />
      )}
    </View>
  )
}
PaginationButtons.defaultProps = {
  prevLabel: 'Previous Page',
  nextLabel: 'Next Page',
  containerStyle: undefined,
}

const styles = StyleSheet.create({
  pagination: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 10,
  },
  paginationButton: {
    borderRadius: 5,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
  },
})
