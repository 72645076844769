type NotificationID = string | number
export type NewNotification = {
  id?: NotificationID
  label: string
}
export type Notification = {
  id: NotificationID
  label: string
  clearedTimestamp?: Date
}
export type NotificationsState = {
  notifications: Array<Notification>
}

export function initialState(): NotificationsState {
  return {
    notifications: [],
  }
}
