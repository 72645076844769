import * as React from 'react'
import { StyleSheet } from 'react-native'
import { logger } from '../logger'
import { Modal, Heading } from './ModalElements'
import * as Form from '../components/form'
import { Button } from '../components/Button'
import { View } from '../components'
import { useThemeColors } from '../constants/colors'
import { useDispatch, useSelector } from '../state/store'
import { modalsActions } from '../state/modalsSlice'
import { ModalStringData } from '../state/modalsTypes'
import { logEvent, analytics } from '../firebase/analytics'
import { auth, signInWithEmailAndPassword } from '../firebase/auth'
import { NoRefresh } from '../components/NoRefresh'

type FormError = {
  email?: string
  password?: string
  generic?: string
}
export function AccountLoginModal(): JSX.Element {
  const dispatch = useDispatch()
  const prefillData = useSelector(
    (state) => state.modals.data
  ) as ModalStringData

  const [email, setEmail] = React.useState(prefillData?.email ?? '')
  const [password, setPassword] = React.useState(prefillData?.password ?? '')
  const [hidePassword, setHidePassword] = React.useState(true)
  const [formError, setFormError] = React.useState({} as FormError)

  const passwordRef = React.useRef<Form.TextInputRefType>(null)
  const submitRef = React.useRef<Form.SubmitButtonRefType>(null)

  const color = useThemeColors().button
  const showPasswordButtonColorStyles = color.formLabelAux.container
  const showPasswordButtonLabelColorStyles = color.formLabelAux.label
  return (
    <Modal testID="account-login">
      <Heading>Log Into Your Multiverse&nbsp;Account</Heading>
      <NoRefresh>
        <Form.Item testID="email-address">
          <Form.Label>Email address</Form.Label>
          <Form.TextInput
            value={email}
            onChangeText={(textValue) => {
              setEmail(textValue)
            }}
            autoCompleteType="email"
            keyboardType="email-address"
            onKeyPress={(event) => {
              switch (event.nativeEvent.key) {
                case 'Enter':
                  if (email && password && submitRef.current?.onPress) {
                    submitRef.current.onPress().catch((e) => {
                      logger.warn('unexpected promise rejection on submit', e)
                    })
                  } else {
                    passwordRef?.current?.focus()
                  }
                  break
                case 'Tab':
                  event.preventDefault()
                  passwordRef?.current?.focus()
                  break
                default:
              }
            }}
          />
          <Form.Error error={formError.email} />
        </Form.Item>
        <Form.Item testID="password">
          <View style={styles.passwordLabelRow}>
            <Form.Label>Password</Form.Label>
            <Button
              label={hidePassword ? 'show password' : 'hide password'}
              style={[styles.showPasswordButton, showPasswordButtonColorStyles]}
              labelStyle={[
                styles.showPasswordButtonLabel,
                showPasswordButtonLabelColorStyles,
              ]}
              onPress={async () => {
                setHidePassword(!hidePassword)
              }}
            />
          </View>
          <Form.TextInput
            ref={passwordRef}
            value={password}
            onChangeText={(textValue) => {
              setPassword(textValue)
            }}
            autoCompleteType="password"
            secureTextEntry={hidePassword}
            onKeyPress={(event) => {
              if (
                event.nativeEvent.key === 'Enter' &&
                email &&
                password &&
                submitRef.current?.onPress
              ) {
                submitRef.current.onPress().catch((e) => {
                  logger.warn('unexpected promise rejection on submit', e)
                })
              }
            }}
          />
          <Form.Error error={formError.password} />
        </Form.Item>
        <Form.SubmitButton
          ref={submitRef}
          label="Login"
          onPress={async () => {
            await submitEmailAndPassword(email, password, setFormError)
            return false
          }}
        />
        <Form.Error error={formError.generic} />
        <Form.Alternative.Row>
          <Form.Alternative.Link
            textLabel="New user?"
            label="Create an account"
            onPress={() => {
              dispatch(
                modalsActions.showCreateAccountModal({ email, password })
              )
            }}
          />
          <Form.Alternative.Link
            label="Forgot password?"
            onPress={() => {
              dispatch(modalsActions.showForgotPasswordModal({ email }))
            }}
          />
        </Form.Alternative.Row>
      </NoRefresh>
    </Modal>
  )
}

const styles = StyleSheet.create({
  passwordLabelRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  showPasswordButton: {
    borderRadius: 5,
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    top: -5,
  },
  showPasswordButtonLabel: {
    fontSize: 14,
    lineHeight: 22,
  },
})

async function submitEmailAndPassword(
  email: string,
  password: string,
  setFormError: React.Dispatch<React.SetStateAction<FormError>>
): Promise<boolean> {
  setFormError({})
  return signInWithEmailAndPassword(auth, email, password)
    .then((user) => {
      if (user.user) {
        logEvent(analytics(), 'login', {
          uid: user.user.uid,
          email: user.user.email,
          emailVerified: user.user.emailVerified,
          displayName: user.user.displayName,
        })
      }
      return true
    })
    .catch((error) => {
      switch (error.code) {
        case 'auth/invalid-email':
          setFormError({
            email: 'Please enter a valid email address.',
          })
          break
        case 'auth/wrong-password':
        case 'auth/user-not-found':
        case 'auth/user-disabled':
          setFormError({
            password: 'Invalid email and password combination.',
          })
          break
        default:
          setFormError({
            generic: error.message,
          })
      }
      return false
    })
}
