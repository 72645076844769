import * as React from 'react'

/**
 * usePrevious hook - useful for retrieving the previous value of a state variable
 *
 * Example:
 * ```
 * const [number, setNumber] = React.useState(0)
 * const prevNumber = usePrevious(number)
 *
 * // prevNumber is `undefined`
 *
 * setNumber(100)
 *
 * // on next render, `number` will be `100` but `prevNumber` will be `0`
 * ```
 *
 * Note: Always starts with undefined during first render
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T>()
  React.useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
