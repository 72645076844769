export type BuildState = {
  currentBuild: string
  latestBuild: string
  noRefresh: boolean
  noRefreshCounter: number
}

export function initialState(): BuildState {
  return {
    currentBuild: '',
    latestBuild: '',
    noRefresh: false,
    noRefreshCounter: 0,
  }
}
