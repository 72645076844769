import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { View, Text } from '..'
import { useThemeColors } from '../../constants/colors'
import { testIdToNativeId } from '../../helpers/testId'

type Props = {
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  children: React.ReactNode
  testID?: string
}
export function Label({
  style = undefined,
  labelStyle = undefined,
  children,
  testID,
}: Props): JSX.Element {
  return (
    <LabelView testID={testID} style={style}>
      <LabelText style={labelStyle}>{children}</LabelText>
    </LabelView>
  )
}

export function SubLabel({
  style = undefined,
  labelStyle = undefined,
  children,
  testID,
}: Props): JSX.Element {
  const colors = useThemeColors()
  const colorStyle = { color: colors.medium }
  return (
    <LabelView testID={testID} style={[styles.subLabelView, style]}>
      <LabelText style={[colorStyle, labelStyle]}>{children}</LabelText>
    </LabelView>
  )
}

const styles = StyleSheet.create({
  labelText: {
    fontSize: 14,
    lineHeight: 14,
  },
  labelView: {
    marginBottom: 8,
  },
  subLabelView: {
    marginTop: 4,
  },
})

type LabelViewProps = {
  style?: StyleProp<ViewStyle>
  children: React.ReactNode
  testID?: string
}
export function LabelView({
  style,
  children,
  testID,
}: LabelViewProps): JSX.Element {
  return (
    <View
      nativeID={testIdToNativeId(testID, 'form-label')}
      testID={testID}
      style={[styles.labelView, style]}
    >
      {children}
    </View>
  )
}
LabelView.defaultProps = {
  style: undefined,
}

type LabelTextProps = {
  style?: StyleProp<TextStyle>
  testID?: string
  children: React.ReactNode
}
export function LabelText({
  style,
  children,
  testID,
}: LabelTextProps): JSX.Element {
  return (
    <Text testID={testID} style={[styles.labelText, style]}>
      {children}
    </Text>
  )
}
LabelText.defaultProps = {
  style: undefined,
}
