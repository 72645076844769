import * as React from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { TopicDoc, topicsCollectionPath } from '@mv/api/lib/src/schema/forums'
import {
  PlanetChatTabParamList,
  PlanetsStackNavigationSpecificRoute,
  DrawerNavigationRoute,
  PlanetsStackNavigationProps,
} from './types'
import { ChatTab } from '../components/planets/screens/planetTabs/ChatTab'
import { Text } from '../components'
import { usePlanetFromPlanetLocator } from '../hooks/usePlanetFromPlanetLocator'
import { Loading } from '../components/Loading'
import { PLANET_NOT_FOUND } from '../constants/messages'
import { PlanetChatTabs } from '../components/planets/PlanetChatTabs'
import {
  db,
  collection,
  CollectionReference,
  query,
  where,
  getDocs,
} from '../firebase/firestore'
import { logger } from '../logger'
import { TabView } from '../components/TabView'

const PlanetChatTabNavigator = createStackNavigator<PlanetChatTabParamList>()

export function PlanetChatNavigator(): JSX.Element {
  const navigation = useNavigation<PlanetsStackNavigationProps>()
  const route = useRoute<
    PlanetsStackNavigationSpecificRoute<'PlanetsShow'> | DrawerNavigationRoute
  >()
  const planetLocator = route.params?.planetLocator || 'AI'
  const planet = usePlanetFromPlanetLocator(planetLocator)

  const isMounted = React.useRef(true)
  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  const [topics, setTopics] = React.useState<TopicDoc[] | null>(null)

  React.useEffect(() => {
    if (planet?.id) {
      getDocs(
        query(
          collection(
            db,
            topicsCollectionPath(planet.id)
          ) as CollectionReference<TopicDoc>,
          where('topicType', '==', 'chat')
        )
      )
        .then((collectionSnapshot) => {
          if (isMounted.current) {
            const topicDocs = collectionSnapshot.docs.map((snap) => snap.data())
            setTopics(topicDocs)
          }
        })
        .catch(() => {
          logger.error(
            `Something went wrong getting topic docs from planet ${planet.id}`
          )
        })
    }
  }, [planet?.id])

  const backgroundStyle = {
    backgroundColor: 'transparent',
  }

  if (!planet) {
    if (planet === null) {
      return (
        <TabView>
          <Text>{PLANET_NOT_FOUND}</Text>
        </TabView>
      )
    }
    return <Loading />
  }

  if (!topics) {
    return <Loading />
  }
  if (topics.length === 0) {
    navigation.reset({
      index: 0,
      routes: [{ name: 'PlanetsShow', params: { planetLocator } }],
    })
    return (
      <TabView>
        <Text>Nope</Text>
      </TabView>
    )
  }
  return (
    <PlanetChatTabNavigator.Navigator
      screenOptions={{
        headerShown: true,
        detachPreviousScreen: true,
        cardStyle: backgroundStyle,
        header: () => <PlanetChatTabs topics={topics} />,
      }}
      mode="modal"
      detachInactiveScreens
    >
      <PlanetChatTabNavigator.Screen
        name="PlanetChat"
        options={() => ({
          title: 'Multiverse Homeworld',
        })}
        initialParams={route.params}
      >
        {() => <ChatTab planetId={planet.id} />}
      </PlanetChatTabNavigator.Screen>
    </PlanetChatTabNavigator.Navigator>
  )
}
