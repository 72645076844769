import { getFirestore } from 'firebase/firestore'
import { app } from './init'

export const db = getFirestore(app)

// Add symbols to export from firebase/firestore here
export {
  doc,
  getDoc,
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  startAt,
  startAfter,
  endAt,
  limit,
  limitToLast,
  onSnapshot,
  updateDoc,
  runTransaction,
  writeBatch,
  WriteBatch,
  terminate,
  connectFirestoreEmulator,
  Query as FirestoreQuery,
  DocumentSnapshot,
  DocumentReference,
  CollectionReference,
  QuerySnapshot,
  QueryDocumentSnapshot,
  FieldValue,
  Timestamp,
} from 'firebase/firestore'
export type { DocumentData } from 'firebase/firestore'
