import * as React from 'react'

export function useIsMounted(): React.MutableRefObject<boolean> {
  const isMounted = React.useRef(true)
  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )
  return isMounted
}
