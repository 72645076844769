/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet } from 'react-native'
import { planet } from '@mv/api/lib/src/types'
import { TopicId, PostId, Post } from '@mv/api/lib/src/schema/forums'
import { Text, View } from '..'
import { FunctionsError, makeCallableFunction } from '../../firebase/functions'
import { RootState } from '../../state/state'
import { useSelector } from '../../state/store'
import * as Form from '../form'
import { NoRefresh } from '../NoRefresh'
import { logger } from '../../logger'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../../constants/messages'
import { usePrevious } from '../../hooks/usePrevious'
import { DisplayAddress } from '../DisplayAddress'
import { Button } from '../Button'
import { AnimateDownFadeIn } from '../animations/AnimateDownFadeIn'
import { CloseX } from '../svg/CloseX'
import { useThemeColors } from '../../constants/colors'
import { useTheme } from '../../hooks/useTheme'

export type PlanetChatPostFormRefType = Form.TextInputRefType

const STATUS_TRIGGER_TIME = 5 * 1000 // 5 seconds

type FormError = {
  generic?: string
}

type Props = {
  planetId: planet.Id
  topicId: TopicId
  referencedPost?: Post // use this for referring to another post
  removeReferencedPost?: () => void
  reset?: () => void
}
function PlanetChatPostFormNeedRefForwarding(
  {
    planetId,
    topicId,
    referencedPost = undefined,
    removeReferencedPost = undefined,
    reset,
  }: Props,
  ref: React.ForwardedRef<PlanetChatPostFormRefType>
): JSX.Element | null {
  const user = useSelector((state) => state.user)
  const colors = useThemeColors()
  const { theme } = useTheme()
  const styles = themedStyles[theme]

  const [postText, setPostText] = React.useState('')
  const prevPostText = usePrevious(postText)
  const [formError, setFormError] = React.useState<FormError>({})

  const statusPendingRef = React.useRef(false)
  const lastStatusUpdateTimeRef = React.useRef(0)
  const submitRef = React.useRef<Form.SubmitButtonRefType>(null)

  React.useEffect(() => {
    if (
      !statusPendingRef.current &&
      new Date().getTime() - lastStatusUpdateTimeRef.current >
        STATUS_TRIGGER_TIME &&
      postText &&
      prevPostText !== postText
    ) {
      statusPendingRef.current = true
      lastStatusUpdateTimeRef.current = new Date().getTime()
      markAsTyping(planetId, topicId, undefined, user)
        .then(() => {
          statusPendingRef.current = false
        })
        .catch((e) => {
          logger.warn(e)
        })
    }
  }, [planetId, topicId, user, postText, prevPostText])

  const borderStyle = {
    borderTopColor: colors.chat.borderColor,
  }
  const inResponseXBorderStyle = {
    borderColor: colors.chat.inResponseCloseColor,
  }
  const textInputBorderStyle = {
    borderColor: colors.chat.textInputBordercolor,
  }
  return (
    <NoRefresh disabled={!postText}>
      <Form.Item style={[styles.formRow, borderStyle]}>
        <View style={styles.postFormView}>
          <AnimateDownFadeIn>
            {referencedPost ? (
              <View style={styles.inResponseText}>
                <Text>
                  Replying to{' '}
                  <DisplayAddress
                    textWeight="semiBold"
                    address={referencedPost.authorAddress}
                  />
                  :
                  <View
                    style={[styles.inResponseXView, inResponseXBorderStyle]}
                  >
                    <Button
                      onPress={async () => {
                        if (removeReferencedPost) removeReferencedPost()
                      }}
                    >
                      <CloseX
                        style={[styles.inResponseX, inResponseXBorderStyle]}
                      />
                    </Button>
                  </View>
                </Text>
              </View>
            ) : null}
          </AnimateDownFadeIn>
          <View style={styles.textInputView}>
            <Form.TextInput
              ref={ref}
              testID="forumPostFormTextInput"
              style={[styles.textInput, textInputBorderStyle]}
              placeholder="Message"
              value={postText}
              onChangeText={async (textValue) => {
                setPostText(textValue)
              }}
              onKeyPress={(event) => {
                switch (event.nativeEvent.key) {
                  case 'Enter':
                    if (postText.trim() && submitRef.current?.onPress) {
                      submitRef.current.onPress().catch((e) => {
                        logger.warn('unexpected promise rejection on submit', e)
                      })
                    }
                    break
                  default:
                }
              }}
            />
            <Form.SubmitButton
              testID="forumPostFormSubmitButton"
              ref={submitRef}
              label="Send"
              labelStyle={styles.submitLabel}
              style={styles.submit}
              containerStyle={styles.submitContainer}
              onPress={async () => {
                setFormError({})
                const success = await submitPost(
                  planetId,
                  topicId,
                  undefined,
                  referencedPost?.postId,
                  postText,
                  user,
                  setFormError
                )
                if (success) {
                  setPostText('')
                  if (removeReferencedPost) removeReferencedPost()
                  if (reset) reset()
                }
                return false
              }}
              disabled={!postText.trim()}
            />
          </View>
        </View>
      </Form.Item>
      <Form.Error error={formError.generic} />
    </NoRefresh>
  )
}

export const PlanetChatPostForm = React.forwardRef(
  PlanetChatPostFormNeedRefForwarding
)

const themedStyles = {
  hadron: StyleSheet.create({
    formRow: {
      borderTopWidth: 1,
      flexDirection: 'row',
      paddingTop: 16,
    },
    inResponseText: {
      marginBottom: 16,
    },
    inResponseX: {
      borderRadius: 3,
      borderWidth: 1,
      height: 10,
      width: 10,
    },
    inResponseXView: {
      borderRadius: 3,
      borderWidth: 1,
      marginLeft: 16,
      padding: 2,
    },
    postFormView: {
      flex: 1,
      marginRight: 12,
    },
    submit: {
      // bottom: 12,
      paddingBottom: 4,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 4,
      // position: 'absolute',
      // right: 0,
    },
    submitContainer: {
      flex: undefined,
      marginLeft: 16,
      marginRight: 20,
    },
    submitLabel: {
      fontSize: 12,
    },
    textInput: {
      borderRadius: 25,
      borderWidth: 1,
      flex: 1,
      fontSize: 14,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
    },
    textInputView: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
    },
  }),
  multiverse2022: StyleSheet.create({
    formRow: {
      flexDirection: 'row',
      paddingTop: 32,
    },
    inResponseText: {
      marginBottom: 16,
    },
    inResponseX: {
      borderRadius: 3,
      borderWidth: 1,
      height: 10,
      width: 10,
    },
    inResponseXView: {
      borderRadius: 3,
      borderWidth: 1,
      marginLeft: 16,
      padding: 2,
    },
    postFormView: {
      flex: 1,
      marginRight: 12,
    },
    submit: {},
    submitContainer: {
      flex: undefined,
      marginLeft: 16,
      marginRight: 20,
    },
    submitLabel: {
      fontSize: 12,
    },
    textInput: {
      flex: 1,
    },
    textInputView: {
      alignItems: 'center',
      flexDirection: 'row',
      flex: 1,
    },
  }),
}

async function submitPost(
  planetId: planet.Id,
  topicId: TopicId,
  replyTo: PostId | undefined,
  referencedPostId: PostId | undefined,
  postText: string,
  user: RootState['user'],
  setFormError: React.Dispatch<React.SetStateAction<FormError>>
): Promise<boolean> {
  if (!user.user || !user.user.account) {
    setFormError({
      generic: ERROR_USER_NOT_LOGGED_IN,
    })
    return false
  }

  const createPost = makeCallableFunction('forums-createPost')
  try {
    // TODO: pass referencedPost id and address to createPost
    await createPost({
      id: user.user.fuid,
      planetId,
      topicId,
      replyTo,
      referencedPostId,
      body: {
        bodyType: 'text',
        _: {
          body: {
            text: postText,
          },
        },
      },
    })
    return true
  } catch (e) {
    const error = e as FunctionsError
    const errors: FormError = {}
    logger.error('Failed to create planet forum post', error)
    errors.generic = ERROR_OOPS
    setFormError(errors)
    return false
  }
}

async function markAsTyping(
  planetId: planet.Id,
  topicId: TopicId,
  replyTo: PostId | undefined,
  user: RootState['user']
): Promise<boolean> {
  if (!(user.user && user.user.account && topicId)) {
    return false
  }

  const createPost = makeCallableFunction('forums-createPost')
  try {
    await createPost({
      id: user.user.fuid,
      planetId,
      topicId,
      replyTo,
      referencedPostId: undefined,
      body: undefined,
    })
    return true
  } catch (e) {
    const error = e as FunctionsError
    logger.error('Failed to update post with user typing status', error)
    return false
  }
}
