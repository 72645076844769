import * as React from 'react'
import { useIsFocused } from '@react-navigation/native'
import { Loading } from './Loading'

type Props = {
  children: React.ReactNode
}

function NavigationScreen({ children }: Props): JSX.Element {
  const isFocused = useIsFocused()
  if (isFocused) {
    return <>{children}</>
  }
  return <Loading />
}

export function wrapScreenWithNavigationScreen(
  screen: () => JSX.Element
): () => JSX.Element {
  return () => <NavigationScreen>{screen()}</NavigationScreen>
}
