import * as React from 'react'
import { Text, TextProps } from '.'
import { TextPreset } from './Text'

export type Props = {
  date: Date
  textWeight?: TextProps['weight']
  textPreset?: TextPreset
  style?: TextProps['style']
}
export function TimeAgo({
  date,
  textWeight = undefined,
  textPreset = undefined,
  style = undefined,
}: Props): JSX.Element {
  const [tick, setTick] = React.useState(0)
  const [timeDisplay, setTimeDisplay] = React.useState('')

  React.useEffect(() => {
    const secsAgo = secondsAgo(date)
    setTimeDisplay(humanizeTime(secsAgo))

    const absoluteSecsAgo = Math.abs(secsAgo)
    let waitTime = 1000 * 60 * 15 // longest refresh time is 15 minutes
    if (absoluteSecsAgo < 60) {
      waitTime = 1000 // one second accuracy within 1 minute
    } else if (absoluteSecsAgo < 60 * 10) {
      waitTime = 1000 * 15 // 15 seconds accuracy within 10 minutes
    } else if (absoluteSecsAgo < 60 * 60) {
      waitTime = 1000 * 30 // 30 seconds accuracy within an hour
    }
    const timeout = setTimeout(() => {
      setTick(tick + 1)
    }, waitTime)
    return () => {
      clearTimeout(timeout)
    }
  }, [tick, date])

  return (
    <Text preset={textPreset} weight={textWeight} style={style}>
      {timeDisplay}
    </Text>
  )
}

function secondsAgo(date: Date): number {
  // Round each time first so no fractional seconds so updates will be in lock step
  return (
    Math.round(new Date().getTime() / 1000) - Math.round(date.getTime() / 1000)
  )
}

const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const WEEK = DAY * 7
const MONTH = DAY * 30.4
const YEAR = DAY * 365

// TODO: replace with moment library equivalent later
function humanizeTime(seconds: number): string {
  const suffix = seconds >= 0 ? 'ago' : 'from now'
  const absoluteSeconds = Math.abs(seconds)

  if (absoluteSeconds < MINUTE) {
    return `${absoluteSeconds} sec ${suffix}`
  }
  if (absoluteSeconds < HOUR) {
    const minutes = Math.round(absoluteSeconds / MINUTE)
    return `${minutes} min ${suffix}`
  }
  if (absoluteSeconds < DAY) {
    const hours = Math.round(absoluteSeconds / HOUR)
    return `${hours} hr ${suffix}`
  }
  if (absoluteSeconds < WEEK) {
    const days = Math.round(absoluteSeconds / DAY)
    return `${days} day${days === 1 ? '' : 's'} ${suffix}`
  }
  if (absoluteSeconds < MONTH) {
    const weeks = Math.round(absoluteSeconds / WEEK)
    return `${weeks} week${weeks === 1 ? '' : 's'} ${suffix}`
  }
  if (absoluteSeconds < YEAR) {
    const months = Math.round(absoluteSeconds / MONTH)
    return `${months} month${months === 1 ? '' : 's'} ${suffix}`
  }
  const years = Math.round(absoluteSeconds / YEAR)
  return `${years} year${years === 1 ? '' : 's'} ${suffix}`
}
