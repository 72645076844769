import * as React from 'react'
import { StyleSheet } from 'react-native'
import { schema } from '@mv/api'
import { View, Text } from '..'
import { Button } from '../Button'
import { useThemeColors } from '../../constants/colors'
import { useSelector, useDispatch } from '../../state/store'
import { userActions } from '../../state/userSlice'
import { logger } from '../../logger'
import { useIsImpersonating } from '../../hooks/useIsImpersonating'

export function ImpersonationStatusBar(): JSX.Element | null {
  const user = useSelector((state) => state.user)
  const colors = useThemeColors()
  const dispatch = useDispatch()
  const isImpersonating = useIsImpersonating()

  const viewColors = {
    backgroundColor: colors.activityIndicator,
  }
  const textColors = {
    color: colors.background,
  }
  if (isImpersonating) {
    return (
      <View style={[styles.container, viewColors]}>
        <Button
          label="Stop Impersonating"
          labelStyle={[styles.text, styles.button, textColors]}
          onPress={async () => {
            if (user.user) {
              dispatch(
                userActions.login({
                  fuid: user.user.firebaseUser.uid,
                  firebaseUser: user.user.firebaseUser,
                  claims: {},
                })
              ).catch((e) => {
                logger.warn('dispatch login for impersonation rejected', e)
              })
            }
          }}
        />
        <Text weight="semiBold" style={[styles.text, textColors]}>
          {user.user?.account?.[schema.accounts.address]} ({user.user?.fuid})
        </Text>
      </View>
    )
  }

  return null
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'rgba(0,0,0,0.25)',
    borderRadius: 5,
    marginRight: 8,
    paddingLeft: 4,
    paddingRight: 4,
  },
  container: {
    flexDirection: 'row',
    paddingBottom: 4,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 4,
  },
  text: {
    fontSize: 10,
  },
})
