import * as React from 'react'
import {
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TextStyle,
  StyleSheet,
} from 'react-native'
import { Text, Font, Weight, TextPreset } from './Text'
import { View } from './View'
import { usePrevious } from '../hooks/usePrevious'
import { testIdToNativeId } from '../helpers/testId'

export type ButtonRefType = { onPress: () => Promise<void> }

type Props = {
  testID?: string
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  sublabelStyle?: StyleProp<TextStyle>
  onPress?: () => Promise<void>
  onPressIn?: () => void
  onPressOut?: () => void
  activeOpacity?: number
  label?: string
  labelFont?: Font
  labelWeight?: Weight
  labelPreset?: TextPreset
  sublabel?: string
  sublabelFont?: Font
  sublabelWeight?: Weight
  sublabelPreset?: TextPreset
  disabled?: boolean
  children?: React.ReactNode
}
export const Button = React.forwardRef<ButtonRefType, Props>(
  (
    {
      testID,
      style,
      labelStyle,
      sublabelStyle,
      onPress,
      onPressIn,
      onPressOut,
      activeOpacity,
      label,
      labelFont,
      labelWeight,
      labelPreset,
      sublabel,
      sublabelFont,
      sublabelWeight,
      sublabelPreset,
      disabled,
      children,
    }: Props,
    ref
  ): JSX.Element => {
    const myOnPress = async () => {
      if (onPress) await onPress()
    }
    React.useImperativeHandle(ref, () => ({
      onPress: myOnPress,
    }))

    const isMounted = React.useRef(true)
    const [onPressRunning, setOnPressRunning] = React.useState(false)
    const prevOnPressRunning = usePrevious(onPressRunning)

    React.useEffect(
      () => () => {
        isMounted.current = false
      },
      []
    )

    React.useEffect(() => {
      if (onPress && prevOnPressRunning !== onPressRunning && onPressRunning) {
        const onPressMaybePromise = onPress()
        Promise.resolve(onPressMaybePromise).finally(() => {
          if (isMounted.current) {
            setOnPressRunning(false)
          }
        })
      }
    }, [onPress, prevOnPressRunning, onPressRunning])

    const body = children || (
      <View
        nativeID={testIdToNativeId(testID, 'button', label)}
        testID={disabled ? testID : undefined}
        style={[styles.containerFlex, style]}
      >
        <Text
          preset={labelPreset}
          font={labelFont}
          weight={labelWeight}
          style={labelStyle}
        >
          {label}
        </Text>
        {sublabel && (
          <Text
            preset={sublabelPreset}
            font={sublabelFont}
            weight={sublabelWeight}
            style={sublabelStyle}
          >
            {sublabel}
          </Text>
        )}
      </View>
    )

    if (disabled) {
      return <>{body}</>
    }

    return (
      <TouchableOpacity
        testID={testID}
        onPress={() => {
          setOnPressRunning(true)
        }}
        onPressIn={() => {
          if (onPressIn) onPressIn()
        }}
        onPressOut={() => {
          if (onPressOut) onPressOut()
        }}
        activeOpacity={activeOpacity ?? 0.5}
        style={styles.containerFlex}
      >
        {body}
      </TouchableOpacity>
    )
  }
)
Button.defaultProps = {
  testID: undefined,
  style: undefined,
  labelStyle: undefined,
  sublabelStyle: undefined,
  onPress: undefined,
  onPressIn: undefined,
  onPressOut: undefined,
  activeOpacity: undefined,
  label: undefined,
  labelFont: undefined,
  labelWeight: undefined,
  labelPreset: undefined,
  sublabel: undefined,
  sublabelFont: undefined,
  sublabelWeight: undefined,
  sublabelPreset: undefined,
  disabled: false,
  children: undefined,
}

const styles = StyleSheet.create({
  containerFlex: {
    // flex: 1,
  },
})
