import * as React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useThemeColors } from '../../constants/colors'
import { View } from '..'
import { MenuIcon } from '../svg/MenuIcon'
import { MultiverseLogo } from '../svg/logos/MultiverseLogo'
import { DrawerNavigationProps } from '../../navigation/types'
import { useDispatch } from '../../state/store'
import { contextMenuActions } from '../../state/contextMenuSlice'

export function MobileHeaderBar(): JSX.Element {
  const navigation = useNavigation<DrawerNavigationProps>()
  const dispatch = useDispatch()
  const navColors = useThemeColors().nav
  const navColorStyle = {
    backgroundColor: navColors.background,
  }
  return (
    <View style={[styles.container, navColorStyle]}>
      <TouchableOpacity
        onPress={() => {
          dispatch(contextMenuActions.hideContextMenu())
          navigation.openDrawer()
        }}
        style={styles.menuIcon}
      >
        <MenuIcon color={navColors.menuButton} />
      </TouchableOpacity>
      <MultiverseLogo height={18} inverse />
      <View style={styles.menuIcon} />
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    justifyContent: 'space-between',
  },
  menuIcon: {
    width: 40,
  },
})
