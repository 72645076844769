import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { Slider as RNSlider } from '@miblanchard/react-native-slider'
import { View } from '../View'
import { Text } from '../Text'
import { SliderTrackMark } from './slider/SliderTrackMark'
import { SliderAboveThumb } from './slider/SliderAboveThumb'
import { TextInput } from './TextInput'
import { useThemeColors } from '../../constants/colors'

import { DEFAULT_SLIDER_HEIGHT } from './slider/constants'
import { testIdToNativeId } from '../../helpers/testId'

const TEXT_INPUT_FONT_SIZE = 16

type Props = {
  containerStyle?: StyleProp<ViewStyle>
  logScale?: boolean
  value: number
  minimumValue: number
  maximumValue: number
  onValueChange?: (value: number) => void
  onSlidingStart?: (value: number) => void
  onSlidingComplete?: (value: number) => void
  snapValues?: Array<number>
  allowFloat?: boolean
  disableThousandsSeparator?: boolean
  suffix?: string
  testID?: string
}

export function Slider({
  containerStyle,
  logScale = false,
  value,
  minimumValue,
  maximumValue,
  onValueChange,
  onSlidingStart,
  onSlidingComplete,
  snapValues = [],
  allowFloat = false,
  disableThousandsSeparator = false,
  suffix = '',
  testID,
}: Props): JSX.Element {
  const outerToInnerValue = React.useCallback(
    (outerValue: number): number => (logScale ? log10(outerValue) : outerValue),
    [logScale]
  )
  const innerToOuterValue = React.useCallback(
    (innerValue: number): number => {
      if (!logScale) {
        return innerValue
      }
      return 10 ** innerValue
    },
    [logScale]
  )
  const stringify = React.useCallback(
    (num: number, addSuffix = true): string => {
      const suffixString = addSuffix ? ` ${suffix}` : ''
      if (disableThousandsSeparator) {
        return `${num.toString()}${suffixString}`
      }
      return `${num.toLocaleString()}${suffixString}`
    },
    [disableThousandsSeparator, suffix]
  )

  const [sliderInnerValue, setSliderInnerValue] = React.useState<number>(
    outerToInnerValue(value)
  )
  const [snapValuesInner, setSnapValuesInner] = React.useState<Array<number>>(
    []
  )
  const [textInputValue, setTextInputValue] = React.useState<string>('')

  React.useEffect(() => {
    if (snapValues) {
      setSnapValuesInner(snapValues.map((v) => (logScale ? log10(v) : v)))
    }
  }, [snapValues, logScale])

  React.useEffect(() => {
    const calculatedOuterValue = innerToOuterValue(sliderInnerValue)

    setTextInputValue(
      allowFloat
        ? stringify(calculatedOuterValue, false)
        : stringify(Math.round(calculatedOuterValue), false)
    )
  }, [sliderInnerValue, innerToOuterValue, allowFloat, stringify])

  const sliderColors = useThemeColors().slider

  function snapInner(innerValue: number): number {
    if (snapValuesInner.length === 0) {
      return innerValue
    }
    return snapValuesInner.reduce((prev, current) => {
      const currentDistance = Math.abs(innerValue - current)
      const prevDistance = Math.abs(innerValue - prev)
      if (currentDistance < prevDistance) return current
      return prev
    })
  }

  /**
   * returns a number from 0 to 1 representing the position of the marker/value
   * on the slider
   * */
  function markerNormalizedPosition(): number {
    return (
      (sliderInnerValue - innerMinimumValue) /
      (innerMaximumValue - innerMinimumValue)
    )
  }

  const innerMinimumValue = outerToInnerValue(minimumValue)
  const innerMaximumValue = outerToInnerValue(maximumValue)
  const calculatedOuterValue = innerToOuterValue(sliderInnerValue)
  const displayValue = allowFloat
    ? calculatedOuterValue
    : Math.round(calculatedOuterValue)
  const textInputStyle = {
    // approximation of width - font is not monospaced
    width: approximateTextInputWidth(maximumValue),
  }
  return (
    <View
      nativeID={testIdToNativeId(testID, 'form-slider')}
      testID={testID}
      style={[styles.container, containerStyle]}
    >
      <View style={[styles.sliderView]}>
        <RNSlider
          animateTransitions
          value={sliderInnerValue}
          onValueChange={([v]) => {
            setSliderInnerValue(v)
            if (onValueChange) onValueChange(innerToOuterValue(v))
          }}
          onSlidingStart={([v]) => {
            if (onSlidingStart) onSlidingStart(innerToOuterValue(v))
          }}
          onSlidingComplete={([v]) => {
            setSliderInnerValue(snapInner(v))
            if (onSlidingComplete) onSlidingComplete(innerToOuterValue(v))
          }}
          minimumValue={innerMinimumValue}
          maximumValue={innerMaximumValue}
          minimumTrackTintColor={sliderColors.minimumTrackTintColor}
          maximumTrackTintColor={sliderColors.maximumTrackTintColor}
          thumbTintColor={sliderColors.thumbTintColor}
          trackMarks={snapValuesInner}
          renderTrackMarkComponent={() => <SliderTrackMark />}
          renderAboveThumbComponent={() => (
            <SliderAboveThumb
              displayValue={stringify(displayValue)}
              normalizedPosition={markerNormalizedPosition()}
            />
          )}
        />
        <View style={styles.legendView}>
          <Text weight="semiBold">{stringify(minimumValue)}</Text>
          <Text weight="semiBold">{stringify(maximumValue)}</Text>
        </View>
      </View>
      <View style={styles.textInputView}>
        <TextInput
          style={[styles.textInput, textInputStyle]}
          value={textInputValue}
          onChangeText={(v) => {
            setTextInputValue(sanitizeString(v))
            const outerValue = convertStringToNumber(v)
            if (outerValue >= minimumValue && outerValue <= maximumValue)
              setSliderInnerValue(outerToInnerValue(outerValue))
          }}
          onBlur={() => {
            const textInputValueInt = convertStringToNumber(textInputValue)
            if (textInputValueInt > maximumValue) {
              setTextInputValue(maximumValue.toString())
              setSliderInnerValue(outerToInnerValue(maximumValue))
            } else if (textInputValueInt < minimumValue) {
              setTextInputValue(minimumValue.toString())
              setSliderInnerValue(outerToInnerValue(minimumValue))
            }
          }}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 40,
    marginTop: 20,
  },
  legendView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: -10,
  },
  sliderView: {
    flex: 1,
  },
  textInput: {
    fontSize: TEXT_INPUT_FONT_SIZE,
    paddingBottom: 6,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 6,
    textAlign: 'right',
  },
  textInputView: {
    height: DEFAULT_SLIDER_HEIGHT,
    justifyContent: 'center',
    marginLeft: 16,
  },
})

function log10(value: number): number {
  return Math.max(Math.log10(value), 0)
}

function sanitizeString(string: string, onlyNumbers = false): string {
  if (onlyNumbers) {
    return string.replace(/[^0-9.]/g, '')
  }
  return string.replace(/[^0-9.,]/g, '')
}
function convertStringToNumber(string: string): number {
  return parseFloat(sanitizeString(string, true))
}

function approximateTextInputWidth(value: number) {
  const numDigits = value.toString().length
  const approximateNumberWidth = TEXT_INPUT_FONT_SIZE * numDigits * 0.75
  const numCommas = Math.floor((numDigits - 1) / 3)
  const approximateCommaWidth = TEXT_INPUT_FONT_SIZE * numCommas * 0.25
  return approximateNumberWidth + approximateCommaWidth
}
