/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { View, Text } from '.'
import { Button } from './Button'
import { useThemeColors } from '../constants/colors'
import { useTheme } from '../hooks/useTheme'

const defaultSortParamKey = 'sort'

type SortSet = {
  label: string
  ascQuery: string
  descQuery: string
  defaultDirection: 'desc' | 'asc'
}
type SortProps = {
  currentSortQuery: string | undefined
  sorts: SortSet[]
  navFunction: (screenName: string, params: Record<string, string>) => void
  sortParamKey?: string
  sortHeading?: string
  sortHeadingStyles?: StyleProp<ViewStyle>
  sortHeadingTextStyles?: StyleProp<TextStyle>
}
export function SortBar({
  currentSortQuery,
  sorts,
  navFunction,
  sortParamKey: sortParamKeyProp,
  sortHeading,
  sortHeadingStyles,
  sortHeadingTextStyles,
}: SortProps): JSX.Element | null {
  const route = useRoute()
  const screenName = route.name
  const tabColors = useThemeColors().tabs.minor
  const { theme } = useTheme()
  const styles = themedStyles[theme]

  const { activeColor } = tabColors
  let { inactiveColor } = tabColors
  if (theme === 'hadron') {
    inactiveColor = activeColor
  }

  const sortParamKey = sortParamKeyProp || defaultSortParamKey
  if (sorts.length === 0) return null

  const sortSelectorArray = sorts.map(
    ({ label, ascQuery, descQuery, defaultDirection }) => {
      const labelStyle: TextStyle[] = [styles.sortItemView]

      const descNavParams = { ...route.params, [sortParamKey]: descQuery }
      if (!descQuery) {
        delete descNavParams?.[sortParamKey]
      }
      const ascNavParams = { ...route.params, [sortParamKey]: ascQuery }
      if (!ascQuery) delete ascNavParams?.[sortParamKey]
      let navParams = defaultDirection === 'desc' ? descNavParams : ascNavParams

      let labelColorStyle = { color: inactiveColor }
      let direction = ''
      if (ascQuery === (currentSortQuery || '')) {
        labelStyle.push({ borderBottomColor: activeColor })
        labelColorStyle = { color: activeColor }
        direction = '↑'
        navParams = descNavParams
      } else if (descQuery === (currentSortQuery || '')) {
        labelStyle.push({ borderBottomColor: activeColor })
        labelColorStyle = { color: activeColor }
        direction = '↓'
        navParams = ascNavParams
      }

      return (
        <Button
          key={descQuery}
          onPress={async () => {
            navFunction(screenName, navParams)
          }}
        >
          <View style={labelStyle}>
            <Text
              preset="textLink"
              style={[styles.sortItemText, labelColorStyle]}
            >
              {label}
            </Text>
            <Text
              preset="textLink"
              style={[
                styles.sortItemText,
                styles.sortItemDirectionIndicator,
                labelColorStyle,
              ]}
            >
              {direction}
            </Text>
          </View>
        </Button>
      )
    }
  )
  return (
    <View style={styles.container}>
      {!!sortHeading && (
        <View style={[styles.sortItemView, sortHeadingStyles]}>
          <Text
            preset="textLink"
            weight={theme === 'hadron' ? 'semiBold' : undefined}
            style={[styles.sortItemText, sortHeadingTextStyles]}
          >
            {sortHeading}
          </Text>
        </View>
      )}
      <View style={styles.sortRow}>{sortSelectorArray}</View>
    </View>
  )
}
SortBar.defaultProps = {
  sortHeading: 'Sort By:',
  sortParamKey: defaultSortParamKey,
  sortHeadingStyles: undefined,
  sortHeadingTextStyles: undefined,
}

const themedStyles = {
  hadron: StyleSheet.create({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 12,
      marginTop: 4,
    },
    sortItemDirectionIndicator: {
      paddingLeft: 2,
    },
    sortItemText: {},
    sortItemView: {
      borderBottomColor: 'transparent',
      borderBottomWidth: 2,
      flexDirection: 'row',
      marginRight: 16,
      paddingBottom: 1,
    },
    sortRow: {
      flexDirection: 'row',
    },
  }),
  multiverse2022: StyleSheet.create({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 32,
      marginTop: 0,
    },
    sortItemDirectionIndicator: {
      paddingLeft: 2,
    },
    sortItemText: {},
    sortItemView: {
      flexDirection: 'row',
      marginRight: 24,
    },
    sortRow: {
      flexDirection: 'row',
    },
  }),
}
