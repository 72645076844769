import * as React from 'react'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import {
  PlanetProposal,
  PlanetSymbolDoc,
  planetPath,
  planetSymbolPath,
  planetsCollectionPath,
} from '@mv/api/lib/src/schema/planets'
import {
  convertDisplayAddressToEthereumAddress,
  addressDisplayPrefixReplacement,
} from '@mv/api/lib/src/schema/accounts'
import { logger } from '../../../../logger'
import { Text, Section } from '../../..'
import { Heading } from '../../../Screen'
import {
  PlanetsNavigationProps,
  PlanetsStackParamList,
} from '../../../../navigation/types'
import {
  db,
  doc,
  getDoc,
  DocumentReference,
  query,
  collection,
  CollectionReference,
  where,
  limit,
  getDocs,
} from '../../../../firebase/firestore'
import { Loading } from '../../../Loading'
import { PlanetPledgeForm } from '../../PlanetPledgeForm'
import { PlanetPledgeDisplay } from '../../PlanetPledgeDisplay'
import { TabView } from '../../../TabView'
import { PLANET_NOT_FOUND } from '../../../../constants/messages'

export function PledgeTab(): JSX.Element {
  const [notFound, setNotFound] = React.useState(false)
  const [planet, setPlanet] = React.useState<PlanetProposal | undefined>(
    undefined
  )

  const navigation = useNavigation<PlanetsNavigationProps>()
  const route = useRoute<RouteProp<PlanetsStackParamList, 'PlanetsShow'>>()
  const planetLocator = route.params?.planetLocator || 'AI'

  React.useEffect(() => {
    async function getPlanetFromPlanetLocator() {
      if (
        !(
          planetLocator.startsWith('0x') ||
          planetLocator.startsWith(addressDisplayPrefixReplacement)
        )
      ) {
        // planetLocator is likely a symbol
        const symbolDocSnapshot = await getDoc(
          doc(
            db,
            planetSymbolPath(planetLocator.trim().toUpperCase())
          ) as DocumentReference<PlanetSymbolDoc>
        )
        if (symbolDocSnapshot.exists()) {
          const symbolDoc = symbolDocSnapshot.data()
          const planetDocSnapshot = await getDoc(
            doc(
              db,
              planetPath(symbolDoc.planet)
            ) as DocumentReference<PlanetProposal>
          )
          if (planetDocSnapshot.exists()) {
            setPlanet(planetDocSnapshot.data())
            return
          }
        }
      }
      if (
        RegExp(`^(0x|${addressDisplayPrefixReplacement})[a-fA-F0-9]{40}$`).test(
          planetLocator
        )
      ) {
        // planetLocator appears to be a complete planetId
        const planetDocSnapshot = await getDoc(
          doc(
            db,
            planetPath(convertDisplayAddressToEthereumAddress(planetLocator))
          ) as DocumentReference<PlanetProposal>
        )
        if (planetDocSnapshot.exists()) {
          setPlanet(planetDocSnapshot.data())
          return
        }
      }
      if (
        planetLocator.startsWith('0x') ||
        planetLocator.startsWith(addressDisplayPrefixReplacement)
      ) {
        const normPlanetLocator =
          convertDisplayAddressToEthereumAddress(planetLocator).toLowerCase()
        // planetLocator is not an exact match for the planetId - try for a loose match
        const planetCollectionDocsSnapshot = await getDocs(
          query(
            collection(
              db,
              planetsCollectionPath
            ) as CollectionReference<PlanetProposal>,
            where('lowercaseId', '>=', normPlanetLocator),
            limit(2)
          )
        )
        if (!planetCollectionDocsSnapshot.empty) {
          const potentialPlanetDocs = planetCollectionDocsSnapshot.docs.map(
            (snapshot) => snapshot.data()
          )
          if (
            potentialPlanetDocs[0]?.lowercaseId.startsWith(normPlanetLocator) &&
            (potentialPlanetDocs.length === 1 ||
              !potentialPlanetDocs[1]?.lowercaseId.startsWith(
                normPlanetLocator
              ))
          ) {
            // one match, this is the planet we are looking for
            setPlanet(potentialPlanetDocs[0])
            return
          }
        }
      }
      setNotFound(true)
    }
    getPlanetFromPlanetLocator().catch((e) =>
      logger.warn(
        'error encountered when getting planet from planetLocator: ',
        e
      )
    )
  }, [planetLocator])

  React.useEffect(() => {
    if (planet && planetLocator !== planet.symbol) {
      navigation.replace('PlanetsShow', { planetLocator: planet.symbol })
    }
  }, [planet, planetLocator, navigation])

  if (notFound) {
    return (
      <TabView>
        <Heading>Multiverse Planets</Heading>
        <Text>{PLANET_NOT_FOUND}</Text>
      </TabView>
    )
  }

  if (planet) {
    return (
      <TabView>
        <Section heading="Pledges">
          <PlanetPledgeDisplay planetId={planet.id} />
          <PlanetPledgeForm planetId={planet.id} />
        </Section>
      </TabView>
    )
  }
  return <Loading />
}
