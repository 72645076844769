/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import {
  useWindowDimensions,
  StyleProp,
  StyleSheet,
  ViewStyle,
  View as RNView,
} from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { TopicDoc } from '@mv/api/lib/src/schema/forums'
import { PlanetsChatNavigationRoute } from '../../navigation/types'
import { Text, View } from '..'
import { LinkButton } from '../LinkButton'
import { Button } from '../Button'
import { TabView } from '../TabView'
import { useThemeColors } from '../../constants/colors'
import { useDispatch } from '../../state/store'
import { contextMenuActions } from '../../state/contextMenuSlice'
import { useTheme } from '../../hooks/useTheme'
import { Weight, TextPreset } from '../Text'

const PREDEFINED_TOPICS_SORT_ORDER = [
  'main',
  'governance',
  'planets',
  'token',
  'random',
]

type Props = {
  topics: TopicDoc[]
  style?: StyleProp<ViewStyle>
}
export function PlanetChatTabs({ topics, style }: Props): JSX.Element | null {
  const navigation = useNavigation()
  const route = useRoute<PlanetsChatNavigationRoute>()
  const dispatch = useDispatch()
  const colors = useThemeColors()
  const windowDimensions = useWindowDimensions()
  const { theme } = useTheme()

  const routeTopicId = route.params?.topicId

  const [sortedTopics, setSortedTopics] = React.useState<
    TopicDoc[] | undefined
  >(undefined)
  const [topicMap, setTopicMap] = React.useState<Record<string, true>>({})
  const [showTabs, setShowTabs] = React.useState(false)

  const tabViewWidth = React.useRef<number | undefined>(undefined)
  /** Ref for a Record where key is topicId and value is the ReactNode of the
   * topic tab */
  const tabRenders = React.useRef<Record<string, React.ReactNode>>({})
  /** Ref for a Record where key is topicId and value is width in pixels of the
   * topic tab */
  const tabRenderWidths = React.useRef<Record<string, number>>({})
  const moreRef = React.useRef<RNView>(null)
  /** Ref for Array of ReactNode topic buttons that will be displayed in the
   * more/overflow menu */
  const moreTopics = React.useRef<React.ReactNode[]>([])

  const windowWidth = React.useRef<number>(0)

  const borderColorStyle = {
    borderBottomColor: colors.chat.borderColor,
  }
  const contextMenuColorStyle = {
    backgroundColor: colors.context.backgroundColor,
  }
  const contextMenuDividerStyle = {
    borderTopColor: colors.context.dividerColor,
  }

  React.useEffect(() => {
    windowWidth.current = windowDimensions.width
    dispatch(contextMenuActions.hideContextMenu())
  }, [dispatch, windowDimensions])

  React.useEffect(() => {
    const newTopicMap: Record<string, true> = {}
    topics.forEach((topic) => {
      newTopicMap[topic.id] = true
    })
    setTopicMap(newTopicMap)
    setSortedTopics(sortTopicDocs(topics))
  }, [topics])

  React.useEffect(() => {
    if (sortedTopics && sortedTopics.length > 0) {
      if (!routeTopicId || !topicMap[routeTopicId]) {
        // if routeTopicId is not a valid topicId, route the user to the first
        // (default) topic
        navigation.setParams({ topicId: sortedTopics[0].id })
      } else {
        // routeTopicId has changed - rerender for new layout
        tabRenders.current = {}
        tabRenderWidths.current = {}
        setShowTabs(false)
      }
    }
  }, [navigation, sortedTopics, topicMap, routeTopicId])

  const checkReceivedAllTabWidths = React.useCallback(() => {
    const tabRenderWidthsSize = Object.keys(tabRenderWidths.current).length
    if (topics.length + 1 === tabRenderWidthsSize) {
      // received all tab widths
      setShowTabs(true)
    }
  }, [topics.length])

  const styles = themedStyles[theme]

  /** Array of ReactNode topic tabs that will be displayed to the left (main
   * area) */
  const renderedTopics: React.ReactNode[] = []
  moreTopics.current = []

  if (sortedTopics) {
    if (!showTabs) {
      sortedTopics.forEach((topic) => {
        let labelPreset: TextPreset | undefined = 'textLink'
        let labelWeight: Weight | undefined
        if (theme === 'hadron') {
          labelPreset = undefined
          labelWeight = routeTopicId === topic.id ? 'semiBold' : 'normal'
        }
        const renderedTopicTab = (
          <View
            key={`${topic.id}-${Date.now()}`}
            onLayout={(event) => {
              if (event.nativeEvent.layout.width > 0) {
                tabRenderWidths.current[topic.id] =
                  event.nativeEvent.layout.width
                checkReceivedAllTabWidths()
              }
            }}
          >
            <LinkButton
              label={topic.displayName || topic.id}
              labelPreset={labelPreset}
              labelWeight={labelWeight}
              labelStyle={[
                routeTopicId === topic.id
                  ? { color: colors.tabs.minor.activeColor }
                  : { color: colors.tabs.minor.inactiveColor },
                styles.label,
              ]}
              onPress={() => {
                dispatch(contextMenuActions.hideContextMenu())
                navigation.setParams({ topicId: topic.id })
              }}
            />
          </View>
        )
        tabRenders.current[topic.id] = renderedTopicTab
        renderedTopics.push(renderedTopicTab)
      })
      const renderedMoreButton = (
        <View
          key="/more"
          onLayout={(event) => {
            tabRenderWidths.current['/more'] = event.nativeEvent.layout.width
            checkReceivedAllTabWidths()
          }}
          ref={moreRef}
        >
          <LinkButton
            label="More..."
            labelStyle={styles.moreLabel}
            onPress={() => {
              if (moreRef.current) {
                moreRef.current.measure(
                  (_x, _y, width, height, pageX, pageY) => {
                    dispatch(
                      contextMenuActions.setContextMenu({
                        coordinates: {
                          top: pageY + height,
                          right: windowWidth.current - (pageX + width),
                        },
                        menu: (
                          <View style={contextMenuColorStyle}>
                            {moreTopics.current}
                          </View>
                        ),
                        parentUID: 'chat-topic-tab-more',
                      })
                    )
                  }
                )
              }
            }}
          />
        </View>
      )
      tabRenders.current['/more'] = renderedMoreButton
      renderedTopics.push(renderedMoreButton)
    } else {
      // If all topics fit in the available space, just render them
      let availableWidth = tabViewWidth.current || 0
      let allTabsWidth = 0
      Object.entries(tabRenderWidths.current).forEach(([topic, width]) => {
        if (topic !== '/more') {
          allTabsWidth += width
        }
      })

      if (allTabsWidth < availableWidth) {
        Object.entries(tabRenders.current).forEach(([topic, render]) => {
          if (topic !== '/more') {
            renderedTopics.push(render)
          }
        })
      } else {
        // If the topics do not fit, then, making sure the current topic is
        // included, see how many can fit in the avaiable space

        // the available space is less than before because we need to add a
        // "More..." button
        availableWidth -= tabRenderWidths.current['/more']

        // we should also take in account the width of the current topic tab
        availableWidth -= tabRenderWidths.current[routeTopicId] || 0

        for (const topic of sortedTopics) {
          if (topic.id === routeTopicId) {
            renderedTopics.push(tabRenders.current[topic.id])
            // Do not need to reduce `availableWidth` as current topic width is
            // already accounted for
          } else {
            const topicWidth = tabRenderWidths.current[topic.id]
            if (topicWidth <= availableWidth) {
              renderedTopics.push(tabRenders.current[topic.id])
              availableWidth -= topicWidth
            } else {
              // no more space in main topic tab area - add to more/overflow array
              moreTopics.current.push(
                <View key={`more-${topic.id}-${Date.now()}`}>
                  <Button
                    onPress={async () => {
                      dispatch(contextMenuActions.hideContextMenu())
                      navigation.setParams({ topicId: topic.id })
                    }}
                  >
                    <View
                      style={[styles.moreItemLabel, contextMenuDividerStyle]}
                    >
                      <Text>{topic.displayName || topic.id}</Text>
                    </View>
                  </Button>
                </View>
              )
              availableWidth = 0 // make sure no other tabs are added to main tab area
            }
          }
        }
      }
    }
  }
  const showTabsStyle = { opacity: showTabs ? 1 : 0 }
  return (
    <TabView
      containerStyle={[
        styles.container,
        borderColorStyle,
        style,
        showTabsStyle,
      ]}
      onLayout={(event) => {
        if (tabViewWidth.current !== event.nativeEvent.layout.width) {
          tabViewWidth.current = event.nativeEvent.layout.width
        }
      }}
    >
      <View style={styles.mainTabs}>{renderedTopics}</View>
      {moreTopics.current.length > 0 && (
        <View style={styles.more}>{tabRenders.current['/more']}</View>
      )}
    </TabView>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    container: {
      borderBottomWidth: 1,
      flexDirection: 'row',
      maxWidth: 800,
      paddingBottom: 8,
    },
    label: {
      fontSize: 18,
      marginRight: 40,
    },
    mainTabs: {
      flex: 1,
      flexDirection: 'row',
    },
    more: {},
    moreItemLabel: {
      borderTopWidth: 1,
      fontSize: 18,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 12,
    },
    moreLabel: {
      fontSize: 18,
    },
  }),
  multiverse2022: StyleSheet.create({
    container: {
      flexDirection: 'row',
      maxWidth: 800,
      paddingBottom: 32,
    },
    label: {
      fontSize: 18,
      marginRight: 40,
    },
    mainTabs: {
      flex: 1,
      flexDirection: 'row',
    },
    more: {},
    moreItemLabel: {
      borderTopWidth: 1,
      fontSize: 18,
      paddingBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 12,
    },
    moreLabel: {
      fontSize: 18,
    },
  }),
}

const predefinedSortRecordString: Record<string, number> = {}
PREDEFINED_TOPICS_SORT_ORDER.forEach((topic, index) => {
  predefinedSortRecordString[topic] = index
})
function sortTopicDocs(topicDocs: TopicDoc[]): TopicDoc[] {
  const presortedTopics: TopicDoc[] = []
  const rest: TopicDoc[] = []
  topicDocs.forEach((topic) => {
    if (predefinedSortRecordString[topic.id] != null) {
      presortedTopics[predefinedSortRecordString[topic.id]] = topic
    } else {
      rest.push(topic)
    }
  })
  return [...presortedTopics.filter((t) => t), ...rest]
}
