import React from 'react'
import { StyleProp, ViewStyle, StyleSheet } from 'react-native'
import { Svg, G, Path } from 'react-native-svg'

type Props = {
  style?: StyleProp<ViewStyle>
  color?: string
}

/**
 * Renders an SVG close X
 */
export function MenuIcon({ style, color }: Props): JSX.Element {
  return (
    <Svg
      viewBox="0 0 50 50"
      style={[styles.svg, style]}
      fill={color}
      stroke={color}
    >
      <G id="menu-hamburger">
        <Path d="M8.667,15h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,15,8.667,15z" />
        <Path d="M8.667,37h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,37,8.667,37z" />
        <Path d="M8.667,26h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,26,8.667,26z" />
      </G>
    </Svg>
  )
}
MenuIcon.defaultProps = {
  style: undefined,
  color: undefined,
}

const styles = StyleSheet.create({
  svg: {
    height: 32,
    paddingBottom: 4,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 4,
    width: 32,
  },
})
