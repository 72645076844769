/* eslint-disable react-native/no-unused-styles */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import {
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps,
} from 'react-native'
import { useThemeColors } from '../../constants/colors'
import { testIdToNativeId } from '../../helpers/testId'
import { useTheme } from '../../hooks/useTheme'
import { fonts } from '../../constants/fonts'

export { TextInput as TextInputRefType } from 'react-native'

function TextInputNeedRefForwarding(
  props: TextInputProps,
  ref: React.ForwardedRef<RNTextInput>
) {
  const { style, testID, ...otherProps } = props
  const { theme } = useTheme()
  const color = useThemeColors()
  const colorStyle = {
    ...color.textInput,
  }
  const styles = themedStyles[theme]
  const fontStyle = theme === 'hadron' ? undefined : fonts.FraktionMono.normal
  return (
    <RNTextInput
      nativeID={testIdToNativeId(testID, 'form-input-text')}
      testID={testID}
      ref={ref}
      style={[styles.textInput, fontStyle, colorStyle, style]}
      {...otherProps}
    />
  )
}
export const TextInput = React.forwardRef<RNTextInput, TextInputProps>(
  TextInputNeedRefForwarding
)

const themedStyles = {
  hadron: StyleSheet.create({
    textInput: {
      borderWidth: 1,
      fontSize: 20,
      paddingBottom: 6,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 6,
    },
  }),
  multiverse2022: StyleSheet.create({
    textInput: {
      borderRadius: 8,
      borderWidth: 1,
      fontSize: 18,
      lineHeight: 27,
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
    },
  }),
}
