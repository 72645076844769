import * as React from 'react'
import { StyleProp, TextStyle } from 'react-native'
import { Schedule } from '@mv/api/lib/src/schema/accounts/locks'
import { Text } from '..'
import { DocumentReference, getDoc } from '../../firebase/firestore'
import { logger } from '../../logger'

type Props = {
  schedule: DocumentReference<Schedule>
  style: StyleProp<TextStyle>
}
export function MyWalletRecentTransactionsUnlockMemo({
  schedule,
  style,
}: Props): JSX.Element | null {
  const [memo, setMemo] = React.useState(' ')
  const isMounted = React.useRef(true)

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    getDoc(schedule)
      .then((scheduleDoc) => {
        if (isMounted.current) {
          setMemo(scheduleDoc.data()?._.description || ' ')
        }
      })
      .catch((e) => {
        logger.error(`Failed to retrieve unlock schedule (${schedule.id})`, e)
      })
  }, [schedule])

  return <Text style={style}>{memo}</Text>
}
