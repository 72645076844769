export const ERROR_OOPS = 'Oops, something went wrong. Please try again later.'

export const ERROR_OOPS_SHORT = 'Oops, something went wrong.'

export const ERROR_USER_NOT_LOGGED_IN = 'User not logged in.'

export const ERROR_VERIFICATION_LOCK_OUT =
  'Verification codes are unavailable due to too many failed attempts. Please try again later.'

export const PLANET_NOT_FOUND =
  'Sorry, no planet found with this symbol or address!'
