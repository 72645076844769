import * as React from 'react'
import { Screen, Heading } from '../../Screen'
import { CreateOrEditPlanetForm } from '../CreateOrEditPlanetForm'

export function CreatePlanetScreen(): JSX.Element {
  return (
    <Screen testID="screen-planet-create">
      <Heading>Create a Planet</Heading>
      <CreateOrEditPlanetForm />
    </Screen>
  )
}
