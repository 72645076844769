import * as React from 'react'
import Constants from 'expo-constants'
import { ConstantsExtra } from '../types/appConfig'
import { useSelector } from '../state/store'

const featuresConfig = Constants.manifest.extra
  ?.features as ConstantsExtra['features']
const homeworldChatAndForumEnabled = featuresConfig.enableHomeworldChatAndForums

export function useFeatureHomeworldChat(): boolean {
  const { firebaseUser } = useSelector((state) => ({
    firebaseUser: state.user.user?.firebaseUser,
  }))
  const [showHomeworldChat, setShowHomeworldChat] = React.useState<boolean>(
    homeworldChatAndForumEnabled === true
  )
  React.useEffect(() => {
    if (homeworldChatAndForumEnabled === 'onlyOlderUsers') {
      const NINE_MONTHS = 1000 * 60 * 60 * 24 * 274
      if (firebaseUser && firebaseUser.metadata.creationTime) {
        setShowHomeworldChat(
          Date.parse(firebaseUser.metadata.creationTime) <
            Date.now() - NINE_MONTHS
        )
      }
    }
  }, [firebaseUser])

  return showHomeworldChat
}
