import * as React from 'react'
import { TabView } from '../../../TabView'
import { MyWalletStakingSummary } from '../../MyWalletStakingSummary'

export function StakingTab(): JSX.Element {
  return (
    <TabView>
      <MyWalletStakingSummary />
    </TabView>
  )
}
