import * as React from 'react'
import { StyleSheet } from 'react-native'
import Clipboard from 'expo-clipboard'
import { schema } from '@mv/api'
import { View } from '.'
import { useAdmin } from '../hooks/useAdmin'
import { useDispatch } from '../state/store'
import { modalsActions } from '../state/modalsSlice'
import { DisplayAddressDropDownMenuButton } from './DisplayAddressDropDownMenuButton'

type Props = {
  address: string
}

export function DisplayAddressDropDownMenu({ address }: Props): JSX.Element {
  const isAdmin = useAdmin('planetAdmin')
  const dispatch = useDispatch()

  return (
    <View style={[styles.menu]}>
      <DisplayAddressDropDownMenuButton
        label="Copy Address to Clipboard"
        onPress={async () => {
          Clipboard.setString(
            schema.accounts.convertEthereumAddressToDisplayAddress(address)
          )
        }}
      />
      {isAdmin && (
        <DisplayAddressDropDownMenuButton
          label="Send AI..."
          onPress={async () => {
            dispatch(
              modalsActions.showGenericModal({
                messages: [
                  'Transfer UI prepopulated with address here...',
                  address,
                ],
                addCloseLink: true,
              })
            )
          }}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  menu: {
    backgroundColor: 'rgba(240,240,240,0.9)',
  },
})
