import * as React from 'react'
import {
  PlanetProposal,
  PlanetSymbolDoc,
  planetPath,
  planetSymbolPath,
  planetsCollectionPath,
} from '@mv/api/lib/src/schema/planets'
import {
  convertDisplayAddressToEthereumAddress,
  addressDisplayPrefixReplacement,
} from '@mv/api/lib/src/schema/accounts'
import { logger } from '../logger'
import {
  db,
  doc,
  DocumentReference,
  getDoc,
  query,
  limit,
  where,
  collection,
  CollectionReference,
  getDocs,
} from '../firebase/firestore'

export function usePlanetFromPlanetLocator(
  planetLocator: string
): PlanetProposal | undefined | null {
  const [planet, setPlanet] = React.useState<PlanetProposal | undefined | null>(
    undefined
  )

  React.useEffect(() => {
    async function getPlanetFromPlanetLocator() {
      if (
        !(
          planetLocator.startsWith('0x') ||
          planetLocator.startsWith(addressDisplayPrefixReplacement)
        )
      ) {
        // planetLocator is likely a symbol
        const symbolDocSnapshot = await getDoc(
          doc(
            db,
            planetSymbolPath(planetLocator.trim().toUpperCase())
          ) as DocumentReference<PlanetSymbolDoc>
        )
        if (symbolDocSnapshot.exists()) {
          const symbolDoc = symbolDocSnapshot.data()
          const planetDocSnapshot = await getDoc(
            doc(
              db,
              planetPath(symbolDoc.planet)
            ) as DocumentReference<PlanetProposal>
          )
          if (planetDocSnapshot.exists()) {
            setPlanet(planetDocSnapshot.data())
            return
          }
        }
      }
      if (
        RegExp(`^(0x|${addressDisplayPrefixReplacement})[a-fA-F0-9]{40}$`).test(
          planetLocator
        )
      ) {
        // planetLocator appears to be a complete planetId
        const planetDocSnapshot = await getDoc(
          doc(
            db,
            planetPath(convertDisplayAddressToEthereumAddress(planetLocator))
          ) as DocumentReference<PlanetProposal>
        )
        if (planetDocSnapshot.exists()) {
          setPlanet(planetDocSnapshot.data())
          return
        }
      }
      if (
        planetLocator.startsWith('0x') ||
        planetLocator.startsWith(addressDisplayPrefixReplacement)
      ) {
        const normPlanetLocator =
          convertDisplayAddressToEthereumAddress(planetLocator).toLowerCase()
        // planetLocator is not an exact match for the planetId - try for a loose match
        const planetCollectionDocsSnapshot = await getDocs(
          query(
            collection(
              db,
              planetsCollectionPath
            ) as CollectionReference<PlanetProposal>,
            where('lowercaseId', '>=', normPlanetLocator),
            limit(2)
          )
        )
        if (!planetCollectionDocsSnapshot.empty) {
          const potentialPlanetDocs = planetCollectionDocsSnapshot.docs.map(
            (snapshot) => snapshot.data() as PlanetProposal
          )
          if (
            potentialPlanetDocs[0]?.lowercaseId.startsWith(normPlanetLocator) &&
            (potentialPlanetDocs.length === 1 ||
              !potentialPlanetDocs[1]?.lowercaseId.startsWith(
                normPlanetLocator
              ))
          ) {
            // one match, this is the planet we are looking for
            setPlanet(potentialPlanetDocs[0])
            return
          }
        }
      }
      setPlanet(null)
    }
    getPlanetFromPlanetLocator().catch((e) =>
      logger.error(
        'error encountered when getting planet from planetLocator: ',
        e
      )
    )
  }, [planetLocator])

  return planet
}
