import React from 'react'
import { RootState } from '../state/state'
import { useSelector } from '../state/store'
import { AccountLoginModal } from './AccountLoginModal'
import { AccountCreateModal } from './AccountCreateModal'
import { AccountForgotPasswordModal } from './AccountForgotPasswordModal'
import { AccountChangePasswordModal } from './AccountChangePasswordModal'
import { AccountEditProfileModal } from './AccountEditProfileModal'
import { WithdrawalModal } from './WithdrawalModal'
import { MetaMaskTransferModal } from './MetaMaskTransferModal'
import { TotpSetupModal } from './TotpSetupModal'
import { GenericModal } from './GenericModal'
import { logEvent, analytics } from '../firebase/analytics'
import { TransactionSuccessModal } from './TransactionSuccessModal'
import { StakeModal } from './StakeModal'
import { UnstakeModal } from './UnstakeModal'

export function Modal(): JSX.Element {
  const modal = useSelector((state: RootState) => state.modals)
  if (modal.displayModal) {
    logEvent(analytics(), 'screen_view', {
      firebase_screen: 'portal', // TODO: Set desired values for these required event params
      firebase_screen_class: 'modal', // TODO: Set desired values for these required event params
      app_name: 'portal',
      screen_name: `Modal ${modal.displayModal}`,
    })
  }
  switch (modal.displayModal) {
    case 'generic':
      return <GenericModal modal={modal.data} />
    case 'login':
      return <AccountLoginModal />
    case 'createAccount':
      return <AccountCreateModal />
    case 'forgotPassword':
      return <AccountForgotPasswordModal />
    case 'changePassword':
      return <AccountChangePasswordModal />
    case 'editProfile':
      return <AccountEditProfileModal />
    case 'withdrawal':
      return <WithdrawalModal />
    case 'metaMaskTransfer':
      return <MetaMaskTransferModal />
    case 'transactionSuccess':
      return <TransactionSuccessModal />
    case 'totpSetup':
      return <TotpSetupModal />
    case 'stake':
      return <StakeModal />
    case 'unstake':
      return <UnstakeModal />
    default:
      return <></>
  }
}
