import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { View, Text } from '..'
import { useThemeColors } from '../../constants/colors'
import { testIdToNativeId } from '../../helpers/testId'

type Props = {
  error?: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  testID?: string
}
export function Error({
  error,
  style,
  textStyle,
  testID,
}: Props): JSX.Element | null {
  const color = useThemeColors()
  const colorStyle = {
    ...color.textError,
  }

  if (!error) {
    return null
  }

  return (
    <View
      nativeID={testIdToNativeId(testID, 'form-error')}
      testID={testID}
      style={[styles.view, style]}
    >
      <Text style={[styles.text, colorStyle, textStyle]}>{error}</Text>
    </View>
  )
}
Error.defaultProps = {
  error: undefined,
  style: undefined,
  textStyle: undefined,
}

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    lineHeight: 16 * 1.2,
  },
  view: {
    paddingTop: 6,
  },
})
