import * as React from 'react'
import { StyleSheet, StyleProp, TextStyle } from 'react-native'
// Note: The documentation at https://docs.ethers.io/v5/cookbook/react-native/
// recommends importing "@ethersproject/shims" first, but that doesn't seem to work,
// and doesn't seem necessary for the small amount of functionality we need.
import { ethers } from 'ethers'
import MetaMaskOnboarding from '@metamask/onboarding'
import Constants from 'expo-constants'
import { token } from '@mv/api/lib/src/types'
import { getChecksumAddress } from '@mv/api/lib/src/types/ethereum'
import { logger } from '../../logger'
import { useDispatch, useSelector } from '../../state/store'
import { metaMaskActions } from '../../state/metaMaskSlice'
import { View, Text } from '..'
import { SubmitButton } from '../form'
import { LinkButton } from '../LinkButton'
import { Loading } from '../Loading'
import { useThemeColors } from '../../constants/colors'
import { MetaMask, MetaMaskIntegrationStage } from '../../state/metaMaskTypes'
import { logEvent, analytics } from '../../firebase/analytics'
import { ConstantsExtra } from '../../types/appConfig'

const onboarding = new MetaMaskOnboarding()

export function MyWalletSetupMetaMask(): JSX.Element {
  const dispatch = useDispatch()
  const { metaMask, metaMaskStage, connectedAccounts } = useSelector(
    (state) => ({
      metaMask: state.metaMask.metaMask,
      metaMaskStage: state.metaMask.metaMaskIntegrationStage,
      connectedAccounts: state.metaMask.connectedAccounts,
    })
  )
  const colors = useThemeColors()

  const [metaMaskButtonRender, setMetaMaskButtonRender] = React.useState(
    <Loading size="small" />
  )
  const [metaMaskStepText, setMetaMaskStepText] = React.useState(<></>)
  const [metaMaskStepTextBelow, setMetaMaskStepTextBelow] = React.useState(
    <></>
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { ethereum }: { ethereum?: MetaMask['ethereum'] } = (window as any) || {
    ethereum: undefined,
  }

  const setMetaMaskWithChainId = React.useCallback(
    (chainId: MetaMask['chainId']) => {
      if (ethereum && chainId) {
        dispatch(metaMaskActions.setMetaMask({ ethereum, chainId }))
      }
    },
    [dispatch, ethereum]
  )
  const setMetaMaskStep = React.useCallback(
    (step: MetaMaskIntegrationStage) => {
      dispatch(metaMaskActions.setIntegrationStage(step))
    },
    [dispatch]
  )
  const setConnectedAccounts = React.useCallback(
    (accounts: string[]) => {
      dispatch(
        metaMaskActions.setConnectedAccounts(
          accounts
            .map((lowerCaseAddress) => {
              try {
                return getChecksumAddress(lowerCaseAddress)
              } catch (e) {
                logger.warn('Received invalid address from MetaMask')
                return ''
              }
            })
            .filter((x) => !!x)
        )
      )
    },
    [dispatch]
  )
  const setErc20Balance = React.useCallback(
    (balance: string) => {
      dispatch(metaMaskActions.setErc20Balance(balance))
    },
    [dispatch]
  )
  const resetMetaMaskState = React.useCallback(() => {
    dispatch(metaMaskActions.resetAll())
  }, [dispatch])

  React.useEffect(() => resetMetaMaskState, [resetMetaMaskState])

  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled() && ethereum) {
      // Lookup and subscribe to changes to the chainId and accounts.
      ethereum
        .request({ method: 'eth_chainId' })
        .then(setMetaMaskWithChainId)
        .catch((e) => {
          logger.warn('Unable to identify metamask ethereum chain', e)
        })
      ethereum
        .request({ method: 'eth_accounts' })
        .then(setConnectedAccounts)
        .catch((e) => {
          logger.warn('Unable to retrieve metamask connected accounts', e)
        })
      ethereum.on('chainChanged', setMetaMaskWithChainId)
      ethereum.on('accountsChanged', setConnectedAccounts)

      // Remove listeners when unloading the component.
      return () => {
        logger.info('Unsubscribing from MetaMask')
        ethereum.removeListener('chainChanged', setMetaMaskWithChainId)
        ethereum.removeListener('accountsChanged', setConnectedAccounts)
      }
    }
    return () => {}
  }, [ethereum, setMetaMaskWithChainId, setConnectedAccounts])

  React.useEffect(() => {
    if (!metaMask) {
      // INSTALL METAMASK
      setMetaMaskStep('install')
      return
    }

    onboarding.stopOnboarding()

    if (metaMask?.chainId !== config.chainId) {
      // SWITCH METAMASK CHAIN
      setMetaMaskStep('switchChain')
    } else if (connectedAccounts.length === 0) {
      // CONNECT METAMASK ACCOUNTS TO MULTIVERSE
      setMetaMaskStep('connect')
    } else {
      // READY TO TRANSFER
      setMetaMaskStep('ready')
    }
  }, [setMetaMaskStep, metaMask, connectedAccounts])

  React.useEffect(() => {
    if (metaMask && connectedAccounts.length > 0) {
      const address = connectedAccounts[0]
      getERC20Balance(metaMask, address)
        .then((balance) => {
          setErc20Balance(balance)
        })
        .catch((e) => logger.error(e))
    } else {
      setErc20Balance('') // Clear any balance from a disconnected account
    }
  }, [setErc20Balance, metaMask, connectedAccounts])

  React.useEffect(() => {
    if (metaMaskStage === 'install') {
      setMetaMaskButtonRender(
        InstallMetaMaskButton({
          onBoardingFunction: onboarding.startOnboarding,
        })
      )
      setMetaMaskStepText(<></>)
      setMetaMaskStepTextBelow(
        <Text style={[styles.smallText, { color: colors.medium }]}>
          Requires Chrome, Firefox, or Edge browsers running on desktop
        </Text>
      )
    } else if (metaMaskStage === 'switchChain') {
      setMetaMaskButtonRender(SwitchChainButton({ metaMask }))
      setMetaMaskStepText(
        <Text style={styles.text}>
          Please switch to the {config.chainName} in MetaMask
        </Text>
      )
      setMetaMaskStepTextBelow(
        <MathWalletWarning
          isMathWallet={ethereum?.isMathWallet}
          errorStyle={colors.textError}
        />
      )
    } else if (metaMaskStage === 'connect') {
      setMetaMaskButtonRender(ConnectAccountButton({ metaMask }))
      setMetaMaskStepText(
        <Text style={styles.text}>
          Be sure to select a MetaMask Wallet with ERC20 AI tokens.
        </Text>
      )
      setMetaMaskStepTextBelow(
        <MathWalletWarning
          isMathWallet={ethereum?.isMathWallet}
          errorStyle={colors.textError}
        />
      )
    } else if (metaMaskStage === 'ready') {
      setMetaMaskButtonRender(
        <View>
          <ConnectAccountButton metaMask={metaMask} alreadyConnected />
          <LinkButton
            onPress={async () => {
              if (metaMask) {
                logEvent(analytics(), 'wallet', { click: 'add token' })
                await metaMask.ethereum
                  .request({
                    method: 'wallet_watchAsset',
                    params: {
                      type: 'ERC20', // Initially only supports ERC20, but eventually more!
                      options: {
                        address: multiverseContract.address, // The address that the token is at.
                        symbol: 'AI', // A ticker symbol or shorthand, up to 11 chars.
                        decimals: 18, // The number of decimals in the token
                        image:
                          'https://portal.multiverse.ai/images/ai-icon.svg?v=1', // A string url of the token logo
                      },
                    },
                  })
                  .catch((e: unknown) => logger.warn(e))
              }
              return false
            }}
            label="Add Multiverse Contract to MetaMask"
          />
        </View>
      )
      setMetaMaskStepText(
        <View>
          <Text style={styles.text}>
            MetaMask setup and integration is now complete and you are ready to
            use MetaMask to deposit your AI into the Multiverse.
          </Text>
          <Text style={styles.text}>
            You can connect additional MetaMask accounts to Multiverse by using
            the link below.
          </Text>
        </View>
      )
      setMetaMaskStepTextBelow(
        <MathWalletWarning
          isMathWallet={ethereum?.isMathWallet}
          errorStyle={colors.textError}
        />
      )
    }
  }, [
    metaMaskStage,
    metaMask,
    colors.medium,
    colors.textError,
    ethereum?.isMathWallet,
  ])

  return (
    <View>
      {metaMaskStage !== 'ready' && (
        <Text style={styles.text}>
          We&apos;ve integrated with MetaMask to make transfering ERC20 AI
          tokens into your Multiverse Wallet easy.
        </Text>
      )}
      {metaMaskStepText}
      <View style={styles.buttonRow}>{metaMaskButtonRender}</View>
      {metaMaskStepTextBelow}
    </View>
  )
}

const constantsExtra = Constants.manifest.extra as ConstantsExtra
const { config } = constantsExtra
const { abi } = constantsExtra.contracts.multiverse
const multiverseContract = new ethers.Contract(config.multiverseContract, abi)

async function getERC20Balance(
  metaMask: MetaMask,
  address: string
): Promise<string> {
  const txn = await multiverseContract.populateTransaction.balanceOf(address)
  const balance = (await metaMask.ethereum.request({
    method: 'eth_call',
    params: [txn],
  })) as string
  // Note: "0x" is returned for a 0 balance, which BigInt doesn't like.
  return token.toDecimalValue(BigInt(balance === '0x' ? '0x0' : balance), 18)
}

export function InstallMetaMaskButton({
  onBoardingFunction,
  disabled = undefined,
}: {
  onBoardingFunction: () => void
  disabled?: boolean
}): JSX.Element {
  if (disabled) {
    return (
      <SubmitButton
        label="MetaMask Already Installed"
        onPress={async () => false}
        limitWidth
        disabled
      />
    )
  }
  return (
    <SubmitButton
      label="Install MetaMask"
      onPress={async () => {
        onBoardingFunction()
        logEvent(analytics(), 'wallet', { click: 'install metamask' })
        return false
      }}
      limitWidth
    />
  )
}

export function SwitchChainButton({
  metaMask,
  disabled = undefined,
}: {
  metaMask?: MetaMask
  disabled?: boolean
}): JSX.Element {
  if (!metaMask) {
    return (
      <SubmitButton
        onPress={async () => false}
        label={`Switch to ${config.chainName} in MetaMask`}
        disabled
        limitWidth
      />
    )
  }
  if (disabled) {
    return (
      <SubmitButton
        onPress={async () => false}
        label="MetaMask on Correct Ethereum Chain"
        disabled
        limitWidth
      />
    )
  }
  return (
    <SubmitButton
      onPress={async () => {
        logEvent(analytics(), 'wallet', { click: 'switch metamask chain' })
        await metaMask.ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: config.chainId }],
          })
          .then(() => false) // handled by ethereum.on('accountsChanged') listener
          .catch((e: unknown) => {
            logger.warn(e)
          }) // e.g. if the user cancels
        return false
      }}
      label={`Switch to ${config.chainName} in MetaMask`}
      limitWidth
    />
  )
}

export function ConnectAccountButton({
  metaMask,
  alreadyConnected = undefined,
  disabled = undefined,
}: {
  metaMask?: MetaMask
  alreadyConnected?: boolean
  disabled?: boolean
}): JSX.Element {
  if (!metaMask || disabled) {
    return (
      <SubmitButton
        onPress={async () => false}
        label="Connect MetaMask Wallets"
        disabled
        limitWidth
      />
    )
  }
  if (alreadyConnected) {
    return (
      <LinkButton
        onPress={async () => {
          logEvent(analytics(), 'wallet', { click: 'connect metamask' })
          await metaMask.ethereum
            .request({
              method: 'wallet_requestPermissions',
              params: [{ eth_accounts: {} }],
            })
            .then(() => false) // handled by ethereum.on('accountsChanged') listener
            .catch((e: unknown) => logger.warn(e)) // e.g. if the user cancels
          return false
        }}
        label="Add MetaMask Wallets"
      />
    )
  }
  return (
    <SubmitButton
      onPress={async () => {
        logEvent(analytics(), 'wallet', { click: 'connect metamask' })
        await metaMask.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(() => false) // handled by ethereum.on('accountsChanged') listener
          .catch((e: unknown) => logger.warn(e)) // e.g. if the user cancels
        return false
      }}
      label="Connect MetaMask Wallets"
      limitWidth
    />
  )
}

function MathWalletWarning({
  isMathWallet,
  errorStyle,
}: {
  isMathWallet: true | undefined
  errorStyle: StyleProp<TextStyle>
}): JSX.Element {
  if (isMathWallet) {
    return (
      <View style={styles.warningView}>
        <Text style={[styles.smallText, errorStyle]}>
          Having MathWallet and MetaMask installed at the same time may cause
          problems.
        </Text>
        <Text style={[styles.smallText, errorStyle]}>
          Please uninstall MathWallet.
        </Text>
      </View>
    )
  }
  return <></>
}

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  smallText: {
    fontSize: 14,
    paddingBottom: 8,
  },
  text: {
    paddingBottom: 8,
  },
  warningView: {
    marginTop: 4,
  },
})
