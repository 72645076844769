import * as React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { Post, ReactionType } from '@mv/api/lib/src/schema/forums'
import { useSelector } from '../../state/store'
import { PlanetForumPostActionsReactionButton } from './PlanetForumPostActionsReactionButton'

const adminReactions: ReactionType[] = ['🦄', '🦟']

type Props = {
  post: Post
  userReactions?: Partial<Record<ReactionType, true>>
  buttonStyle?: StyleProp<ViewStyle>
}
export function PlanetForumPostActionsAdminReactionButtons({
  post,
  userReactions,
  buttonStyle = undefined,
}: Props): JSX.Element | null {
  const user = useSelector((state) => state.user)

  const rendered = adminReactions.map((reaction) => {
    let userReactionActive = 'unknown'
    if (userReactions) {
      userReactionActive = userReactions[reaction] ? 'active' : 'inactive'
    }

    return (
      <PlanetForumPostActionsReactionButton
        testID={`reaction-${reaction}-${userReactionActive}`}
        key={`reaction-${reaction}`}
        post={post}
        reaction={reaction}
        userReacted={userReactions?.[reaction] || false}
        user={user}
        style={buttonStyle}
      />
    )
  })
  return <>{rendered}</>
}
