import * as React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { Text, View } from '..'
import { Screen, Heading } from '../Screen'
import { useSelector } from '../../state/store'
import { SubmitButton } from '../form'
import {
  User as FirebaseUser,
  sendEmailVerification,
} from '../../firebase/auth'
import { useScreenMargins } from '../../hooks/useScreenMargins'
// import { HomeworldNavigator } from '../../navigation/HomeworldNavigator'
import { AnimateDownFadeIn } from '../animations/AnimateDownFadeIn'

export function DefaultStartScreen(): JSX.Element {
  const { user } = useSelector((state) => ({
    user: state.user.user,
  }))
  const screenMargins = useScreenMargins().style

  const marginStyle = {
    marginLeft: Math.min(screenMargins.marginLeft, 25),
    marginRight: Math.min(screenMargins.marginRight, 25),
    marginTop: Math.min(screenMargins.marginTop, 25),
    marginBottom: 0,
  }

  const welcomeMessage =
    "Hi, you've reached a web page for Multiverse users. Please login or create an account to continue."

  if (!user) {
    return (
      <Screen testID="screen-loggedOut">
        <Heading>Multiverse Home</Heading>
        <Text>{welcomeMessage}</Text>
      </Screen>
    )
  }

  return (
    <Screen testID="screen-loggedIn" scrollable={false} noMargins>
      <View style={styles.innerContainer}>
        {user.firebaseUser.uid === user.fuid && (
          <VerificationButton
            firebaseUser={user.firebaseUser}
            style={marginStyle}
          />
        )}
        <Text>Welcome to the Multiverse Portal!</Text>
      </View>
    </Screen>
  )
}

function VerificationButton({
  style,
  firebaseUser,
}: {
  style?: StyleProp<ViewStyle>
  firebaseUser: FirebaseUser | undefined
}): JSX.Element | null {
  let rendered: React.ReactNode = null
  if (!firebaseUser || firebaseUser.emailVerified) {
    rendered = null
  } else {
    rendered = (
      <View style={[styles.container, style]}>
        <Text>
          Your email has not yet been verified. Please check your email for
          verification instructions.
        </Text>
        <View style={styles.submitView}>
          <SubmitButton
            label="Resend Verification Email"
            onPress={async () => {
              await sendEmailVerification(firebaseUser)
              return false
            }}
            limitWidth
          />
        </View>
      </View>
    )
  }

  return <AnimateDownFadeIn>{rendered}</AnimateDownFadeIn>
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(220,220,255, 0.5)',
    borderRadius: 25,
    marginBottom: 20,
    paddingBottom: 20,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
  },
  innerContainer: { flex: 1 },
  submitView: {
    flexDirection: 'row',
    marginTop: 8,
  },
})
