import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from '../../state/store'
import { modalsActions } from '../../state/modalsSlice'
import { UserState } from '../../state/userTypes'
import { View, Text } from '..'
import { SubmitButton } from '../form'
import { MetaMask } from '../../state/metaMaskTypes'

export function MyWalletTransferIn(): JSX.Element {
  const dispatch = useDispatch()
  const { user, metaMask, metaMaskStage, erc20Balance } = useSelector(
    (state) => ({
      user: state.user,
      metaMask: state.metaMask.metaMask,
      metaMaskStage: state.metaMask.metaMaskIntegrationStage,
      erc20Balance: state.metaMask.erc20Balance,
    })
  )

  const transferDisabled = !metaMask || metaMaskStage !== 'ready'
  return (
    <View>
      <Text style={styles.text}>
        ERC20 AI tokens can be swapped into your Multiverse Wallet from an
        Ethereum Wallet. Ethereum gas fees will apply.
      </Text>
      <View style={styles.buttonRow}>
        <TransferAIButton
          erc20Balance={erc20Balance}
          metaMask={metaMask}
          user={user}
          dispatch={dispatch}
          disabled={transferDisabled}
        />
      </View>
    </View>
  )
}

function TransferAIButton({
  dispatch,
  erc20Balance,
  metaMask,
  user,
  disabled = undefined,
}: {
  erc20Balance: string
  metaMask?: MetaMask
  user: UserState
  dispatch: ReturnType<typeof useDispatch>
  disabled?: boolean
}): JSX.Element {
  const label = 'Deposit AI from MetaMask'
  if (!metaMask) {
    return (
      <SubmitButton
        onPress={async () => false}
        label={label}
        disabled
        limitWidth
      />
    )
  }
  return (
    <SubmitButton
      onPress={async () => {
        dispatch(
          modalsActions.showMetaMaskTransferModal({
            maxAmount: erc20Balance,
            selectedAddress: metaMask.ethereum.selectedAddress,
            userAddress: user.user?.account?.address || '',
            metaMask,
          })
        )
        return false
      }}
      label={label}
      disabled={disabled}
      limitWidth
    />
  )
}

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  text: {
    paddingBottom: 8,
  },
})
