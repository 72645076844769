import { TopicId, PostId } from '@mv/api/lib/src/schema/forums'
import { planet } from '@mv/api/lib/src/types'

export type CacheItem<T> = {
  data: T
  lastRetrieved: Date
}

export type DisplayNameCacheItem = CacheItem<string>
export const DISPLAY_NAME_CACHE_EXPIRATION_TIME_IN_MS = 1000 * 60 // one minute

type Address = string
export type FullPostId = {
  planetId: planet.Id
  topicId: TopicId
  postId: PostId
}
type StringifiedFullPostId = string
export type PostSummary = {
  authorAddress: Address
  text: string
}
export type CacheState = {
  displayNames: Record<Address, DisplayNameCacheItem>
  postSummaries: Record<StringifiedFullPostId, CacheItem<PostSummary>>
}
export function stringifyFullPostId({
  planetId,
  topicId,
  postId,
}: FullPostId): StringifiedFullPostId {
  return `${planetId}/${topicId}/${postId}`
}

export function initialState(): CacheState {
  return {
    displayNames: {},
    postSummaries: {},
  }
}
