import { AdminRole } from '@mv/api/lib/src/schema/accounts'
import { useSelector } from '../state/store'
import { useSuperAdminClaim } from './useSuperAdminClaim'

export function useAdmin(type: AdminRole): boolean {
  const user = useSelector((state) => state.user)
  const isSuperAdmin = useSuperAdminClaim()
  if (isSuperAdmin) {
    return true
  }
  return !!user.user?.account?.roles?.includes(type)
}
