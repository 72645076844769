import * as React from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import {
  DrawerNavigationRoute,
  MyWalletTabParamList,
  MyWalletTabProps,
  MyWalletTabRoute,
} from '../../navigation/types'
import { Tabs as GenericTabs } from '../Tabs'
import { useScreenMargins } from '../../hooks/useScreenMargins'

type TabDescription<ParamList> = {
  name: string
  screen: keyof ParamList
}
const tabs: TabDescription<MyWalletTabParamList>[] = [
  { name: 'Overview', screen: 'MyWalletOverview' },
  { name: 'History', screen: 'MyWalletHistory' },
  { name: 'Staking', screen: 'MyWalletStaking' },
]

export function Tabs(): JSX.Element {
  const navigation = useNavigation<MyWalletTabProps>()
  const route = useRoute<MyWalletTabRoute | DrawerNavigationRoute>()
  const { marginLeft, marginRight } = useScreenMargins().style
  const marginStyle = {
    marginLeft,
    marginRight,
  }

  const tabDescriptionArray = tabs.map((tab) => ({
    name: tab.name,
    onPress: () => {
      navigation.navigate(tab.screen)
    },
    isActive: tab.screen === route.name,
  }))

  return (
    <GenericTabs
      textPreset="largeTextLink"
      style={marginStyle}
      tabs={tabDescriptionArray}
    />
  )
}
