import * as React from 'react'
import { StyleSheet } from 'react-native'
import { View, Text } from '..'
import { testIdToNativeId } from '../../helpers/testId'

export function ConcludingMessage({
  children,
  testID,
}: {
  children: React.ReactNode
  testID?: string
}): JSX.Element {
  return (
    <View
      nativeID={testIdToNativeId(testID, 'form-concluding-message')}
      testID={testID}
      style={styles.labelView}
    >
      <Text style={styles.labelText}>{children}</Text>
    </View>
  )
}
const styles = StyleSheet.create({
  labelText: {
    fontSize: 18,
    lineHeight: 18 * 1.2,
  },
  labelView: {
    marginBottom: 40,
    marginTop: 40,
  },
})
