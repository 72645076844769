import * as React from 'react'
import { StyleSheet } from 'react-native'
import { token, planet, balance } from '@mv/api/lib/src/types'
import { INITIAL_PLEDGE_LIMIT } from '@mv/api/lib/src/requests/planet'
import { logger } from '../../logger'
import { View, Text, Section } from '..'
import * as Form from '../form'
import { RootState } from '../../state/state'
import { useSelector } from '../../state/store'
import { FunctionsError, makeCallableFunction } from '../../firebase/functions'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../../constants/messages'
import { NoRefresh } from '../NoRefresh'

type FormError = {
  amount?: string
  generic?: string
}

type Props = {
  planetId: planet.Id
}
export function PlanetPledgeForm({ planetId }: Props): JSX.Element {
  const user = useSelector((state) => state.user)

  const [amount, setAmount] = React.useState('')

  const [formError, setFormError] = React.useState({} as FormError)

  const submitRef = React.useRef<Form.SubmitButtonRefType>(null)

  return (
    <Section>
      <Text>
        Consider making a non-binding pledge of AI to show your support or
        interest in this planet
      </Text>
      <View style={styles.form}>
        <NoRefresh>
          <Form.Item>
            <Form.TextInput
              placeholder="Amount of AI to pledge"
              value={amount}
              onChangeText={(textValue) => {
                setAmount(textValue.replace(/[^0-9.]/, ''))
              }}
              onKeyPress={(event) => {
                switch (event.nativeEvent.key) {
                  case 'Enter':
                    if (amount && submitRef.current?.onPress) {
                      submitRef.current.onPress().catch((e) => {
                        logger.warn('unexpected promise rejection on submit', e)
                      })
                    }
                    break
                  default:
                }
              }}
            />
            <Form.Error error={formError.amount} />
          </Form.Item>
          <Form.SubmitButton
            ref={submitRef}
            label="Save"
            onPress={async () => {
              setFormError({})
              await submitPledge(
                planetId,
                token.decimalValueToUnits(amount),
                user,
                setFormError
              )
              return false
            }}
            limitWidth
          />
          <Form.Error error={formError.generic} />
        </NoRefresh>
      </View>
    </Section>
  )
}

const styles = StyleSheet.create({
  form: {
    marginTop: 12,
    maxWidth: 300,
  },
})

async function submitPledge(
  planetId: planet.Id,
  amount: token.Units,
  user: RootState['user'],
  setFormError: React.Dispatch<React.SetStateAction<FormError>>
): Promise<void> {
  if (!user.user) {
    setFormError({
      generic: ERROR_USER_NOT_LOGGED_IN,
    })
    return
  }

  const userAIBalanceAmounts = user.user.balances?.[token.AI]._
  const userAITotalBalance = userAIBalanceAmounts
    ? BigInt(balance.total(userAIBalanceAmounts))
    : 0n
  try {
    const bigIntAmount = BigInt(amount || '0')
    if (
      bigIntAmount > userAITotalBalance &&
      bigIntAmount > INITIAL_PLEDGE_LIMIT
    ) {
      setFormError({
        amount: `You cannot currently pledge more than ${
          userAITotalBalance > INITIAL_PLEDGE_LIMIT
            ? token.approximate(userAITotalBalance)
            : Math.floor(token.approximate(INITIAL_PLEDGE_LIMIT))
        } AI`,
      })
      return
    }
  } catch (e) {
    setFormError({ amount: 'Invalid amount entered' })
    return
  }

  const makePledge = makeCallableFunction('planet-pledge')
  try {
    const response = await makePledge({
      id: user.user.fuid,
      planetId,
      amount,
    })
    const responseData = response.data
    if (responseData && responseData.success) {
      return
    }
    throw new Error('Unexpected response received after pledge call concluded')
  } catch (_e) {
    const e = _e as FunctionsError
    const errors: FormError = {}
    logger.error('pledge error', e)
    switch (e.code) {
      case 'invalid-argument':
        if (/token/i.test(e.message)) {
          if (/Invalid token amount/.test(e.message)) {
            errors.amount = 'Invalid token amount'
          } else {
            errors.amount = e.message
          }
        } else {
          errors.generic = ERROR_OOPS
        }
        break
      default:
        errors.generic = ERROR_OOPS
    }
    setFormError(errors)
  }
}
