import * as React from 'react'
import { StyleSheet } from 'react-native'
import { logger } from '../logger'
import { Modal, Heading } from './ModalElements'
import * as Form from '../components/form'
import { Button } from '../components/Button'
import { View } from '../components'
import { useThemeColors } from '../constants/colors'
import { useDispatch, useSelector } from '../state/store'
import { modalsActions } from '../state/modalsSlice'
import { ModalStringData } from '../state/modalsTypes'
import { makeCallableFunction } from '../firebase/functions'
import { logEvent, analytics } from '../firebase/analytics'
import {
  auth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from '../firebase/auth'

type FormError = {
  email?: string
  password?: string
  confirmPassword?: string
  termsCheckbox?: string
  verifyAgeCheckbox?: string
  emailContactCheckbox?: string
  notUSCheckbox?: string
  generic?: string
}
export function AccountCreateModal(): JSX.Element {
  const dispatch = useDispatch()
  const prefillData = useSelector(
    (state) => state.modals.data
  ) as ModalStringData

  const [email, setEmail] = React.useState(prefillData?.email ?? '')
  const [password, setPassword] = React.useState(prefillData?.password ?? '')
  const [confirmPassword, setConfirmPassword] = React.useState(
    prefillData?.confirmPassword ?? ''
  )
  const [hidePassword, setHidePassword] = React.useState(true)
  const [hideConfirmPassword, setHideConfirmPassword] = React.useState(true)
  const [formError, setFormError] = React.useState({} as FormError)
  const [disabled, setDisabled] = React.useState(true)
  const [termsCheckbox, setTermsCheckbox] = React.useState(false)
  const [verifyAgeCheckbox, setVerifyAgeCheckbox] = React.useState(false)
  const [emailContactCheckbox, setEmailContactCheckbox] = React.useState(false)
  const [notUSCheckbox, setNotUSCheckbox] = React.useState(false)

  React.useEffect(() => {
    if (
      email &&
      password &&
      confirmPassword &&
      termsCheckbox &&
      verifyAgeCheckbox &&
      emailContactCheckbox &&
      notUSCheckbox
    ) {
      if (disabled) setDisabled(false)
    } else if (!disabled) setDisabled(true)
  }, [
    email,
    password,
    confirmPassword,
    termsCheckbox,
    verifyAgeCheckbox,
    emailContactCheckbox,
    notUSCheckbox,
    disabled,
  ])

  const color = useThemeColors().button
  const showPasswordButtonColorStyles = color.formLabelAux.container
  const showPasswordButtonLabelColorStyles = color.formLabelAux.label

  return (
    <Modal testID="account-create">
      <Heading>Create Your Multiverse&nbsp;Account</Heading>
      <Form.Item>
        <Form.Label>Email address</Form.Label>
        <Form.TextInput
          value={email}
          onChangeText={(textValue) => {
            setEmail(textValue)
          }}
          autoCompleteType="email"
        />
        <Form.Error error={formError.email} />
      </Form.Item>
      <Form.Item>
        <View style={styles.passwordLabelRow}>
          <Form.Label>Password</Form.Label>
          <Button
            label={hidePassword ? 'show password' : 'hide password'}
            style={[styles.showPasswordButton, showPasswordButtonColorStyles]}
            labelStyle={[
              styles.showPasswordButtonLabel,
              showPasswordButtonLabelColorStyles,
            ]}
            onPress={async () => {
              setHidePassword(!hidePassword)
            }}
          />
        </View>
        <Form.TextInput
          value={password}
          onChangeText={(textValue) => {
            setPassword(textValue)
          }}
          autoCompleteType="password"
          secureTextEntry={hidePassword}
        />
        <Form.Error error={formError.password} />
      </Form.Item>
      <Form.Item>
        <View style={styles.passwordLabelRow}>
          <Form.Label>Confirm Password</Form.Label>
          <Button
            label={hideConfirmPassword ? 'show password' : 'hide password'}
            style={[styles.showPasswordButton, showPasswordButtonColorStyles]}
            labelStyle={[
              styles.showPasswordButtonLabel,
              showPasswordButtonLabelColorStyles,
            ]}
            onPress={async () => {
              setHideConfirmPassword(!hideConfirmPassword)
            }}
          />
        </View>
        <Form.TextInput
          value={confirmPassword}
          onChangeText={(textValue) => {
            setConfirmPassword(textValue)
          }}
          autoCompleteType="password"
          secureTextEntry={hideConfirmPassword}
        />
        <Form.Error error={formError.confirmPassword || formError.generic} />
      </Form.Item>
      <Form.Item style={styles.checkboxContainer}>
        <Form.Label
          style={styles.cookieNotice}
          labelStyle={styles.cookieNoticeLabel}
        >
          Multiverse Labs uses cookies and stores your private data to provide
          the Multiverse service
        </Form.Label>
        <Form.CheckBox
          label="I agree to Multiverse Labs' Terms of Use and Privacy Policy."
          labelStyle={styles.checkboxLabel}
          onChangeValue={(newValue) => {
            setTermsCheckbox(newValue)
          }}
          defaultValue={termsCheckbox}
        />
        <Form.Error
          error={formError.termsCheckbox}
          style={styles.checkboxError}
          textStyle={styles.checkboxErrorText}
        />
        <Form.CheckBox
          label="I am 16 or older."
          labelStyle={styles.checkboxLabel}
          onChangeValue={(newValue) => {
            setVerifyAgeCheckbox(newValue)
          }}
          defaultValue={verifyAgeCheckbox}
        />
        <Form.Error
          error={formError.verifyAgeCheckbox}
          style={styles.checkboxError}
          textStyle={styles.checkboxErrorText}
        />
        <Form.CheckBox
          label="I agree to allow Multiverse Labs to contact me via email."
          labelStyle={styles.checkboxLabel}
          onChangeValue={(newValue) => {
            setEmailContactCheckbox(newValue)
          }}
          defaultValue={emailContactCheckbox}
        />
        <Form.Error
          error={formError.emailContactCheckbox}
          style={styles.checkboxError}
          textStyle={styles.checkboxErrorText}
        />
        <Form.CheckBox
          label="I am not a US citizen nor a US resident."
          labelStyle={styles.checkboxLabel}
          onChangeValue={(newValue) => {
            setNotUSCheckbox(newValue)
          }}
          defaultValue={notUSCheckbox}
        />
        <Form.Error
          error={formError.notUSCheckbox}
          style={styles.checkboxError}
          textStyle={styles.checkboxErrorText}
        />
      </Form.Item>

      <Form.SubmitButton
        label="Create Account"
        onPress={async () => {
          setFormError({})
          await submitEmailAndPassword(
            email,
            password,
            confirmPassword,
            termsCheckbox,
            verifyAgeCheckbox,
            emailContactCheckbox,
            notUSCheckbox,
            setFormError
          )
          return false
        }}
        disabled={disabled}
      />
      <Form.Alternative.Row>
        <Form.Alternative.Link
          textLabel="Already have an account?"
          label="Login"
          onPress={() => {
            dispatch(modalsActions.showLoginModal({ email, password }))
          }}
        />
      </Form.Alternative.Row>
    </Modal>
  )
}

const styles = StyleSheet.create({
  checkboxContainer: {
    marginBottom: 40,
    marginTop: 20,
  },
  checkboxError: {
    marginLeft: 20,
    paddingTop: 0,
  },
  checkboxErrorText: {
    fontSize: 12,
  },
  checkboxLabel: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  cookieNotice: {
    alignSelf: 'center',
    marginBottom: 20,
    width: '60%',
  },
  cookieNoticeLabel: {
    fontSize: 12,
    fontStyle: 'italic',
    lineHeight: 12 * 1.5,
  },
  passwordLabelRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  showPasswordButton: {
    borderRadius: 5,
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    top: -5,
  },
  showPasswordButtonLabel: {
    fontSize: 14,
    lineHeight: 22,
  },
})

async function submitEmailAndPassword(
  email: string,
  password: string,
  confirmPassword: string,
  termsCheckbox: boolean,
  verifyAgeCheckbox: boolean,
  emailContactCheckbox: boolean,
  notUSCheckbox: boolean,
  setFormError: React.Dispatch<React.SetStateAction<FormError>>
): Promise<boolean> {
  const errors: FormError = {}
  if (!termsCheckbox) {
    errors.termsCheckbox =
      'You must agree to our policies to create an account.'
  }
  if (!verifyAgeCheckbox) {
    errors.verifyAgeCheckbox = 'You must be 16 or older to create an account.'
  }
  if (!emailContactCheckbox) {
    errors.emailContactCheckbox =
      'You must agree to allow us to email you to create an account.'
  }
  if (!notUSCheckbox) {
    errors.notUSCheckbox =
      'You must not be a US citizen or US resident to create an account.'
  }
  if (password !== confirmPassword) {
    errors.confirmPassword = 'Passwords do not match.'
  }
  if (Object.keys(errors).length > 0) {
    setFormError(errors)
    return false
  }
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  ).catch((error) => {
    switch (error.code) {
      case 'auth/invalid-email':
        setFormError({
          email: 'Please enter a valid email address.',
        })
        break
      case 'auth/email-already-in-use':
        setFormError({
          email:
            'Unable to use this email for a new account. Another account may already exist with this email address.',
        })
        break
      case 'auth/weak-password':
        // As of May 2021, Firebase's only password requirement is 6 characters or longer
        setFormError({
          password: `Please enter a longer or stronger password.`,
        })
        break
      case 'auth/operation-not-allowed':
        setFormError({
          generic:
            'Oops. We are currently not accepting new accounts. Please try again later.',
        })
        break
      default:
        setFormError({
          generic: error.message,
        })
    }
  })
  if (!userCredential || !userCredential.user) {
    return false
  }
  const { user } = userCredential

  logEvent(analytics(), 'sign_up', {
    uid: user.uid,
    email: user.email,
    emailVerified: user.emailVerified,
    displayName: user.displayName,
  })

  sendEmailVerification(user).catch((e) =>
    logger.warn('Email Verification Send Error: ', e)
  )
  const ensure = makeCallableFunction('account-ensure')
  return ensure({ id: user.uid })
    .then(() => true)
    .catch((error) => {
      logger.error(`ensure account failed for ${user.uid}`, error)
      return false
    })
}
