import * as React from 'react'
import {
  StyleSheet,
  StyleProp,
  TextStyle,
  ViewStyle,
  Platform,
  Linking,
  TouchableOpacity,
} from 'react-native'
import { Text, TextProps, View } from '..'
import { TextPreset } from '../Text'
import { testIdToNativeId } from '../../helpers/testId'

type ExternalLinkButtonProps = {
  color: string
  label: string
  labelStyle?: StyleProp<TextStyle>
  labelWeight?: TextProps['weight']
  labelPreset?: TextPreset
  style?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  url: string
  testID?: string
}
export function ExternalLinkButton(
  props: ExternalLinkButtonProps
): JSX.Element {
  const {
    url,
    color,
    label,
    labelStyle,
    labelWeight,
    labelPreset,
    style,
    containerStyle,
    testID,
  } = props
  const labelColorStyle = {
    color,
  }

  return (
    <View
      nativeID={testIdToNativeId(testID, 'button', label)}
      style={[styles.container, containerStyle]}
    >
      <TouchableOpacity
        onPress={async () => {
          if (url) {
            if (Platform.OS === 'web' && window) {
              window.open(url, '_blank')
            } else {
              await Linking.openURL(url)
            }
          }
        }}
        activeOpacity={0.8}
      >
        <View style={[styles.view, style]}>
          <Text
            preset={labelPreset}
            weight={labelWeight}
            style={[labelColorStyle, labelStyle]}
          >
            {label}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}
ExternalLinkButton.defaultProps = {
  labelStyle: undefined,
  labelWeight: undefined,
  style: undefined,
  containerStyle: undefined,
}

const styles = StyleSheet.create({
  container: {
    marginLeft: 10,
    marginRight: 10,
  },
  view: {
    marginBottom: 6,
    marginTop: 6,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
  },
})
