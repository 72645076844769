import React, { useState, useEffect } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { RootState } from '../state/state'
import { useSelector } from '../state/store'
import { notificationsActions } from '../state/notificationsSlice'
import { Notification } from '../state/notificationsTypes'
import { Text, View } from '.'
import { useThemeColors } from '../constants/colors'

export const Notifications = (): JSX.Element | null => {
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications
  )

  const [notificationsRender, setNotificationsRender] = useState(
    null as JSX.Element | null
  )

  // When notifications have changed, update the rendered notifications
  useEffect(() => {
    const unclearedNotifications = notifications.filter(
      (n) => !n.clearedTimestamp
    )
    if (unclearedNotifications.length) {
      setNotificationsRender(
        <>
          {notifications.map((notification) => (
            <NotificationDisplay
              key={notification.id}
              notification={notification}
            />
          ))}
        </>
      )
    } else {
      setNotificationsRender(null)
    }
  }, [notifications])

  if (notificationsRender) {
    return (
      <View style={styles._container} pointerEvents="box-none">
        <View style={styles.notifications}>{notificationsRender}</View>
      </View>
    )
  }
  return null
}

const styles = StyleSheet.create({
  _container: {
    alignItems: 'center',
    flex: 1,
    position: 'absolute',
    width: '100%',
    zIndex: 10,
  },
  closeContainer: {
    borderTopRightRadius: 10,
    bottom: 0,
    justifyContent: 'center',
    marginRight: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  closeText: {},
  notification: {
    borderRadius: 10,
    marginTop: 10,
    paddingBottom: 10,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 10,
  },
  notificationText: {},
  notifications: {
    flex: 1,
  },
})

type NotificationProps = {
  notification: Notification
}
const NotificationDisplay = ({
  notification,
}: NotificationProps): JSX.Element | null => {
  const { container: containerStyle, text: textStyle } =
    useThemeColors().notification
  const dispatch = useDispatch()
  if (notification.clearedTimestamp) {
    return null
  }
  const clearNotification = (id: Notification['id']) => {
    dispatch(notificationsActions.clearNotification(id))
  }
  return (
    <View style={[styles.notification, containerStyle]}>
      <TouchableOpacity
        style={styles.closeContainer}
        onPress={() => {
          clearNotification(notification.id)
        }}
      >
        <Text
          testID="close"
          weight="bold"
          style={[styles.closeText, textStyle]}
        >
          ✕
        </Text>
      </TouchableOpacity>

      <Text
        testID="label"
        weight="semiBold"
        style={[styles.notificationText, textStyle]}
      >
        {notification.label}
      </Text>
    </View>
  )
}
