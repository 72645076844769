import * as React from 'react'
import { StyleSheet } from 'react-native'
import { isEqual } from 'lodash'
import { planet } from '@mv/api/lib/src/types'
import {
  TopicId,
  PostDoc,
  ReactionType,
  Post,
  PostId,
  TopicDoc,
  topicPostsCollectionPath,
  topicPath,
} from '@mv/api/lib/src/schema/forums'
import {
  ReactionsPerPostDoc,
  reactionsPerPostCollectionPath,
} from '@mv/api/lib/src/schema/accounts/reactions'
import { Loading } from '../Loading'
import { View } from '..'
import {
  db,
  doc,
  getDocs,
  DocumentReference,
  collection,
  CollectionReference,
  onSnapshot,
  query,
  QuerySnapshot,
  where,
  orderBy,
  limit,
  startAfter,
  startAt,
} from '../../firebase/firestore'
import {
  PlanetChatPostForm,
  PlanetChatPostFormRefType,
} from './PlanetChatPostForm'
import { UserReactionLookup, PostsRecord } from './planetForumTypes'
import { useSelector, useDispatch } from '../../state/store'
// import { PlanetChatFlatList } from './PlanetChatFlatList'
import { PlanetChatScrollView } from './PlanetChatScrollView'
import { cacheActions } from '../../state/cacheSlice'
import { Props as PlanetForumPostTypingStatusProps } from './PlanetForumPostTypingStatus'
import { isAddressEqual } from '../../helpers'
import { logger } from '../../logger'

const INITIAL_POST_LIMIT = 25
const POST_LIMIT_CHUNK_SIZE = 25

type PostsData = {
  postIds: PostId[]
  postsRecord: PostsRecord
}

type Props = {
  planetId: planet.Id
  topicId: TopicId
}
export function PlanetChatRoom({
  planetId,
  topicId,
}: Props): JSX.Element | null {
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.user.user?.fuid)
  const userAddress = useSelector((state) => state.user.user?.account?.address)
  const isMounted = React.useRef(true)
  const [loading, setLoading] = React.useState(true)
  const [updating, setUpdating] = React.useState(false)
  const [postLimit, setPostLimit] = React.useState(INITIAL_POST_LIMIT)
  const olderPostsSnapshotSize = React.useRef(0)
  const [lastPostIdAtTimeOfLoad, setLastPostIdAtTimeOfLoad] =
    React.useState<PostId | null>(null)
  const [postsData, setPostsData] = React.useState<PostsData>({
    postIds: [],
    postsRecord: {},
  })
  const [morePostsExist, setMorePostsExist] = React.useState<boolean>(false)
  const [topicDoc, setTopicDoc] = React.useState<TopicDoc | undefined>(
    undefined
  )
  // const [postsMetadata, setPostsMetadata] = React.useState<PostsMetadata>({})
  const [userReactions, setUserReactions] = React.useState<UserReactionLookup>(
    {}
  )
  const [firstLoadTime] = React.useState(new Date())
  const [referencedPost, setReferencedPost] = React.useState<Post | undefined>(
    undefined
  )
  const postFormRef = React.useRef<PlanetChatPostFormRefType>(null)
  const [typingStatusLastTime, setTypingStatusLastTime] =
    React.useState<PlanetForumPostTypingStatusProps['lastTime']>(null)

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    setPostsData({
      postIds: [],
      postsRecord: {},
    })
    setLoading(true)

    const topicDocRef = doc(
      db,
      topicPath(planetId, topicId)
    ) as DocumentReference<TopicDoc>
    return onSnapshot(topicDocRef, (snapshot) => {
      if (isMounted.current) {
        setTopicDoc(snapshot.data())
      }
    })
  }, [planetId, topicId])

  // initial fetch of latest post
  React.useEffect(() => {
    const postsCollectionRef = collection(
      db,
      topicPostsCollectionPath(planetId, topicId)
    ) as CollectionReference<PostDoc>
    const latestPostQuery = query(
      postsCollectionRef,
      orderBy('postId', 'desc'),
      limit(1)
    )
    getDocs(latestPostQuery)
      .then((snapshot) => {
        if (snapshot.empty) {
          setLastPostIdAtTimeOfLoad('')
        } else {
          setLastPostIdAtTimeOfLoad(snapshot.docs[0].data().postId)
        }
      })
      .catch((e) => {
        logger.warn('Unable to fetch latest post', e)
        // TODO: Consider surfacing an error to the user
      })
  }, [planetId, topicId])

  // subscribe to new posts (after the latest post)
  React.useEffect(() => {
    if (lastPostIdAtTimeOfLoad !== null) {
      const postsCollectionRef = collection(
        db,
        topicPostsCollectionPath(planetId, topicId)
      ) as CollectionReference<PostDoc>
      const newPostsQuery = query(
        postsCollectionRef,
        orderBy('postId', 'asc'),
        startAfter(lastPostIdAtTimeOfLoad)
      )
      const unsubscribe = onSnapshot(newPostsQuery, (snapshot) => {
        if (isMounted.current) {
          const postSummariesArgs: Parameters<
            typeof cacheActions.setPostSummariesFromPosts
          >[0] = []
          setPostsData((currentPostsData) =>
            newPostsDataFromSnapshot(
              snapshot,
              planetId,
              topicId,
              currentPostsData,
              postSummariesArgs
            )
          )
          if (postSummariesArgs.length > 0) {
            dispatch(cacheActions.setPostSummariesFromPosts(postSummariesArgs))
          }
        }
      })
      return unsubscribe
    }
    return () => {}
  }, [dispatch, planetId, topicId, lastPostIdAtTimeOfLoad])

  // subscribe to older posts (before and including the latest post)
  React.useEffect(() => {
    if (lastPostIdAtTimeOfLoad !== null) {
      if (lastPostIdAtTimeOfLoad) {
        const postsCollectionRef = collection(
          db,
          topicPostsCollectionPath(planetId, topicId)
        ) as CollectionReference<PostDoc>
        const olderPostsQuery = query(
          postsCollectionRef,
          orderBy('postId', 'desc'),
          startAt(lastPostIdAtTimeOfLoad),
          limit(postLimit + 1)
        )
        const unsubscribe = onSnapshot(olderPostsQuery, (snapshot) => {
          if (isMounted.current) {
            setLoading(false)
            setUpdating(false)
            if (snapshot.size === postLimit + 1) {
              setMorePostsExist(true)
            } else {
              setMorePostsExist(false)
            }
            olderPostsSnapshotSize.current = snapshot.size

            const postSummariesArgs: Parameters<
              typeof cacheActions.setPostSummariesFromPosts
            >[0] = []
            setPostsData((currentPostsData) =>
              newPostsDataFromSnapshot(
                snapshot,
                planetId,
                topicId,
                currentPostsData,
                postSummariesArgs,
                postLimit
              )
            )
            if (postSummariesArgs.length > 0) {
              dispatch(
                cacheActions.setPostSummariesFromPosts(postSummariesArgs)
              )
            }
          }
        })
        setUpdating(true)
        return unsubscribe
      }

      // lastPostIdAtTimeOfLoad is '' - no older posts
      setLoading(false)
      setUpdating(false)
    }
    return () => {}
  }, [dispatch, planetId, topicId, lastPostIdAtTimeOfLoad, postLimit])

  React.useEffect(() => {
    if (userId) {
      const reactionsPerPostCollectionRef = collection(
        db,
        reactionsPerPostCollectionPath(userId)
      ) as CollectionReference<ReactionsPerPostDoc>
      const reactionPerPostQuery = query(
        reactionsPerPostCollectionRef,
        where('postDocPath', '>=', topicPostsCollectionPath(planetId, topicId)),
        where(
          'postDocPath',
          '<=',
          `${topicPostsCollectionPath(planetId, topicId)}~`
        )
      )
      return onSnapshot(reactionPerPostQuery, (snapshot) => {
        if (isMounted.current) {
          // TODO: Pagination
          setUserReactions((currentUserReactions) => {
            const newUserReactions = currentUserReactions
            snapshot.docChanges().forEach((docChange) => {
              switch (docChange.type) {
                case 'added':
                case 'modified':
                  {
                    const reactionsForPost = docChange.doc.data()
                    const postId = getPostIdFromDocPath(
                      reactionsForPost.postDocPath
                    )
                    const reactionArray = Object.keys(
                      reactionsForPost.reactions
                    ) as ReactionType[]
                    newUserReactions[postId] = reactionArray.reduce(
                      (acc, r) => {
                        acc[r] = true
                        return acc
                      },
                      {} as Partial<Record<ReactionType, true>>
                    )
                  }
                  break
                case 'removed':
                default:
                // impossible
              }
            })
            return newUserReactions
          })
        }
      })
    }
    return () => {}
  }, [userId, planetId, topicId])

  React.useEffect(() => {
    let typingStatusLastTimeValue: PlanetForumPostTypingStatusProps['lastTime']
    if (
      !topicDoc?.lastReplyTypingAddress ||
      !topicDoc?.lastReplyTypingTime ||
      !userAddress
    ) {
      typingStatusLastTimeValue = null
    } else if (!isAddressEqual(topicDoc.lastReplyTypingAddress, userAddress)) {
      typingStatusLastTimeValue = topicDoc.lastReplyTypingTime?.toDate()
    }
    setTypingStatusLastTime(typingStatusLastTimeValue)
  }, [
    userAddress,
    topicDoc?.lastReplyTypingAddress,
    topicDoc?.lastReplyTypingTime,
  ])

  const onReply = React.useCallback((replyPost: Post) => {
    if (postFormRef.current) {
      setReferencedPost(replyPost)
      postFormRef.current.focus()
    }
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <View style={styles.container}>
      <View style={styles.body}>
        <PlanetChatScrollView
          postIds={postsData.postIds}
          postsRecord={postsData.postsRecord}
          userReactions={userReactions}
          firstLoadTime={firstLoadTime}
          onLoadMore={
            morePostsExist && !updating
              ? () => {
                  setPostLimit(postLimit + POST_LIMIT_CHUNK_SIZE)
                }
              : undefined
          }
          onReply={onReply}
          typingStatusLastTime={typingStatusLastTime}
          updating={updating}
        />
        <PlanetChatPostForm
          ref={postFormRef}
          planetId={planetId}
          topicId={topicId}
          referencedPost={referencedPost}
          removeReferencedPost={() => {
            setReferencedPost(undefined)
          }}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    marginBottom: 8,
  },
  container: {
    flex: 1,
    maxWidth: 800,
  },
})

function getPostIdFromDocPath(docPath: string): string {
  return docPath.replace(/^.*\//, '')
}

function newPostsDataFromSnapshot(
  snapshot: QuerySnapshot<PostDoc>,
  planetId: planet.Id,
  topicId: TopicId,
  currentPostsData: PostsData,
  postSummariesArgs: Parameters<
    typeof cacheActions.setPostSummariesFromPosts
  >[0],
  postLimit?: number
): PostsData {
  const newPostsData: PostsData = { ...currentPostsData }
  const { postIds, postsRecord } = newPostsData
  snapshot.docChanges().forEach((change) => {
    const postData = change.doc.data()
    switch (change.type) {
      case 'added':
        if (!postsRecord[postData.postId]) {
          // post not seen before - add author info into cache (for references)

          postSummariesArgs.push({
            fullPostId: {
              planetId,
              topicId,
              postId: postData.postId,
            },
            post: postData,
          })

          // add new post to the postsRecord and then to posts array if visible
          // unless there is a postLimit and the new item is beyond the postLimit
          if (!postLimit || change.newIndex < postLimit) {
            postsRecord[postData.postId] = postData
            if (postData.visible) {
              if (postIds.length === 0) {
                postIds.push(postData.postId)
              } else if (postData.postId < postIds[postIds.length - 1]) {
                // add to the end
                postIds.push(postData.postId)
              } else if (postData.postId > postIds[0]) {
                // add to the beginning
                postIds.unshift(postData.postId)
              } else {
                // unfortunately, post is not getting added to the front or back
                // add in correct time order
                const indexToInsertBefore = postIds.findIndex(
                  (postId) => postId < postData.postId
                )
                postIds.splice(indexToInsertBefore, 0, postData.postId)
              }
            }
          }
        } else if (
          postsRecord[postData.postId].bodyType !== postData.bodyType ||
          !isEqual(postsRecord[postData.postId].reactions, postData.reactions)
        ) {
          // post bodyType or reactions have changed since last subscription - update
          if (postsRecord[postData.postId].bodyType !== postData.bodyType) {
            postSummariesArgs.push({
              fullPostId: {
                planetId,
                topicId,
                postId: postData.postId,
              },
              post: postData,
            })
          }
          postsRecord[postData.postId] = postData
        }
        break
      case 'modified':
        // if post has changed bodyType, update the postSummary
        if (postsRecord[postData.postId].bodyType !== postData.bodyType) {
          // also update summaries if bodyType has changed
          postSummariesArgs.push({
            fullPostId: {
              planetId,
              topicId,
              postId: postData.postId,
            },
            post: postData,
          })
        }

        // update post in postsRecord
        postsRecord[postData.postId] = postData

        break
      case 'removed':
      default:
      // impossible
    }
  })
  return newPostsData
}
