import * as React from 'react'
import Constants from 'expo-constants'
import { Modal, Heading } from './ModalElements'
import { useSelector } from '../state/store'
import { ModalStringData } from '../state/modalsTypes'
import { Text } from '../components'
import { LinkButton } from '../components/LinkButton'

const config = Constants.manifest.extra?.config

export function TransactionSuccessModal(): JSX.Element {
  const { heading, transactionType, transactionHash } = useSelector(
    (state) => state.modals.data
  ) as ModalStringData

  return (
    <Modal testID="wallet-deposit">
      <Heading>{heading || 'Transaction Received'}</Heading>
      <Text>
        Your {transactionType || 'transaction'} request has been received and is
        now pending confirmation.
      </Text>
      {!!transactionHash && (
        <>
          <Text>Your transaction hash is:</Text>
          <LinkButton
            url={`https://${config.etherscanHostname}/tx/${transactionHash}`}
            label={transactionHash}
          />
        </>
      )}
    </Modal>
  )
}
