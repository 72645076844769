import { Ionicons } from '@expo/vector-icons'
import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import * as React from 'react'
import { logger } from '../logger'

export default function useCachedResources(): boolean {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false)

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync().catch((e) =>
          logger.warn('preventAutoHideAsync error:', e)
        )

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
        })
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        logger.warn(e)
      } finally {
        setLoadingComplete(true)
        SplashScreen.hideAsync().catch((e) =>
          logger.warn('hideAsync error:', e)
        )
      }
    }

    loadResourcesAndDataAsync().catch((e) =>
      logger.warn('loadResourcesAndDataAsync error:', e)
    )
  }, [])

  return isLoadingComplete
}
