import * as React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import Animated, {
  withRepeat,
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated'

type Props = {
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
}

export function AnimatePulse({
  children,
  style = undefined,
}: Props): JSX.Element | null {
  const opacity = useSharedValue(1)
  const opacityStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }))
  opacity.value = withRepeat(
    withTiming(0.7, {
      duration: 1000,
      easing: Easing.inOut(Easing.quad),
    }),
    -1,
    true
  )

  if (children === null) {
    return null
  }
  return <Animated.View style={[style, opacityStyle]}>{children}</Animated.View>
}
