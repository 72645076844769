/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { ScrollView, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { Text, View } from '.'
import { Button } from './Button'
import { useThemeColors } from '../constants/colors'
import { useTheme } from '../hooks/useTheme'
import { TextPreset } from './Text'

type TabDescription = {
  name: string
  onPress: () => void
  isActive: boolean
}
type Props = {
  tabs: TabDescription[]
  style?: StyleProp<ViewStyle>
  tabStyle?: StyleProp<ViewStyle>
  textPreset?: TextPreset
}
export function Tabs({
  tabs,
  style = undefined,
  tabStyle = undefined,
  textPreset = undefined,
}: Props): JSX.Element {
  const tabColors = useThemeColors().tabs
  const { theme } = useTheme()

  const styles = themedStyles[theme]

  const tabArray = tabs.map((tab) => (
    <View
      key={tab.name}
      style={[
        styles.tab,
        {
          borderBottomColor: tabColors.major.borderBottomColor,
        },
        tabStyle,
        tab.isActive ? styles.tabActive : undefined,
      ]}
    >
      <Button
        onPress={async () => {
          tab.onPress()
        }}
      >
        <Text
          preset={textPreset}
          weight={theme === 'hadron' ? 'semiBold' : undefined}
          style={{
            color: tab.isActive
              ? tabColors.major.activeColor
              : tabColors.major.inactiveColor,
          }}
        >
          {theme === 'hadron' ? tab.name.toUpperCase() : tab.name}
        </Text>
      </Button>
    </View>
  ))

  return (
    <ScrollView horizontal>
      <View style={[styles.container, style]}>{tabArray}</View>
    </ScrollView>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    container: {
      flexDirection: 'row',
      marginBottom: 24,
    },
    tab: {
      marginRight: 48,
      paddingBottom: 8,
    },
    tabActive: {
      borderBottomWidth: 2,
    },
  }),
  multiverse2022: StyleSheet.create({
    container: {
      flexDirection: 'row',
      marginBottom: 32,
    },
    tab: {
      marginRight: 48,
      paddingBottom: 4,
    },
    tabActive: {
      borderBottomWidth: 2,
    },
  }),
}
