import React from 'react'
import { logger } from '../logger'
import { auth } from '../firebase/auth'
import { useDispatch } from '../state/store'
import { userActions } from '../state/userSlice'
import { modalsActions } from '../state/modalsSlice'

export function UserAuthentication(): null {
  const dispatch = useDispatch()

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // user state has changed - hide any modals
      dispatch(modalsActions.hideModal())
      if (user) {
        // User is signed in.
        dispatch(
          userActions.login({ fuid: user.uid, firebaseUser: user, claims: {} })
        ).catch((e) => {
          logger.warn('dispatch login rejected', e)
        })
      } else {
        dispatch(userActions.logout()).catch((e) => {
          logger.warn('dispatch logout rejected', e)
        })
      }
    })
  }, [dispatch])

  return null
}
