type MetaMaskEthereumRequestMethods = 'eth_chainId' | 'eth_accounts' | string
type MetaMaskEthereumRequestParams<T extends MetaMaskEthereumRequestMethods> = {
  method: T
  params?: unknown[] | unknown
}
type MetaMaskEthereumResponse<T> = T extends 'eth_chainId'
  ? string
  : T extends 'eth_accounts'
  ? string[]
  : unknown

type MetaMaskEthereumEvents = 'chainChanged' | 'accountsChanged' | string
type MetaMastEthereumEventResponse<T> = T extends 'chainChanged'
  ? string
  : T extends 'accountsChanged'
  ? string[]
  : unknown

type MetaMaskListener<T> = (value: T) => void
export type MetaMask = {
  ethereum: {
    isMetaMask: true
    isMathWallet: undefined | true
    selectedAddress: string
    request: <T extends string>(
      params: MetaMaskEthereumRequestParams<T>
    ) => Promise<MetaMaskEthereumResponse<T>>
    on: <T extends MetaMaskEthereumEvents>(
      event: T,
      listener: MetaMaskListener<MetaMastEthereumEventResponse<T>>
    ) => void
    removeListener: <T extends MetaMaskEthereumEvents>(
      event: T,
      listener: MetaMaskListener<MetaMastEthereumEventResponse<T>>
    ) => void
  }
  chainId: string
}

export type MetaMaskIntegrationStage =
  | 'install'
  | 'switchChain'
  | 'connect'
  | 'addContracts'
  | 'ready'

export type MetaMaskState = {
  metaMask?: MetaMask
  metaMaskIntegrationStage?: MetaMaskIntegrationStage
  connectedAccounts: string[]
  erc20Balance: string
}

export function initialState(): MetaMaskState {
  return {
    connectedAccounts: [],
    erc20Balance: '',
  }
}
