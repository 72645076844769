import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { draftCreateNewPlanetsCollectionPath } from '@mv/api/lib/src/schema/planets'
import { View } from '..'
import { MenuButton } from '../MenuButton'
import { PlanetsNavigationProps } from '../../navigation/types'
import { useEarlyAccess } from '../../hooks/useEarlyAccess'
import {
  db,
  collection,
  limit,
  onSnapshot,
  query,
} from '../../firebase/firestore'
import { useSelector } from '../../state/store'

export function ListMyPlanetDraftsButton(): JSX.Element | null {
  const isMounted = React.useRef(true)
  const navigation = useNavigation<PlanetsNavigationProps>()
  const hasSaveDraftAccess = useEarlyAccess('saveDraftPlanet')
  const user = useSelector((state) => state.user)

  const [hasSavedDrafts, setHasSavedDrafts] = React.useState(false)

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    if (user.user) {
      const draftCollectionRef = collection(
        db,
        draftCreateNewPlanetsCollectionPath(user.user.fuid)
      )
      return onSnapshot(query(draftCollectionRef, limit(1)), (snap) => {
        if (isMounted.current) {
          setHasSavedDrafts(!snap.empty)
        }
      })
    }
    return () => {}
  }, [user.user, user.user?.fuid])

  if (!hasSaveDraftAccess || !hasSavedDrafts) {
    return null
  }

  return (
    <View style={styles.buttonRow}>
      <MenuButton
        label="My Draft Planets"
        onPress={() => {
          navigation.navigate('PlanetsMyDrafts')
        }}
      />
    </View>
  )
}
const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
    marginBottom: 20,
  },
})
