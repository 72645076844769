import * as React from 'react'
import { StyleSheet } from 'react-native'
import { ethers } from 'ethers'
import Constants from 'expo-constants'
import { convertDisplayAddressToEthereumAddress } from '@mv/api/lib/src/schema/accounts'
import { decimalValueToUnits, DecimalValue } from '@mv/api/lib/src/types/token'
import { logger } from '../logger'
import { Modal, Heading } from './ModalElements'
import * as Form from '../components/form'
import { useDispatch, useSelector } from '../state/store'
import { modalsActions } from '../state/modalsSlice'
import { ERROR_OOPS } from '../constants/messages'
import { NoRefresh } from '../components/NoRefresh'
import { Text } from '../components'
import { logEvent, analytics } from '../firebase/analytics'
import { ConstantsExtra } from '../types/appConfig'

interface MetaMaskError extends Error {
  code: unknown
}

type FormError = {
  amount?: string
  generic?: string
}

const constantsExtra = Constants.manifest.extra as ConstantsExtra
const { config } = constantsExtra
const { abi } = constantsExtra.contracts.multiverse
const multiverseContract = new ethers.Contract(config.multiverseContract, abi)

export function MetaMaskTransferModal(): JSX.Element | null {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const modalDefinition = useSelector((state) => state.modals)

  const [amount, setAmount] = React.useState(
    (modalDefinition.displayModal === 'metaMaskTransfer' &&
      modalDefinition.data.maxAmount) ||
      ''
  )
  const [continueOnMetaMask, setContinueOnMetaMask] = React.useState(false)
  const [formError, setFormError] = React.useState({} as FormError)

  if (modalDefinition.displayModal !== 'metaMaskTransfer') {
    // impossible
    return null
  }
  const { selectedAddress, userAddress, metaMask } = modalDefinition.data
  return (
    <Modal testID="wallet-metamask-transfer">
      <Heading>Deposit From MetaMask</Heading>
      <NoRefresh>
        <Form.Item>
          <Form.Label>AI Amount</Form.Label>
          <Form.TextInput
            value={amount}
            onChangeText={(textValue) => {
              setAmount(textValue)
            }}
          />
          <Form.Error error={formError.amount} />
        </Form.Item>
        <Form.SubmitButton
          label="Continue on MetaMask"
          onPress={async () => {
            setFormError({})
            setContinueOnMetaMask(true)
            if (user.user?.account?.address) {
              try {
                const txn = await constructDepositTransaction(
                  selectedAddress,
                  userAddress,
                  amount
                )
                logger.info('Sending transaction', txn)
                const txnHash = (await metaMask.ethereum.request({
                  method: 'eth_sendTransaction',
                  params: [txn],
                })) as string
                // TODO: Display this to the user with a link to etherscan?
                logger.info(`Txn hash: ${txnHash}`)
                logEvent(analytics(), 'transfer_in', { hash: txnHash })
                return () => {
                  dispatch(
                    modalsActions.showMetaMaskTransferSuccessModal(txnHash)
                  )
                }
              } catch (_e) {
                const e = _e as MetaMaskError
                // TODO: This should be surfaced as an alert to the user.
                switch (e.code) {
                  case 4001:
                    logger.warn(e)
                    setFormError({
                      generic:
                        'User cancelled or rejected transaction on MetaMask',
                    })
                    break
                  default:
                    logger.error(e)
                    setFormError({
                      generic: ERROR_OOPS,
                    })
                }
              }
            }
            setContinueOnMetaMask(false)
            return false
          }}
        />
        {continueOnMetaMask && (
          <>
            <Text style={styles.continueText}>
              Please continue the transfer on MetaMask
            </Text>
            <Form.Item>
              <Text
                weight="semiBold"
                style={styles.formValue}
                numberOfLines={1}
              >
                Ethereum Transaction Details
              </Text>
            </Form.Item>
            <Form.Item>
              <Form.Label>Using Contract (AI Token Contract)</Form.Label>
              <Text
                weight="semiBold"
                style={styles.formValue}
                numberOfLines={1}
              >
                {config.multiverseContract}
              </Text>
            </Form.Item>
            <Form.Item>
              <Form.Label>From (Your MetaMask Wallet Address)</Form.Label>
              <Text
                weight="semiBold"
                style={styles.formValue}
                numberOfLines={1}
              >
                {selectedAddress}
              </Text>
            </Form.Item>
            <Form.Item>
              <Form.Label>Amount</Form.Label>
              <Text
                weight="semiBold"
                style={styles.formValue}
                numberOfLines={1}
              >
                {parseFloat(amount)}
              </Text>
            </Form.Item>
            <Form.Item>
              <Form.Label>To (Multiverse)</Form.Label>
              <Text
                weight="semiBold"
                style={styles.formValue}
                numberOfLines={1}
              >
                {config.multiverseGateway}
              </Text>
            </Form.Item>
            <Form.Item>
              <Form.Label>For (Your Multiverse Address)</Form.Label>
              <Text
                weight="semiBold"
                style={styles.formValue}
                numberOfLines={1}
              >
                {user.user?.account?.address}
              </Text>
            </Form.Item>
          </>
        )}
        <Form.Error error={formError.generic} />
      </NoRefresh>
    </Modal>
  )
}

const styles = StyleSheet.create({
  continueText: {
    marginBottom: 16,
    marginTop: 8,
  },
  formValue: {
    fontSize: 16,
  },
})

type Transaction = Omit<ethers.PopulatedTransaction, 'gasLimit'> & {
  gasLimit?: string
}
async function constructDepositTransaction(
  from: string,
  to: string,
  value: DecimalValue
) {
  const transaction = await multiverseContract.populateTransaction.deposit(
    convertDisplayAddressToEthereumAddress(config.multiverseGateway),
    decimalValueToUnits(value),
    convertDisplayAddressToEthereumAddress(to)
  )
  const { gasLimit } = transaction
  let gasLimitHexString: undefined | string
  if (gasLimit) {
    if (gasLimit._isBigNumber) {
      gasLimitHexString = gasLimit.toHexString()
    } else {
      gasLimitHexString = gasLimit.toString()
    }
  }
  const txn: Transaction = { ...transaction, gasLimit: gasLimitHexString }

  return { from, ...txn }
}
