import * as React from 'react'
import { StyleSheet } from 'react-native'
import Constants from 'expo-constants'
import { Type as TransactionType } from '@mv/api/lib/src/schema/ledger/transaction'
import { event } from '@mv/api/lib/src/schema/contracts'
import { Schedule } from '@mv/api/lib/src/schema/accounts/locks'
import { convertEthereumAddressToDisplayAddress } from '@mv/api/lib/src/schema/accounts'
import { ledger } from '@mv/api/lib/src/schema'
import { token } from '@mv/api/lib/src/types'
import { ConstantsExtra } from '../../types/appConfig'
import { View } from '..'
import { DocumentReference } from '../../firebase/firestore'
import { LinkButton } from '../LinkButton'
import { humanizeBalance } from '../../helpers'
import { DefaultBackgroundColorsTable } from '../table'
import { MyWalletRecentTransactionsUnlockMemo } from './MyWalletRecentTransactionsUnlockMemo'
import { useTheme } from '../../hooks/useTheme'

const config = Constants.manifest.extra?.config as ConstantsExtra['config']

type TransactionRowEntry = {
  key: string
  transactionTypeString: string
  otherAccountString: string
  amountString: string
  memoString: string | React.ReactNode
  transactionTimestamp: Date | null
  transactionStatus: string | React.ReactNode
}

type Props = {
  uid: string
  pendingEvents: event.Event[]
  recentTransactions: ledger.Document[]
  dateStyle?: Parameters<typeof DefaultBackgroundColorsTable>[0]['dateStyle']
}

export function MyWalletTransactionTable({
  uid,
  pendingEvents,
  recentTransactions,
  dateStyle,
}: Props): JSX.Element | null {
  const { theme } = useTheme()
  const tableData = extractTableDataFromRecentTransactions(
    uid,
    pendingEvents,
    recentTransactions
  )

  return (
    <View style={styles.table}>
      <DefaultBackgroundColorsTable
        data={tableData}
        columns={[
          'transactionTypeString',
          'otherAccountString',
          'amountString',
          'memoString',
          'transactionTimestamp',
          'transactionStatus',
        ]}
        columnOptions={{
          transactionTypeString: {
            name: 'Transaction',
          },
          otherAccountString: { name: 'To/From' },
          amountString: { name: 'Value' },
          memoString: { name: 'Memo' },
          transactionTimestamp: { name: 'Time' },
          transactionStatus: { name: 'Status' },
        }}
        rowStyle={styles.row}
        rowVerticalPadding={theme === 'hadron' ? 20 : 16}
        rowHorizontalPadding={theme === 'hadron' ? 20 : 16}
        spaceBetweenColumns={40}
        cellTextStyle={styles.entryText}
        dateStyle={dateStyle}
      />
    </View>
  )
}
const styles = StyleSheet.create({
  entryText: {
    fontSize: 14,
  },
  row: {},
  table: {
    flex: 1,
  },
})

function extractTableDataFromRecentTransactions(
  uid: string,
  pendingEvents: event.Event[],
  recentTransactions: ledger.Document[]
) {
  const tableData: TransactionRowEntry[] = []
  pendingEvents.forEach((evt) => {
    tableData.push({
      key: `pending-${evt.blockHash}`,
      transactionTypeString: 'Receive',
      otherAccountString: evt.args.from,
      amountString: `${humanizeBalance(evt.args.value ?? '0')} AI`,
      memoString: '',
      transactionTimestamp: null,
      transactionStatus: (
        <LinkButton
          labelStyle={styles.entryText}
          url={`https://${config.etherscanHostname}/tx/${evt.transactionHash}`}
          label="(Pending)"
        />
      ),
    })
  })
  recentTransactions.forEach((txn) => {
    const transactionType = Object.keys(txn._.payload)[0]
    switch (txn.type) {
      case TransactionType.Transfer:
      case TransactionType.Withdrawal:
      case TransactionType.Inbound:
      case TransactionType.Unlock:
        {
          let otherAccountString = ''
          let transactionTypeString = ''
          let amount: token.Units = '0'
          let memoString: string | React.ReactNode = ''
          switch (txn.type) {
            case TransactionType.Transfer:
              {
                amount = txn._.payload.transfer.amount
                memoString = txn._.payload.transfer.memo ?? ''
                const to = txn._.payload.transfer.to || 'impossible'
                const from = txn._.payload.transfer.from || 'impossible'
                if (to !== uid) {
                  otherAccountString =
                    convertEthereumAddressToDisplayAddress(to)
                } else {
                  otherAccountString =
                    convertEthereumAddressToDisplayAddress(from)
                }
                if ((txn._.payload.transfer.locked?.length || 0) > 0) {
                  transactionTypeString = 'Locked Transfer'
                } else {
                  transactionTypeString = 'Transfer'
                }
              }
              break
            case TransactionType.Withdrawal:
              amount = txn._.payload.withdrawal.amount
              otherAccountString = txn._.payload.withdrawal.to || 'impossible'
              transactionTypeString = 'Withdraw'
              break
            case TransactionType.Inbound:
              amount = txn._.payload.inbound.amount
              otherAccountString = txn._.payload.inbound.from || 'impossible'
              transactionTypeString = 'Receive'
              break
            case TransactionType.Unlock:
              amount = txn._.payload.unlock.amount
              transactionTypeString = 'Unlock'
              memoString = (
                <MyWalletRecentTransactionsUnlockMemo
                  schedule={
                    txn._.payload.unlock
                      .schedule as unknown as DocumentReference<Schedule>
                  }
                  style={styles.entryText}
                />
              )
              break
            default:
          }
          let transactionStatus: string | React.ReactNode = ''
          if (transactionType === 'transfer' || transactionType === 'unlock') {
            transactionStatus = 'Completed'
          } else if (txn.transactionHash) {
            transactionStatus = (
              <LinkButton
                labelStyle={styles.entryText}
                url={`https://${config.etherscanHostname}/tx/${txn.transactionHash}`}
                label="Completed"
              />
            )
          } else {
            transactionStatus = 'Pending'
          }

          tableData.push({
            key: `txn-${txn.timestamp.toMillis()}`,
            transactionTypeString,
            otherAccountString,
            amountString: `${humanizeBalance(amount ?? '0')} AI`,
            memoString,
            transactionTimestamp: txn.timestamp.toDate(),
            transactionStatus,
          })
        }
        break
      default:
    }
  })
  return tableData
}
