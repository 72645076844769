import * as React from 'react'
import { planet } from '@mv/api/lib/src/types'
import {
  TopicId,
  topicsCollectionPath,
  TopicDoc,
} from '@mv/api/lib/src/schema/forums'
import { Loading } from '../Loading'
import { Section } from '..'
import {
  db,
  collection,
  onSnapshot,
  CollectionReference,
} from '../../firebase/firestore'
import { PlanetForumTopic } from './PlanetForumTopic'

type Props = {
  planetId: planet.Id
  sectionHeading?: string
  autoLoadTopicId?: TopicId
}
export function PlanetForums({
  planetId,
  sectionHeading,
  autoLoadTopicId,
}: Props): JSX.Element | null {
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    if (loading && !autoLoadTopicId) {
      const topicsCollectionRef = collection(
        db,
        topicsCollectionPath(planetId)
      ) as CollectionReference<TopicDoc>
      return onSnapshot(topicsCollectionRef, () => {
        // TODO: Save topics to state for display
        setLoading(false)
      })
    }
    return () => {}
  }, [loading, planetId, autoLoadTopicId])

  let renderBody: JSX.Element

  if (autoLoadTopicId) {
    renderBody = (
      <PlanetForumTopic planetId={planetId} topicId={autoLoadTopicId} />
    )
  } else if (loading) {
    renderBody = <Loading />
  } else {
    // TODO: Display topics
    renderBody = <></>
  }

  if (sectionHeading && renderBody) {
    return <Section heading={sectionHeading}>{renderBody}</Section>
  }
  return renderBody
}
