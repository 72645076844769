import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Post, ReactionType } from '@mv/api/lib/src/schema/forums'
import { View } from '..'
import { Button } from '../Button'
import { PlanetForumPostForm } from './PlanetForumPostForm'
import * as Form from '../form'
import { PlanetForumPostActionsDeleteButton } from './PlanetForumPostActionsDeleteButton'
import { PlanetForumPostActionsReactionButtons } from './PlanetForumPostActionsReactionButtons'
import { useThemeColors } from '../../constants/colors'
import { AnimateDownFadeIn } from '../animations/AnimateDownFadeIn'

type FormError = {
  action?: string
}

type Props = {
  post: Post
  userReactions?: Partial<Record<ReactionType, true>>
  showReplyButton: boolean
  showDeleteButton: boolean
  showReactionButtons: boolean
  onReply?: () => void
}
export function PlanetForumPostActions({
  post,
  userReactions,
  showReplyButton,
  showDeleteButton,
  showReactionButtons,
  onReply = undefined,
}: Props): JSX.Element {
  const colors = useThemeColors()
  const [showPostForm, setShowPostForm] = React.useState(false)
  const [formError, setFormError] = React.useState<FormError>({})

  const activeColorStyle = {
    color: colors.button.primary.container.backgroundColor,
  }

  const replyButtonColorStyle = showPostForm ? activeColorStyle : undefined
  return (
    <>
      <View style={styles.actions}>
        {showReactionButtons && (
          <PlanetForumPostActionsReactionButtons
            post={post}
            userReactions={userReactions}
          />
        )}
        {showReplyButton && (
          <Button
            testID="forumPostActionsReplyButton"
            label="reply"
            labelStyle={[styles.actionsButtonLabel, replyButtonColorStyle]}
            labelWeight="extraLight"
            style={styles.actionsButton}
            onPress={async () => {
              if (onReply) {
                onReply()
              } else {
                setShowPostForm(!showPostForm)
              }
            }}
          />
        )}
        {showDeleteButton && (
          <PlanetForumPostActionsDeleteButton
            post={post}
            style={styles.actionsButton}
            labelStyle={styles.actionsButtonLabel}
            handleError={(errorMessage: string | null) => {
              if (errorMessage === null) {
                setFormError({})
              } else {
                setFormError({ action: errorMessage })
              }
            }}
          />
        )}
      </View>
      <Form.Error error={formError.action} />
      <AnimateDownFadeIn>
        {showPostForm ? (
          <View style={styles.containerPostForm}>
            <PlanetForumPostForm
              planetId={post.planet}
              topicId={post.topic}
              replyTo={post.postId}
              reset={() => {
                setShowPostForm(false)
              }}
            />
          </View>
        ) : null}
      </AnimateDownFadeIn>
    </>
  )
}

const styles = StyleSheet.create({
  actions: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 8,
  },
  actionsButton: {
    marginLeft: 4,
    marginRight: 4,
  },
  actionsButtonLabel: {
    fontSize: 12,
  },
  containerPostForm: {
    marginTop: 8,
  },
})
