import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { Svg, Rect, RadialGradient, Defs, Stop } from 'react-native-svg'

type Props = {
  style?: StyleProp<ViewStyle>
  topLeftColor?: string
  bottomRightColor?: string
}

/**
 * Renders an SVG close X
 */
export function PlanetBackgroundGradient({
  style,
  topLeftColor,
  bottomRightColor,
}: Props): JSX.Element {
  return (
    <Svg style={style} preserveAspectRatio="none" viewBox="0 0 1 1">
      <Defs>
        <RadialGradient
          id="gradient"
          cx="0%"
          cy="0%"
          r="140%"
          fx="50%"
          fy="50%"
        >
          <Stop offset="0%" stopColor={topLeftColor} stopOpacity="1" />
          <Stop offset="100%" stopColor={bottomRightColor} stopOpacity="1" />
        </RadialGradient>
      </Defs>
      <Rect width="100%" height="100%" fill="url(#gradient)" />
    </Svg>
  )
}
PlanetBackgroundGradient.defaultProps = {
  style: undefined,
  topLeftColor: '#152133',
  bottomRightColor: '#375b8f',
}
