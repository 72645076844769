import React from 'react'
import { StyleSheet } from 'react-native'
import { GenericModalData } from '../state/modalsTypes'
import { Modal, Heading, Body } from './ModalElements'
import { modalsActions } from '../state/modalsSlice'
import { useDispatch } from '../state/store'
import { LinkButton } from '../components/LinkButton'

type Props = {
  modal: GenericModalData
}
export function GenericModal({ modal }: Props): JSX.Element {
  const dispatch = useDispatch()

  return (
    <Modal>
      {!!modal.title && <Heading>{modal.title}</Heading>}
      {!!modal.messages && <Body messages={modal.messages} />}
      {!!modal.addCloseLink && (
        <LinkButton
          label="Close"
          onPress={() => {
            dispatch(modalsActions.hideModal())
          }}
          labelStyle={styles.close}
        />
      )}
    </Modal>
  )
}

const styles = StyleSheet.create({
  close: {
    marginTop: 20,
  },
})
