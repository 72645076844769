/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import {
  PlanetProposal,
  PlanetSymbolDoc,
  planetPath,
  planetSymbolPath,
  planetsCollectionPath,
} from '@mv/api/lib/src/schema/planets'
import {
  convertDisplayAddressToEthereumAddress,
  addressDisplayPrefixReplacement,
} from '@mv/api/lib/src/schema/accounts'
import { logger } from '../../../logger'
import { useSelector } from '../../../state/store'
import { View, Text } from '../..'
import { Screen, Heading } from '../../Screen'
import { MenuButton } from '../../MenuButton'
import {
  PlanetsNavigationProps,
  PlanetsStackParamList,
} from '../../../navigation/types'
import {
  db,
  doc,
  getDoc,
  DocumentReference,
  query,
  collection,
  CollectionReference,
  where,
  limit,
  getDocs,
} from '../../../firebase/firestore'
import { Loading } from '../../Loading'
import { useAdmin } from '../../../hooks/useAdmin'
import { PlanetTabNavigator } from '../../../navigation/PlanetTabNavigator'
import { useScreenMargins } from '../../../hooks/useScreenMargins'
import { PLANET_NOT_FOUND } from '../../../constants/messages'
import { useTheme } from '../../../hooks/useTheme'

export function ShowPlanetProposalScreen(): JSX.Element {
  const { theme } = useTheme()
  const [notFound, setNotFound] = React.useState(false)
  const [planet, setPlanet] = React.useState<PlanetProposal | undefined>(
    undefined
  )

  const navigation = useNavigation<PlanetsNavigationProps>()
  const route = useRoute<RouteProp<PlanetsStackParamList, 'PlanetsShow'>>()
  const planetLocator = route.params?.planetLocator || 'AI'

  const user = useSelector((state) => state.user)
  const hasEditPlanetClaim = useAdmin('planetAdmin')
  const isMultiverseHomeworld = !route.params?.planetLocator
  const showEditButton =
    route.params?.planetLocator &&
    planet &&
    (planet.founder === user.user?.fuid || hasEditPlanetClaim)

  const styles = themedStyles[theme]
  const screenMargins = useScreenMargins().style

  const verticalMarginStyle = {
    marginTop: screenMargins.marginTop,
    marginBottom: screenMargins.marginBottom,
  }
  const horizontalMarginStyle = {
    marginLeft: screenMargins.marginLeft,
    marginRight: screenMargins.marginRight,
  }

  React.useEffect(() => {
    async function getPlanetFromPlanetLocator() {
      if (
        !(
          planetLocator.startsWith('0x') ||
          planetLocator.startsWith(addressDisplayPrefixReplacement)
        )
      ) {
        // planetLocator is likely a symbol
        const symbolDocSnapshot = await getDoc(
          doc(
            db,
            planetSymbolPath(planetLocator.trim().toUpperCase())
          ) as DocumentReference<PlanetSymbolDoc>
        )
        if (symbolDocSnapshot.exists()) {
          const symbolDoc = symbolDocSnapshot.data()
          const planetDocSnapshot = await getDoc(
            doc(
              db,
              planetPath(symbolDoc.planet)
            ) as DocumentReference<PlanetProposal>
          )
          if (planetDocSnapshot.exists()) {
            setPlanet(planetDocSnapshot.data())
            return
          }
        }
      }
      if (
        RegExp(`^(0x|${addressDisplayPrefixReplacement})[a-fA-F0-9]{40}$`).test(
          planetLocator
        )
      ) {
        // planetLocator appears to be a complete planetId
        const planetDocSnapshot = await getDoc(
          doc(
            db,

            planetPath(convertDisplayAddressToEthereumAddress(planetLocator))
          ) as DocumentReference<PlanetProposal>
        )
        if (planetDocSnapshot.exists()) {
          setPlanet(planetDocSnapshot.data())
          return
        }
      }
      if (
        planetLocator.startsWith('0x') ||
        planetLocator.startsWith(addressDisplayPrefixReplacement)
      ) {
        const normPlanetLocator =
          convertDisplayAddressToEthereumAddress(planetLocator).toLowerCase()
        // planetLocator is not an exact match for the planetId - try for a loose match
        const planetCollectionDocsSnapshot = await getDocs(
          query(
            collection(
              db,
              planetsCollectionPath
            ) as CollectionReference<PlanetProposal>,
            where('lowercaseId', '>=', normPlanetLocator),
            limit(2)
          )
        )
        if (!planetCollectionDocsSnapshot.empty) {
          const potentialPlanetDocs = planetCollectionDocsSnapshot.docs.map(
            (snapshot) => snapshot.data() as PlanetProposal
          )
          if (
            potentialPlanetDocs[0]?.lowercaseId.startsWith(normPlanetLocator) &&
            (potentialPlanetDocs.length === 1 ||
              !potentialPlanetDocs[1]?.lowercaseId.startsWith(
                normPlanetLocator
              ))
          ) {
            // one match, this is the planet we are looking for
            setPlanet(potentialPlanetDocs[0])
            return
          }
        }
      }
      setNotFound(true)
    }
    getPlanetFromPlanetLocator().catch((e) =>
      logger.warn(
        'error encountered when getting planet from planetLocator: ',
        e
      )
    )
  }, [planetLocator])

  React.useEffect(() => {
    if (planet && planetLocator !== planet.symbol) {
      // navigation.replace('PlanetsShow', { planetLocator: planet.symbol })
    }
  }, [planet, planetLocator, navigation])

  if (notFound) {
    return (
      <Screen>
        <Heading>Multiverse Planets</Heading>
        <Text>{PLANET_NOT_FOUND}</Text>
      </Screen>
    )
  }

  if (planet) {
    const testID = `screen-planet-${planet.symbol}`
    if (isMultiverseHomeworld) {
      return (
        <Screen
          testID={testID}
          scrollable={false}
          noMargins
          style={verticalMarginStyle}
        >
          <PlanetTabNavigator />
        </Screen>
      )
    }
    return (
      <Screen
        testID={testID}
        scrollable={false}
        noMargins
        style={verticalMarginStyle}
      >
        <View style={horizontalMarginStyle}>
          <Heading>{planet.name || planet.symbol}</Heading>
          {showEditButton && (
            <View style={styles.editButtonView}>
              <MenuButton
                label="Edit"
                onPress={() => {
                  navigation.navigate('PlanetsEdit', {
                    planetId: planet.id,
                  })
                }}
              />
            </View>
          )}
          {!!planet._.details.brief && (
            <View style={styles.briefView}>
              <Text preset="paragraphXLarge" style={styles.briefText}>
                {planet._.details.brief}
              </Text>
            </View>
          )}
        </View>
        <PlanetTabNavigator />
      </Screen>
    )
  }
  return <Loading />
}

const themedStyles = {
  hadron: StyleSheet.create({
    briefText: {
      fontSize: 24,
    },
    briefView: {
      marginBottom: 20,
    },
    editButtonView: {
      flexDirection: 'row',
      position: 'absolute',
      right: 0,
    },
  }),
  multiverse2022: StyleSheet.create({
    briefText: {},
    briefView: {
      marginBottom: 48,
    },
    editButtonView: {
      flexDirection: 'row',
      position: 'absolute',
      right: 0,
    },
  }),
}
