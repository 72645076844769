import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import {
  DraftPlanetDoc,
  draftCreateNewPlanetsCollectionPath,
} from '@mv/api/lib/src/schema/planets'
import { Screen, Heading } from '../../Screen'
import {
  db,
  collection,
  onSnapshot,
  CollectionReference,
} from '../../../firebase/firestore'
import { useSelector } from '../../../state/store'
import { Loading } from '../../Loading'
import { View, Text } from '../..'
import { MenuButton } from '../../MenuButton'
import { CreatePlanetButton } from '../CreatePlanetButton'
import { PlanetFormFields } from '../CreateOrEditPlanetForm'
import { PlanetsNavigationProps } from '../../../navigation/types'
import { useEarlyAccess } from '../../../hooks/useEarlyAccess'

export function MyDraftPlanetsScreen(): JSX.Element {
  const navigation = useNavigation<PlanetsNavigationProps>()

  const hasSaveDraftAccess = useEarlyAccess('saveDraftPlanet')

  const user = useSelector((state) => state.user)
  const [planetDraftDocs, setPlanetDraftDocs] = React.useState<
    DraftPlanetDoc[] | null
  >(null)

  React.useEffect(() => {
    if (user.user) {
      return onSnapshot(
        collection(
          db,
          draftCreateNewPlanetsCollectionPath(user.user.fuid)
        ) as CollectionReference<DraftPlanetDoc>,
        (snap) => {
          setPlanetDraftDocs(snap.docs.map((d) => d.data()))
        }
      )
    }
    return () => {}
  }, [user.user, user.user?.fuid])

  if (hasSaveDraftAccess === null) {
    return <Loading />
  }
  if (hasSaveDraftAccess === false) {
    navigation.navigate('PlanetsList', {})
  }

  const draftPlanetButtons = planetDraftDocs?.map((draft) => {
    const { id, creationTime, lastUpdateTime, planetJSON } = draft
    const planetDraft = JSON.parse(planetJSON) as PlanetFormFields
    const { planetName, symbol, brief } = planetDraft
    return (
      <MenuButton
        key={id}
        onPress={() => {
          navigation.navigate('PlanetsCreate', { draftId: id })
        }}
      >
        <View style={styles.draftPlanetButton}>
          {!!planetName && <Text>{planetName}</Text>}
          {!!symbol && <Text>{symbol}</Text>}
          {!!brief && <Text>{brief}</Text>}
          {creationTime && (
            <Text>Draft created: {creationTime.toDate().toLocaleString()}</Text>
          )}
          {creationTime &&
            lastUpdateTime &&
            creationTime.toMillis() !== lastUpdateTime.toMillis() && (
              <Text>
                Draft last edited: {lastUpdateTime.toDate().toLocaleString()}
              </Text>
            )}
        </View>
      </MenuButton>
    )
  })

  return (
    <Screen testID="planet-drafts">
      <Heading>My Draft Planets</Heading>
      {!planetDraftDocs && <Loading />}
      {planetDraftDocs?.length === 0 && (
        <View>
          <Text style={styles.noDraftsText}>No draft planets.</Text>
          <CreatePlanetButton />
        </View>
      )}
      <View style={styles.draftPlanetsView}>{draftPlanetButtons}</View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  draftPlanetButton: {
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
  },
  draftPlanetsView: {
    maxWidth: 600,
  },
  noDraftsText: {
    marginBottom: 10,
  },
})
