import { Analytics, getAnalytics } from 'firebase/analytics'
import { app } from './init'

// Note: Ideally this would be exported as `const analytics = getAnalytics(app)`
// but calling getAnalytics() in the jest framework causes a configuration
// Error even when mocking this module, as it is imported by the `logger`
// module as well.
export function analytics(): Analytics {
  return getAnalytics(app)
}

// Exporting what the app currently needs. Feel free to expand the
// list of exported symbols.
export { logEvent, setUserId } from 'firebase/analytics'
