import * as React from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import { planet } from '@mv/api/lib/src/types'
import {
  Pledge,
  planetPledgesCollectionPath,
  planetPledgePath,
} from '@mv/api/lib/src/schema/planets'
import { convertEthereumAddressToDisplayAddress } from '@mv/api/lib/src/schema/accounts'
import { View, Text, Section } from '..'
import { useSelector } from '../../state/store'
import {
  db,
  collection,
  CollectionReference,
  onSnapshot,
  query,
  orderBy,
  limit,
  doc,
  DocumentReference,
} from '../../firebase/firestore'
import { useThemeColors } from '../../constants/colors'

type Props = {
  planetId: planet.Id
}
export function PlanetPledgeDisplay({ planetId }: Props): JSX.Element | null {
  const isMounted = React.useRef(true)
  const user = useSelector((state) => state.user)
  const [topPledges, setTopPledges] = React.useState<Pledge[] | null>(null)
  const [topPledgesContainUserPledge, setTopPledgesContainUserPledge] =
    React.useState(false)
  const [userPledge, setUserPledge] = React.useState<Pledge | null>(null)
  const highlightBackgroundColor = useThemeColors().highlightBackground

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  // subscribe to planet pledges
  React.useEffect(
    () =>
      onSnapshot(
        query(
          collection(
            db,
            planetPledgesCollectionPath(planetId)
          ) as CollectionReference<Pledge>,
          orderBy('approximateAmount', 'desc'),
          limit(20)
        ),
        (snap) => {
          if (isMounted.current) {
            if (snap.empty) {
              setTopPledges(null)
            }
            let hasUserPledge = false
            const topPledgeData = snap.docs.map((d) => {
              const data = d.data()
              data.address = convertEthereumAddressToDisplayAddress(
                data.address
              )
              if (data.address === user.user?.account?.address)
                hasUserPledge = true
              return data
            })
            setTopPledges(topPledgeData)
            setTopPledgesContainUserPledge(hasUserPledge)
          }
        }
      ),
    [planetId, user.user?.account?.address]
  )

  // Load user's pledge
  React.useEffect(() => {
    if (user.user?.fuid) {
      return onSnapshot(
        doc(
          db,
          planetPledgePath(planetId, user.user.fuid)
        ) as DocumentReference<Pledge>,
        (snapshot) => {
          if (isMounted.current) {
            const userPledgeForThisPlanet = snapshot.data()
            if (!userPledgeForThisPlanet) {
              setUserPledge(null)
              return
            }
            userPledgeForThisPlanet.address =
              convertEthereumAddressToDisplayAddress(
                userPledgeForThisPlanet.address
              )
            setUserPledge(userPledgeForThisPlanet)
          }
        }
      )
    }
    return () => {} // consistent return - no unsubscribe needed
  }, [planetId, user.user?.fuid])

  if (!topPledges) {
    // no pledges
    return null
  }

  const pledgeRenderArray = (
    !topPledgesContainUserPledge && userPledge
      ? [...topPledges, userPledge]
      : topPledges
  ).map((pledge) => {
    const rowStyle: ViewStyle[] = [styles.pledgeTableRow]
    if (pledge.address === user.user?.account?.address) {
      rowStyle.push(styles.myPledgeRow)
      rowStyle.push({ backgroundColor: highlightBackgroundColor })
    }
    return (
      <View key={pledge.address} style={rowStyle}>
        <Text numberOfLines={1} style={styles.pledgeTableAddress}>
          {pledge.address}
        </Text>
        <Text style={styles.pledgeTableAmount}>
          {pledge.approximateAmount}&nbsp;AI
        </Text>
      </View>
    )
  })

  if (pledgeRenderArray.length === 0) {
    return null
  }

  return (
    <Section>
      <View style={styles.pledgeTable}>
        <View style={styles.pledgeTableRow}>
          <Text
            weight="semiBold"
            style={[styles.pledgeTableAddress, styles.pledgeTableHeading]}
          >
            Pledger
          </Text>
          <Text
            weight="semiBold"
            style={[styles.pledgeTableAmount, styles.pledgeTableHeading]}
          >
            Amount
          </Text>
        </View>
        {pledgeRenderArray}
      </View>
    </Section>
  )
}

const styles = StyleSheet.create({
  myPledgeRow: {
    borderRadius: 3,
    marginLeft: -4,
    marginRight: -4,
    paddingLeft: 4,
    paddingRight: 4,
  },
  pledgeTable: {
    flex: 1,
    maxWidth: 500,
  },
  pledgeTableAddress: {
    flex: 4,
    textAlign: 'left',
  },
  pledgeTableAmount: {
    flex: 1,
    textAlign: 'right',
  },
  pledgeTableHeading: {
    paddingBottom: 4,
  },
  pledgeTableRow: {
    flexDirection: 'row',
    paddingBottom: 2,
    paddingTop: 2,
  },
})
