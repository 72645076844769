/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react'
import { Text as DefaultText, StyleSheet } from 'react-native'
import { useThemeColors } from '../constants/colors'
import { fonts, Fonts, FontSet } from '../constants/fonts'
import { useMobileLayout } from '../hooks/useMobileLayout'
import { useTheme } from '../hooks/useTheme'

const desktopPresetFontStyles = {
  displayXLarge: {
    font: 'PlayfairDisplay',
    weight: 'bold',
    fontSize: 64,
    lineHeight: 1.25,
  },
  displayLarge: {
    font: 'PlayfairDisplay',
    weight: 'bold',
    fontSize: 48,
    lineHeight: 1.25,
  },
  headerMedium: {
    font: 'PlayfairDisplay',
    weight: 'bold',
    fontSize: 32,
    lineHeight: 1.25,
  },
  headerSmall: {
    font: 'PlayfairDisplay',
    weight: 'bold',
    fontSize: 28,
    lineHeight: 1.25,
  },
  paragraphXLarge: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 20,
    lineHeight: 1.5,
  },
  paragraphLarge: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 18,
    lineHeight: 1.5,
  },
  paragraph: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 16,
    lineHeight: 1.5,
  },
  paragraphSmall: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 14,
    lineHeight: 1.5,
  },
  paragraphXSmall: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 12,
    lineHeight: 1.5,
  },
  caption: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 11,
    lineHeight: 1.5,
  },
  largeTextLink: {
    font: 'FraktionMono',
    weight: 'bold',
    fontSize: 28,
    lineHeight: 1.25,
  },
  textLink: {
    font: 'FraktionMono',
    weight: 'bold',
    fontSize: 18,
    lineHeight: 1.5,
  },
}
const mobilePresetFontStyles = {
  displayXLarge: {
    font: 'PlayfairDisplay',
    weight: 'bold',
    fontSize: 48,
    lineHeight: 1.25,
  },
  displayLarge: {
    font: 'PlayfairDisplay',
    weight: 'bold',
    fontSize: 32,
    lineHeight: 1.25,
  },
  headerMedium: {
    font: 'PlayfairDisplay',
    weight: 'bold',
    fontSize: 28,
    lineHeight: 1.25,
  },
  headerSmall: {
    font: 'PlayfairDisplay',
    weight: 'bold',
    fontSize: 24,
    lineHeight: 1.25,
  },
  paragraphXLarge: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 18,
    lineHeight: 1.5,
  },
  paragraphLarge: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 16,
    lineHeight: 1.5,
  },
  paragraph: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 14,
    lineHeight: 1.5,
  },
  paragraphSmall: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 12,
    lineHeight: 1.5,
  },
  paragraphXSmall: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 11,
    lineHeight: 1.5,
  },
  caption: {
    font: 'FraktionMono',
    weight: 'normal',
    fontSize: 10,
    lineHeight: 1.5,
  },
  largeTextLink: {
    font: 'FraktionMono',
    weight: 'bold',
    fontSize: 20,
    lineHeight: 1.25,
  },
  textLink: {
    font: 'FraktionMono',
    weight: 'bold',
    fontSize: 16,
    lineHeight: 1.5,
  },
}
const presetFontStyles = {
  desktop: desktopPresetFontStyles,
  mobile: mobilePresetFontStyles,
}

export type Font = keyof Fonts
export type Weight = keyof FontSet
export type TextPreset =
  | keyof typeof desktopPresetFontStyles
  | keyof typeof mobilePresetFontStyles

export type TextProps = DefaultText['props'] & {
  font?: Font
  weight?: Weight
  preset?: TextPreset
}
export function Text(props: TextProps): JSX.Element {
  const { style, weight, ...otherProps } = props
  const color = useThemeColors().text
  const { theme } = useTheme()
  const isMobile = useMobileLayout()

  let { font } = props
  if (theme === 'hadron' && font === 'default') {
    font = 'Inter'
  }

  const textStyle = {
    ...color,
  }

  const { preset } = props
  let presetStyle
  let presetFontFamilyStyle
  if (theme === 'hadron') {
    // ignore all preset values
  } else if (preset) {
    const presetStyleSet = presetFontStyles[isMobile ? 'mobile' : 'desktop']
    const presetFontStyle = presetStyleSet[preset]
    const presetFont = presetFontStyle.font as Font
    const presetWeight = presetFontStyle.weight as Weight
    presetFontFamilyStyle = fonts[presetFont][presetWeight]
    presetStyle = {
      fontSize: presetFontStyle.fontSize,
      lineHeight: presetFontStyle.lineHeight * presetFontStyle.fontSize,
    }
  }

  const defaultFont =
    theme === 'hadron' ? fonts.Inter.normal : fonts.default.normal
  const fontWeightStyle =
    (font && weight && fonts[font]?.[weight]) ||
    (!presetStyle ? defaultFont : undefined)

  return (
    <DefaultText
      style={[
        [
          styles.text,
          presetFontFamilyStyle,
          presetStyle,
          fontWeightStyle,
          textStyle,
          style,
        ],
      ]}
      {...otherProps}
    />
  )
}
Text.defaultProps = {
  font: 'default',
  weight: 'regular',
}
const styles = StyleSheet.create({
  text: {
    fontSize: 16,
  },
})
