import * as React from 'react'
import { Section } from '../../..'
import { TabView } from '../../../TabView'
import { MyWalletTransactionHistory } from '../../MyWalletTransactionHistory'

export function HistoryTab(): JSX.Element {
  return (
    <TabView>
      <Section heading="Transaction History">
        <MyWalletTransactionHistory />
      </Section>
    </TabView>
  )
}
