import * as React from 'react'
import { StyleSheet } from 'react-native'
import { ReactionType, Post } from '@mv/api/lib/src/schema/forums'
import { View } from '..'
import { useAdmin } from '../../hooks/useAdmin'
import { PlanetForumPostActionsAdminReactionButtons } from './PlanetForumPostActionsAdminReactionButtons'

type Props = {
  post: Post
  userReactions?: Partial<Record<ReactionType, true>>
}
export function PlanetChatPostActionsAdmin({
  post,
  userReactions,
}: Props): JSX.Element | null {
  const isPlanetAdmin = useAdmin('planetAdmin')
  const isPlanetChatAdmin = useAdmin('planetChatAdmin')
  if (!isPlanetAdmin && !isPlanetChatAdmin) {
    return null
  }

  return (
    <View style={styles.actions}>
      <PlanetForumPostActionsAdminReactionButtons
        post={post}
        userReactions={userReactions}
        buttonStyle={styles.reactionButton}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  actions: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 4,
  },
  reactionButton: {
    marginLeft: 0,
    marginRight: 8,
  },
})
