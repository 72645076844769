import * as React from 'react'
import { useRoute, RouteProp } from '@react-navigation/native'
import { PlanetChatTabParamList } from '../../../../navigation/types'
import { TabView } from '../../../TabView'
import { PlanetChatRoom } from '../../PlanetChatRoom'

type Props = {
  planetId: string
}

function AlwaysChatTab({ planetId }: Props): JSX.Element | null {
  const route = useRoute<RouteProp<PlanetChatTabParamList, 'PlanetChat'>>()
  const topicId = route.params?.topicId || undefined

  if (!topicId) {
    return null
  }
  return (
    <TabView scrollable={false}>
      <PlanetChatRoom planetId={planetId ?? ''} topicId={topicId} />
    </TabView>
  )
}

export const ChatTab = React.memo(AlwaysChatTab)
