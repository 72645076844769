/* eslint-disable @typescript-eslint/no-explicit-any */
import { Middleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useSelector as defaultUseSelector,
  useDispatch as defaultUseDispatch,
} from 'react-redux'
import Constants from 'expo-constants'
import { userSlice } from './userSlice'
import { metaMaskSlice } from './metaMaskSlice'
import { notificationsSlice } from './notificationsSlice'
import { modalsSlice } from './modalsSlice'
import { buildSlice } from './buildSlice'
import { contextMenuSlice } from './contextMenuSlice'
import { cacheSlice } from './cacheSlice'
import { RootState } from './state'

const reducers = {
  [modalsSlice.name]: modalsSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [buildSlice.name]: buildSlice.reducer,
  [metaMaskSlice.name]: metaMaskSlice.reducer,
  [contextMenuSlice.name]: contextMenuSlice.reducer,
  [cacheSlice.name]: cacheSlice.reducer,
}

export const useSelector: TypedUseSelectorHook<RootState> = defaultUseSelector

/** Set to true to console log actions */
const debugLoggingEnabled = true
const debugLoggingMiddleware = ((_store) => (next) => (action) => {
  if (action.type) {
    const { type, ...rest } = action
    // eslint-disable-next-line no-console
    console.log(`[${type}]`, rest, {
      state: _store.getState(),
    })
  }
  return next(action)
}) as Middleware<any, any>

const defaultMiddlewareOptions = {
  serializableCheck: false,
  immutableCheck: {
    ignoredPaths: [
      'user.firebaseUser',
      'user.user.firebaseUser',
      'user.user.unsubscribeBalances',
      'metaMask.metaMask',
    ],
  },
}

function makeStore({
  preloadedState,
  includeDebugMiddleware = false,
}: {
  preloadedState?: Partial<RootState>
  includeDebugMiddleware?: boolean
}) {
  return configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      includeDebugMiddleware
        ? getDefaultMiddleware(defaultMiddlewareOptions).prepend(
            debugLoggingMiddleware
          )
        : getDefaultMiddleware(defaultMiddlewareOptions),
    preloadedState,
  })
}

export const store = makeStore({
  includeDebugMiddleware: debugLoggingEnabled && Constants.debugMode,
})

// Export for tests
export function makeTestStore(
  preloadedState?: Partial<RootState>
): typeof store {
  return makeStore({ preloadedState, includeDebugMiddleware: false })
}

export type AppDispatch = typeof store.dispatch
export const useDispatch = (): AppDispatch => defaultUseDispatch<AppDispatch>()

const { getState } = store
export type CombinedState = ReturnType<typeof getState>
