import * as React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { PlanetsStackParamList } from './types'

import { wrapScreenWithNavigationScreen } from '../components/NavigationScreen'
import { ListPlanetProposalsScreen } from '../components/planets/screens/ListPlanetProposalsScreen'
import { ShowPlanetProposalScreen } from '../components/planets/screens/ShowPlanetProposalScreen'
import { CreatePlanetScreen } from '../components/planets/screens/CreatePlanetScreen'
import { EditPlanetScreen } from '../components/planets/screens/EditPlanetScreen'
import { MyDraftPlanetsScreen } from '../components/planets/screens/MyDraftPlanetsScreen'

const PlanetsStack = createStackNavigator<PlanetsStackParamList>()

const WrappedCreatePlanetScreen =
  wrapScreenWithNavigationScreen(CreatePlanetScreen)
const WrappedListPlanetProposalsScreen = wrapScreenWithNavigationScreen(
  ListPlanetProposalsScreen
)
const WrappedShowPlanetProposalScreen = wrapScreenWithNavigationScreen(
  ShowPlanetProposalScreen
)
const WrappedEditPlanetScreen = wrapScreenWithNavigationScreen(EditPlanetScreen)
const WrappedMyDraftPlanetsScreen =
  wrapScreenWithNavigationScreen(MyDraftPlanetsScreen)

export function PlanetsNavigator(): JSX.Element {
  return (
    <PlanetsStack.Navigator
      screenOptions={{ headerShown: false, detachPreviousScreen: true }}
      initialRouteName="PlanetsList"
      mode="modal"
      detachInactiveScreens
    >
      <PlanetsStack.Screen
        name="PlanetsCreate"
        component={WrappedCreatePlanetScreen}
        options={{ title: 'Multiverse Planets - Create a Planet' }}
      />
      <PlanetsStack.Screen
        name="PlanetsList"
        component={WrappedListPlanetProposalsScreen}
        options={{ title: 'Multiverse Planets' }}
      />
      <PlanetsStack.Screen
        name="PlanetsShow"
        component={WrappedShowPlanetProposalScreen}
        options={({ route }) => ({
          title: `Multiverse Planets - ${route.params.planetLocator}`,
        })}
      />
      <PlanetsStack.Screen
        name="PlanetsEdit"
        component={WrappedEditPlanetScreen}
        options={{ title: `Multiverse Planets - Edit Planet` }}
      />
      <PlanetsStack.Screen
        name="PlanetsMyDrafts"
        component={WrappedMyDraftPlanetsScreen}
        options={{ title: `Multiverse Planets - My Drafts` }}
      />
    </PlanetsStack.Navigator>
  )
}
