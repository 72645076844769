import * as React from 'react'
import { Image, StyleSheet } from 'react-native'
import QRCode from 'qrcode'
import { requests } from '@mv/api'
import { logger } from '../logger'
import { Modal, Heading } from './ModalElements'
import * as Form from '../components/form'
import { View, Text } from '../components'
import { Loading } from '../components/Loading'
import { RootState } from '../state/state'
import { useDispatch, useSelector } from '../state/store'
import { userActions } from '../state/userSlice'
import { modalsActions } from '../state/modalsSlice'
import { NoRefresh } from '../components/NoRefresh'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../constants/messages'
import { makeCallableFunction, FunctionsError } from '../firebase/functions'
import { logEvent, analytics } from '../firebase/analytics'

const MODAL_HEADING = 'Authentication App Setup'

type FormError = {
  firstName?: string
  middleName?: string
  lastName?: string
  generic?: string
  verificationCode?: string
  qrCode?: string
  confirmationCode?: string
}

export function TotpSetupModal(): JSX.Element {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const [auth, setAuth] = React.useState<requests.account.TwoFactorAuth>({})
  const [firstName, setFirstName] = React.useState(
    user.user?.profile?.firstName ?? ''
  )
  const [middleName, setMiddleName] = React.useState(
    user.user?.profile?.middleName ?? ''
  )
  const [lastName, setLastName] = React.useState(
    user.user?.profile?.lastName ?? ''
  )
  const [totpParameters, setTotpParameters] =
    React.useState<null | requests.auth.SetupOtpResponse>(null)
  const [qrCode, setQrCode] = React.useState('')

  const [confirmCode, setConfirmCode] = React.useState('')
  const [formError, setFormError] = React.useState({} as FormError)

  const isMounted = React.useRef(true)

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    if (totpParameters && totpParameters.success) {
      QRCode.toDataURL(totpParameters.otpAuthUri)
        .then((dataUrl) => {
          if (isMounted) {
            setQrCode(dataUrl)
          }
        })
        .catch((err) => {
          logger.error('TOTP QR Code generation error', err)
          if (isMounted) {
            setFormError({
              qrCode: 'Error generating QR code. Please try again later.',
            })
          }
        })
    }
  }, [totpParameters])

  if (!totpParameters) {
    return (
      <Modal testID="account-totp-setup-recovery">
        <Heading>{MODAL_HEADING}</Heading>
        <NoRefresh>
          <View style={styles.marginBottom}>
            <Text weight="semiBold">Account recovery details</Text>
            <Text>
              Please verify or update your personal details below. This
              information will be used to confirm your identity if you ever need
              to recover your account.
            </Text>
          </View>
          <Form.Item>
            <Form.Label>First Name</Form.Label>
            <Form.TextInput
              value={firstName}
              onChangeText={(textValue) => {
                setFirstName(textValue)
              }}
            />
            <Form.Error error={formError.firstName} />
          </Form.Item>
          <Form.Item>
            <Form.Label>Middle Name</Form.Label>
            <Form.TextInput
              value={middleName}
              onChangeText={(textValue) => {
                setMiddleName(textValue)
              }}
            />
            <Form.Error error={formError.middleName} />
          </Form.Item>
          <Form.Item>
            <Form.Label>Last Name</Form.Label>
            <Form.TextInput
              value={lastName}
              onChangeText={(textValue) => {
                setLastName(textValue)
              }}
            />
            <Form.Error error={formError.lastName} />
          </Form.Item>
          <Form.TwoFactorStep
            setFormError={setFormError}
            setAuth={setAuth}
            errorMessage={formError.verificationCode}
          >
            <Form.SubmitButton
              label="Get QR Code"
              onPress={async () => {
                setFormError({})
                const result = await submitTotpSetup(
                  firstName,
                  middleName,
                  lastName,
                  user,
                  setFormError,
                  auth
                )
                if (result) {
                  return () => {
                    setTotpParameters(result)
                  }
                }
                return false
              }}
              disabled={Form.disableTwoFactoredSubmit(auth)}
            />
          </Form.TwoFactorStep>
          <Form.Error error={formError.generic} />
        </NoRefresh>
      </Modal>
    )
  }

  if (qrCode || formError.qrCode) {
    return (
      <Modal testID="account-totp-setup-code">
        <Heading>{MODAL_HEADING}</Heading>
        <NoRefresh>
          <Form.Item style={styles.center}>
            <Text>Scan this QR code with your authentication app</Text>
            {!!qrCode && (
              <Image source={{ uri: qrCode }} style={styles.qrCode} />
            )}
            <Form.Error error={formError.qrCode} />
            {!!totpParameters.secret && (
              <View style={styles.row}>
                <Text>Manual setup code: </Text>
                <Text
                  weight="semiBold"
                  style={(styles.totpManualCode, { marginTop: 0 })}
                >
                  {formatSecret(totpParameters.secret)}
                </Text>
              </View>
            )}
          </Form.Item>
          <Form.Item>
            <Form.Label>Authentication Code</Form.Label>
            <Form.TextInput
              value={confirmCode}
              onChangeText={(textValue) => {
                setConfirmCode(textValue)
              }}
            />
            <Form.SubLabel>
              Enter the code from your authentication app
            </Form.SubLabel>
            <Form.Error error={formError.confirmationCode} />
          </Form.Item>
          <Form.SubmitButton
            label="Confirm Authentication Code"
            onPress={async () => {
              setFormError({})
              const result = await submitTotpConfirmationCode(
                confirmCode,
                user,
                setFormError
              )
              if (result) {
                return async () => {
                  dispatch(
                    modalsActions.showGenericModal({
                      title: MODAL_HEADING,
                      messages: [
                        'Congratulations! You have successfully activated 2-factor authentication with your authentication app.',
                        <NoRefresh key="resetCode">
                          <Text weight="semiBold" style={styles.marginVertical}>
                            One-time reset code: {totpParameters.resetCodes[0]}
                          </Text>
                        </NoRefresh>,
                        'This code can be used to recover your account in case you lose access to your authentication app. Please write it down and keep it in a safe place.',
                      ],
                      addCloseLink: true,
                    })
                  )
                  if (user.user) {
                    logEvent(analytics(), 'totp_setup_completed', {
                      uid: user.user?.fuid,
                    })
                    await dispatch(userActions.loadUserAccount())
                  }
                }
              }
              return false
            }}
            disabled={Form.disableTwoFactoredSubmit({
              totpToken: confirmCode,
            })}
          />
          <Form.Error error={formError.generic} />
        </NoRefresh>
      </Modal>
    )
  }

  return (
    <Modal>
      <Heading>{MODAL_HEADING}</Heading>
      <NoRefresh>
        <Loading />
      </NoRefresh>
    </Modal>
  )
}

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: 20,
  },
  marginVertical: {
    marginBottom: 10,
    marginTop: 10,
  },
  qrCode: {
    height: 300,
    width: 300,
  },
  row: {
    flexDirection: 'row',
  },
  totpManualCode: {
    fontSize: 18,
    marginTop: 10,
  },
})

async function submitTotpSetup(
  firstName: string,
  middleName: string,
  lastName: string,
  user: RootState['user'],
  setFormError: React.Dispatch<React.SetStateAction<FormError>>,
  auth: requests.account.TwoFactorAuth
): Promise<requests.auth.SetupOtpResponse | false> {
  if (!user.user) {
    setFormError({
      generic: ERROR_USER_NOT_LOGGED_IN,
    })
    return false
  }

  const setupOtp = makeCallableFunction('auth-setupOtp')

  try {
    const response = await setupOtp({
      id: user.user.fuid,
      profile: {
        firstName,
        middleName,
        lastName,
      },
      auth,
    })
    if (response.data.success === true) {
      return response.data
    }
  } catch (_e) {
    const e = _e as FunctionsError
    const errors: FormError = {}
    logger.error('Failed to Setup TOTP', e)
    errors.verificationCode = Form.getTwoFactorFormError(
      e.code,
      e.message,
      auth
    )
    if (!errors.verificationCode) {
      errors.generic = ERROR_OOPS
    }
    setFormError(errors)
    return false
  }
  setFormError({
    generic: ERROR_OOPS,
  })
  return false
}

async function submitTotpConfirmationCode(
  token: string,
  user: RootState['user'],
  setFormError: React.Dispatch<React.SetStateAction<FormError>>
): Promise<boolean> {
  if (!user.user) {
    setFormError({
      generic: ERROR_USER_NOT_LOGGED_IN,
    })
    return false
  }

  const confirmOtp = makeCallableFunction('auth-confirmOtp')

  try {
    const response = await confirmOtp({
      id: user.user.fuid,
      token,
    })
    if (response.data.success === true) {
      return true
    }
  } catch (_e) {
    const e = _e as FunctionsError
    const errors: FormError = {}
    errors.confirmationCode = Form.getTwoFactorFormError(e.code, e.message, {
      totpToken: 'dummyToken',
    })
    if (!errors.confirmationCode) {
      logger.error('Failed to Confirm TOTP', e)
      errors.generic = ERROR_OOPS
    } else {
      logger.warn('Failed to Confirm TOTP', e)
    }
    setFormError(errors)
    return false
  }
  setFormError({
    generic: ERROR_OOPS,
  })
  return false
}

/**
 * Returns a string with a space every 4 characters
 */
function formatSecret(s: string): string {
  return (s.match(/.{1,4}/g) || []).join(' ')
}
