import { StyleSheet, ViewStyle } from 'react-native'
import { useMobileLayout } from './useMobileLayout'
import { useTheme } from './useTheme'

type MarginStyle = {
  marginBottom: number
  marginLeft: number
  marginRight: number
  marginTop: number
}
type ScreenMargins = {
  style: MarginStyle
  styleSheet: ViewStyle
}

export function useScreenMargins(): ScreenMargins {
  const isMobileDevice = useMobileLayout()
  const { theme } = useTheme()
  const styles = themedStyles[theme]
  const styleSheet = themedStyleSheet[theme]

  return isMobileDevice
    ? { style: styles.mobile, styleSheet: styleSheet.mobile }
    : { style: styles.regular, styleSheet: styleSheet.regular }
}

const themedStyles = {
  hadron: {
    mobile: {
      marginBottom: 0,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 20,
    },
    regular: {
      marginBottom: 20,
      marginLeft: 32,
      marginRight: 32,
      marginTop: 20,
    },
  },
  multiverse2022: {
    mobile: {
      marginBottom: 0,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 20,
    },
    regular: {
      marginBottom: 100,
      marginLeft: 100,
      marginRight: 100,
      marginTop: 100,
    },
  },
}

const themedStyleSheet = {
  hadron: StyleSheet.create(themedStyles.hadron),
  multiverse2022: StyleSheet.create(themedStyles.multiverse2022),
}
