import * as React from 'react'
import { StyleSheet } from 'react-native'
import { logger } from '../logger'
import { Modal, Heading } from './ModalElements'
import * as Form from '../components/form'
import { RootState } from '../state/state'
import { useDispatch, useSelector } from '../state/store'
import { modalsActions } from '../state/modalsSlice'
import { logEvent, analytics } from '../firebase/analytics'
import { makeCallableFunction, FunctionsError } from '../firebase/functions'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../constants/messages'
import { NoRefresh } from '../components/NoRefresh'
import { Text } from '../components'
import { ModalStakeData } from '../state/modalsTypes'
import { humanizeBalance } from '../helpers'
import { MyWalletStakingSummaryEarlyUnstakeNotice } from '../components/wallet/MyWalletStakingSummaryEarlyUnstakeNotice'

const MODAL_HEADING = 'Multiverse Staking'

type FormError = {
  amount?: string
  generic?: string
}

export function StakeModal(): JSX.Element {
  const isMounted = React.useRef(true)

  const { availableAI, earlyUnstakingFeeInBasisPoints, earlyUnstakingPeriod } =
    useSelector((state) => state.modals.data) as ModalStakeData

  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const [amountFieldValue, setAmountFieldValue] = React.useState('')
  const [formError, setFormError] = React.useState({} as FormError)
  const [stakeDisabled, setStakeDisabled] = React.useState(true)

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    const validAmount = checkValidity(amountFieldValue)
    if (validAmount) {
      setStakeDisabled(false)
    } else {
      setStakeDisabled(true)
    }
  }, [amountFieldValue])

  const validAmount = checkValidity(amountFieldValue)

  return (
    <Modal testID="wallet-stake">
      <Heading>Stake</Heading>
      <Text weight="bold" style={[styles.available]}>
        Available AI:{' '}
        {humanizeBalance(
          user.user?.balances?.AI._.available || availableAI,
          18
        )}{' '}
        AI
      </Text>
      <NoRefresh>
        <Form.Item>
          <Form.Label>AI Amount to Stake</Form.Label>
          <Form.TextInput
            testID="amountInput"
            value={amountFieldValue}
            onChangeText={(textValue) => {
              setAmountFieldValue(textValue)
            }}
          />
          <Form.Error error={formError.amount} />
        </Form.Item>
        <MyWalletStakingSummaryEarlyUnstakeNotice
          earlyUnstakingFeeInBasisPoints={earlyUnstakingFeeInBasisPoints}
          earlyUnstakingPeriod={earlyUnstakingPeriod}
        />

        <Form.SubmitButton
          testID="submitButton"
          label={validAmount ? `Stake ${validAmount} AI` : 'Stake'}
          onPress={async () => {
            setFormError({})
            const result = await submitStake(user, validAmount, setFormError)
            if (result) {
              return () => {
                dispatch(
                  modalsActions.showGenericModal({
                    title: MODAL_HEADING,
                    messages: [
                      `Your stake request of ${validAmount} AI has been received.`,
                    ],
                  })
                )
                logEvent(analytics(), 'stake', {
                  amount: validAmount,
                  staker: user.user?.fuid,
                })
              }
            }
            return false
          }}
          disabled={stakeDisabled}
        />
      </NoRefresh>
    </Modal>
  )
}
const styles = StyleSheet.create({
  available: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
})

async function submitStake(
  user: RootState['user'],
  amount: string,
  setFormError: React.Dispatch<React.SetStateAction<FormError>>
): Promise<boolean> {
  if (!user.user) {
    setFormError({
      generic: ERROR_USER_NOT_LOGGED_IN,
    })
    return false
  }

  const staking = makeCallableFunction('staking-staking')
  try {
    await staking({
      id: user.user.fuid,
      action: 'stake',
      amount,
    })
    return true
  } catch (_e) {
    const e = _e as FunctionsError
    const errors: FormError = {}

    switch (e.code) {
      case 'invalid-argument':
        logger.warn('stake error', e)
        if (/token|amount|number/i.test(e.message)) {
          errors.amount = 'Invalid amount entered.'
        } else {
          errors.generic = ERROR_OOPS
        }
        break
      case 'failed-precondition':
        logger.warn('stake error', e)
        errors.amount = 'Insufficient funds. Please enter a smaller amount.'
        break
      default:
        logger.error('stake error', e)
        errors.generic = ERROR_OOPS
    }

    setFormError(errors)
    return false
  }
}

function checkValidity(amountString: string): string {
  let trimmedAmountString = amountString.trim()
  if (!/^[0-9.,]+$/.test(trimmedAmountString)) {
    return ''
  }
  if (trimmedAmountString.includes('.')) {
    if (!/^[0-9,]*\.[0-9]*$/.test(trimmedAmountString)) {
      return ''
    }
  }

  trimmedAmountString = trimmedAmountString
    .replace(/,/g, '') // remove commas
    .replace(/^0+/, '') // remove leading zeroes
    .replace(/^\./, '0.') // add 0 if decimal point at beginning
    .replace(/(\.[0-9]*?)0+$/, '$1') // remove all trailing 0 if decimal point
    .replace(/\.$/, '') // remove decimal point at end

  return trimmedAmountString
}
