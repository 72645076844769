import { getAuth } from 'firebase/auth'
import { app } from './init'

export const auth = getAuth(app)

// Exporting what the app currently needs. Feel free to expand the
// list of exported symbols.
export {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from 'firebase/auth'

export type { User } from 'firebase/auth'
