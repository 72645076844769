/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialState } from './buildTypes'

export const buildSlice = createSlice({
  name: 'build',
  initialState: initialState(),
  reducers: {
    setCurrentBuild(state, action: PayloadAction<string | undefined>) {
      state.currentBuild = action.payload || ''
    },
    setLatestBuild(state, action: PayloadAction<string | undefined>) {
      state.latestBuild = action.payload || ''
    },
    setNoRefresh(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.noRefresh = true
        state.noRefreshCounter += 1
      } else {
        state.noRefresh = false
        state.noRefreshCounter = Math.max(state.noRefreshCounter - 1, 0)
      }
    },
  },
})

export const buildActions = buildSlice.actions
