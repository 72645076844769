import * as React from 'react'
import { isEqual } from 'lodash'
import {
  DisplayNameField,
  getDisplayName,
  DisplayNameMaxLength,
} from '@mv/api/lib/src/schema/accounts/userProfile'
import * as Form from '../components/form'

const ALL_OPTIONS: DisplayNameField[][] = [
  [],
  ['firstName'],
  ['firstName', 'lastName'],
  ['firstName', 'middleName', 'lastName'],
  ['middleName'],
  ['lastName', 'firstName'],
  ['lastName'],
]

type Props = {
  firstName: string
  middleName: string
  lastName: string
  selectedValue: DisplayNameField[]
  onValueChange: (itemValue: DisplayNameField[]) => void
}

export function AccountEditProfileDisplayNamePicker({
  firstName,
  lastName,
  middleName,
  selectedValue,
  onValueChange,
}: Props): JSX.Element {
  const testProfile = {
    firstName,
    lastName,
    middleName,
  }

  const [options, setOptions] =
    React.useState<DisplayNameField[][]>(ALL_OPTIONS)

  React.useEffect(() => {
    let tempOptions = ALL_OPTIONS
    if (!firstName) {
      tempOptions = tempOptions.filter(
        (option) => !option.includes('firstName')
      )
    }
    if (!middleName) {
      tempOptions = tempOptions.filter(
        (option) => !option.includes('middleName')
      )
    }
    if (!lastName) {
      tempOptions = tempOptions.filter((option) => !option.includes('lastName'))
    }
    tempOptions = tempOptions.filter(
      (option) =>
        getDisplayName({ firstName, lastName, middleName }, option).length <=
        DisplayNameMaxLength
    )
    setOptions(tempOptions)
  }, [firstName, lastName, middleName])

  React.useEffect(() => {
    if (!options.find((option) => isEqual(option, selectedValue))) {
      onValueChange(options[0])
    }
  }, [onValueChange, options, selectedValue])

  const pickerItems = options.map((option) => {
    if (option.length === 0) {
      return (
        <Form.PickerItem key="none" label="-- No name displayed --" value="" />
      )
    }
    const value = option.join(',')
    return (
      <Form.PickerItem
        key={value}
        label={getDisplayName(testProfile, option)}
        value={value}
      />
    )
  })

  return (
    <Form.Picker<string>
      selectedValue={selectedValue.join(',')}
      onValueChange={(_itemValue, itemIndex) => {
        onValueChange(options[itemIndex])
      }}
    >
      {pickerItems}
    </Form.Picker>
  )
}
