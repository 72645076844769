/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react'
import { View as DefaultView } from 'react-native'

export const View = React.forwardRef<DefaultView, DefaultView['props']>(
  (props: DefaultView['props'], ref): JSX.Element => {
    const { style, ...otherProps } = props
    const backgroundColor = 'transparent'

    return (
      <DefaultView
        ref={ref}
        style={[{ backgroundColor }, style]}
        {...otherProps}
      />
    )
  }
)
