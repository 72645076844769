import * as React from 'react'
import { useRoute } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import Constants from 'expo-constants'
import { ConstantsExtra } from '../types/appConfig'
import {
  PlanetTabParamList,
  PlanetsStackNavigationSpecificRoute,
  DrawerNavigationRoute,
} from './types'
import { OverviewTab } from '../components/planets/screens/planetTabs/OverviewTab'
import { PledgeTab } from '../components/planets/screens/planetTabs/PledgeTab'
import { PlanetChatNavigator } from './PlanetChatNavigator'
import { ForumsTab } from '../components/planets/screens/planetTabs/ForumsTab'
import { Tabs } from '../components/planets/Tabs'
import { useFeatureHomeworldChat } from '../hooks/useFeatureHomeworldChat'

const PlanetTabs = createStackNavigator<PlanetTabParamList>()

const featuresConfig = Constants.manifest.extra
  ?.features as ConstantsExtra['features']
const planetsChatAndForumEnabled = featuresConfig.enablePlanetChatAndForums

export function PlanetTabNavigator(): JSX.Element {
  const route = useRoute<
    PlanetsStackNavigationSpecificRoute<'PlanetsShow'> | DrawerNavigationRoute
  >()
  const backgroundStyle = {
    backgroundColor: 'transparent',
  }
  const planetLocator = route.params?.planetLocator
  const showHomeworldChat = useFeatureHomeworldChat()

  if (!planetLocator) {
    return (
      <PlanetTabs.Navigator
        screenOptions={{
          headerShown: true,
          detachPreviousScreen: true,
          cardStyle: backgroundStyle,
          header: () => <Tabs />,
        }}
        mode="modal"
        detachInactiveScreens
      >
        {showHomeworldChat && (
          <PlanetTabs.Screen
            name="PlanetTabChat"
            component={PlanetChatNavigator}
            options={() => ({
              title: 'Multiverse Homeworld',
            })}
            initialParams={route.params}
          />
        )}
        <PlanetTabs.Screen
          name="PlanetTabOverview"
          component={OverviewTab}
          options={() => ({
            title: 'Multiverse Homeworld',
          })}
          initialParams={route.params}
        />
        {showHomeworldChat && (
          <PlanetTabs.Screen
            name="PlanetTabForums"
            component={ForumsTab}
            options={() => ({
              title: 'Multiverse Homeworld',
            })}
            initialParams={route.params}
          />
        )}
      </PlanetTabs.Navigator>
    )
  }

  return (
    <PlanetTabs.Navigator
      screenOptions={{
        headerShown: true,
        detachPreviousScreen: true,
        cardStyle: backgroundStyle,
        header: () => <Tabs />,
      }}
      mode="modal"
      detachInactiveScreens
    >
      <PlanetTabs.Screen
        name="PlanetTabOverview"
        component={OverviewTab}
        options={() => ({
          title: `Multiverse Planets - ${planetLocator}`,
        })}
        initialParams={route.params}
      />
      {planetsChatAndForumEnabled && (
        <PlanetTabs.Screen
          name="PlanetTabChat"
          component={PlanetChatNavigator}
          options={() => ({
            title: `Multiverse Planets - ${planetLocator} - Brainstorm`,
          })}
          initialParams={route.params}
        />
      )}
      {planetsChatAndForumEnabled && (
        <PlanetTabs.Screen
          name="PlanetTabForums"
          component={ForumsTab}
          options={() => ({
            title: `Multiverse Planets - ${planetLocator} - Forums`,
          })}
          initialParams={route.params}
        />
      )}
      <PlanetTabs.Screen
        name="PlanetTabPledge"
        component={PledgeTab}
        options={() => ({
          title: `Multiverse Planets - ${planetLocator} - Pledging`,
        })}
        initialParams={route.params}
      />
    </PlanetTabs.Navigator>
  )
}
