import { useTheme } from '../hooks/useTheme'

const tintColorLight = '#2f95dc'
const tintColorDark = '#fff'

type Color = string
type TextInputColorSets = {
  backgroundColor: Color
  borderColor: Color
  color: Color
}
type NavItemColorSet = {
  activeTintColor: Color
  activeBackgroundColor: Color
  inactiveTintColor: Color
  inactiveBackgroundColor: Color
}
type NavColorSet = {
  background: Color
  item: NavItemColorSet
  horizontalRule: Color
  infoText: Color
  footerItem: NavItemColorSet
  menuButton: Color
  border: Color | undefined
}
type LogoColorSet = {
  hadron: {
    base: Color
    inverseBase: Color
    a: Color
    inverseA: Color
  }
  multiverse: {
    base: Color
  }
}
type ModalColorSet = {
  lightboxBackground: Color
  background: Color
}
type TabColorSet = {
  major: {
    borderBottomColor: Color
    activeColor: Color
    inactiveColor: Color
  }
  minor: {
    borderBottomColor: Color
    activeColor: Color
    inactiveColor: Color
  }
}
type ButtonColorSet = {
  container: BackgroundColorOnly
  containerPressed: BackgroundColorOnly
  label: ColorOnly
}
type ButtonColorSets = {
  primary: ButtonColorSet
  formLabelAux: ButtonColorSet
  disabled: ButtonColorSet
  menu: ButtonColorSet
  reaction: {
    active: Color
    inactive: Color
  }
}
type LockedTokensColorSets = {
  headingColor: Color
  summaryColor: Color
  borderColor: Color
  detailsTitleColor: Color
  detailsHeadingColor: Color
  detailsColor: Color
  scheduleHeadingColor: Color
  scheduleColor: Color
  scheduleBackgroundColor: Color
}
type ChatColorSet = {
  recentHighlightOnColor: Color
  recentHighlightOffColor: Color
  borderColor: Color
  textInputBordercolor: Color
  inResponseCloseColor: Color
  inReplyTo: {
    backgroundColor: Color
    color: Color
  }
  background: Color
  actionButton: {
    activeColor: Color
    color: Color
  }
}
type CardColorSet = {
  container: {
    borderColor: Color
  } & BackgroundColorOnly
  divider: BackgroundColorOnly
  brief: ColorOnly
  stats: {
    label: ColorOnly
    value: ColorOnly
  }
  hero: BackgroundColorOnly
}
type NotificationColorSet = {
  container: BackgroundColorOnly
  text: ColorOnly
}
type ContextMenuColorSet = {
  backgroundColor: Color
  dividerColor: Color
}
type TableColorSet = {
  headingBackgroundColor: Color
  backgroundColors: [Color, Color]
}
type WalletColorSet = {
  balanceColor: Color
  stakingBonus: Color
  stakingBonusHighlight: Color
}
type TextColors = {
  color: Color
  backgroundColor: Color
}
type CheckBoxColors = {
  on: Color
  off: Color
} & BackgroundColorOnly
type SliderColors = {
  maximumTrackTintColor: Color
  minimumTrackTintColor: Color
  marker: Color
  thumbTintColor: Color
}
type BackgroundColorOnly = {
  backgroundColor: Color
}
type ColorOnly = {
  color: Color
}
export type ColorSet = {
  foreground: Color
  light: Color
  medium: Color
  background: Color
  extraLightBackground: Color
  highlightBackground: Color
  highlightBackgroundOff: Color
  inverseForeground: Color
  inverseBackground: Color
  activityIndicator: Color
  tabs: TabColorSet
  table: TableColorSet
  text: TextColors
  modal: ModalColorSet
  nav: NavColorSet
  link: ColorOnly
  button: ButtonColorSets
  textError: TextColors
  textInput: TextInputColorSets
  checkBox: CheckBoxColors
  slider: SliderColors
  tint: Color
  tabIconDefault: Color
  tabIconSelected: Color
  logo: LogoColorSet
  planetCard: CardColorSet
  notification: NotificationColorSet
  lockedTokens: LockedTokensColorSets
  chat: ChatColorSet
  context: ContextMenuColorSet
  wallet: WalletColorSet
}
type Colors = {
  hadron: {
    light: ColorSet
    dark: ColorSet
  }
  multiverse2022: {
    light: ColorSet
    dark: ColorSet
  }
}
// export const useThemeColors = (): ColorSet => allColors[useTheme().colorScheme]
export const useThemeColors = (): ColorSet => allColors[useTheme().theme].light

const hadronLight: ColorSet = {
  foreground: '#000000',
  light: '#C0C0C0',
  medium: '#666666',
  background: '#fff',
  extraLightBackground: '#F2F2F2',
  highlightBackground: 'rgba(220,0,0,0.1)', // or use purple '#F0E8FF',
  highlightBackgroundOff: 'rgba(220,0,0,0)',
  inverseForeground: '#FFFFFF',
  inverseBackground: '#000',
  activityIndicator: '#dc0000',
  text: {
    color: '#000000',
    backgroundColor: 'transparent',
  },
  table: {
    headingBackgroundColor: '#FFFFFF',
    backgroundColors: ['#F2F2F2', '#FFFFFF'],
  },
  tabs: {
    major: {
      activeColor: '#000000',
      inactiveColor: '#006edc',
      borderBottomColor: '#000000',
    },
    minor: {
      activeColor: '#000000',
      inactiveColor: '#006edc',
      borderBottomColor: 'transparent',
    },
  },
  modal: {
    lightboxBackground: 'rgba(0,0,0,0.7)',
    background: '#FFFFFF',
  },
  nav: {
    background: '#000',
    item: {
      activeTintColor: '#000000',
      activeBackgroundColor: '#F0F0F0',
      inactiveTintColor: '#FFFFFF',
      inactiveBackgroundColor: 'transparent',
    },
    footerItem: {
      activeTintColor: '#9E9E9E',
      activeBackgroundColor: 'transparent',
      inactiveTintColor: '#9E9E9E',
      inactiveBackgroundColor: 'transparent',
    },
    horizontalRule: 'rgba(255,255,255,0.38)',
    infoText: '#C0C0C0',
    menuButton: '#FFFFFF',
    border: undefined,
  },
  link: {
    color: '#006EDC',
  },
  button: {
    primary: {
      container: {
        backgroundColor: '#006EDC',
      },
      containerPressed: {
        backgroundColor: '#006EDC',
      },
      label: {
        color: '#FFFFFF',
      },
    },
    menu: {
      container: {
        backgroundColor: '#FFFFFF',
      },
      containerPressed: {
        backgroundColor: '#FFFFFF',
      },
      label: {
        color: '#222222',
      },
    },
    formLabelAux: {
      container: {
        backgroundColor: '#F0F0F0',
      },
      containerPressed: {
        backgroundColor: '#F0F0F0',
      },
      label: {
        color: '#000000',
      },
    },
    reaction: {
      active: '#006EDC',
      inactive: '#DCDCDC',
    },
    disabled: {
      container: {
        backgroundColor: '#ACACAC',
      },
      containerPressed: {
        backgroundColor: '#ACACAC',
      },
      label: {
        color: '#FFFFFF',
      },
    },
  },
  textInput: {
    backgroundColor: 'transparent',
    borderColor: '#DADADA',
    color: '#000000',
  },
  textError: {
    backgroundColor: 'transparent',
    color: '#DC0000',
  },
  checkBox: {
    on: '#000000',
    off: '#999999',
    backgroundColor: 'transparent',
  },
  slider: {
    maximumTrackTintColor: '#B3B3B3',
    minimumTrackTintColor: '#444444',
    marker: '#DC0000',
    thumbTintColor: '#333333',
  },
  tint: tintColorLight,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColorLight,
  logo: {
    hadron: {
      base: '#000000',
      inverseBase: '#FFFFFF',
      a: '#DC0000',
      inverseA: '#DC0000',
    },
    multiverse: {
      base: '#000000',
    },
  },
  planetCard: {
    container: {
      backgroundColor: '#f7f8fa',
      borderColor: '#e9eaf0',
    },
    divider: {
      backgroundColor: '#888b94',
    },
    brief: {
      color: '#14192b',
    },
    stats: {
      label: { color: '#888b94' },
      value: { color: '#14192b' },
    },
    hero: {
      backgroundColor: '#152133',
    },
  },
  notification: {
    container: {
      backgroundColor: 'rgba(64,32,192,0.8)',
    },
    text: {
      color: '#FFFFFF',
    },
  },
  lockedTokens: {
    headingColor: '#616161',
    summaryColor: '#121212',
    borderColor: '#E0E0E0',
    detailsTitleColor: '#121212',
    detailsHeadingColor: '#616161',
    detailsColor: '#212121',
    scheduleHeadingColor: '#616161',
    scheduleColor: '#121212',
    scheduleBackgroundColor: '#F9F9F9',
  },
  chat: {
    recentHighlightOnColor: 'rgba(255,255,128,0.5)',
    recentHighlightOffColor: 'rgba(255,255,128,0)',
    borderColor: '#C0C0C0',
    textInputBordercolor: '#E0E0E0',
    inResponseCloseColor: '#999999',
    inReplyTo: {
      backgroundColor: '#F8F8F8',
      color: '#666666',
    },
    background: 'transparent',
    actionButton: {
      activeColor: '#000000',
      color: '#000000',
    },
  },
  context: {
    backgroundColor: 'rgba(240,240,240,0.9)',
    dividerColor: '#FFFFFF',
  },
  wallet: {
    balanceColor: '#000000',
    stakingBonus: '#000000',
    stakingBonusHighlight: '#000000',
  },
}

const hadronDark: ColorSet = {
  foreground: '#FFFFFF',
  light: '#666666',
  medium: '#C0C0C0',
  background: '#000000',
  extraLightBackground: '#333333',
  highlightBackground: 'rgba(220,0,0,0.7)', // or use blue '#453db8',
  highlightBackgroundOff: 'rgba(220,0,0,0)',
  inverseForeground: '#000000',
  inverseBackground: '#FFF',
  activityIndicator: '#dc0000',
  text: {
    color: '#FFFFFF',
    backgroundColor: 'transparent',
  },
  table: {
    headingBackgroundColor: '#000000',
    backgroundColors: ['#333333', '#000000'],
  },
  tabs: {
    major: {
      activeColor: '#FFFFFF',
      inactiveColor: '#006edc',
      borderBottomColor: '#FFFFFF',
    },
    minor: {
      activeColor: '#FFFFFF',
      inactiveColor: '#006edc',
      borderBottomColor: 'transparent',
    },
  },
  modal: {
    lightboxBackground: 'rgba(255,255,255,0.2)',
    background: '#000000',
  },
  nav: {
    background: '#FFF',
    item: {
      activeTintColor: '#000',
      activeBackgroundColor: 'transparent',
      inactiveTintColor: '#000000',
      inactiveBackgroundColor: 'transparent',
    },
    footerItem: {
      activeTintColor: '#9E9E9E',
      activeBackgroundColor: 'transparent',
      inactiveTintColor: '#9E9E9E',
      inactiveBackgroundColor: 'transparent',
    },
    horizontalRule: 'rgba(0,0,0,0.38)',
    infoText: '#A2A2A2',
    menuButton: '#000000',
    border: undefined,
  },
  link: {
    color: '#A2B9FB',
  },
  button: {
    primary: {
      container: {
        backgroundColor: '#006EDC',
      },
      containerPressed: {
        backgroundColor: '#006EDC',
      },
      label: {
        color: '#FFFFFF',
      },
    },
    menu: {
      container: {
        backgroundColor: '#FFFFFF',
      },
      containerPressed: {
        backgroundColor: '#FFFFFF',
      },
      label: {
        color: '#222222',
      },
    },
    formLabelAux: {
      container: {
        backgroundColor: '#666666',
      },
      containerPressed: {
        backgroundColor: '#666666',
      },
      label: {
        color: '#F0F0F0',
      },
    },
    reaction: {
      active: '#006EDC',
      inactive: '#666666',
    },
    disabled: {
      container: {
        backgroundColor: '#ACACAC',
      },
      containerPressed: {
        backgroundColor: '#ACACAC',
      },
      label: {
        color: '#FFFFFF',
      },
    },
  },
  textInput: {
    backgroundColor: '#333333',
    borderColor: '#666666',
    color: '#FFFFFF',
  },
  textError: {
    backgroundColor: 'transparent',
    color: '#DC0000',
  },
  checkBox: {
    on: '#FFFFFF',
    off: '#666666',
    backgroundColor: 'transparent',
  },
  slider: {
    maximumTrackTintColor: '#444444',
    minimumTrackTintColor: '#B3B3B3',
    marker: '#DC0000',
    thumbTintColor: '#D0D0D0',
  },
  tint: tintColorDark,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColorDark,
  logo: {
    hadron: {
      base: '#FFFFFF',
      inverseBase: '#000000',
      a: '#DC0000',
      inverseA: '#DC0000',
    },
    multiverse: {
      base: '#FFFFFF',
    },
  },
  planetCard: {
    container: {
      backgroundColor: '#f7f8fa',
      borderColor: '#e9eaf0',
    },
    divider: {
      backgroundColor: '#888b94',
    },
    brief: {
      color: '#14192b',
    },
    stats: {
      label: { color: '#888b94' },
      value: { color: '#14192b' },
    },
    hero: {
      backgroundColor: '#152133',
    },
  },
  notification: {
    container: {
      backgroundColor: 'rgba(64,32,192,0.8)',
    },
    text: {
      color: '#FFFFFF',
    },
  },
  lockedTokens: {
    headingColor: '#616161',
    summaryColor: '#121212',
    borderColor: '#E0E0E0',
    detailsTitleColor: '#121212',
    detailsHeadingColor: '#616161',
    detailsColor: '#212121',
    scheduleHeadingColor: '#616161',
    scheduleColor: '#121212',
    scheduleBackgroundColor: '#F9F9F9',
  },
  chat: {
    recentHighlightOnColor: 'rgba(220,0,0,1)',
    recentHighlightOffColor: 'rgba(220,0,0,0)',
    borderColor: '#C0C0C0',
    textInputBordercolor: '#E0E0E0',
    inResponseCloseColor: '#999999',
    inReplyTo: {
      backgroundColor: '#F8F8F8',
      color: '#666666',
    },
    background: 'transparent',
    actionButton: {
      activeColor: '#000000',
      color: '#000000',
    },
  },
  context: {
    backgroundColor: 'rgba(240,240,240,0.9)',
    dividerColor: '#FFFFFF',
  },
  wallet: {
    balanceColor: '#000000',
    stakingBonus: '#000000',
    stakingBonusHighlight: '#000000',
  },
}

const multiverse2022light: ColorSet = {
  foreground: '#000000',
  light: '#C0C0C0',
  medium: '#666666',
  background: '#fbf9ef',
  extraLightBackground: '#F2F2F2',
  highlightBackground: 'rgba(220,0,0,0.1)', // or use purple '#F0E8FF',
  highlightBackgroundOff: 'rgba(220,0,0,0)',
  inverseForeground: '#FFFFFF',
  inverseBackground: '#000',
  activityIndicator: '#E51E99',
  text: {
    color: '#000000',
    backgroundColor: 'transparent',
  },
  table: {
    headingBackgroundColor: 'transparent',
    backgroundColors: ['#FFDCB4', '#FFDCB4'],
  },
  tabs: {
    major: {
      activeColor: '#E51E99',
      inactiveColor: '#053C78',
      borderBottomColor: '#E51E99',
    },
    minor: {
      activeColor: '#E51E99',
      inactiveColor: '#053C78',
      borderBottomColor: 'transparent',
    },
  },
  modal: {
    lightboxBackground: 'rgba(0,0,0,0.5)',
    background: '#FFFFFF',
  },
  nav: {
    background: '#fbf9ef',
    item: {
      activeTintColor: '#E51E99',
      activeBackgroundColor: 'transparent',
      inactiveTintColor: '#053C78',
      inactiveBackgroundColor: 'transparent',
    },
    footerItem: {
      activeTintColor: '#053C78',
      activeBackgroundColor: 'transparent',
      inactiveTintColor: '#053C78',
      inactiveBackgroundColor: 'transparent',
    },
    horizontalRule: 'transparent', // 'rgba(0,0,0,0.2)',
    infoText: '#000000',
    menuButton: '#E51E99',
    border: 'rgba(0,0,0,0.2)',
  },
  link: {
    color: '#053C78',
  },
  button: {
    primary: {
      container: {
        backgroundColor: '#053C78',
      },
      containerPressed: {
        backgroundColor: '#032A54',
      },
      label: {
        color: '#FFFFFF',
      },
    },
    menu: {
      container: {
        backgroundColor: '#FFFFFF',
      },
      containerPressed: {
        backgroundColor: '#F0F0F0',
      },
      label: {
        color: '#222222',
      },
    },
    formLabelAux: {
      container: {
        backgroundColor: '#F0F0F0',
      },
      containerPressed: {
        backgroundColor: '#E0E0E0',
      },
      label: {
        color: '#000000',
      },
    },
    reaction: {
      active: '#006EDC',
      inactive: '#DCDCDC',
    },
    disabled: {
      container: {
        backgroundColor: '#ACACAC',
      },
      containerPressed: {
        backgroundColor: '#ACACAC',
      },
      label: {
        color: '#FFFFFF',
      },
    },
  },
  textInput: {
    backgroundColor: '#FFFFFF',
    borderColor: '#e0e0e0',
    color: '#000000',
  },
  textError: {
    backgroundColor: 'transparent',
    color: '#DC0000',
  },
  checkBox: {
    on: '#000000',
    off: '#999999',
    backgroundColor: 'transparent',
  },
  slider: {
    maximumTrackTintColor: '#B3B3B3',
    minimumTrackTintColor: '#444444',
    marker: '#DC0000',
    thumbTintColor: '#333333',
  },
  tint: tintColorLight,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColorLight,
  logo: {
    hadron: {
      base: '#000000',
      inverseBase: '#FFFFFF',
      a: '#DC0000',
      inverseA: '#DC0000',
    },
    multiverse: {
      base: '#E51E99',
    },
  },
  planetCard: {
    container: {
      backgroundColor: '#f7f8fa',
      borderColor: '#e9eaf0',
    },
    divider: {
      backgroundColor: '#888b94',
    },
    brief: {
      color: '#14192b',
    },
    stats: {
      label: { color: '#888b94' },
      value: { color: '#14192b' },
    },
    hero: {
      backgroundColor: '#152133',
    },
  },
  notification: {
    container: {
      backgroundColor: 'rgba(64,32,192,0.8)',
    },
    text: {
      color: '#FFFFFF',
    },
  },
  lockedTokens: {
    headingColor: '#616161',
    summaryColor: '#121212',
    borderColor: '#E0E0E0',
    detailsTitleColor: '#121212',
    detailsHeadingColor: '#616161',
    detailsColor: '#212121',
    scheduleHeadingColor: '#616161',
    scheduleColor: '#121212',
    scheduleBackgroundColor: '#F9F9F9',
  },
  chat: {
    recentHighlightOnColor: 'rgba(255,255,128,0.5)',
    recentHighlightOffColor: 'rgba(255,255,128,0)',
    borderColor: '#E0E0E0',
    textInputBordercolor: '#E0E0E0',
    inResponseCloseColor: '#999999',
    inReplyTo: {
      backgroundColor: '#FFDCB4',
      color: '#000000',
    },
    background: '#FFFFFF',
    actionButton: {
      activeColor: '#000000',
      color: '#053C78',
    },
  },
  context: {
    backgroundColor: 'rgba(240,240,240,0.9)',
    dividerColor: '#FFFFFF',
  },
  wallet: {
    balanceColor: '#1464B4',
    stakingBonus: '#1464B4',
    stakingBonusHighlight: '#15b143',
  },
}
const multiverse2022dark = multiverse2022light

export const allColors: Colors = {
  hadron: {
    light: hadronLight,
    dark: hadronDark,
  },
  multiverse2022: {
    light: multiverse2022light,
    dark: multiverse2022dark,
  },
}
