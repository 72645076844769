import * as React from 'react'
import { StyleSheet } from 'react-native'
import {
  TopicId,
  PostDoc,
  ReactionType,
  topicPostsCollectionPath,
} from '@mv/api/lib/src/schema/forums'
import {
  ReactionsPerPostDoc,
  reactionsPerPostCollectionPath,
} from '@mv/api/lib/src/schema/accounts/reactions'
import { planet } from '@mv/api/lib/src/types'
import { logger } from '../../logger'
import { Loading } from '../Loading'
import { View, Text } from '..'
import {
  db,
  collection,
  CollectionReference,
  onSnapshot,
  query,
  where,
} from '../../firebase/firestore'
import { PlanetForumPostForm } from './PlanetForumPostForm'
import { PlanetForumThreadedPosts } from './PlanetForumThreadedPosts'
import {
  ThreadedPost,
  ThreadedPosts,
  UserReactionLookup,
} from './planetForumTypes'
import { useSelector } from '../../state/store'

type Props = {
  planetId: planet.Id
  topicId: TopicId
}
export function PlanetForumTopic({
  planetId,
  topicId,
}: Props): JSX.Element | null {
  const userId = useSelector((state) => state.user.user?.fuid)
  const isMounted = React.useRef(true)
  const [loading, setLoading] = React.useState(true)
  const [posts, setPosts] = React.useState<ThreadedPosts>(new Map())
  const [userReactions, setUserReactions] = React.useState<
    UserReactionLookup | undefined
  >(undefined)
  const [firstLoadTime] = React.useState(new Date())

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    const topicPostsCollectionRef = collection(
      db,
      topicPostsCollectionPath(planetId, topicId)
    ) as CollectionReference<PostDoc>
    return onSnapshot(topicPostsCollectionRef, (snapshot) => {
      if (isMounted.current) {
        setLoading(false)
        const postMap: ThreadedPosts = new Map()
        snapshot.docs.forEach((doc) => {
          // TODO: Optimization - this may become slow when dealing with high
          // reply depth and many messages
          // * Cache the thread maps so the map doesn't have to be traversed
          //   for each deeply threaded reply (only if Map get proves slower
          //   than expected)
          // * Build map only once and use snapShot.docChanges to update
          // TODO: Pagination
          const data: Partial<ThreadedPost> = doc.data()
          if (data.visible) {
            data.thread = new Map()
            const docPath = doc.id.split('-')
            let threadMap = postMap
            for (let i = 0; i < docPath.length; i += 1) {
              if (i === docPath.length - 1) {
                // last segment
                threadMap.set(docPath[i], data as ThreadedPost)
              } else {
                const post = threadMap.get(docPath[i])
                if (post) {
                  threadMap = post.thread
                } else {
                  logger.error(
                    `Unable to construct thread map with ${doc.id}. Parent ${docPath[i]} is missing.`
                  )
                }
              }
            }
          }
        })
        setPosts(postMap)
      }
    })
  }, [planetId, topicId])

  React.useEffect(() => {
    if (userId) {
      const reactionsPerPostCollectionRef = collection(
        db,
        reactionsPerPostCollectionPath(userId)
      ) as CollectionReference<ReactionsPerPostDoc>
      const reactionsPerPostQuery = query(
        reactionsPerPostCollectionRef,
        where('postDocPath', '>=', topicPostsCollectionPath(planetId, topicId)),
        where(
          'postDocPath',
          '<=',
          `${topicPostsCollectionPath(planetId, topicId)}~`
        )
      )
      return onSnapshot(reactionsPerPostQuery, (snapshot) => {
        if (isMounted.current) {
          // TODO: Optimize so all user reactions are not rebuilt when one changes
          // * Use snapshot.docChanges to only update affected items
          // TODO: Pagination
          const userReactionsForThisTopic: UserReactionLookup = {}
          snapshot.docs.forEach((doc) => {
            const reactionsForPost = doc.data()
            const postId = getPostIdFromDocPath(reactionsForPost.postDocPath)
            const reactionArray = Object.keys(
              reactionsForPost.reactions
            ) as ReactionType[]
            userReactionsForThisTopic[postId] = reactionArray.reduce(
              (acc, r) => {
                acc[r] = true
                return acc
              },
              {} as Partial<Record<ReactionType, true>>
            )
          })
          setUserReactions(userReactionsForThisTopic)
        }
      })
    }
    return () => {}
  }, [userId, planetId, topicId])

  if (loading) {
    return <Loading />
  }

  if (posts.size === 0) {
    return (
      <View style={styles.container}>
        <View style={styles.body}>
          <Text>No posts.</Text>
        </View>
        <PlanetForumPostForm planetId={planetId} topicId={topicId} />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.body}>
        <PlanetForumPostForm planetId={planetId} topicId={topicId} />
        <PlanetForumThreadedPosts
          posts={posts}
          userReactions={userReactions}
          firstLoadTime={firstLoadTime}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    marginBottom: 8,
  },
  container: {
    maxWidth: 800,
  },
})

function getPostIdFromDocPath(docPath: string): string {
  return docPath.replace(/^.*\//, '')
}
