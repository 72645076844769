import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { Svg, G, Path } from 'react-native-svg'
import { useThemeColors } from '../../../constants/colors'

type Props = {
  color?: string
  width?: number
  height?: number
  style?: StyleProp<ViewStyle>
}
export function VLogo({
  color,
  width,
  height,
  style = undefined,
}: Props): JSX.Element {
  const colors = useThemeColors().logo.hadron
  const vColor = color || colors.base

  let calculatedWidth: number | undefined
  let calculatedHeight: number | undefined
  if (width) {
    calculatedWidth = width
    calculatedHeight = calculatedWidth * 0.683547375872181
  } else if (height) {
    calculatedHeight = height
    calculatedWidth = calculatedHeight * 1.46295638795195
  }
  return (
    <Svg
      viewBox="0 0 49.445 33.795"
      width={calculatedWidth}
      height={calculatedHeight}
      style={style}
    >
      <G id="v">
        <Path
          fill={vColor}
          // d="M209.664,33.795L187.198,0h7.249l17.665,27.219L229.586,0h7.057L214.32,33.795H209.664z"
          d="M22.466,33.795L0,0h7.249l17.665,27.219L42.388,0h7.057L27.122,33.795H22.466z"
        />
      </G>
    </Svg>
  )
}
VLogo.defaultProps = {
  color: undefined,
  width: undefined,
  height: undefined,
}

// M209.664,33.795L187.198,0h7.249l17.665,27.219L229.586,0h7.057L214.32,33.795H209.664z

// M22.466,33.795L0,0h7.249l17.665,27.219L42.388,0h7.057L27.122,33.795H22.466z
