/* eslint-disable react-native/no-unused-styles */
import React, { ReactNode } from 'react'
import {
  ScrollView,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
  TouchableOpacity,
} from 'react-native'
import { View, Text } from '../components'
import { useThemeColors } from '../constants/colors'
import { useDispatch } from '../state/store'
import { useMobileLayout } from '../hooks/useMobileLayout'
import { modalsActions } from '../state/modalsSlice'
import { CloseX } from '../components/svg/CloseX'
import { testIdToNativeId } from '../helpers/testId'
import { useTheme } from '../hooks/useTheme'
import { TextPreset } from '../components/Text'

type ModalProps = {
  children: React.ReactNode
  disableClose?: boolean
  testID?: string
}
export function Modal({
  children,
  testID,
  disableClose,
}: ModalProps): JSX.Element {
  const dispatch = useDispatch()
  const isMobileDevice = useMobileLayout()
  const colors = useThemeColors()
  const { theme } = useTheme()

  const styles = themedStyles[theme]
  const lightboxColorStyles = {
    backgroundColor: isMobileDevice
      ? colors.modal.background
      : colors.modal.lightboxBackground,
  }
  const modalColorStyles = {
    backgroundColor: colors.modal.background,
  }

  const hideModal = () => {
    dispatch(modalsActions.hideModal())
  }

  const containerStyles = isMobileDevice
    ? styles.containerMobile
    : styles.container
  const modalStyles = isMobileDevice
    ? [styles.containerModalMobile, modalColorStyles]
    : [styles.containerModal, modalColorStyles]

  return (
    <ScrollView
      style={[styles.scrollView, lightboxColorStyles]}
      contentContainerStyle={containerStyles}
    >
      <View nativeID={testIdToNativeId(testID, 'modal')} style={modalStyles}>
        {disableClose ? null : (
          <View
            nativeID={testIdToNativeId(testID, 'modal-close')}
            style={isMobileDevice ? styles.modalCloseMobile : styles.modalClose}
          >
            <TouchableOpacity
              onPress={() => {
                hideModal()
              }}
            >
              <CloseX color={colors.foreground} />
            </TouchableOpacity>
          </View>
        )}
        {children}
      </View>
    </ScrollView>
  )
}

type HeadingProps = {
  preset?: TextPreset
  containerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<TextStyle>
  children: React.ReactNode
  testID?: string
}
/**
 * Renders the Modal Heading
 */
export function Heading({
  containerStyle,
  style,
  children,
  testID,
  preset = 'displayLarge',
}: HeadingProps): JSX.Element {
  const { theme } = useTheme()

  const styles = themedStyles[theme]

  return (
    <View
      nativeID={testIdToNativeId(testID, 'modal-heading')}
      testID={testID}
      style={[styles.headingContainer, containerStyle]}
    >
      <Text
        preset={preset}
        weight={theme === 'hadron' ? 'bold' : undefined}
        style={[styles.headingText, style]}
      >
        {children}
      </Text>
    </View>
  )
}
Heading.defaultProps = {
  containerStyle: undefined,
  style: undefined,
}

type BodyProps = {
  messages: Array<ReactNode>
}
/**
 * Renders the Modal Body
 */
export function Body({ messages }: BodyProps): JSX.Element {
  const renderedText = messages.map((message, index: number) => {
    if (typeof message === 'string' || typeof message === 'number') {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Text key={index}>{message}</Text>
      )
    }
    return message
  })
  return <>{renderedText}</>
}
Heading.defaultProps = {
  containerStyle: undefined,
  style: undefined,
}

const themedStyles = {
  hadron: StyleSheet.create({
    container: {
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 80,
      paddingBottom: 80,
    },
    containerMobile: {
      alignItems: 'stretch',
      flexDirection: 'column',
      height: '100%',
    },
    containerModal: {
      paddingBottom: 80,
      paddingLeft: 100,
      paddingRight: 100,
      paddingTop: 80,
      position: 'relative',
      top: '5%',
      width: 640,
    },
    containerModalMobile: {
      minHeight: '100%',
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 60,
      position: 'relative',
      width: '100%',
    },
    headingContainer: {
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 40,
    },
    headingText: {
      fontSize: 36,
      textAlign: 'center',
    },
    modalClose: {
      position: 'absolute',
      right: 20,
      top: 20,
    },
    modalCloseMobile: {
      position: 'absolute',
      right: 20,
      top: 20,
    },
    scrollView: {
      // alignItems: 'center',
      // flexDirection: 'column',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  }),
  multiverse2022: StyleSheet.create({
    container: {
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 80,
      paddingBottom: 80,
    },
    containerMobile: {
      alignItems: 'stretch',
      flexDirection: 'column',
      height: '100%',
    },
    containerModal: {
      paddingBottom: 40,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 72,
      position: 'relative',
      top: '5%',
      width: 600,
    },
    containerModalMobile: {
      minHeight: '100%',
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 60,
      position: 'relative',
      width: '100%',
    },
    headingContainer: {
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 40,
    },
    headingText: {
      textAlign: 'center',
    },
    modalClose: {
      position: 'absolute',
      right: 40,
      top: 40,
    },
    modalCloseMobile: {
      position: 'absolute',
      right: 20,
      top: 20,
    },
    scrollView: {
      // alignItems: 'center',
      // flexDirection: 'column',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  }),
}
