import * as React from 'react'
import { useRoute } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import {
  MyWalletTabParamList,
  MyWalletTabRoute,
  DrawerNavigationRoute,
} from './types'
import { OverviewTab } from '../components/wallet/screens/walletTabs/OverviewTab'
import { HistoryTab } from '../components/wallet/screens/walletTabs/HistoryTab'
import { StakingTab } from '../components/wallet/screens/walletTabs/StakingTab'
import { Tabs } from '../components/wallet/Tabs'

const WalletTabs = createStackNavigator<MyWalletTabParamList>()

export function MyWalletTabNavigator(): JSX.Element {
  const route = useRoute<MyWalletTabRoute | DrawerNavigationRoute>()
  const backgroundStyle = {
    backgroundColor: 'transparent',
  }

  return (
    <WalletTabs.Navigator
      screenOptions={{
        headerShown: true,
        detachPreviousScreen: true,
        cardStyle: backgroundStyle,
        header: () => <Tabs />,
      }}
      mode="modal"
      detachInactiveScreens
    >
      <WalletTabs.Screen
        name="MyWalletOverview"
        component={OverviewTab}
        options={() => ({
          title: `My Multiverse Wallet`,
        })}
        initialParams={route.params}
      />
      <WalletTabs.Screen
        name="MyWalletHistory"
        component={HistoryTab}
        options={() => ({
          title: `My Multiverse Wallet History`,
        })}
        initialParams={route.params}
      />
      <WalletTabs.Screen
        name="MyWalletStaking"
        component={StakingTab}
        options={() => ({
          title: `My Multiverse Staking`,
        })}
        initialParams={route.params}
      />
    </WalletTabs.Navigator>
  )
}
