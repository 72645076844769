import React from 'react'
import { Svg, G, Path } from 'react-native-svg'
import { useThemeColors } from '../../../constants/colors'
import { useTheme } from '../../../hooks/useTheme'

type Props = {
  inverse?: boolean
  width?: number
  height?: number
}
export function MultiverseLogo({ inverse, width, height }: Props): JSX.Element {
  const { theme } = useTheme()
  const colors = useThemeColors().logo

  if (theme === 'hadron') {
    const baseColor = inverse ? colors.hadron.inverseBase : colors.hadron.base
    const aColor = inverse ? colors.hadron.inverseA : colors.hadron.a

    let calculatedWidth: number | undefined
    let calculatedHeight: number | undefined
    if (width) {
      calculatedWidth = width * 1.1267
      calculatedHeight = calculatedWidth * 0.1069
    } else if (height) {
      calculatedHeight = height
      calculatedWidth = calculatedHeight * 9.3567
    }
    return (
      <Svg
        viewBox="0 0 404.784 33.795"
        width={calculatedWidth}
        height={calculatedHeight}
      >
        <G id="multiverse">
          <Path
            fill={baseColor}
            d="M45.556,33.795V10.129L28.371,33.795h-5.232L5.905,10.177v23.618H0V0h5.953l20.066,27.603L45.988,0h5.472v33.795H45.556z"
          />
          <Path
            fill={baseColor}
            d="M68.161,33.795c-7.681,0-10.609-3.313-10.609-8.161V0h6.385v25.683c0,2.016,1.344,3.12,4.224,3.12h20.978
	c2.88,0,4.225-1.104,4.225-3.12V0h6.384v25.634c0,4.849-1.584,8.161-9.265,8.161H68.161z"
          />
          <Path
            fill={baseColor}
            d="M105.839,33.795V0h6.433v28.803h27.411v4.992H105.839z"
          />
          <Path
            fill={baseColor}
            d="M149.328,33.795V4.992h-17.713V0h41.332v4.992h-17.233v28.803H149.328z"
          />
          <Path fill={baseColor} d="M177.455,33.795V0h6.385v33.795H177.455z" />
          <Path
            fill={aColor}
            d="M209.664,33.795L187.198,0h7.249l17.665,27.219L229.586,0h7.057L214.32,33.795H209.664z"
          />
          <Path
            fill={baseColor}
            d="M239.759,33.795V0h34.755v4.992h-28.37v8.449h26.931v4.992h-26.931v10.369h28.37v4.992H239.759z"
          />
          <Path
            fill={baseColor}
            d="M312.049,33.795l-7.921-15.073h-17.81v15.073h-6.385V0h28.707c7.681,0,10.608,3.313,10.608,8.161v2.4
	c0,4.369-2.353,7.489-8.353,8.065l7.969,15.169H312.049z M312.864,8.113c0-2.016-1.344-3.121-4.224-3.121h-22.322v8.737h22.322
	c2.88,0,4.224-1.104,4.224-3.12V8.113z"
          />
          <Path
            fill={baseColor}
            d="M324.814,33.795v-4.992h29.378c2.881,0,4.225-1.104,4.225-3.12V21.41c0-2.017-1.344-3.12-4.225-3.12h-19.874
	c-7.68,0-10.608-3.313-10.608-8.161V8.161c0-4.848,2.929-8.161,10.608-8.161h28.611v4.992h-28.611c-2.88,0-4.224,1.104-4.224,3.121
	v2.064c0,2.017,1.344,3.12,4.224,3.12h19.874c7.681,0,10.609,3.313,10.609,8.161v4.176c0,4.849-2.929,8.161-10.609,8.161H324.814z"
          />
          <Path
            fill={baseColor}
            d="M370.029,33.795V0h34.755v4.992h-28.37v8.449h26.931v4.992h-26.931v10.369h28.37v4.992H370.029z"
          />
        </G>
      </Svg>
    )
  }

  const baseColor = colors.multiverse.base

  let calculatedWidth: number | undefined
  let calculatedHeight: number | undefined
  if (width) {
    calculatedWidth = width
    calculatedHeight = calculatedWidth * (190.0781 / 200)
  } else if (height) {
    calculatedHeight = height
    calculatedWidth = calculatedHeight * (200 / 190.0781)
  }
  return (
    <Svg
      viewBox="0 0 200 190.0781"
      width={calculatedWidth}
      height={calculatedHeight}
    >
      <G id="multiverse">
        <Path
          fill={baseColor}
          d="M83.0337,0V49.0978H0V69.0267C25.254,78.95,55.7052,91.631,66.671,95.4744v.1173C58.1221,98.0509,26.8406,110.6469,0,121.3261V141.074H83.0337v49.0041H94.4356V0Zm0,128.48H50.1775c-14.0538,0-31.28.5005-40.82,1.2139v-.1173c13.7339-4.5248,49.5828-18.6329,73.6758-27.2356Zm0-38.1261c-29.0227-11.36-60.5488-24.201-73.74-28.3v-.1173c8.7408.4685,23.6886.5216,38.9031.5216H83.0337Z"
        />
        <Path
          fill={baseColor}
          d="M116.9484,190.0781l.0179-87.4357L200,138.9374V124.2568l-44.26-18.5793c-15.6071-6.09-23.1882-9.231-27.4042-10.5191v-.2343c4.8121-1.5332,12.4458-4.2055,28.5013-10.8279L200,66.23V51.1134L116.9663,86.8226,116.9484,0H105.5466V190.0781Z"
        />
      </G>
    </Svg>
  )
}
MultiverseLogo.defaultProps = {
  inverse: false,
  width: undefined,
  height: undefined,
}
