import * as React from 'react'
import { StyleSheet } from 'react-native'
import { View, Text } from '.'
import { Button } from './Button'
import { useDispatch } from '../state/store'
import { contextMenuActions } from '../state/contextMenuSlice'

type Props = {
  label: string
  onPress: () => Promise<void>
}
export function DisplayAddressDropDownMenuButton({
  label,
  onPress,
}: Props): JSX.Element {
  const dispatch = useDispatch()

  return (
    <Button
      onPress={async () => {
        dispatch(contextMenuActions.hideContextMenu())
        await onPress()
      }}
    >
      <View style={styles.menuItem}>
        <Text>{label}</Text>
      </View>
    </Button>
  )
}

const styles = StyleSheet.create({
  menuItem: {
    borderTopColor: '#FFFFFF',
    borderTopWidth: 1,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
  },
})
