import * as React from 'react'
import {
  TouchableOpacity,
  StyleSheet,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native'
import { View } from '../View'
import { Text, TextPreset } from '../Text'
import { useThemeColors } from '../../constants/colors'
import { testIdToNativeId } from '../../helpers/testId'

export { TextInput as TextInputRefType } from 'react-native'

type Props = {
  defaultValue?: boolean
  label?: string
  labelStyle?: StyleProp<TextStyle>
  labelPreset?: TextPreset
  containerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<TextStyle>
  onChangeValue: (newValue: boolean) => void
  disabled?: boolean
  testID?: string
}
export function CheckBox(props: Props): JSX.Element {
  const {
    defaultValue,
    label,
    labelStyle,
    labelPreset,
    containerStyle,
    style,
    onChangeValue,
    disabled,
    testID,
  } = props

  const [checkBoxValue, setCheckBoxValue] = React.useState(defaultValue)

  React.useEffect(() => {
    setCheckBoxValue(defaultValue)
  }, [defaultValue])

  const colors = useThemeColors()
  const boxColorStyle = {
    color: checkBoxValue ? colors.checkBox.on : colors.checkBox.off,
    backgroundColor: colors.checkBox.backgroundColor,
  }

  const renderedCheckBox = (
    <>
      <Text style={[styles.box, boxColorStyle, style]}>
        {checkBoxValue ? '☒' : '☐'}
      </Text>
      {!!label && (
        <Text
          preset={labelPreset}
          style={[
            styles.label,
            labelPreset ? undefined : styles.labelFontSize,
            labelStyle,
          ]}
        >
          {label}
        </Text>
      )}
    </>
  )

  const renderedBody = (
    <View
      nativeID={testIdToNativeId(testID, 'form-checkbox')}
      testID={testID}
      style={[styles.container, containerStyle]}
    >
      {renderedCheckBox}
    </View>
  )
  if (disabled) {
    return renderedBody
  }
  return (
    <TouchableOpacity
      onPress={() => {
        const newValue = !checkBoxValue
        setCheckBoxValue(newValue)
        onChangeValue(newValue)
      }}
      activeOpacity={0.7}
    >
      {renderedBody}
    </TouchableOpacity>
  )
}
CheckBox.defaultProps = {
  defaultValue: false,
  label: undefined,
  labelStyle: undefined,
  containerStyle: undefined,
  style: undefined,
  disabled: false,
}

const styles = StyleSheet.create({
  box: {
    fontSize: 20,
    marginLeft: 20,
    paddingBottom: 1,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  label: {
    marginLeft: 20,
  },
  labelFontSize: {
    fontSize: 14,
  },
})
