import { TextStyle, StyleSheet } from 'react-native'

type FontStyle = {
  fontFamily?: TextStyle['fontFamily']
  fontWeight?: TextStyle['fontWeight']
}
export type FontSet = {
  thin: FontStyle
  extraLight: FontStyle
  light: FontStyle
  normal: FontStyle
  medium: FontStyle
  semiBold: FontStyle
  bold: FontStyle
}
export type Fonts = {
  default: FontSet
  ProximaNova: FontSet
  Inter: FontSet
  PlayfairDisplay: FontSet
  FraktionMono: FontSet
}

type FontNameSet = {
  thin: string
  extraLight: string
  light: string
  normal: string
  medium: string
  semiBold: string
  bold: string
}
type FontNames = {
  default: FontNameSet
  ProximaNova: FontNameSet
  Inter: FontNameSet
  PlayfairDisplay: FontNameSet
  FraktionMono: FontNameSet
}
const PromixaNovaFontNames = {
  thin: 'ProximaNova',
  extraLight: 'ProximaNova',
  light: 'ProximaNova',
  normal: 'ProximaNova',
  medium: 'ProximaNova',
  semiBold: 'ProximaNovaSemiBold',
  bold: 'ProximaNovaBold',
}
const InterFontNames = {
  thin: 'InterThin',
  extraLight: 'InterExtraLight',
  light: 'InterLight',
  normal: 'InterNormal',
  medium: 'InterMedium',
  semiBold: 'InterSemiBold',
  bold: 'InterBold',
}
const PlayfairDisplayFontNames = {
  thin: 'PlayfairDisplayNormal',
  extraLight: 'PlayfairDisplayNormal',
  light: 'PlayfairDisplayNormal',
  normal: 'PlayfairDisplayNormal',
  medium: 'PlayfairDisplayMedium',
  semiBold: 'PlayfairDisplaySemiBold',
  bold: 'PlayfairDisplayBold',
}
const FraktionMonoFontNames = {
  thin: 'FraktionMonoLight',
  extraLight: 'FraktionMonoLight',
  light: 'FraktionMonoLight',
  normal: 'FraktionMonoNormal',
  medium: 'FraktionMonoMedium',
  semiBold: 'FraktionMonoMedium',
  bold: 'FraktionMonoBold',
}
export const fontNames: FontNames = {
  default: InterFontNames,
  ProximaNova: PromixaNovaFontNames,
  Inter: InterFontNames,
  PlayfairDisplay: PlayfairDisplayFontNames,
  FraktionMono: FraktionMonoFontNames,
}

const ProximaNova: FontSet = StyleSheet.create({
  bold: {
    fontFamily: fontNames.ProximaNova.bold,
    fontWeight: '400',
  },
  extraLight: {
    fontFamily: fontNames.ProximaNova.extraLight,
    fontWeight: '400',
  },
  light: {
    fontFamily: fontNames.ProximaNova.light,
    fontWeight: '400',
  },
  medium: {
    fontFamily: fontNames.ProximaNova.normal,
    fontWeight: '400',
  },
  normal: {
    fontFamily: fontNames.ProximaNova.normal,
    fontWeight: '400',
  },
  semiBold: {
    fontFamily: fontNames.ProximaNova.semiBold,
    fontWeight: '400',
  },
  thin: {
    fontFamily: fontNames.ProximaNova.thin,
    fontWeight: '400',
  },
})
const Inter: FontSet = StyleSheet.create({
  bold: {
    fontFamily: fontNames.Inter.bold,
    fontWeight: '400',
  },
  extraLight: {
    fontFamily: fontNames.Inter.extraLight,
    fontWeight: '400',
  },
  light: {
    fontFamily: fontNames.Inter.light,
    fontWeight: '400',
  },
  medium: {
    fontFamily: fontNames.Inter.medium,
    fontWeight: '400',
  },
  normal: {
    fontFamily: fontNames.Inter.normal,
    fontWeight: '400',
  },
  semiBold: {
    fontFamily: fontNames.Inter.semiBold,
    fontWeight: '400',
  },
  thin: {
    fontFamily: fontNames.Inter.thin,
    fontWeight: '400',
  },
})
const PlayfairDisplay: FontSet = StyleSheet.create({
  bold: {
    fontFamily: fontNames.PlayfairDisplay.bold,
    fontWeight: '400',
  },
  extraLight: {
    fontFamily: fontNames.PlayfairDisplay.extraLight,
    fontWeight: '400',
  },
  light: {
    fontFamily: fontNames.PlayfairDisplay.light,
    fontWeight: '400',
  },
  medium: {
    fontFamily: fontNames.PlayfairDisplay.medium,
    fontWeight: '400',
  },
  normal: {
    fontFamily: fontNames.PlayfairDisplay.normal,
    fontWeight: '400',
  },
  semiBold: {
    fontFamily: fontNames.PlayfairDisplay.semiBold,
    fontWeight: '400',
  },
  thin: {
    fontFamily: fontNames.PlayfairDisplay.thin,
    fontWeight: '400',
  },
})
const FraktionMono: FontSet = StyleSheet.create({
  bold: {
    fontFamily: fontNames.FraktionMono.bold,
    fontWeight: '400',
  },
  extraLight: {
    fontFamily: fontNames.FraktionMono.extraLight,
    fontWeight: '400',
  },
  light: {
    fontFamily: fontNames.FraktionMono.light,
    fontWeight: '400',
  },
  medium: {
    fontFamily: fontNames.FraktionMono.medium,
    fontWeight: '400',
  },
  normal: {
    fontFamily: fontNames.FraktionMono.normal,
    fontWeight: '400',
  },
  semiBold: {
    fontFamily: fontNames.FraktionMono.semiBold,
    fontWeight: '400',
  },
  thin: {
    fontFamily: fontNames.FraktionMono.thin,
    fontWeight: '400',
  },
})
export const fonts: Fonts = {
  default: FraktionMono,
  ProximaNova,
  Inter,
  PlayfairDisplay,
  FraktionMono,
}
