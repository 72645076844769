/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  initialState,
  MetaMask,
  MetaMaskIntegrationStage,
} from './metaMaskTypes'

export const metaMaskSlice = createSlice({
  name: 'metaMask',
  initialState: initialState(),
  reducers: {
    setMetaMask(state, action: PayloadAction<MetaMask | undefined>) {
      state.metaMask = action.payload
    },
    setIntegrationStage(
      state,
      action: PayloadAction<MetaMaskIntegrationStage | undefined>
    ) {
      state.metaMaskIntegrationStage = action.payload
    },
    setConnectedAccounts(state, action: PayloadAction<string[]>) {
      state.connectedAccounts = action.payload
    },
    setErc20Balance(state, action: PayloadAction<string>) {
      state.erc20Balance = action.payload
    },
    resetAll() {
      return initialState()
    },
  },
})

export const metaMaskActions = metaMaskSlice.actions
