import Constants from 'expo-constants'

export type BuildData = {
  version: string
  commit: string
  time: string
}

export const Build = {
  currentVersion(): string | undefined {
    return Constants.manifest.version
  },

  async latest(): Promise<BuildData> {
    // Circumvent caching by adding the current time to the request.
    const res = await fetch(`/latest_build.json?at=${new Date().getTime()}`)
    return (await res.json()) as BuildData
  },
}
