import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { GLView, ExpoWebGLRenderingContext } from 'expo-gl'
import * as THREE from 'three'
import { View } from '..'

type Size = {
  width: number
  height: number
}
type Props = {
  size?: Size
  showWireframe?: boolean
  textureMapUrl?: string
  useTextureMapAsBumpMap?: boolean
  color?: string
  style?: StyleProp<ViewStyle>
}
export function RenderPlanet(props: Props): JSX.Element {
  const {
    size,
    style,
    textureMapUrl,
    useTextureMapAsBumpMap,
    color,
    showWireframe,
  } = props

  const [renderedScene, setRenderedScene] = React.useState<THREE.Scene | null>(
    null
  )
  const [renderedSphere, setRenderedSphere] = React.useState<THREE.Mesh | null>(
    null
  )
  const [renderedWireSphere, setRenderedWireSphere] =
    React.useState<THREE.LineSegments | null>(null)

  React.useEffect(() => {
    if (renderedSphere && textureMapUrl) {
      const textureMap = textureMapUrl
        ? new THREE.TextureLoader().load(textureMapUrl)
        : null
      const material = new THREE.MeshPhongMaterial({
        map: textureMap,
        bumpMap: useTextureMapAsBumpMap ? textureMap : null,
        bumpScale: 0.05,
      })
      renderedSphere.material = material
    }
  }, [
    renderedSphere,
    renderedWireSphere,
    textureMapUrl,
    useTextureMapAsBumpMap,
  ])

  React.useEffect(() => {
    if (renderedSphere && !textureMapUrl) {
      const material = new THREE.MeshPhongMaterial({
        color: new THREE.Color(color),
        flatShading: true,
      })
      renderedSphere.material = material
    }
    if (renderedWireSphere) {
      const wireframeMaterial = new THREE.LineBasicMaterial({
        color,
        opacity: 0.5,
        transparent: true,
      })
      renderedWireSphere.material = wireframeMaterial
    }
  }, [renderedSphere, renderedWireSphere, color, textureMapUrl])

  React.useEffect(() => {
    if (renderedWireSphere && renderedScene) {
      renderedScene.remove(renderedWireSphere)
      if (showWireframe) {
        renderedScene.add(renderedWireSphere)
      }
    }
  }, [renderedWireSphere, renderedScene, showWireframe])

  function onContextCreate(gl: ExpoWebGLRenderingContext) {
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      75,
      gl.drawingBufferWidth / gl.drawingBufferHeight,
      0.1,
      1000
    )
    const renderer = new THREE.WebGLRenderer({ context: gl })
    renderer.setSize(gl.drawingBufferWidth, gl.drawingBufferHeight)

    const keyLight = new THREE.DirectionalLight(0xffffff, 1)
    keyLight.position.set(-3, 3, 4).normalize()
    const edgeLight = new THREE.DirectionalLight(0xffffff, 1)
    edgeLight.position.set(-2, 2, -1).normalize()
    const backLight = new THREE.DirectionalLight(0xffffff, 0.8)
    backLight.position.set(2, -2, -1).normalize()
    scene.add(keyLight)
    scene.add(edgeLight)
    scene.add(backLight)
    // scene.add(new THREE.AmbientLight(0xffffff, 0.1))

    const segments = 32
    const geometry = new THREE.SphereBufferGeometry(1, segments, segments / 2)
    const textureMap = props.textureMapUrl
      ? new THREE.TextureLoader().load(props.textureMapUrl)
      : null
    const material = new THREE.MeshPhongMaterial({
      map: textureMap,
      bumpMap: props.useTextureMapAsBumpMap ? textureMap : null,
      bumpScale: 0.05,
      color: new THREE.Color(props.textureMapUrl ? undefined : props.color),
      flatShading: !props.textureMapUrl,
    })
    if (!props.textureMapUrl && props.color)
      material.color = new THREE.Color(props.color)

    const sphere = new THREE.Mesh(geometry, material)
    setRenderedSphere(sphere)
    scene.add(sphere)

    // wireframe
    const wireframeSphere = new THREE.WireframeGeometry(
      new THREE.SphereBufferGeometry(1.01, segments, segments / 2)
    )
    const wireframeMaterial = new THREE.LineBasicMaterial({
      color: props.color,
      opacity: 0.5,
      transparent: true,
    })
    const wireSphere = new THREE.LineSegments(
      wireframeSphere,
      wireframeMaterial
    )
    setRenderedWireSphere(wireSphere)
    if (showWireframe) {
      scene.add(wireSphere)
    }

    setRenderedScene(scene)

    camera.position.z = 2.5
    const render = () => {
      requestAnimationFrame(render)
      if (camera.position.z > 2.0) camera.position.z -= 0.001
      if (sphere.rotation.x < 0.5) sphere.rotation.x += 0.001
      sphere.rotation.y += 0.003
      if (wireSphere) {
        if (wireSphere.rotation.x < 0.5) wireSphere.rotation.x += 0.001
        wireSphere.rotation.y += 0.003
      }
      renderer.render(scene, camera)

      gl.flush()
      gl.endFrameEXP()
    }
    render()

    // gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight)
    // gl.clearColor(0, 1, 1, 1)

    // // Create vertex shader (shape & position)
    // const vert = gl.createShader(gl.VERTEX_SHADER)
    // gl.shaderSource(
    //   vert,
    //   `
    //   void main(void) {
    //     gl_Position = vec4(0.0, 0.0, 0.0, 1.0);
    //     gl_PointSize = 150.0;
    //   }
    // `
    // )
    // gl.compileShader(vert)

    // // Create fragment shader (color)
    // const frag = gl.createShader(gl.FRAGMENT_SHADER)
    // gl.shaderSource(
    //   frag,
    //   `
    //   void main(void) {
    //     gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
    //   }
    // `
    // )
    // gl.compileShader(frag)

    // // Link together into a program
    // const program = gl.createProgram()
    // gl.attachShader(program, vert)
    // gl.attachShader(program, frag)
    // gl.linkProgram(program)
    // gl.useProgram(program)

    // gl.clear(gl.COLOR_BUFFER_BIT)
    // gl.drawArrays(gl.POINTS, 0, 1)

    // gl.flush()
    // gl.endFrameEXP()
  }

  return (
    <View style={[styles.container, style]}>
      <GLView
        style={[size]}
        onContextCreate={(gl) => {
          onContextCreate(gl)
        }}
      />
    </View>
  )
}
RenderPlanet.defaultProps = {
  size: {
    width: 300,
    height: 300,
  },
  showWireframe: false,
  textureMapUrl: '',
  useTextureMapAsBumpMap: true,
  color: '#DC0000',
  style: undefined,
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

export function tempPlanetColorFromHexString(hexString: string): string {
  const hue = hexToNumber(hexString.slice(2, 4), 360)
  const saturation = hexToNumber(hexString.slice(4, 6), 50, 50)
  return `hsl(${hue}, ${saturation}%, 50%)`
}

function hexToNumber(hexString: string, range: number, bias = 0): number {
  return Math.floor(
    (parseInt(hexString, 16) * range) / 16 ** hexString.length + bias
  )
}
