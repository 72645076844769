/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StyleSheet, Image } from 'react-native'
import crypto from 'crypto'
import { Planet } from '@mv/api/lib/src/schema/planets'
import { convertEthereumAddressToDisplayAddress } from '@mv/api/lib/src/schema/accounts'
import { PlanetsNavigationProps } from '../../navigation/types'
import { View, Text } from '..'
import { Button } from '../Button'
import { RenderPlanet, tempPlanetColorFromHexString } from './RenderPlanet'
import { useThemeColors } from '../../constants/colors'
import { PlanetBackgroundGradient } from '../svg/PlanetBackgroundGradient'
import { shortAddress } from '../../helpers'
import { useTheme } from '../../hooks/useTheme'
import { SubmitButton } from '../form'

export const THEMED_DIMENSIONS = {
  hadron: {
    PLANET_CARD_LEFT_RIGHT_MARGIN: 10,
    PLANET_CARD_RADIUS: 24,
    PLANET_CARD_WIDTH: 350,
    PLANET_CARD_BORDER_WIDTH: 1,
    PLANET_CARD_HERO_WIDTH: 350 - 2 * 1, // PLANET_CARD_WIDTH - 2 * PLANET_CARD_BORDER_WIDTH
    PLANET_CARD_HERO_HEIGHT: 350 - 2 * 1, // PLANET_CARD_HERO_WIDTH
  },
  multiverse2022: {
    PLANET_CARD_LEFT_RIGHT_MARGIN: 12,
    PLANET_CARD_RADIUS: 16,
    PLANET_CARD_WIDTH: 320,
    PLANET_CARD_BORDER_WIDTH: 0,
    PLANET_CARD_HERO_WIDTH: 320 - 2 * 0, // PLANET_CARD_WIDTH - 2 * PLANET_CARD_BORDER_WIDTH
    PLANET_CARD_HERO_HEIGHT: 320, // PLANET_CARD_HERO_WIDTH
  },
}
// export const PLANET_CARD_LEFT_RIGHT_MARGIN = 10
// const PLANET_CARD_RADIUS = 24
// export const PLANET_CARD_WIDTH = 350
// const PLANET_CARD_BORDER_WIDTH = 1
// const PLANET_CARD_HERO_WIDTH = PLANET_CARD_WIDTH - 2 * PLANET_CARD_BORDER_WIDTH
// const PLANET_CARD_HERO_HEIGHT = PLANET_CARD_HERO_WIDTH

export function PlanetSummaryCard({ planet }: { planet: Planet }): JSX.Element {
  const navigation = useNavigation<PlanetsNavigationProps>()
  const colors = useThemeColors()
  const cardColors = colors.planetCard
  const { theme } = useTheme()
  const styles = themedStyles[theme]

  const planetColor =
    planet._.details.renderOptions?.color ||
    tempPlanetColorFromHexString(planet.id)
  const planetNameColor = {
    color: planetColor,
  }
  const planetButtonColor = {
    backgroundColor: planetColor,
  }
  const planetButtonLabelColor = {
    color: colors.background,
  }

  const cardImageUri = planet._.details.images?.card?.uri

  // TODO - Revert to using actual founderAddress after planet founders are reassigned
  // const { founderAddress } = planet
  const founderAddress = `0x${crypto
    .createHash('sha1')
    .update(planet.id)
    .digest('hex')}`

  if (theme === 'hadron') {
    return (
      <View key={planet.id} style={[styles.container, cardColors.container]}>
        <View style={[styles.planetHero]}>
          <PlanetBackgroundGradient style={[styles.planetHeroBackground]} />
          <RenderPlanet
            color={planetColor}
            size={{
              width: THEMED_DIMENSIONS.hadron.PLANET_CARD_HERO_WIDTH,
              height: THEMED_DIMENSIONS.hadron.PLANET_CARD_HERO_HEIGHT,
            }}
            textureMapUrl={planet._.details.renderOptions?.textureMapUrl}
            showWireframe={planet._.details.renderOptions?.showWireframe}
            useTextureMapAsBumpMap={
              planet._.details.renderOptions?.useTextureMapAsBumpMap
            }
          />
        </View>
        <View style={styles.cardBody}>
          <View>
            <View style={styles.planetNameView}>
              <Text weight="bold" style={[styles.planetName, planetNameColor]}>
                {planet.name}
              </Text>
              <Text style={styles.planetAddress}>
                {convertEthereumAddressToDisplayAddress(planet.id)}
              </Text>
            </View>
            {!!planet._.details.brief && (
              <Text
                weight="semiBold"
                style={[styles.planetBrief, cardColors.brief]}
              >
                {planet._.details.brief}
              </Text>
            )}
            <Text
              weight="semiBold"
              style={[styles.planetFounder, cardColors.brief]}
            >
              Founder:{' '}
              {planet._.details.displayOverrides?.founderName ||
                shortAddress(founderAddress)}
            </Text>
          </View>
          <View>
            <View style={[styles.divider, cardColors.divider]} />
            <View style={styles.stats}>
              <Text
                weight="bold"
                style={[styles.statsHeading, planetNameColor]}
              >
                {planet.name} Stats
              </Text>
              <View style={styles.statsRow}>
                <Text style={[styles.statsLabel, cardColors.stats.label]}>
                  Pledgers
                </Text>
                <Text
                  weight="bold"
                  style={[styles.statsValue, cardColors.stats.value]}
                >
                  {planet.numPledgers}
                </Text>
              </View>
              <View style={styles.statsRow}>
                <Text style={[styles.statsLabel, cardColors.stats.label]}>
                  Total Amount Pledged
                </Text>
                <Text
                  weight="bold"
                  style={[styles.statsValue, cardColors.stats.value]}
                >
                  {Math.round(planet.approximateTotalPledgeAmount * 100) / 100}{' '}
                  AI
                </Text>
              </View>
              <View style={styles.statsRow}>
                <Text style={[styles.statsLabel, cardColors.stats.label]}>
                  Planetary Stage
                </Text>
                <Text
                  weight="bold"
                  style={[styles.statsValue, cardColors.stats.value]}
                >
                  {planet._.details.displayOverrides?.stage || planet.stage}
                </Text>
              </View>
              <View style={styles.statsRow}>
                <Text style={[styles.statsLabel, cardColors.stats.label]}>
                  Governance
                </Text>
                <Text
                  weight="bold"
                  style={[styles.statsValue, cardColors.stats.value]}
                >
                  {planet._.details.displayOverrides?.governance || 'TBD'}
                </Text>
              </View>
            </View>
            <Button
              style={[styles.button, planetButtonColor]}
              labelStyle={[styles.buttonLabel, planetButtonLabelColor]}
              label={`Show Details for ${planet.symbol}`}
              onPress={async () => {
                navigation.navigate('PlanetsShow', {
                  planetLocator: planet.symbol,
                })
              }}
            />
          </View>
        </View>
      </View>
    )
  }

  return (
    <View key={planet.id} style={[styles.container, cardColors.container]}>
      {cardImageUri && (
        <Image source={{ uri: cardImageUri }} style={styles.cardImage} />
      )}
      <View style={styles.cardBody}>
        <View>
          <View style={styles.planetNameView}>
            <Text preset="headerSmall" style={[styles.planetName]}>
              {planet.name}
            </Text>
          </View>
          {!!planet._.details.brief && (
            <Text
              weight="semiBold"
              style={[styles.planetBrief, cardColors.brief]}
            >
              {planet._.details.brief}
            </Text>
          )}
          <Text
            weight="semiBold"
            style={[styles.planetFounder, cardColors.brief]}
          >
            Founder:{' '}
            {planet._.details.displayOverrides?.founderName ||
              shortAddress(founderAddress)}
          </Text>
        </View>
        <View>
          <SubmitButton
            style={[styles.button]}
            labelStyle={[styles.buttonLabel, planetButtonLabelColor]}
            label="Learn more"
            onPress={async () => {
              navigation.navigate('PlanetsShow', {
                planetLocator: planet.symbol,
              })
              return false
            }}
          />
        </View>
      </View>
    </View>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    button: {
      borderRadius: 50,
      flex: 1,
    },
    buttonLabel: {
      paddingBottom: 16,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 16,
      textAlign: 'center',
    },
    cardBody: {
      flex: 1,
      justifyContent: 'space-between',
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
    },
    cardImage: {},
    container: {
      borderTopLeftRadius: THEMED_DIMENSIONS.hadron.PLANET_CARD_RADIUS,
      borderTopRightRadius: THEMED_DIMENSIONS.hadron.PLANET_CARD_RADIUS,
      // borderRadius: PLANET_CARD_RADIUS,
      borderWidth: THEMED_DIMENSIONS.hadron.PLANET_CARD_BORDER_WIDTH,
      flexDirection: 'column',
      marginBottom: 8,
      marginLeft: THEMED_DIMENSIONS.hadron.PLANET_CARD_LEFT_RIGHT_MARGIN,
      marginRight: THEMED_DIMENSIONS.hadron.PLANET_CARD_LEFT_RIGHT_MARGIN,
      width: THEMED_DIMENSIONS.hadron.PLANET_CARD_WIDTH,
    },
    divider: {
      height: 1,
      marginBottom: 20,
      marginTop: 10,
    },
    planetAddress: {
      fontSize: 11,
      opacity: 0.7,
    },
    planetBrief: {
      fontSize: 16,
      lineHeight: 16 * 1.75,
      paddingBottom: 20,
    },
    planetFounder: {
      fontSize: 16,
      lineHeight: 16 * 1.75,
      paddingBottom: 20,
    },
    planetHero: {
      borderTopLeftRadius: THEMED_DIMENSIONS.hadron.PLANET_CARD_RADIUS,
      borderTopRightRadius: THEMED_DIMENSIONS.hadron.PLANET_CARD_RADIUS,
      // borderRadius: PLANET_CARD_RADIUS,
      height: THEMED_DIMENSIONS.hadron.PLANET_CARD_HERO_HEIGHT,
      width: THEMED_DIMENSIONS.hadron.PLANET_CARD_HERO_WIDTH,
    },
    planetHeroBackground: {
      borderTopLeftRadius: THEMED_DIMENSIONS.hadron.PLANET_CARD_RADIUS,
      borderTopRightRadius: THEMED_DIMENSIONS.hadron.PLANET_CARD_RADIUS,
      // borderRadius: PLANET_CARD_RADIUS,
      bottom: 0,
      height: THEMED_DIMENSIONS.hadron.PLANET_CARD_HERO_HEIGHT,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: THEMED_DIMENSIONS.hadron.PLANET_CARD_HERO_WIDTH,
    },
    planetName: {
      fontSize: 32,
    },
    planetNameView: {
      marginBottom: 14,
    },
    stats: {},
    statsHeading: {
      fontSize: 16,
      marginBottom: 20,
    },
    statsLabel: {
      fontSize: 13,
      marginBottom: 40,
    },
    statsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    statsValue: {
      fontSize: 13,
      textAlign: 'right',
    },
  }),
  multiverse2022: StyleSheet.create({
    button: {},
    buttonLabel: {},
    cardBody: {
      flex: 1,
      justifyContent: 'space-between',
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
    },
    cardImage: {
      borderTopLeftRadius: THEMED_DIMENSIONS.multiverse2022.PLANET_CARD_RADIUS,
      borderTopRightRadius: THEMED_DIMENSIONS.multiverse2022.PLANET_CARD_RADIUS,
      height: 320,
      width: 320,
    },
    container: {
      borderRadius: THEMED_DIMENSIONS.multiverse2022.PLANET_CARD_RADIUS,
      borderWidth: 0,
      flexDirection: 'column',
      marginBottom: 24,
      marginLeft:
        THEMED_DIMENSIONS.multiverse2022.PLANET_CARD_LEFT_RIGHT_MARGIN,
      marginRight:
        THEMED_DIMENSIONS.multiverse2022.PLANET_CARD_LEFT_RIGHT_MARGIN,
      shadowColor: 'rgba(0,0,0,0.1)',
      shadowOffset: {
        width: 2,
        height: 2,
      },
      shadowRadius: 10,
      width: THEMED_DIMENSIONS.multiverse2022.PLANET_CARD_WIDTH,
    },
    divider: {
      height: 1,
      marginBottom: 20,
      marginTop: 10,
    },
    planetAddress: {},
    planetBrief: {
      paddingBottom: 24,
    },
    planetFounder: {
      paddingBottom: 24,
    },
    planetHero: {},
    planetHeroBackground: {},
    planetName: {},
    planetNameView: {
      marginBottom: 8,
    },
    stats: {},
    statsHeading: {},
    statsLabel: {},
    statsRow: {},
    statsValue: {},
  }),
}
