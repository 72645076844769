import * as React from 'react'
import { StyleSheet } from 'react-native'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated'
import { usePrevious } from '../../hooks/usePrevious'

const DEFAULT_HEIGHT_TRANSITION_TIME = 250
const DEFAULT_OPACITY_TRANSITION_TIME = 250

type Props = {
  children: React.ReactNode
  heightTransitionTime?: number
  opacityTransitionTime?: number
  animate?: boolean
}
export function AnimateDownFadeIn({
  children,
  heightTransitionTime = DEFAULT_HEIGHT_TRANSITION_TIME,
  opacityTransitionTime = DEFAULT_OPACITY_TRANSITION_TIME,
  animate = true,
}: Props): JSX.Element | null {
  if (!animate) {
    return <>{children}</>
  }
  return (
    <InternalAnimateDownFadeIn
      animate={animate}
      heightTransitionTime={heightTransitionTime}
      opacityTransitionTime={opacityTransitionTime}
    >
      {children}
    </InternalAnimateDownFadeIn>
  )
}

function InternalAnimateDownFadeIn({
  children,
  heightTransitionTime,
  opacityTransitionTime,
}: Required<Props>): JSX.Element | null {
  const [showHeight, setShowHeight] = React.useState<number | null>(null)
  const [isHidden, setIsHidden] = React.useState(true)
  const [show, setShow] = React.useState<boolean | null>(null)
  const [heightIsAnimated, setHeightIsAnimated] = React.useState<boolean>(true)
  const timeoutRef = React.useRef<NodeJS.Timeout[]>([])
  const [childrenToRender, setChildrenToRender] = React.useState(children)
  const prevShow = usePrevious(show)
  const height = useSharedValue(0)
  const opacity = useSharedValue(0)
  const animationHeightStyle = useAnimatedStyle(
    () => ({
      height: heightIsAnimated
        ? withTiming(height.value, {
            duration: heightTransitionTime,
            easing: Easing.inOut(Easing.quad),
          })
        : undefined,
    }),
    [heightIsAnimated]
  )
  const animationOpacityStyle = useAnimatedStyle(() => ({
    opacity: withTiming(opacity.value, {
      duration: opacityTransitionTime,
      easing: Easing.inOut(Easing.quad),
    }),
  }))

  React.useEffect(
    () => () => {
      timeoutRef.current.forEach((timeout) => {
        clearTimeout(timeout)
      })
    },
    []
  )

  React.useEffect(() => {
    if (children !== null) {
      setChildrenToRender(children)
    }
  }, [children])

  React.useEffect(() => {
    if (isHidden && children === null) {
      setChildrenToRender(children)
    }
  }, [children, isHidden])

  React.useEffect(() => {
    if (showHeight !== null) {
      if (showHeight > 0 && children) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [showHeight, children])

  React.useEffect(() => {
    if (prevShow !== show && showHeight !== null) {
      setHeightIsAnimated(true)
      if (show) {
        setIsHidden(false)
        height.value = showHeight
        timeoutRef.current.push(
          setTimeout(() => {
            opacity.value = 1
            timeoutRef.current.push(
              setTimeout(() => {
                // show animation complete
                timeoutRef.current = []
                setHeightIsAnimated(false)
              }, opacityTransitionTime)
            )
          }, heightTransitionTime)
        )
      } else {
        opacity.value = 0
        timeoutRef.current.push(
          setTimeout(() => {
            height.value = 0
            timeoutRef.current.push(
              setTimeout(() => {
                // hide animation complete
                timeoutRef.current = []
                setIsHidden(true)
              }, heightTransitionTime)
            )
          }, opacityTransitionTime)
        )
      }
    }
  }, [
    prevShow,
    show,
    height,
    showHeight,
    opacity,
    heightTransitionTime,
    opacityTransitionTime,
  ])

  return (
    <Animated.View style={[styles.container, animationHeightStyle]}>
      <Animated.View
        style={[animationOpacityStyle]}
        onLayout={(event) => {
          setShowHeight(event.nativeEvent.layout.height)
        }}
      >
        {childrenToRender}
      </Animated.View>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
})
