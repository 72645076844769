import * as React from 'react'
import { event } from '@mv/api/lib/src/schema/contracts'
import { convertDisplayAddressToEthereumAddress } from '@mv/api/lib/src/schema/accounts'
import { ledger } from '@mv/api/lib/src/schema'
import { View, Text } from '..'
import {
  db,
  query,
  collection,
  CollectionReference,
  where,
  orderBy,
  limit,
  onSnapshot,
} from '../../firebase/firestore'
import { useSelector } from '../../state/store'
import { Loading } from '../Loading'
import { MyWalletTransactionTable } from './MyWalletTransactionTable'

export function MyWalletRecentTransactions(): JSX.Element | null {
  const user = useSelector((state) => state.user)

  const isMounted = React.useRef(true)

  const [recentTransactions, setRecentTransactions] = React.useState<
    null | ledger.Document[]
  >(null)

  const [pendingEvents, setPendingEvents] = React.useState<event.Event[]>([])

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    if (user.user?.fuid) {
      const recentTransactionsQuery = query(
        collection(
          db,
          ledger.collectionPath
        ) as CollectionReference<ledger.Document>,
        where('accounts', 'array-contains', user.user.fuid),
        orderBy('timestamp', 'desc'),
        limit(5)
      )
      return onSnapshot(recentTransactionsQuery, (snap) => {
        if (isMounted.current) {
          setRecentTransactions(snap.docs.map((doc) => doc.data()))
        }
      })
    }
    return () => {}
  }, [user.user?.fuid])

  React.useEffect(() => {
    if (user.user?.account?.address) {
      const ethAddress = convertDisplayAddressToEthereumAddress(
        user.user.account.address
      )
      const pendingEventsQuery = query(
        collection(
          db,
          event.getCollectionPath('Multiverse')
        ) as CollectionReference<event.Event>,
        where(event.name, '==', 'Deposit'),
        where(event.status, '==', 'UNCONFIRMED'),
        where('args.account', '==', ethAddress)
      )
      return onSnapshot(pendingEventsQuery, (snap) => {
        if (isMounted.current) {
          setPendingEvents(snap.docs.map((doc) => doc.data()))
        }
      })
    }
    return () => {}
  }, [user.user?.account?.address])

  if (recentTransactions === null) {
    return <Loading size="small" />
  }
  if (!user.user) {
    // impossible
    return null
  }
  if (recentTransactions.length === 0 && pendingEvents.length === 0) {
    return (
      <View>
        <Text>No transactions found.</Text>
      </View>
    )
  }

  return (
    <MyWalletTransactionTable
      uid={user.user.fuid}
      pendingEvents={pendingEvents}
      recentTransactions={recentTransactions}
    />
  )
}
