/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  NavigationContainerRef,
} from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { ColorSchemeName } from 'react-native'
import { logEvent, analytics } from '../firebase/analytics'
import { RootStackParamList } from './types'
import LinkingConfiguration from './LinkingConfiguration'
import {
  LoggedInDrawerNavigator,
  LoggedOutDrawerNavigator,
} from './DrawerNavigator'
import { useSelector } from '../state/store'

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName
}): JSX.Element {
  const userLoggedIn = useSelector((state) => state.user.loggedIn)
  const navigationRef = React.useRef<NavigationContainerRef>(null)
  const routeNameRef = React.useRef<string>()

  React.useEffect(() => {
    // log first load
    logEvent(analytics(), 'screen_view')
  }, [])

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current =
          navigationRef.current?.getCurrentRoute()?.name || 'unknownRoute'
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current
        const currentRouteName =
          navigationRef.current?.getCurrentRoute()?.name || 'unknownRoute'

        if (previousRouteName !== currentRouteName) {
          // log route changes
          logEvent(analytics(), 'screen_view')
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName
      }}
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      {userLoggedIn && <LoggedInRootNavigator />}
      {!userLoggedIn && <LoggedOutRootNavigator />}
    </NavigationContainer>
  )
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const RootStack = createStackNavigator<RootStackParamList>()

function LoggedOutRootNavigator() {
  return (
    <RootStack.Navigator mode="modal" screenOptions={{ headerShown: false }}>
      <RootStack.Screen name="Root" component={LoggedOutDrawerNavigator} />
    </RootStack.Navigator>
  )
}

function LoggedInRootNavigator() {
  return (
    <RootStack.Navigator mode="modal" screenOptions={{ headerShown: false }}>
      <RootStack.Screen name="Root" component={LoggedInDrawerNavigator} />
    </RootStack.Navigator>
  )
}
