import * as React from 'react'
import {
  ScrollView,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native'
import { View, Text, Weight } from '..'
import { TimeAgo } from '../TimeAgo'
import { useThemeColors } from '../../constants/colors'
import { useTheme } from '../../hooks/useTheme'

type DateStyle = 'timeAgo' | 'dateAndTimeAgo'

type ColumnOptions<T> = Partial<
  Record<
    keyof T,
    {
      /** set to true to ignore in headings */
      hideHeading?: boolean
      /** name override to use in heading */
      name?: string
      style?: StyleProp<ViewStyle>
    }
  >
>
type TableDatumSettings = { key: string }
type TableProps<T> = {
  data: Array<TableDatumSettings & T>
  columns: Array<keyof T>
  columnOptions?: ColumnOptions<T>
  /** number of rows to display */
  limit?: number
  containerStyle?: StyleProp<ViewStyle>
  headingTextStyle?: StyleProp<TextStyle>
  headingTextWeight?: Weight
  cellTextStyle?: StyleProp<TextStyle>
  cellTextWeight?: Weight
  rowStyle?: StyleProp<ViewStyle>
  rowVerticalPadding?: number
  rowHorizontalPadding?: number
  columnStyle?: StyleProp<ViewStyle>
  /** pixels between columns */
  spaceBetweenColumns?: number
  /** pixels between rows */
  spaceBetweenRows?: number
  rowBorderRadius?: number
  dateStyle?: DateStyle
}
type Props<T> = TableProps<T> & {
  headingBackgroundColor?: string
  backgroundColors?: Array<string>
}

export function Table<T>({
  data,
  columns,
  columnOptions = {},
  limit = undefined,
  containerStyle = undefined,
  headingTextStyle = undefined,
  headingTextWeight = 'semiBold',
  spaceBetweenColumns = 16,
  spaceBetweenRows = 8,
  cellTextWeight = 'normal',
  cellTextStyle = undefined,
  rowStyle = undefined,
  rowVerticalPadding = 20,
  rowHorizontalPadding = 20,
  columnStyle = undefined,
  headingBackgroundColor = undefined,
  backgroundColors = [],
  rowBorderRadius = undefined,
  dateStyle = 'timeAgo',
}: Props<T>): JSX.Element | null {
  const displayArray: JSX.Element[][] = []

  for (
    let rowIndex = 0;
    rowIndex < Math.min(limit || data.length, data.length);
    rowIndex += 1
  ) {
    const row = data[rowIndex]
    columns.forEach((col, columnIndex) => {
      const spacingStyle = {
        paddingRight:
          columnIndex !== columns.length - 1
            ? spaceBetweenColumns
            : rowHorizontalPadding,
        paddingTop: rowVerticalPadding / 2,
        paddingBottom: rowVerticalPadding / 2,
        paddingLeft: columnIndex === 0 ? rowHorizontalPadding : undefined,
        marginTop: rowIndex !== 0 ? spaceBetweenRows : undefined,
        borderTopLeftRadius: columnIndex === 0 ? rowBorderRadius : undefined,
        borderBottomLeftRadius: columnIndex === 0 ? rowBorderRadius : undefined,
        borderTopRightRadius:
          columnIndex === columns.length - 1 ? rowBorderRadius : undefined,
        borderBottomRightRadius:
          columnIndex === columns.length - 1 ? rowBorderRadius : undefined,
      }
      const backgroundColorStyle = {
        backgroundColor:
          backgroundColors.length === 0
            ? undefined
            : backgroundColors[rowIndex % backgroundColors.length],
      }
      if (!displayArray[columnIndex]) {
        const headingName = columnOptions[col]?.hideHeading ? (
          <>&nbsp;</>
        ) : (
          columnOptions[col]?.name || col
        )
        const headingBackgroundColorStyle = {
          backgroundColor: headingBackgroundColor,
        }
        displayArray[columnIndex] = [
          <View
            key={`heading-${col.toString()}`}
            style={[rowStyle, spacingStyle, headingBackgroundColorStyle]}
          >
            <Text weight={headingTextWeight} style={headingTextStyle}>
              {headingName}
            </Text>
          </View>,
        ]
      }
      const datum = row[col]
      if (datum instanceof Date) {
        let dateRender = <></>
        switch (dateStyle) {
          case 'dateAndTimeAgo':
            dateRender = (
              <>
                {datum.toLocaleString(undefined, {
                  dateStyle: 'medium',
                })}{' '}
                (
                <TimeAgo style={cellTextStyle} date={datum} />)
              </>
            )
            break
          default:
            dateRender = <TimeAgo style={cellTextStyle} date={datum} />
            break
        }
        displayArray[columnIndex].push(
          <View
            key={row.key}
            style={[rowStyle, spacingStyle, backgroundColorStyle]}
          >
            <Text
              weight={cellTextWeight}
              style={[styles.cellText, cellTextStyle]}
              numberOfLines={1}
            >
              {dateRender}
            </Text>
          </View>
        )
      } else if (typeof datum === 'string' || typeof datum === 'number') {
        displayArray[columnIndex].push(
          <View
            key={row.key}
            style={[rowStyle, spacingStyle, backgroundColorStyle]}
          >
            <Text
              weight={cellTextWeight}
              style={[styles.cellText, cellTextStyle]}
              numberOfLines={1}
            >
              {datum || <>&nbsp;</>}
            </Text>
          </View>
        )
      } else if (React.isValidElement(datum)) {
        displayArray[columnIndex].push(
          <View
            key={row.key}
            style={[rowStyle, spacingStyle, backgroundColorStyle]}
          >
            {datum}
          </View>
        )
      } else {
        // unknown or unexpected data - insert empty string to keep table balanced
        displayArray[columnIndex].push(
          <View
            key={row.key}
            style={[rowStyle, spacingStyle, backgroundColorStyle]}
          >
            <Text
              weight={cellTextWeight}
              style={[styles.cellText, cellTextStyle]}
              numberOfLines={1}
            >
              &nbsp;
            </Text>
          </View>
        )
      }
    })
  }
  const tableRender = (
    <View style={[styles.container, containerStyle]}>
      {displayArray.map((column, index) => (
        <View
          key={`${columns[index].toString()}`}
          style={[
            styles.row,
            columnStyle,
            columnOptions[columns[index]]?.style,
          ]}
        >
          {column}
        </View>
      ))}
    </View>
  )

  return (
    <ScrollView horizontal style={styles.scrollView}>
      {tableRender}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  cellText: {},
  container: {
    flexDirection: 'row',
  },
  row: {},
  scrollView: { flex: 1 },
})

export function DefaultBackgroundColorsTable<T>({
  data,
  columns,
  columnOptions = {},
  limit = undefined,
  containerStyle = undefined,
  headingTextStyle = undefined,
  headingTextWeight = 'semiBold',
  spaceBetweenColumns = 16,
  spaceBetweenRows = undefined,
  cellTextWeight = 'normal',
  cellTextStyle = undefined,
  rowStyle = undefined,
  rowVerticalPadding = undefined,
  rowHorizontalPadding = undefined,
  columnStyle = undefined,
  rowBorderRadius = undefined,
  dateStyle = 'timeAgo',
}: TableProps<T>): JSX.Element | null {
  const tableColors = useThemeColors().table
  const { theme } = useTheme()
  return (
    <Table
      data={data}
      columns={columns}
      columnOptions={columnOptions}
      limit={limit}
      containerStyle={containerStyle}
      headingTextStyle={headingTextStyle}
      headingTextWeight={headingTextWeight}
      spaceBetweenColumns={spaceBetweenColumns}
      spaceBetweenRows={spaceBetweenRows || theme === 'hadron' ? 0 : 8}
      cellTextWeight={cellTextWeight}
      cellTextStyle={cellTextStyle}
      rowStyle={rowStyle}
      rowVerticalPadding={rowVerticalPadding || theme === 'hadron' ? 20 : 16}
      rowHorizontalPadding={
        rowHorizontalPadding || theme === 'hadron' ? 20 : 32
      }
      columnStyle={columnStyle}
      headingBackgroundColor={tableColors.headingBackgroundColor}
      backgroundColors={tableColors.backgroundColors}
      rowBorderRadius={rowBorderRadius || theme === 'hadron' ? undefined : 4}
      dateStyle={dateStyle}
    />
  )
}
