/* eslint-disable react-native/no-unused-styles */
/* eslint-disable react/jsx-props-no-spreading */
/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

// import { Ionicons } from '@expo/vector-icons'
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer'
import { useNavigation, StackActions } from '@react-navigation/native'
import * as React from 'react'
import { Linking, StyleSheet } from 'react-native'
import { logger } from '../logger'
import { DefaultStartScreen } from '../components/screens/DefaultStartScreen'
import { useThemeColors } from '../constants/colors'
import { useDispatch } from '../state/store'
import { modalsActions } from '../state/modalsSlice'
import { auth } from '../firebase/auth'
import { View } from '../components'
import { LoggedInNavDisplay } from '../components/nav/LoggedInNavDisplay'
import { ExternalLinksDrawerItems } from '../components/nav/ExternalLinksDrawerItems'
import { MultiverseLogo } from '../components/svg/logos/MultiverseLogo'
import { CustomDrawerContentProps } from '../components/nav/types'
import {
  DrawerParamList,
  DrawerNavigationProps,
  DrawerNavigatorProps,
} from './types'
import { NavFooterLinks } from '../components/nav/NavFooterLinks'
import { useMobileLayout } from '../hooks/useMobileLayout'
import { useSuperAdminClaim } from '../hooks/useSuperAdminClaim'
import { PlanetsNavigator } from './PlanetsNavigator'
import { MyWalletScreen } from '../components/wallet/screens/MyWalletScreen'
import { AdminScreen } from '../components/admin/screens/AdminScreen'
import { wrapScreenWithNavigationScreen } from '../components/NavigationScreen'
import { MobileHeaderBar } from '../components/nav/MobileHeaderBar'
import { useTheme } from '../hooks/useTheme'

const SHOW_EXTERNAL_LINKS = false
const SHOW_HOMEWORLD_NAV = false

const Drawer = createDrawerNavigator<DrawerParamList>()

type Props = DrawerNavigatorProps
function ResponsiveDrawerNavigator(props: Props): JSX.Element {
  const isMobileDevice = useMobileLayout()

  const { drawerType, ...otherProps } = props

  const responsiveDrawerType = isMobileDevice ? 'front' : 'permanent'
  return <Drawer.Navigator drawerType={responsiveDrawerType} {...otherProps} />
}

export function LoggedInDrawerNavigator(): JSX.Element {
  const isSuperAdmin = useSuperAdminClaim()
  const isMobileDevice = useMobileLayout()
  const themeColors = useThemeColors()
  const { theme } = useTheme()
  const navColors = themeColors.nav
  const drawerColorStyles = {
    backgroundColor: navColors.background,
    borderRightColor: navColors.border,
  }

  const styles = themedStyles[theme]

  return (
    <ResponsiveDrawerNavigator
      initialRouteName="MyWalletScreen"
      drawerStyle={[styles.drawer, drawerColorStyles]}
      drawerContent={(props) => (
        <LoggedInCustomDrawerContent {...props} navColors={navColors} />
      )}
      screenOptions={{
        headerShown: isMobileDevice,
        header: () => <MobileHeaderBar />,
      }}
    >
      <Drawer.Screen
        name="MyWalletScreen"
        options={{
          title: 'My Multiverse Wallet',
          drawerLabel: 'My Wallet',
        }}
        component={WrappedMyWalletScreen}
      />
      <Drawer.Screen
        name="PlanetsScreen"
        options={{
          title: 'Multiverse Planets',
          drawerLabel: 'Planets',
        }}
        component={PlanetsNavigator}
      />
      {isSuperAdmin && (
        <Drawer.Screen
          name="AdminScreen"
          options={{
            title: 'My Multiverse Wallet',
            drawerLabel: 'My Wallet',
          }}
          component={WrappedAdminScreen}
        />
      )}
    </ResponsiveDrawerNavigator>
  )
}

export function LoggedOutDrawerNavigator(): JSX.Element {
  const isMobileDevice = useMobileLayout()
  const navColors = useThemeColors().nav
  const { theme } = useTheme()
  const drawerColorStyles = {
    backgroundColor: navColors.background,
  }

  const styles = themedStyles[theme]

  return (
    <ResponsiveDrawerNavigator
      initialRouteName="LoginScreen"
      drawerStyle={[styles.drawer, drawerColorStyles]}
      drawerContent={(props) => (
        <LoggedOutCustomDrawerContent {...props} navColors={navColors} />
      )}
      screenOptions={{
        headerShown: isMobileDevice,
        header: () => <MobileHeaderBar />,
      }}
    >
      <Drawer.Screen
        name="StartScreen"
        options={{
          title: 'Multiverse Home',
          drawerLabel: 'Home',
        }}
        component={StartScreen}
      />
      <Drawer.Screen
        name="LoginScreen"
        options={{ title: 'Multiverse Login' }}
        component={LoginScreen}
      />
      <Drawer.Screen
        name="JoinScreen"
        options={{ title: 'Multiverse Join' }}
        component={JoinScreen}
      />
    </ResponsiveDrawerNavigator>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    customNav: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    drawer: {
      width: 200,
    },
    drawerItem: {
      marginRight: -22, // react navigation has a DrawerItem label marginRight: 32 that this counters https://github.com/react-navigation/react-navigation/blob/cc94cce297533028e8dc633ca781d45ae2f7447e/packages/drawer/src/views/DrawerItem.tsx#L227
      width: 178,
    },
    drawerItemLabel: {
      fontFamily: 'InterSemiBold',
      fontSize: 16,
    },
    drawerScrollView: {
      borderBottomWidth: 1,
      borderTopWidth: 1,
    },
    logo: {
      alignItems: 'center',
      marginBottom: 40,
      marginTop: 40,
    },
    mainNav: {
      paddingBottom: 20,
      paddingTop: 20,
    },
  }),
  multiverse2022: StyleSheet.create({
    customNav: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    drawer: {
      width: 260,
    },
    drawerItem: {
      marginLeft: 16, // react naviation has a DrawerItem padding of 8 which added together is 24 (spec)
      marginRight: -8, // react navigation has a DrawerItem label marginRight: 32 that this counters to make 24 (spec) https://github.com/react-navigation/react-navigation/blob/cc94cce297533028e8dc633ca781d45ae2f7447e/packages/drawer/src/views/DrawerItem.tsx#L227
      width: 272, // 320 - 24 - 24
    },
    drawerItemLabel: {
      fontFamily: 'FraktionMonoBold',
      fontSize: 18,
      fontWeight: '400',
    },
    drawerScrollView: {
      borderBottomWidth: 1,
      borderTopWidth: 1,
    },
    logo: {
      alignItems: 'flex-start',
      marginLeft: 24,
      marginTop: 24,
    },
    mainNav: {
      paddingBottom: 20,
      paddingTop: 20,
    },
  }),
}

function LoggedOutCustomDrawerContent(props: CustomDrawerContentProps) {
  const {
    activeTintColor,
    inactiveTintColor,
    activeBackgroundColor,
    inactiveBackgroundColor,
    labelStyle,
    navColors,
    ...otherProps
  } = props

  const dispatch = useDispatch()
  const { theme } = useTheme()

  const navItemColors = navColors.item
  const navItemColorProps = {
    activeTintColor: activeTintColor || navItemColors.activeTintColor,
    inactiveTintColor: inactiveTintColor || navItemColors.inactiveTintColor,
    activeBackgroundColor:
      activeBackgroundColor || navItemColors.activeBackgroundColor,
    inactiveBackgroundColor:
      inactiveBackgroundColor || navItemColors.inactiveBackgroundColor,
  }
  const navHorizontalRuleColorStyle = {
    borderTopColor: navColors.horizontalRule,
    borderBottomColor: navColors.horizontalRule,
  }

  const { state: drawerState, ...drawerProps } = otherProps
  const filteredDrawerState = { ...drawerState }
  filteredDrawerState.routes = filteredDrawerState.routes.filter(
    (route) => !['LoginScreen', 'JoinScreen'].includes(route.name)
  )

  const styles = themedStyles[theme]
  const logoWidth = theme === 'hadron' ? 150 : 48

  return (
    <View nativeID="navigation-loggedOut" style={styles.customNav}>
      <View style={styles.logo}>
        <MultiverseLogo width={logoWidth} inverse />
      </View>
      <DrawerContentScrollView
        {...props}
        style={[styles.drawerScrollView, navHorizontalRuleColorStyle]}
      >
        <View style={styles.mainNav}>
          <DrawerItem
            {...navItemColorProps}
            style={styles.drawerItem}
            label="Multiverse Labs"
            labelStyle={styles.drawerItemLabel}
            onPress={() => Linking.openURL('https://multiverse.ai/')}
          />
          <DrawerItemList
            {...navItemColorProps}
            itemStyle={styles.drawerItem}
            labelStyle={[styles.drawerItemLabel, labelStyle]}
            state={filteredDrawerState}
            {...drawerProps}
          />
          <DrawerItem
            {...navItemColorProps}
            style={styles.drawerItem}
            label="Create Account"
            labelStyle={[styles.drawerItemLabel, labelStyle]}
            onPress={() => {
              dispatch(modalsActions.showCreateAccountModal())
            }}
          />
          <DrawerItem
            {...navItemColorProps}
            style={styles.drawerItem}
            label="Login"
            labelStyle={[styles.drawerItemLabel, labelStyle]}
            onPress={() => {
              dispatch(modalsActions.showLoginModal())
            }}
          />
        </View>
        {SHOW_EXTERNAL_LINKS && <ExternalLinksDrawerItems {...props} />}
      </DrawerContentScrollView>
      <NavFooterLinks />
    </View>
  )
}
function LoggedInCustomDrawerContent(props: CustomDrawerContentProps) {
  const {
    activeTintColor,
    inactiveTintColor,
    activeBackgroundColor,
    inactiveBackgroundColor,
    labelStyle,
    navColors,
    ...otherProps
  } = props
  const navigation = useNavigation<DrawerNavigationProps>()
  const dispatch = useDispatch()
  const isSuperAdmin = useSuperAdminClaim()
  const { theme } = useTheme()

  const navItemColors = navColors.item
  const navItemColorProps = {
    activeTintColor: activeTintColor || navItemColors.activeTintColor,
    inactiveTintColor: inactiveTintColor || navItemColors.inactiveTintColor,
    activeBackgroundColor:
      activeBackgroundColor || navItemColors.activeBackgroundColor,
    inactiveBackgroundColor:
      inactiveBackgroundColor || navItemColors.inactiveBackgroundColor,
  }
  const navHorizontalRuleColorStyle = {
    borderTopColor: navColors.horizontalRule,
    borderBottomColor: navColors.horizontalRule,
  }

  const { state: drawerState } = otherProps

  const styles = themedStyles[theme]
  const logoWidth = theme === 'hadron' ? 150 : 48

  return (
    <View nativeID="navigation-loggedIn" style={styles.customNav}>
      <View style={styles.logo}>
        <MultiverseLogo width={logoWidth} inverse />
      </View>
      <DrawerContentScrollView
        {...props}
        style={[styles.drawerScrollView, navHorizontalRuleColorStyle]}
      >
        <View style={styles.mainNav}>
          {SHOW_HOMEWORLD_NAV && (
            <DrawerItem
              {...navItemColorProps}
              style={styles.drawerItem}
              label="Homeworld"
              labelStyle={[styles.drawerItemLabel, labelStyle]}
              onPress={() => {
                navigation.navigate('StartScreen')
              }}
              focused={
                drawerState.routes[drawerState.index].name === 'StartScreen'
              }
            />
          )}
          <DrawerItem
            {...navItemColorProps}
            style={styles.drawerItem}
            label="Explore"
            labelStyle={[styles.drawerItemLabel, labelStyle]}
            onPress={() => {
              navigation.dispatch(StackActions.replace('PlanetsList'))
              navigation.navigate('PlanetsScreen')
            }}
            focused={
              drawerState.routes[drawerState.index].name === 'PlanetsScreen'
            }
          />
          <DrawerItem
            {...navItemColorProps}
            style={styles.drawerItem}
            label="My Wallet"
            labelStyle={[styles.drawerItemLabel, labelStyle]}
            onPress={() => {
              navigation.navigate('MyWalletScreen')
            }}
            focused={
              drawerState.routes[drawerState.index].name === 'MyWalletScreen'
            }
          />
          <DrawerItem
            {...navItemColorProps}
            style={styles.drawerItem}
            label="Edit Profile"
            labelStyle={[styles.drawerItemLabel, labelStyle]}
            onPress={async () => {
              await dispatch(modalsActions.showEditProfileModal())
            }}
          />
          <DrawerItem
            {...navItemColorProps}
            style={styles.drawerItem}
            label="Logout"
            labelStyle={[styles.drawerItemLabel, labelStyle]}
            onPress={() => {
              auth
                .signOut()
                .catch((e) =>
                  logger.warn('error encountered when signing out: ', e)
                )
            }}
          />
          {isSuperAdmin && (
            <DrawerItem
              {...navItemColorProps}
              style={styles.drawerItem}
              label="Admin"
              labelStyle={[styles.drawerItemLabel, labelStyle]}
              onPress={() => {
                navigation.navigate('AdminScreen')
              }}
            />
          )}
        </View>
        {SHOW_EXTERNAL_LINKS && <ExternalLinksDrawerItems {...props} />}
      </DrawerContentScrollView>
      <LoggedInNavDisplay />
      <NavFooterLinks />
    </View>
  )
}

function StartScreen() {
  return wrapScreenWithNavigationScreen(DefaultStartScreen)()
}

function LoginScreen() {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(modalsActions.showLoginModal())
  }, [dispatch])
  return wrapScreenWithNavigationScreen(DefaultStartScreen)()
}

function JoinScreen() {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(modalsActions.showCreateAccountModal())
  }, [dispatch])
  return wrapScreenWithNavigationScreen(DefaultStartScreen)()
}

const WrappedMyWalletScreen = wrapScreenWithNavigationScreen(MyWalletScreen)
const WrappedAdminScreen = wrapScreenWithNavigationScreen(AdminScreen)
