import { EarlyAccess } from '@mv/api/lib/src/schema/accounts'
import { useSelector } from '../state/store'
import { useSuperAdminClaim } from './useSuperAdminClaim'

/**
 * returns `null` until the user account document has been loaded
 *
 * returns `true` or `false` depending on if the user has access
 */
export function useEarlyAccess(accessType: EarlyAccess): boolean | null {
  const user = useSelector((state) => state.user)
  const isSuperAdmin = useSuperAdminClaim()

  if (isSuperAdmin) {
    return true
  }

  if (!user.user?.account) {
    return null
  }

  return !!user.user?.account?.earlyAccess?.includes(accessType)
}
