import * as React from 'react'
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  ViewProps,
  ViewStyle,
} from 'react-native'
import { View } from './View'
import { useThemeColors } from '../constants/colors'
import { useScreenMargins } from '../hooks/useScreenMargins'

type Props = {
  children: React.ReactNode
  scrollable?: boolean
  noMargins?: boolean
  containerStyle?: StyleProp<ViewStyle>
  onLayout?: ViewProps['onLayout']
}

export function TabView({
  children,
  scrollable = true,
  noMargins = false,
  containerStyle = undefined,
  onLayout = undefined,
}: Props): JSX.Element {
  const colors = useThemeColors()
  const backgroundColorStyle = {
    backgroundColor: colors.background,
  }

  const { marginLeft, marginRight } = useScreenMargins().style
  const marginStyle = noMargins ? undefined : { marginLeft, marginRight }

  const childrenView = (
    <View
      style={[styles.childrenView, marginStyle, containerStyle]}
      onLayout={onLayout}
    >
      {children}
    </View>
  )

  if (scrollable) {
    return (
      <ScrollView style={[styles.scrollView, backgroundColorStyle]}>
        {childrenView}
      </ScrollView>
    )
  }
  return childrenView
}

const styles = StyleSheet.create({
  childrenView: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
})
