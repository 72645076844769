import * as React from 'react'
import {
  StyleSheet,
  ViewStyle,
  TextStyle,
  StyleProp,
  View as RNView,
} from 'react-native'
import crypto from 'crypto'
import { View, Text, TextProps } from '.'
import { TextPreset } from './Text'
import { shortAddress } from '../helpers'
import { Button } from './Button'
import { DisplayAddressDropDownMenu } from './DisplayAddressDropDownMenu'
import { contextMenuActions } from '../state/contextMenuSlice'
import { useDispatch, useSelector } from '../state/store'
import { cacheActions } from '../state/cacheSlice'
import {
  DisplayNameCacheItem,
  DISPLAY_NAME_CACHE_EXPIRATION_TIME_IN_MS,
} from '../state/cacheTypes'

type Props = {
  address: string
  style?: StyleProp<ViewStyle>
  textWeight?: TextProps['weight']
  textStyle?: StyleProp<TextStyle>
  textPreset?: TextPreset
  first?: number
  last?: number
  useMultiverseAddress?: boolean
  testID?: string
  spaceBetween?: number
  maskAddress?: boolean
  contextMenuEnabled?: boolean
}

export function DisplayAddress({
  address,
  style = undefined,
  textWeight = undefined,
  textStyle = undefined,
  textPreset = 'paragraph',
  first = 5,
  last = 5,
  useMultiverseAddress = true,
  testID = undefined,
  spaceBetween = undefined,
  maskAddress = true,
  contextMenuEnabled = false,
}: Props): JSX.Element {
  const viewRef = React.useRef<RNView>(null)
  const componentUID = React.useRef<string>(generateComponentUID())
  const displayNameCache = useSelector(
    (state) => state.cache.displayNames[address]
  )
  const dispatch = useDispatch()

  const displayName = displayNameCache?.data || ''

  React.useEffect(() => {
    if (!displayNameCache || isExpired(displayNameCache)) {
      dispatch(cacheActions.getDisplayName({ address })).catch(() => {})
    }
  }, [dispatch, address, displayNameCache])

  const displayAddress = maskAddress
    ? `${shortAddress(
        obfuscateAddress(address),
        first,
        last,
        useMultiverseAddress
      ).slice(2)}`
    : `${shortAddress(address, first, last, useMultiverseAddress)}`

  const addressColorStyle = {
    color: colorFromAddress(address),
  }

  const spaceBetweenStyle = spaceBetween
    ? {
        paddingRight: spaceBetween,
      }
    : undefined

  const renderedDisplayAddress = (
    <View
      testID={contextMenuEnabled ? undefined : testID}
      ref={viewRef}
      style={[styles.address, style]}
    >
      {!!displayName && (
        <Text
          preset={textPreset}
          weight={textWeight}
          style={[styles.displayName, textStyle, spaceBetweenStyle]}
        >
          {displayName}
        </Text>
      )}
      <Text
        preset={textPreset}
        weight={textWeight}
        style={[styles.text, addressColorStyle, textStyle]}
      >
        {displayAddress}
      </Text>
    </View>
  )

  if (contextMenuEnabled) {
    return (
      <Button
        testID={testID}
        onPress={async () => {
          if (viewRef.current) {
            viewRef.current.measure((_x, _y, _width, height, pageX, pageY) => {
              dispatch(
                contextMenuActions.setContextMenu({
                  coordinates: { top: pageY + height, left: pageX },
                  menu: <DisplayAddressDropDownMenu address={address} />,
                  parentUID: componentUID.current,
                })
              )
            })
          }
        }}
      >
        {renderedDisplayAddress}
      </Button>
    )
  }
  return renderedDisplayAddress
}

const styles = StyleSheet.create({
  address: {
    flexDirection: 'row',
  },
  displayName: {
    paddingRight: 8,
  },
  text: {},
})

function generateComponentUID(): string {
  return `displayAddress-${Date.now()}-${Math.floor(Math.random() * 100000)}`
}

function isExpired(item: DisplayNameCacheItem) {
  return (
    item.lastRetrieved.getTime() <
    Date.now() - DISPLAY_NAME_CACHE_EXPIRATION_TIME_IN_MS
  )
}

function colorFromAddress(address: string): string {
  const hexString = address.slice(2, address.length)

  const midPoint = Math.floor(hexString.length / 2)

  const hueHex = hexString.slice(midPoint - 3, midPoint - 1)
  const saturationHex = hexString.slice(midPoint - 1, midPoint + 1)
  const luminanceHex = hexString.slice(midPoint + 1, midPoint + 3)
  if (!hueHex || !saturationHex || !luminanceHex) {
    return `#000000`
  }
  const hue = hexToNumber(hueHex, 360)
  const saturation = hexToNumber(saturationHex, 50, 50)
  const luminance = hexToNumber(luminanceHex, 25, 25)
  return `hsl(${hue}, ${saturation}%, ${luminance}%)`
}

function hexToNumber(hexString: string, range: number, bias = 0): number {
  return Math.floor(
    (parseInt(hexString, 16) * range) / 16 ** hexString.length + bias
  )
}

function obfuscateAddress(address: string): string {
  const salt = address
  const hashedAddress = crypto
    .createHash('sha1')
    .update(`${address}${salt}`)
    .digest('hex')
  return `0x${hashedAddress.toUpperCase()}`
}
