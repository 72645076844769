import * as React from 'react'
import { logger } from '../logger'
import { Modal, Heading } from './ModalElements'
import * as Form from '../components/form'
import { useDispatch, useSelector } from '../state/store'
import { modalsActions } from '../state/modalsSlice'
import { ModalStringData } from '../state/modalsTypes'
import { auth, sendPasswordResetEmail } from '../firebase/auth'
import { ERROR_OOPS } from '../constants/messages'
import { NoRefresh } from '../components/NoRefresh'

type FormError = {
  email?: string
  generic?: string
}
export function AccountForgotPasswordModal(): JSX.Element {
  const dispatch = useDispatch()

  const prefillData = useSelector(
    (state) => state.modals.data
  ) as ModalStringData

  const [email, setEmail] = React.useState(prefillData?.email ?? '')
  const [formError, setFormError] = React.useState({} as FormError)
  const [completedSubmission, setCompletedSubmission] = React.useState(false)

  const submitRef = React.useRef<Form.SubmitButtonRefType>(null)

  if (completedSubmission) {
    return (
      <Modal testID="account-forgot-password-completed">
        <Form.ConcludingMessage>
          An email with password reset instructions has been sent to {email}
        </Form.ConcludingMessage>
      </Modal>
    )
  }
  return (
    <Modal testID="account-forgot-password">
      <Heading>Forgot Your Password</Heading>
      <NoRefresh>
        <Form.Item>
          <Form.Label>Email address</Form.Label>
          <Form.TextInput
            value={email}
            onChangeText={(textValue) => {
              setEmail(textValue)
            }}
            autoCompleteType="email"
            keyboardType="email-address"
            onKeyPress={(event) => {
              if (event.nativeEvent.key === 'Enter' && email) {
                if (submitRef.current?.onPress) submitRef.current?.onPress()
              }
            }}
          />
          <Form.Error error={formError.email} />
        </Form.Item>
        <Form.SubmitButton
          ref={submitRef}
          label="Send Temporary Password"
          onPress={async () => {
            setFormError({})
            const result = await submitEmail(email, setFormError)
            if (result) {
              return () => {
                setCompletedSubmission(true)
              }
            }
            return false
          }}
        />
        <Form.Error error={formError.generic} />
        <Form.Alternative.Row>
          <Form.Alternative.Link
            textLabel="Remember your password?"
            label="Login"
            onPress={() => {
              dispatch(modalsActions.showLoginModal({ email }))
            }}
          />
        </Form.Alternative.Row>
      </NoRefresh>
    </Modal>
  )
}

async function submitEmail(
  email: string,
  setFormError: React.Dispatch<React.SetStateAction<FormError>>
): Promise<boolean> {
  return sendPasswordResetEmail(auth, email)
    .then(() => true)
    .catch((error) => {
      switch (error.code) {
        case 'auth/invalid-email':
          setFormError({
            email: 'Please enter a valid email address.',
          })
          break
        case 'auth/user-not-found':
          // ignore this error - pretend the email was sent
          return true
        default:
          setFormError({
            generic: ERROR_OOPS,
          })
          // TODO: Need to implement Logger and update this to use the logger
          logger.warn(error.code, error.message)
      }
      return false
    })
}
