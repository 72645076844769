/**
 * Generates a reasonable `nativeID` string provided a `testID`, optional
 * `prefix`, and optional `altText` (such as a button label text)
 */
export function testIdToNativeId(
  testID: string | undefined,
  prefix?: string,
  altText?: string
): string | undefined {
  const testIdString = testID || altText?.replace(/\s+/g, '-').toLowerCase()
  if (prefix) {
    if (!testIdString) {
      return prefix
    }
    if (testIdString.startsWith(`${prefix}-`)) {
      return testIdString
    }
    return `${prefix}-${testIdString}`
  }
  return testIdString
}
