import * as React from 'react'
import { StyleSheet } from 'react-native'
import { schema } from '@mv/api'
import { Section } from '..'
import { useSuperAdminClaim } from '../../hooks/useSuperAdminClaim'
import * as Form from '../form'
import { db, doc, getDoc, DocumentReference } from '../../firebase/firestore'
import { useSelector, useDispatch } from '../../state/store'
import { userActions } from '../../state/userSlice'
import { logger } from '../../logger'
import { useIsImpersonating } from '../../hooks/useIsImpersonating'

type FormError = {
  fuidOrAddress?: string
}

export function Impersonation(): JSX.Element | null {
  const isSuperAdmin = useSuperAdminClaim()
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const isImpersonating = useIsImpersonating()

  const [fuidOrAddress, setFuidOrAddress] = React.useState('')

  const [formError, setFormError] = React.useState({} as FormError)

  if (!isSuperAdmin || !user) {
    return null
  }

  return (
    <Section containerStyle={styles.section} heading="Impersonation">
      {isImpersonating ? (
        <Form.SubmitButton
          label="Stop Impersonation"
          onPress={async () => {
            setFormError({})

            dispatch(
              userActions.login({
                fuid: user?.firebaseUser.uid,
                firebaseUser: user?.firebaseUser,
                claims: {},
              })
            ).catch((e) => {
              logger.warn('dispatch login for impersonation rejected', e)
            })

            return false
          }}
        />
      ) : (
        <>
          <Form.Item>
            <Form.Label>UID or Address</Form.Label>
            <Form.TextInput
              value={fuidOrAddress}
              onChangeText={(textValue) => {
                setFuidOrAddress(textValue)
              }}
            />
            <Form.Error error={formError.fuidOrAddress} />
          </Form.Item>
          <Form.SubmitButton
            label="Impersonate"
            onPress={async () => {
              setFormError({})
              const fuid = await getFuidFromFuidOrAddress(fuidOrAddress)
              if (!fuid) {
                setFormError({ fuidOrAddress: 'Invalid UID or Address' })
              } else {
                dispatch(
                  userActions.login({
                    fuid,
                    firebaseUser: user?.firebaseUser,
                    claims: {},
                  })
                ).catch((e) => {
                  logger.warn('dispatch login for impersonation rejected', e)
                })
              }
              return false
            }}
          />
        </>
      )}
    </Section>
  )
}

const styles = StyleSheet.create({
  section: {
    maxWidth: 700,
  },
})

async function getFuidFromFuidOrAddress(
  fuidOrAddress: string
): Promise<string | null> {
  const trimmedFuidOrAddress = fuidOrAddress.trim()
  if (!trimmedFuidOrAddress) {
    return null
  }
  if (
    trimmedFuidOrAddress.startsWith('0x') ||
    trimmedFuidOrAddress.startsWith('Mv')
  ) {
    // address based lookup
    const docSnap = await getDoc(
      doc(
        db,
        schema.addresses.documentPath(`0x${trimmedFuidOrAddress.slice(2)}`)
      ) as DocumentReference<schema.addresses.Document>
    )
    if (!docSnap.exists()) {
      return null
    }
    return docSnap.data()?.account || null
  }

  const docSnap = await getDoc(
    doc(db, schema.accounts.accountPath(trimmedFuidOrAddress))
  )
  if (!docSnap.exists()) {
    return null
  }
  return docSnap.id
}
