/* eslint-disable react-native/no-unused-styles */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { StyleSheet } from 'react-native'
import {
  Picker as RNPicker,
  PickerProps,
  PickerItemProps,
} from '@react-native-picker/picker'
import { testIdToNativeId } from '../../helpers/testId'
import { useTheme } from '../../hooks/useTheme'
import { useThemeColors } from '../../constants/colors'
import { fonts } from '../../constants/fonts'

type Props<T> = PickerProps<T> & {
  children: React.ReactNode
  testID?: string
}

export function Picker<T>({
  children,
  style,
  testID,
  ...props
}: Props<T>): JSX.Element {
  const color = useThemeColors()
  const { theme } = useTheme()
  const styles = themedStyles[theme]
  const colorStyle = {
    ...color.textInput,
  }
  const fontStyle = theme === 'hadron' ? undefined : fonts.FraktionMono.normal
  return (
    <RNPicker
      dropdownIconColor="red"
      nativeID={testIdToNativeId(testID, 'form-picker')}
      testID={testID}
      style={[styles.picker, fontStyle, style, colorStyle]}
      {...props}
    >
      {children}
    </RNPicker>
  )
}

type ItemProps<T> = PickerItemProps<T>
export function PickerItem<T>(itemProps: ItemProps<T>): JSX.Element {
  return <RNPicker.Item {...itemProps} />
}

const themedStyles = {
  hadron: StyleSheet.create({
    picker: {
      borderColor: '#DADADA',
      borderWidth: 1,
      fontSize: 20,
      paddingBottom: 6,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 6,
    },
  }),
  multiverse2022: StyleSheet.create({
    picker: {
      borderRadius: 8,
      borderWidth: 1,
      fontSize: 18,
      lineHeight: 27,
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
    },
  }),
}
