import * as React from 'react'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated'
import { usePrevious } from '../../hooks/usePrevious'

const DEFAULT_OPACITY_TRANSITION_TIME = 250

type Props = {
  children: React.ReactNode
  opacityTransitionTime?: number
  animate?: boolean
}
export function AnimateFadeIn({
  children,
  opacityTransitionTime = DEFAULT_OPACITY_TRANSITION_TIME,
  animate = true,
}: Props): JSX.Element | null {
  if (!animate) {
    return <>{children}</>
  }
  return (
    <InternalAnimateFadeIn
      animate={animate}
      opacityTransitionTime={opacityTransitionTime}
    >
      {children}
    </InternalAnimateFadeIn>
  )
}

function InternalAnimateFadeIn({
  children,
  opacityTransitionTime,
}: Required<Props>): JSX.Element | null {
  const [isHidden, setIsHidden] = React.useState(true)
  const [show, setShow] = React.useState<boolean | null>(null)
  const timeoutRef = React.useRef<NodeJS.Timeout[]>([])
  const [childrenToRender, setChildrenToRender] = React.useState(children)
  const prevShow = usePrevious(show)
  const opacity = useSharedValue(0)

  const animationOpacityStyle = useAnimatedStyle(() => ({
    opacity: withTiming(opacity.value, {
      duration: opacityTransitionTime,
      easing: Easing.inOut(Easing.quad),
    }),
  }))

  React.useEffect(
    () => () => {
      timeoutRef.current.forEach((timeout) => {
        clearTimeout(timeout)
      })
    },
    []
  )

  React.useEffect(() => {
    if (children !== null) {
      setChildrenToRender(children)
    }
  }, [children])

  React.useEffect(() => {
    if (isHidden && children === null) {
      setChildrenToRender(children)
    }
  }, [children, isHidden])

  React.useEffect(() => {
    if (children) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [children])

  React.useEffect(() => {
    if (prevShow !== show) {
      if (show) {
        setIsHidden(false)
        opacity.value = 1
        timeoutRef.current.push(
          setTimeout(() => {
            // show animation complete
            timeoutRef.current = []
          }, opacityTransitionTime)
        )
      } else {
        opacity.value = 0
        timeoutRef.current.push(setTimeout(() => {}, opacityTransitionTime))
      }
    }
  }, [prevShow, show, opacity, opacityTransitionTime])

  return (
    <Animated.View style={[animationOpacityStyle]}>
      {childrenToRender}
    </Animated.View>
  )
}
