import * as React from 'react'
import { StyleSheet } from 'react-native'
import { requests } from '@mv/api'
import { logger } from '../logger'
import { Modal, Heading } from './ModalElements'
import * as Form from '../components/form'
import { View } from '../components'
import { Button } from '../components/Button'
import { useThemeColors } from '../constants/colors'
import { FunctionsError, makeCallableFunction } from '../firebase/functions'
import { ERROR_OOPS } from '../constants/messages'
import { NoRefresh } from '../components/NoRefresh'
import { useSelector } from '../state/store'

type FormError = {
  currentPassword?: string
  newPassword?: string
  confirmPassword?: string
  generic?: string
  verificationCode?: string
}
export function AccountChangePasswordModal(): JSX.Element | null {
  const user = useSelector((state) => state.user.user)
  const [currentPassword, setCurrentPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [hideCurrentPassword, setHideCurrentPassword] = React.useState(true)
  const [hideNewPassword, setHideNewPassword] = React.useState(true)
  const [hideConfirmPassword, setHideConfirmPassword] = React.useState(true)
  const [formError, setFormError] = React.useState({} as FormError)
  const [completedSubmission, setCompletedSubmission] = React.useState(false)
  const [auth, setAuth] = React.useState<requests.account.TwoFactorAuth>({})

  const newPasswordRef = React.useRef<Form.TextInputRefType>(null)
  const confirmPasswordRef = React.useRef<Form.TextInputRefType>(null)
  const submitRef = React.useRef<Form.SubmitButtonRefType>(null)

  const color = useThemeColors().button

  if (!user) {
    return null
  }

  if (completedSubmission) {
    return (
      <Modal testID="account-change-password-completed">
        <Form.ConcludingMessage>
          Your password has been updated
        </Form.ConcludingMessage>
      </Modal>
    )
  }

  const showPasswordButtonColorStyles = color.formLabelAux.container
  const showPasswordButtonLabelColorStyles = color.formLabelAux.label

  return (
    <Modal testID="account-change-password">
      <Heading>Change Your Password</Heading>
      <NoRefresh>
        <Form.Item>
          <View style={styles.passwordLabelRow}>
            <Form.Label>Current Password</Form.Label>
            <Button
              label={
                hideCurrentPassword
                  ? 'show current password'
                  : 'hide current password'
              }
              style={[styles.showPasswordButton, showPasswordButtonColorStyles]}
              labelStyle={[
                styles.showPasswordButtonLabel,
                showPasswordButtonLabelColorStyles,
              ]}
              onPress={async () => {
                setHideCurrentPassword(!hideCurrentPassword)
              }}
            />
          </View>
          <Form.TextInput
            value={currentPassword}
            onChangeText={(textValue) => {
              setCurrentPassword(textValue)
            }}
            autoCompleteType="password"
            secureTextEntry={hideCurrentPassword}
            onKeyPress={(event) => {
              switch (event.nativeEvent.key) {
                case 'Enter':
                  if (
                    currentPassword &&
                    newPassword &&
                    confirmPassword &&
                    submitRef.current?.onPress
                  ) {
                    submitRef.current.onPress().catch((e) => {
                      logger.warn('unexpected promise rejection on submit', e)
                    })
                  } else {
                    newPasswordRef?.current?.focus()
                  }
                  break
                case 'Tab':
                  event.preventDefault()
                  newPasswordRef?.current?.focus()
                  break
                default:
              }
            }}
          />
          <Form.Error error={formError.currentPassword} />
        </Form.Item>
        <Form.Item>
          <View style={styles.passwordLabelRow}>
            <Form.Label>New Password</Form.Label>
            <Button
              label={
                hideNewPassword ? 'show new password' : 'hide new password'
              }
              style={[styles.showPasswordButton, showPasswordButtonColorStyles]}
              labelStyle={[
                styles.showPasswordButtonLabel,
                showPasswordButtonLabelColorStyles,
              ]}
              onPress={async () => {
                setHideNewPassword(!hideNewPassword)
              }}
            />
          </View>
          <Form.TextInput
            ref={newPasswordRef}
            value={newPassword}
            onChangeText={(textValue) => {
              setNewPassword(textValue)
            }}
            autoCompleteType="password"
            secureTextEntry={hideNewPassword}
            onKeyPress={(event) => {
              switch (event.nativeEvent.key) {
                case 'Enter':
                  if (
                    currentPassword &&
                    newPassword &&
                    confirmPassword &&
                    submitRef.current?.onPress
                  ) {
                    submitRef.current.onPress().catch((e) => {
                      logger.warn('unexpected promise rejection on submit', e)
                    })
                  } else {
                    confirmPasswordRef?.current?.focus()
                  }
                  break
                case 'Tab':
                  event.preventDefault()
                  confirmPasswordRef?.current?.focus()
                  break
                default:
              }
            }}
          />
          <Form.Error error={formError.newPassword} />
        </Form.Item>
        <Form.Item>
          <View style={styles.passwordLabelRow}>
            <Form.Label>Confirm New Password</Form.Label>
            <Button
              label={
                hideConfirmPassword ? 'show new password' : 'hide new password'
              }
              style={[styles.showPasswordButton, showPasswordButtonColorStyles]}
              labelStyle={[
                styles.showPasswordButtonLabel,
                showPasswordButtonLabelColorStyles,
              ]}
              onPress={async () => {
                setHideConfirmPassword(!hideConfirmPassword)
              }}
            />
          </View>
          <Form.TextInput
            ref={confirmPasswordRef}
            value={confirmPassword}
            onChangeText={(textValue) => {
              setConfirmPassword(textValue)
            }}
            autoCompleteType="password"
            secureTextEntry={hideConfirmPassword}
            onKeyPress={(event) => {
              if (
                event.nativeEvent.key === 'Enter' &&
                currentPassword &&
                newPassword &&
                confirmPassword &&
                submitRef.current?.onPress
              ) {
                submitRef.current?.onPress().catch((e) => {
                  logger.warn('unexpected promise rejection on submit', e)
                })
              }
            }}
          />
          <Form.Error error={formError.confirmPassword} />
        </Form.Item>
        <Form.TwoFactorStep
          setFormError={setFormError}
          setAuth={setAuth}
          errorMessage={formError.verificationCode}
        >
          <Form.SubmitButton
            ref={submitRef}
            label="Change Password"
            onPress={async () => {
              setFormError({})
              const result = await submitNewPassword(
                user.fuid,
                currentPassword,
                newPassword,
                confirmPassword,
                setFormError,
                auth
              )
              if (result) {
                return () => {
                  setCompletedSubmission(true)
                }
              }
              return false
            }}
          />
        </Form.TwoFactorStep>
        <Form.Error error={formError.generic} />
      </NoRefresh>
    </Modal>
  )
}
const styles = StyleSheet.create({
  passwordLabelRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  showPasswordButton: {
    borderRadius: 5,
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    top: -5,
  },
  showPasswordButtonLabel: {
    fontSize: 14,
    lineHeight: 22,
  },
})

async function submitNewPassword(
  fuid: string,
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
  setFormError: React.Dispatch<React.SetStateAction<FormError>>,
  auth: requests.account.TwoFactorAuth
): Promise<boolean> {
  if (!currentPassword || !newPassword) {
    const formErrors: FormError = {}
    if (!currentPassword) {
      formErrors.currentPassword = 'Please enter your current password.'
    }
    if (!newPassword) {
      formErrors.newPassword = 'Please enter your new password.'
    }
    setFormError(formErrors)
    return false
  }
  if (newPassword !== confirmPassword) {
    const formErrors: FormError = {
      confirmPassword: 'New passwords do not match.',
    }
    setFormError(formErrors)
    return false
  }

  const changePassword = makeCallableFunction('account-changePassword')

  try {
    await changePassword({
      id: fuid,
      currentPassword,
      newPassword,
      auth,
    })
  } catch (_e) {
    const e = _e as FunctionsError
    const errors: FormError = {}
    errors.verificationCode = Form.getTwoFactorFormError(
      e.code,
      e.message,
      auth
    )
    if (!errors.verificationCode) {
      switch (e.code) {
        case 'permission-denied':
          errors.currentPassword =
            'Current password did not match, please try again.'
          break
        case 'invalid-argument':
          errors.newPassword = 'Please enter a longer or stronger password.'
          break
        default:
          errors.generic = ERROR_OOPS
      }
    }
    setFormError(errors)
    return false
  }
  return true
}
