/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated'
import { Post, ReactionType, ForumRole } from '@mv/api/lib/src/schema/forums'
import { convertEthereumAddressToDisplayAddress } from '@mv/api/lib/src/schema/accounts'
import { View, Text } from '..'
import { DisplayAddress } from '../DisplayAddress'
import { logger } from '../../logger'
import { TimeAgo } from '../TimeAgo'
import { useSelector, useDispatch } from '../../state/store'
import { useAdmin } from '../../hooks/useAdmin'
import { useThemeColors } from '../../constants/colors'
import { PlanetChatPostActions } from './PlanetChatPostActions'
import { PlanetForumPostText } from './PlanetForumPostText'
import {
  PlanetForumPostTypingStatus,
  Props as PlanetForumPostTypingStatusProps,
} from './PlanetForumPostTypingStatus'
import { AnimateDownFadeIn } from '../animations/AnimateDownFadeIn'
import { cacheActions } from '../../state/cacheSlice'
import { stringifyFullPostId } from '../../state/cacheTypes'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { PlanetForumPostActions } from './PlanetForumPostActions'
import { PlanetChatPostActionsAdmin } from './PlanetChatPostActionsAdmin'
import { useTheme } from '../../hooks/useTheme'

const MAX_REPLY_DEPTH = 12

type PlanetForumPostProps = {
  post: Post
  userReactions?: Partial<Record<ReactionType, true>>
  maxReplyDepth?: number
  threadNode?: React.ReactNode
  firstLoadTime: Date
  onReply: (replyPost: Post) => void
}
function AlwaysPlanetChatPost({
  post,
  userReactions,
  maxReplyDepth = MAX_REPLY_DEPTH,
  threadNode,
  firstLoadTime,
  onReply,
}: PlanetForumPostProps): JSX.Element | null {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const referencedPostSummaryCache = useSelector(
    (state) =>
      state.cache.postSummaries[
        stringifyFullPostId({
          planetId: post.planet,
          topicId: post.topic,
          postId: post.referencedPostIds[0],
        })
      ]
  )
  const colors = useThemeColors()
  const { theme } = useTheme()

  const isPlanetAdmin = useAdmin('planetAdmin')
  const isPlanetChatAdmin = useAdmin('planetChatAdmin')
  const isMobile = useMobileLayout()

  const styles = themedStyles[theme]

  const [typingStatusLastTime, setTypingStatusLastTime] =
    React.useState<PlanetForumPostTypingStatusProps['lastTime']>(null)

  React.useEffect(() => {
    let typingStatusLastTimeValue: PlanetForumPostTypingStatusProps['lastTime']
    if (!post.lastReplyTypingAddress || !post.lastReplyTypingTime) {
      typingStatusLastTimeValue = null
    } else if (
      convertEthereumAddressToDisplayAddress(post.lastReplyTypingAddress) !==
      user.user?.account?.address
    ) {
      typingStatusLastTimeValue = post.lastReplyTypingTime?.toDate()
    }
    setTypingStatusLastTime(typingStatusLastTimeValue)
  }, [
    user.user?.account?.address,
    post.lastReplyTypingAddress,
    post.lastReplyTypingTime,
  ])

  React.useEffect(() => {
    if (post.referencedPostIds.length > 0) {
      // limiting to a single referenced post allows an easy optimization to
      // prevent rerenders by having the selector only update when the
      // referenced post updates
      const referencedPostId = post.referencedPostIds[0]
      if (!referencedPostSummaryCache) {
        dispatch(
          cacheActions.getPostSummary({
            fullPostId: {
              planetId: post.planet,
              topicId: post.topic,
              postId: referencedPostId,
            },
          })
        ).catch(() => {})
      }
    }
  }, [dispatch, post, referencedPostSummaryCache])

  const deletedColorStyle = { color: colors.medium }
  const showReplyButton = post.replyDepth < maxReplyDepth
  const showDeleteButton =
    convertEthereumAddressToDisplayAddress(post.authorAddress) ===
      user.user?.account?.address ||
    isPlanetAdmin ||
    isPlanetChatAdmin

  try {
    let postContentNode: React.ReactNode
    switch (post.bodyType) {
      case 'text':
        postContentNode = (
          <>
            <PlanetForumPostText
              testID="planetFormPostText"
              text={post._.body.text}
            />
            {isMobile && (
              <PlanetForumPostActions
                post={post}
                userReactions={userReactions}
                showReplyButton={showReplyButton}
                showDeleteButton={showDeleteButton}
                showReactionButtons
                onReply={() => {
                  onReply(post)
                }}
              />
            )}
            <PlanetChatPostActionsAdmin
              post={post}
              userReactions={userReactions}
            />
          </>
        )
        break
      case 'deleted':
        postContentNode = (
          <Text
            preset="paragraphSmall"
            style={[styles.deleted, deletedColorStyle]}
          >
            {'Deleted by '}
            <DisplayAddress
              address={post._.body.deleted.deleterAddress}
              textStyle={deletedColorStyle}
            />{' '}
            <TimeAgo
              style={[styles.deleted, deletedColorStyle]}
              date={post._.body.deleted.deletionTime.toDate()}
            />
          </Text>
        )
        break
      default:
        return null
    }

    // Prepare admin badge - show only one role based on priority order
    let adminRoleString = ''
    let adminRoleForDisplay: ForumRole | undefined
    const adminRolePriorityOrder: Record<ForumRole, number> = {
      founder: 1,
      multiverseAdmin: 2,
      forumModerator: 3,
      topicModerator: 4,
    }
    post.authorRoles?.forEach((role) => {
      if (!adminRoleForDisplay) {
        adminRoleForDisplay = role
      } else if (
        adminRolePriorityOrder[adminRoleForDisplay] >
        adminRolePriorityOrder[role]
      ) {
        adminRoleForDisplay = role
      }
    })
    switch (adminRoleForDisplay) {
      case 'founder':
        adminRoleString = 'Founder'
        break
      case 'multiverseAdmin':
        adminRoleString = 'Multiverse Admin'
        break
      case 'forumModerator':
        adminRoleString = 'Forum Moderator'
        break
      case 'topicModerator':
        adminRoleString = 'Moderator'
        break
      default:
        adminRoleString = ''
    }
    const adminBadge =
      adminRoleString === null ? null : (
        <Text
          testID="adminBadge"
          preset="paragraphXSmall"
          weight={theme === 'hadron' ? 'semiBold' : 'bold'}
          style={styles.admin}
        >
          {adminRoleString}
        </Text>
      )

    const referencedPostSummary = referencedPostSummaryCache?.data
    const referencedAuthorAddress = referencedPostSummary?.authorAddress || ''
    const inReplyToSummaryColorStyles = {
      color: colors.chat.inReplyTo.color,
      backgroundColor: colors.chat.inReplyTo.backgroundColor,
    }
    return (
      <AnimateDownFadeIn
        animate={post.postTime.toMillis() > firstLoadTime.getTime()}
      >
        <View style={styles.container}>
          {post.replyDepth > 0 && (
            <View style={styles.leftChannel}>
              <View style={[styles.threadHead]} />
              <View
                style={[
                  styles.threadLine,
                  { marginRight: 24 * post.replyDepth },
                ]}
              />
            </View>
          )}
          <BorderedPost
            timestampInMs={post.postTime.toMillis()}
            style={styles.containerMain}
          >
            <View style={styles.headingLine}>
              <Text
                weight={theme === 'hadron' ? 'extraLight' : undefined}
                style={styles.postedBy}
              >
                <DisplayAddress
                  address={post.authorAddress}
                  textPreset="paragraph"
                  textWeight="semiBold"
                />
                {adminBadge}
                <TimeAgo
                  textPreset="paragraphXSmall"
                  textWeight={theme === 'hadron' ? 'extraLight' : undefined}
                  style={[styles.postedByRight, styles.postedBy]}
                  date={post.postTime.toDate()}
                />
              </Text>
              {!isMobile && post.bodyType === 'text' && (
                <PlanetChatPostActions
                  post={post}
                  userReactions={userReactions}
                  showReplyButton={showReplyButton}
                  showDeleteButton={showDeleteButton}
                  showReactionButtons
                  onReply={() => {
                    onReply(post)
                  }}
                />
              )}
            </View>
            <View>
              {!!referencedAuthorAddress && (
                <View style={styles.inReplyToView}>
                  <Text preset="caption" style={styles.inReplyToText}>
                    Replying to{' '}
                  </Text>
                  {referencedAuthorAddress === '...' ? (
                    <Text preset="caption" style={styles.inReplyToText}>
                      {referencedAuthorAddress}
                    </Text>
                  ) : (
                    <DisplayAddress
                      address={referencedAuthorAddress}
                      textPreset="caption"
                      textWeight={theme === 'hadron' ? 'semiBold' : 'bold'}
                      textStyle={styles.inReplyToText}
                      spaceBetween={4}
                    />
                  )}
                  {!!referencedPostSummary?.text && (
                    <View style={styles.inReplyToSummaryView}>
                      <Text
                        preset="caption"
                        numberOfLines={1}
                        style={[
                          styles.inReplyToSummaryText,
                          inReplyToSummaryColorStyles,
                        ]}
                      >
                        {referencedPostSummary?.text}
                      </Text>
                    </View>
                  )}
                </View>
              )}
              <View style={styles.content}>{postContentNode}</View>
              <View style={threadNode ? styles.threadNode : undefined}>
                <PlanetForumPostTypingStatus
                  lastTime={typingStatusLastTime}
                  style={!threadNode ? styles.threadNode : undefined}
                />
              </View>
            </View>
          </BorderedPost>
        </View>
      </AnimateDownFadeIn>
    )
  } catch (e) {
    logger.error(`Encountered bad data rendering post ${post.postId}`, e)
  }
  return null
}
export const PlanetChatPost = React.memo(AlwaysPlanetChatPost)

const themedStyles = {
  hadron: StyleSheet.create({
    admin: {
      fontSize: 11,
      paddingLeft: 8,
    },
    container: {
      flexDirection: 'row',
    },
    containerMain: {
      borderRadius: 12,
      flex: 1,
      marginTop: 4,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 8,
    },
    content: {
      marginTop: 2,
    },
    deleted: {
      fontStyle: 'italic',
      paddingBottom: 4,
    },
    headingLine: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-between',
    },
    inReplyToSummaryText: {
      fontSize: 11,
      marginLeft: 4,
      paddingLeft: 4,
      paddingRight: 4,
    },
    inReplyToSummaryView: {
      flex: 1,
      flexDirection: 'row',
    },
    inReplyToText: {
      fontSize: 11,
    },
    inReplyToView: {
      flex: 1,
      flexDirection: 'row',
      marginTop: 2,
    },
    leftChannel: { alignItems: 'center' },
    postedBy: {
      fontSize: 11,
    },
    postedByRight: {
      fontSize: 11,
      paddingLeft: 8,
    },
    threadHead: {},
    threadLine: {
      flex: 1,
      marginRight: 24,
    },
    threadNode: {
      marginBottom: 0,
      marginTop: 24,
    },
  }),
  multiverse2022: StyleSheet.create({
    admin: {
      paddingLeft: 8,
    },
    container: {
      flexDirection: 'row',
    },
    containerMain: {
      borderRadius: 12,
      flex: 1,
      marginTop: 4,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 8,
    },
    content: {
      marginTop: 2,
    },
    deleted: {
      fontStyle: 'italic',
      paddingBottom: 4,
    },
    headingLine: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-between',
    },
    inReplyToSummaryText: {
      borderRadius: 2,
      marginLeft: 4,
      paddingLeft: 4,
      paddingRight: 4,
    },
    inReplyToSummaryView: {
      flex: 1,
      flexDirection: 'row',
    },
    inReplyToText: {},
    inReplyToView: {
      flex: 1,
      flexDirection: 'row',
      marginTop: 2,
    },
    leftChannel: { alignItems: 'center' },
    postedBy: {},
    postedByRight: {
      paddingLeft: 8,
    },
    threadHead: {},
    threadLine: {
      flex: 1,
      marginRight: 24,
    },
    threadNode: {
      marginBottom: 0,
      marginTop: 24,
    },
  }),
}

function BorderedPost({
  timestampInMs,
  style,
  children,
}: {
  timestampInMs: number
  style: StyleProp<ViewStyle>
  children: React.ReactNode
}): JSX.Element {
  const isRecent = new Date().getTime() - timestampInMs < 30 * 1000
  const colors = useThemeColors().chat
  const recentColor = useSharedValue(
    isRecent ? colors.recentHighlightOnColor : colors.recentHighlightOffColor
  )
  const animatedRecentColorStyle = useAnimatedStyle(() => ({
    backgroundColor: withTiming(recentColor.value, {
      duration: 10000,
      // easing: Easing.in(Easing.linear),
    }),
  }))
  const notRecentColorStyle = {
    backgroundColor: colors.recentHighlightOffColor,
  }

  React.useEffect(() => {
    if (isRecent) {
      recentColor.value = colors.recentHighlightOffColor
    }
  }, [recentColor, colors.recentHighlightOffColor, isRecent])

  if (isRecent) {
    return (
      <Animated.View style={[style, animatedRecentColorStyle]}>
        {children}
      </Animated.View>
    )
  }
  return <View style={[style, notRecentColorStyle]}>{children}</View>
}
