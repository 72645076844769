import * as React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import {
  Post,
  TopicId,
  PostId,
  ReactionType,
} from '@mv/api/lib/src/schema/forums'
import { planet } from '@mv/api/lib/src/types'
import { logger } from '../../logger'
import { Button } from '../Button'
import { FunctionsError, makeCallableFunction } from '../../firebase/functions'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../../constants/messages'
import { RootState } from '../../state/state'
import { useThemeColors } from '../../constants/colors'
import { Text, View } from '..'
import { VLogo } from '../svg/logos/VLogo'

type Props = {
  post: Post
  reaction: ReactionType
  userReacted: boolean
  user: RootState['user']
  testID?: string
  style?: StyleProp<ViewStyle>
}
export function PlanetForumPostActionsReactionButton({
  post,
  reaction,
  userReacted,
  user,
  testID,
  style = undefined,
}: Props): JSX.Element {
  const colors = useThemeColors()
  const [reacting, setReacting] = React.useState(false)

  const activeColorStyle = {
    color: colors.button.reaction.active,
  }
  const activeBorderColorStyle = {
    borderColor: colors.button.reaction.active,
  }
  const inactiveBorderColorStyle = {
    borderColor: colors.button.reaction.inactive,
  }

  const buttonOpacityStyle = {
    opacity: reacting ? 0.5 : 1,
  }

  let reactionText = <Text>{reaction}</Text>
  if (reaction === '👍') {
    reactionText = (
      <View style={styles.reactionView}>
        <VLogo
          height={10}
          style={{
            transform: [{ rotateZ: '180deg' }],
          }}
        />
      </View>
    )
  } else if (reaction === '👎') {
    reactionText = (
      <View style={styles.reactionView}>
        <VLogo height={10} />
      </View>
    )
  } else {
    reactionText = <Text style={styles.reactionText}>{reaction}</Text>
  }
  return (
    <Button
      testID={testID || `reaction-${reaction}`}
      onPress={async () => {
        setReacting(true)
        await reactToPost(
          post.planet,
          post.topic,
          post.postId,
          reaction,
          userReacted ? 'remove' : 'add',
          user,
          () => {}
        )
        setReacting(false)
      }}
    >
      <View
        style={[
          styles.containerReaction,
          style,
          buttonOpacityStyle,
          userReacted ? activeBorderColorStyle : inactiveBorderColorStyle,
        ]}
      >
        {reactionText}
        <Text
          style={[
            styles.actionsButtonLabel,
            userReacted ? activeColorStyle : undefined,
          ]}
        >
          {displayReactionCount(post.reactions?.[reaction])}
        </Text>
      </View>
    </Button>
  )
}

const styles = StyleSheet.create({
  actionsButtonLabel: {
    fontSize: 10,
    lineHeight: 10,
    paddingLeft: 8,
  },
  containerReaction: {
    alignItems: 'flex-end',
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: 'row',
    marginRight: 8,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
  },
  reactionText: {
    fontSize: 10,
    lineHeight: 10,
  },
  reactionView: { paddingBottom: 0, paddingTop: 0 },
})

async function reactToPost(
  planetId: planet.Id,
  topicId: TopicId,
  postId: PostId,
  reaction: ReactionType,
  action: 'add' | 'remove',
  user: RootState['user'],
  handleError: (errorMessage: string | null) => void
): Promise<boolean> {
  if (!user.user || !user.user.account) {
    handleError(ERROR_USER_NOT_LOGGED_IN)
    return false
  }

  const reactToPostFunction = makeCallableFunction('forums-reactToPost')
  try {
    await reactToPostFunction({
      id: user.user.fuid,
      planetId,
      topicId,
      postId,
      action,
      reaction,
    })
  } catch (e) {
    const error = e as FunctionsError
    logger.error('Failed to update reaction to planet forum post', error)
    handleError(ERROR_OOPS)
    return false
  }
  return true
}

function displayReactionCount(number?: number, decimalPlaces = 1): string {
  if (!number) {
    return '0'
  }
  if (number > 10 ** 6) {
    return `${
      Math.floor(number / 10 ** (6 - decimalPlaces)) / 10 ** decimalPlaces
    }M`
  }
  if (number > 10 ** 3) {
    return `${
      Math.floor(number / 10 ** (3 - decimalPlaces)) / 10 ** decimalPlaces
    }K`
  }
  return `${number}`
}
