import * as React from 'react'
import { StyleSheet } from 'react-native'
import { View, Text } from '..'

type Props = {
  text: string
  testID?: string
}
export function PlanetForumPostText({
  text,
  testID = undefined,
}: Props): JSX.Element {
  return (
    <View style={styles.view}>
      <Text testID={testID} preset="paragraphSmall" style={[styles.text]}>
        {text}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    borderRadius: 8,
    marginBottom: 2,
    marginTop: 2,
    paddingLeft: 6,
    paddingRight: 6,
  },
  view: {
    flexDirection: 'row',
    marginBottom: -2,
    marginLeft: -6,
    marginRight: -6,
    marginTop: -2,
  },
})
