import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { View } from '../../View'
import { Text } from '../../Text'

import { DEFAULT_THUMB_WIDTH } from './constants'

type Props = {
  displayValue?: string
  /** value between 0 and 1 which represents position on the slider */
  normalizedPosition: number
  containerStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  thumbWidth?: number
}
export function SliderAboveThumb({
  displayValue,
  normalizedPosition,
  containerStyle,
  textStyle,
  thumbWidth = DEFAULT_THUMB_WIDTH,
}: Props): JSX.Element {
  const [layoutWidth, setLayoutWidth] = React.useState(0)
  const computedContainerStyle = {
    right: normalizedPosition * (layoutWidth - thumbWidth),
  }
  return (
    <View
      style={[styles.container, computedContainerStyle, containerStyle]}
      onLayout={(event) => {
        setLayoutWidth(event.nativeEvent.layout.width)
      }}
    >
      {!!displayValue && (
        <Text weight="semiBold" style={[styles.text, textStyle]}>
          {displayValue}
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: -4,
  },
  text: {
    fontSize: 12,
  },
})
