import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { View } from '..'
import { testIdToNativeId } from '../../helpers/testId'

type Props = {
  style?: StyleProp<ViewStyle>
  children: React.ReactNode
  testID?: string
}
export function Item({ style, children, testID }: Props): JSX.Element {
  return (
    <View
      nativeID={testIdToNativeId(testID, 'form-item')}
      testID={testID}
      style={[styles.container, style]}
    >
      {children}
    </View>
  )
}
Item.defaultProps = {
  style: undefined,
}
const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
})
