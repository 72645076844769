import * as React from 'react'
import { StyleSheet } from 'react-native'
import * as staking from '@mv/api/lib/src/schema/staking'
import { Section, Text, View } from '..'
import { useThemeColors } from '../../constants/colors'
import { useSelector } from '../../state/store'
import { humanizeBalance } from '../../helpers'
import {
  db,
  doc,
  onSnapshot,
  DocumentReference,
} from '../../firebase/firestore'
import { Loading } from '../Loading'
import { MyWalletStakingSummaryEarlyUnstakeNotice } from './MyWalletStakingSummaryEarlyUnstakeNotice'
import { MyWalletStakingActionButtons } from './MyWalletStakingActionButtons'
import { firestore } from '../../firebase'

export function MyWalletStakingSummary(): JSX.Element | null {
  const user = useSelector((state) => state.user)
  const { wallet } = useThemeColors()
  const [stakeStatistics, setStakeStatistics] = React.useState<
    staking.StakeStatistics | null | undefined
  >(null)
  const [userStake, setUserStake] = React.useState<
    staking.Stake | null | undefined
  >(null)

  React.useEffect(() => {
    const stakeStatisticsDocRef = doc(
      db,
      staking.statisticsDocumentPath()
    ) as DocumentReference<staking.StakeStatistics>
    return onSnapshot(stakeStatisticsDocRef, (snap) => {
      setStakeStatistics(snap.data())
    })
  }, [])

  React.useEffect(() => {
    if (user.user?.fuid) {
      const userId = user.user.fuid
      const userStakeDocRef = doc(
        db,
        staking.documentPath(userId)
      ) as DocumentReference<staking.Stake>
      return onSnapshot(userStakeDocRef, (snap) => {
        if (snap.exists()) {
          setUserStake(snap.data())
        } else {
          setUserStake(emptyStakeDocument(userId))
        }
      })
    }
    return () => {}
  }, [user.user?.fuid])

  const balanceAI = user.user?.balances?.AI

  const balanceColorStyle = {
    color: wallet.balanceColor,
  }

  const userStakeDisplay = userStake ? (
    <View style={styles.stakingView}>
      <Text
        preset="paragraphXLarge"
        weight="bold"
        style={[styles.balance, { color: wallet.stakingBonus }]}
      >
        Your staked balance: {humanizeBalance(userStake.amount.units)}&nbsp;AI
      </Text>
      <Text
        preset="paragraphXLarge"
        weight="bold"
        style={[styles.balance, { color: wallet.stakingBonusHighlight }]}
      >
        Your staking rewards: {humanizeBalance(userStake.rewardAmount.units)}
        &nbsp;Tachyons
      </Text>
    </View>
  ) : (
    isLoadingDisplay(userStake)
  )

  return (
    <View style={styles.container}>
      <View style={[styles.balanceView]}>
        <Text
          preset="paragraphXLarge"
          weight="bold"
          style={[styles.balance, balanceColorStyle]}
        >
          Current available AI balance:{' '}
          {balanceAI === undefined ? (
            <Loading size="small" />
          ) : (
            <>{humanizeBalance(balanceAI._.available, 2)}&nbsp;AI</>
          )}
        </Text>
        <Text preset="paragraphXSmall" style={[styles.note]}>
          Balance does not reflect any pending incoming transactions
        </Text>
      </View>
      {userStakeDisplay}
      <MyWalletStakingSummaryEarlyUnstakeNotice
        earlyUnstakingFeeInBasisPoints={
          stakeStatistics?.earlyUnstakingFeeInBasisPoints
        }
        earlyUnstakingPeriod={stakeStatistics?.earlyUnstakingPeriod}
      />
      <Section heading="Multiverse Staking" containerStyle={styles.section}>
        <View>
          <Text preset="paragraph">
            Staking your AI into Multiverse earns you Tachyons over time. The
            longer you stake, the more you earn. The more you stake, the more
            you earn. In the near future, Tachyons can be used to support new
            planets, supercharge a project, and more. Start staking today to
            start earning today!
          </Text>
        </View>
      </Section>
      <MyWalletStakingActionButtons
        user={user}
        userAiAvailableAmount={balanceAI?._.available}
        userStake={userStake}
        stakingStatistics={stakeStatistics}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  balance: {},
  balanceView: {
    marginBottom: 24,
  },
  container: {
    marginBottom: 40 - 24,
  },
  note: {},
  section: {
    maxWidth: 700,
  },
  stakingView: {
    marginBottom: 24,
  },
})

function isLoadingDisplay(data: null | undefined): JSX.Element | null {
  if (data === undefined) {
    return <Loading size="small" />
  }
  return null
}

function emptyStakeDocument(accountId: string): staking.Stake {
  return {
    accountId,
    amount: {
      units: '0',
      decimalValue: '0',
    },
    rewardAmount: {
      units: '0',
      decimalValue: '0',
    },
    lastUpdateTime: firestore.Timestamp.now(),
    lastRewardTime: firestore.Timestamp.now(),
    rewardBonusMultiplier: 1,
    neverStakedBefore: true,
  }
}
