/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native'
import { Planet, planetsCollectionPath } from '@mv/api/lib/src/schema/planets'
import { logger } from '../../../logger'
import {
  PlanetsStackParamList,
  PlanetsStackNavigationProps,
} from '../../../navigation/types'
import { View, Text, Section } from '../..'
import { Screen, Heading } from '../../Screen'
import { SortBar } from '../../SortBar'
import {
  db,
  onSnapshot,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  startAt,
  endAt,
  limit,
  limitToLast,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '../../../firebase/firestore'
import { Loading } from '../../Loading'
import { useSelector } from '../../../state/store'
import { PlanetSummaryCard, THEMED_DIMENSIONS } from '../PlanetSummaryCard'
import { CreatePlanetButton } from '../CreatePlanetButton'
import { ListMyPlanetDraftsButton } from '../ListMyPlanetDraftsButton'
import { PaginationButtons } from '../../PaginationButtons'
import { useTheme } from '../../../hooks/useTheme'

const MAX_PAGE_SIZE = 10

export function ListPlanetProposalsScreen(): JSX.Element {
  const isMounted = React.useRef(true)

  const navigation = useNavigation<PlanetsStackNavigationProps>()
  const user = useSelector((state) => state.user.user)
  const { theme } = useTheme()

  const [pageSize, setPageSize] = React.useState(0)
  const [planets, setPlanets] = React.useState<Planet[] | undefined>(undefined)
  const [planetsDocSnapshots, setPlanetsDocSnapshots] = React.useState<
    QueryDocumentSnapshot[]
  >([])
  const [anchorPlanetDocSnapshot, setAnchorPlanetDocSnapshot] =
    React.useState<QueryDocumentSnapshot | null>(null)
  const [hasNext, setHasNext] = React.useState(false)
  const [hasPrev, setHasPrev] = React.useState(false)
  const [loadPage, setLoadPage] = React.useState<
    'next' | 'prev' | 'first' | null | undefined
  >(undefined)
  const [totalPlanetCardWidth, setTotalPlanetCardWidth] = React.useState(0)

  const unsubscribePlanetsRef = React.useRef<(() => void) | undefined>()

  const route = useRoute<RouteProp<PlanetsStackParamList, 'PlanetsList'>>()
  const listType = route.params?.type
  const sort = route.params?.sort

  // Cleans up / unsubscribes only when screen is unmounted
  React.useEffect(
    () => () => {
      isMounted.current = false
      if (unsubscribePlanetsRef.current) {
        unsubscribePlanetsRef.current()
      }
    },
    []
  )

  // on very first load, when pageSize has been set, kick off the first loadPage
  React.useEffect(() => {
    if (loadPage === undefined && pageSize > 0) {
      setLoadPage('first')
    }
  }, [pageSize, loadPage])

  React.useEffect(() => {
    if (loadPage) {
      setLoadPage(null)

      if (unsubscribePlanetsRef.current) {
        unsubscribePlanetsRef.current()
        unsubscribePlanetsRef.current = undefined
      }

      let planetQuery = query(collection(db, planetsCollectionPath))

      // filter
      if (listType === 'mine' && user) {
        planetQuery = query(planetQuery, where('founder', '==', user.fuid))
      }

      // sort
      switch (sort) {
        case 'created':
          planetQuery = query(planetQuery, orderBy('creationTime', 'desc'))
          break
        case 'created.asc':
          planetQuery = query(planetQuery, orderBy('creationTime', 'asc'))
          break
        case 'updated':
          planetQuery = query(planetQuery, orderBy('lastUpdateTime', 'desc'))
          break
        case 'updated.asc':
          planetQuery = query(planetQuery, orderBy('lastUpdateTime', 'asc'))
          break
        case 'pledgers':
          planetQuery = query(planetQuery, orderBy('numPledgers', 'desc'))
          break
        case 'pledgers.asc':
          planetQuery = query(planetQuery, orderBy('numPledgers', 'asc'))
          break
        case 'pledgeAmount.asc':
          planetQuery = query(
            planetQuery,
            orderBy('approximateTotalPledgeAmount', 'asc')
          )
          break
        default:
          planetQuery = query(
            planetQuery,
            orderBy('approximateTotalPledgeAmount', 'desc')
          )
      }

      if (loadPage === 'next' && anchorPlanetDocSnapshot) {
        planetQuery = query(
          planetQuery,
          startAt(anchorPlanetDocSnapshot),
          limit(pageSize + 2)
        )
      } else if (loadPage === 'prev' && anchorPlanetDocSnapshot) {
        planetQuery = query(
          planetQuery,
          endAt(anchorPlanetDocSnapshot),
          limitToLast(pageSize + 2)
        )
      } else {
        planetQuery = query(planetQuery, limit(pageSize + 1))
      }

      const onRender = (snapshot: QuerySnapshot) => {
        if (isMounted.current) {
          const doesHavePrev =
            loadPage === 'next' || snapshot.size === pageSize + 2
          const doesHaveNext =
            loadPage === 'prev' ||
            snapshot.size === pageSize + (loadPage === 'next' ? 2 : 1)
          const docs = snapshot.docs.slice(
            doesHavePrev ? 1 : 0,
            doesHaveNext ? -1 : undefined
          )
          setHasPrev(doesHavePrev)
          setHasNext(doesHaveNext)
          setPlanetsDocSnapshots(docs)
          setPlanets(docs.map((doc) => doc.data() as Planet))

          if (loadPage === 'prev' && !doesHavePrev) {
            setLoadPage('first') // Unpin and switch to the first page subscription.
          }
        }
      }
      const onError = (e: Error) => logger.warn('Failed to retreive planets', e)

      if (loadPage === 'first') {
        unsubscribePlanetsRef.current = onSnapshot(
          planetQuery,
          onRender,
          onError
        )
      } else {
        getDocs(planetQuery).then(onRender).catch(onError)
      }
    }
  }, [listType, user, sort, loadPage, anchorPlanetDocSnapshot, pageSize])

  const planetRender =
    (planets &&
      planets.map((planet) => {
        if (planet.symbol === 'AI') {
          return null
        }
        return <PlanetSummaryCard key={planet.id} planet={planet} />
      })) ||
    null

  const paginationContainerStyle = totalPlanetCardWidth
    ? {
        maxWidth: totalPlanetCardWidth,
      }
    : undefined

  const { PLANET_CARD_LEFT_RIGHT_MARGIN, PLANET_CARD_WIDTH } =
    THEMED_DIMENSIONS[theme]
  const styles = themedStyles[theme]

  return (
    <Screen
      testID="planet-list"
      onLayout={(event) => {
        const screenWidth = event.nativeEvent.layout.width
        const numCardsPerRow =
          Math.floor(
            (screenWidth + PLANET_CARD_LEFT_RIGHT_MARGIN * 2) /
              (PLANET_CARD_WIDTH + PLANET_CARD_LEFT_RIGHT_MARGIN * 2)
          ) || 1
        setPageSize(Math.floor(MAX_PAGE_SIZE / numCardsPerRow) * numCardsPerRow)
        setTotalPlanetCardWidth(
          numCardsPerRow *
            (PLANET_CARD_WIDTH + PLANET_CARD_LEFT_RIGHT_MARGIN * 2) -
            PLANET_CARD_LEFT_RIGHT_MARGIN * 2
        )
      }}
    >
      <Heading>Multiverse Planets</Heading>
      <Section>
        <Text preset="largeTextLink">New planets coming soon</Text>
      </Section>
      <View style={styles.actionButtons}>
        <CreatePlanetButton />
        <ListMyPlanetDraftsButton />
      </View>

      {false && !!planets && (
        <Section>
          <SortBar
            currentSortQuery={sort}
            navFunction={(
              screenName: string,
              params: Record<string, string>
            ) => {
              if (params.sort) {
                navigation.setParams({ ...params, page: undefined })
              } else {
                navigation.setParams({ sort: undefined, page: undefined })
              }
              setLoadPage('first')
            }}
            sorts={[
              {
                label: 'Total Pledged',
                ascQuery: 'pledgeAmount.asc',
                descQuery: '',
                defaultDirection: 'desc',
              },
              {
                label: 'Pledgers',
                ascQuery: 'pledgers.asc',
                descQuery: 'pledgers',
                defaultDirection: 'desc',
              },
              {
                label: 'Created',
                ascQuery: 'created.asc',
                descQuery: 'created',
                defaultDirection: 'desc',
              },
              {
                label: 'Updated',
                ascQuery: 'updated.asc',
                descQuery: 'updated',
                defaultDirection: 'desc',
              },
            ]}
          />
          <View style={styles.container}>{planetRender}</View>
          <PaginationButtons
            containerStyle={paginationContainerStyle}
            showPrev={hasPrev}
            showNext={hasNext}
            prevOnPress={async () => {
              setAnchorPlanetDocSnapshot(planetsDocSnapshots[0])
              setLoadPage('prev')
            }}
            nextOnPress={async () => {
              setAnchorPlanetDocSnapshot(
                planetsDocSnapshots[planetsDocSnapshots.length - 1]
              )
              setLoadPage('next')
            }}
          />
        </Section>
      )}
      {!planets && <Loading />}
    </Screen>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    actionButtons: {
      flexDirection: 'row',
    },
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginLeft: -THEMED_DIMENSIONS.hadron.PLANET_CARD_LEFT_RIGHT_MARGIN,
      marginRight: -THEMED_DIMENSIONS.hadron.PLANET_CARD_LEFT_RIGHT_MARGIN,
    },
  }),
  multiverse2022: StyleSheet.create({
    actionButtons: {
      flexDirection: 'row',
    },
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginLeft:
        -THEMED_DIMENSIONS.multiverse2022.PLANET_CARD_LEFT_RIGHT_MARGIN,
      marginRight:
        -THEMED_DIMENSIONS.multiverse2022.PLANET_CARD_LEFT_RIGHT_MARGIN,
    },
  }),
}
