import { useEffect, useState } from 'react'
import { logger } from '../logger'
import { Build } from '../build'
import { notificationsActions } from '../state/notificationsSlice'
import { buildActions } from '../state/buildSlice'
import { useSelector, useDispatch } from '../state/store'

const MINUTES = 60 * 1000

type Props = {
  recheckInterval?: number // prop for testing
}
export const BuildVersionChecker = ({
  recheckInterval = 5 * MINUTES,
}: Props): null => {
  const build = useSelector((state) => state.build)
  const dispatch = useDispatch()

  const [lastCheckTime, setLastCheckTime] = useState(new Date())
  // Stores the current version in the redux state
  useEffect(() => {
    dispatch(buildActions.setCurrentBuild(Build.currentVersion()))
  }, [dispatch])

  // Schedules a check for the latest build every 5 minutes
  useEffect(() => {
    const buildTimer = setTimeout(() => {
      setLastCheckTime(new Date())
    }, recheckInterval)

    Build.latest()
      .then((value) => {
        dispatch(buildActions.setLatestBuild(value.version))
      })
      .catch((e) => logger.error('Unable to determine latest build version', e))

    // Return function to call clearTimeout to run in the event of an unmount
    return () => {
      clearTimeout(buildTimer)
    }
  }, [dispatch, recheckInterval, lastCheckTime])

  // Adds a notification once latestVersion has been updated
  useEffect(() => {
    if (
      build.latestBuild &&
      build.currentBuild &&
      build.latestBuild !== build.currentBuild
    ) {
      if (build.noRefresh) {
        dispatch(
          notificationsActions.addNotification({
            id: 'newVersion',
            label:
              'New version now available. Please hit refresh when convenient.',
          })
        )
      } else {
        reload()
      }
    }
  }, [dispatch, build.latestBuild, build.currentBuild, build.noRefresh])

  return null
}

function reload() {
  if (window) {
    // force reload (window.location.reload(true)) is deprecated but with some browsers
    // window.location.reload() is insufficient, so keeping this here for now to be
    // replaced with a better solution later
    window.location.reload(true)
  }
}
