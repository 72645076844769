import * as React from 'react'
import { StyleSheet } from 'react-native'
import { unrollSchedulesToArray } from '@mv/api/lib/src/schema/accounts/locks'
import { Schedule } from './types'
import { View, Text } from '..'
import { humanizeBalance } from '../../helpers'
import { Button } from '../Button'
import { MyWalletBalanceLockedSchedule } from './MyWalletBalanceLockedSchedule'
import { VLogo } from '../svg/logos/VLogo'
import { useThemeColors } from '../../constants/colors'
import { AnimateDownFadeIn } from '../animations/AnimateDownFadeIn'

type Props = {
  schedules: Schedule[]
}

export function MyWalletBalanceLockedSchedules({
  schedules,
}: Props): JSX.Element | null {
  if (schedules.length === 0) {
    return null
  }
  const renderItems = schedules.map((schedule) => (
    <MyWalletBalanceLockedScheduleRow key={schedule.key} schedule={schedule} />
  ))
  return <View style={[styles.container]}>{renderItems}</View>
}

type RowProps = {
  schedule: Schedule
}
function MyWalletBalanceLockedScheduleRow({
  schedule,
}: RowProps): JSX.Element | null {
  const [showSchedule, setShowSchedule] = React.useState<boolean>(false)
  const lockedTokenColors = useThemeColors().lockedTokens

  const unlockEvents = unrollSchedulesToArray([schedule])
  if (!unlockEvents[0]) {
    return null
  }

  const detailsTitleColorStyle = {
    color: lockedTokenColors.detailsTitleColor,
  }
  const detailsHeadingColorStyle = {
    color: lockedTokenColors.detailsHeadingColor,
  }
  const detailsColorStyle = {
    color: lockedTokenColors.detailsColor,
  }
  const showScheduleIconRotationStyle = showSchedule
    ? undefined
    : {
        transform: [{ rotateZ: `90deg` }],
      }
  const borderColorStyle = {
    borderColor: lockedTokenColors.borderColor,
  }
  const scheduleBackgroundColorStyle = {
    backgroundColor: lockedTokenColors.scheduleBackgroundColor,
  }

  const nextUnlockAmount = humanizeBalance(unlockEvents[0].unlockAmount)
  const nextUnlockDate = unlockEvents[0].unlockTimestamp.toLocaleString(
    undefined,
    {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }
  )
  return (
    <View style={[styles.containerSchedule, borderColorStyle]}>
      <View style={styles.lockedSummaryView}>
        <Text
          weight="medium"
          style={[styles.lockedDescriptionText, detailsTitleColorStyle]}
        >
          {schedule._.description}
        </Text>
        <View style={styles.lockedSummaryNextView}>
          <View style={styles.lockedSummaryNextBlockView}>
            <Text
              weight="semiBold"
              style={[
                styles.lockedSummaryNextBlockHeaderText,
                detailsHeadingColorStyle,
              ]}
            >
              Remaining Locked
            </Text>
            <Text
              style={[
                styles.lockedSummaryNextBlockValueText,
                detailsColorStyle,
              ]}
            >
              {humanizeBalance(schedule._.lockedAmount)} AI
            </Text>
          </View>
          <View style={styles.lockedSummaryNextBlockView}>
            <Text
              weight="semiBold"
              style={[
                styles.lockedSummaryNextBlockHeaderText,
                detailsHeadingColorStyle,
              ]}
            >
              Next unlock
            </Text>
            <Text
              style={[
                styles.lockedSummaryNextBlockValueText,
                detailsColorStyle,
              ]}
            >
              {nextUnlockAmount} AI on {nextUnlockDate}
            </Text>
          </View>
          <View style={[styles.showScheduleIcon]}>
            <Button
              onPress={async () => {
                setShowSchedule(!showSchedule)
              }}
            >
              <View style={showScheduleIconRotationStyle}>
                <VLogo height={12} />
              </View>
            </Button>
          </View>
        </View>
      </View>
      <AnimateDownFadeIn>
        {showSchedule ? (
          <View style={[styles.schedule, scheduleBackgroundColorStyle]}>
            <MyWalletBalanceLockedSchedule schedules={[schedule]} />
          </View>
        ) : null}
      </AnimateDownFadeIn>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerSchedule: {
    borderTopWidth: 1,
  },
  lockedDescriptionText: {
    fontSize: 14,
  },
  lockedSummaryNextBlockHeaderText: {
    fontSize: 11,
    textTransform: 'uppercase',
  },
  lockedSummaryNextBlockValueText: {
    fontSize: 12,
  },
  lockedSummaryNextBlockView: {
    marginRight: 40,
  },
  lockedSummaryNextView: {
    flexDirection: 'row',
    marginTop: 24,
  },
  lockedSummaryView: {
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 16,
  },
  schedule: {
    flex: 1,
    paddingLeft: 32,
    paddingRight: 32,
  },
  showScheduleIcon: {
    alignItems: 'flex-end',
    flex: 1,
    flexDirection: 'column',
    paddingRight: 10,
  },
})
