import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { Svg, G, Path } from 'react-native-svg'

type Props = {
  style?: StyleProp<ViewStyle>
  color?: string
}

/**
 * Renders an SVG close X
 */
export function CloseX({ style, color }: Props): JSX.Element {
  return (
    <Svg viewBox="0 0 6.744 6.75" style={style} fill={color}>
      <G id="close-x">
        <Path d="M3.4 2.8L6.1 0l0.6 0.6 -2.8 2.8 2.8 2.8L6.1 6.8l-2.8-2.8 -2.8 2.8L0 6.1l2.8-2.8L0 0.6 0.6 0 3.4 2.8z" />
      </G>
    </Svg>
  )
}
CloseX.defaultProps = {
  style: { height: 18, width: 18 },
  color: undefined,
}
