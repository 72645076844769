import * as React from 'react'
import { StyleSheet } from 'react-native'
import { PlanetProposal } from '@mv/api/lib/src/schema/planets'
import { Text } from '../../..'
import { TabView } from '../../../TabView'

type Props = {
  planet: PlanetProposal
}

export function AnnouncementsTabDisplay({ planet }: Props): JSX.Element {
  return (
    <TabView>
      <Text style={styles.container}>
        {planet._.details.description || 'No current announcements'}
      </Text>
    </TabView>
  )
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 800,
  },
})
