import {
  Account,
  LocationsDoc,
  balances,
  userProfile,
} from '@mv/api/lib/src/schema/accounts'
import { User as FirebaseUser } from '../firebase/auth'

export type UserState = {
  ready: boolean
  loggedIn: boolean
  user: User | null
  geoAllowed: boolean
}
export type User = {
  fuid: string
  firebaseUser: FirebaseUser
  balances?: balances.BalancesCollection
  unsubscribeBalances?: () => void
  account?: Account
  claims: UserClaims
  profile?: userProfile.UserProfileVisible
  locations?: LocationsDoc
  locationsTimeout?: NodeJS.Timeout
}
export type UserClaims = {
  /**
   * prodDebug is superAdmin
   */
  prodDebug?: boolean
}

export function initialState(): UserState {
  return {
    ready: false,
    loggedIn: false,
    user: null,
    geoAllowed: true,
  }
}
