/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated'
import { PostId, Post } from '@mv/api/lib/src/schema/forums'
import { View, Text } from '..'
import { UserReactionLookup } from './planetForumTypes'
import { PlanetChatPost } from './PlanetChatPost'
import { AnimateDownFadeIn } from '../animations/AnimateDownFadeIn'
import { AnimateFadeIn } from '../animations/AnimateFadeIn'
import { PlanetForumPostTypingStatus } from './PlanetForumPostTypingStatus'
import { Button } from '../Button'
import { useThemeColors } from '../../constants/colors'
import { Loading } from '../Loading'
import { useDispatch, useSelector } from '../../state/store'
import { contextMenuActions } from '../../state/contextMenuSlice'
import { useTheme } from '../../hooks/useTheme'

const DEFAULT_OPACITY_TRANSITION_TIME = 250
const CONTAINER_PADDING_BOTTOM = 8
const TRIGGER_SHOW_JUMP_TO_NEWEST = 150

type Props = {
  postIds: PostId[]
  postsRecord: Record<PostId, Post>
  userReactions: UserReactionLookup
  maxReplyDepth?: number
  firstLoadTime: Date
  onLoadMore?: () => void
  onReply: (replyPost: Post) => void
  typingStatusLastTime?: Date | null
  updating: boolean
}
export function PlanetChatScrollView({
  postIds,
  postsRecord,
  userReactions,
  maxReplyDepth,
  firstLoadTime,
  onLoadMore = undefined,
  onReply,
  typingStatusLastTime = null,
  updating,
}: Props): JSX.Element | null {
  const dispatch = useDispatch()
  const contextMenuShowing = useSelector(
    (state) =>
      state.contextMenu.show &&
      state.contextMenu.contextMenu.parentUID !== 'chat-topic-tab-more'
  )
  const chatColors = useThemeColors().chat
  const { theme } = useTheme()
  const containerHeight = React.useRef<number>(0)
  const contentHeight = React.useRef<number>(0)
  const scrollViewRef = React.useRef<ScrollView>(null)
  const alwaysShowNewest = React.useRef<boolean>(true)
  const scrollY = React.useRef<number>(0)
  const [initialLoadCompleted, setInitialLoadCompleted] =
    React.useState<boolean>(false)
  const [showJumpToNewest, setShowJumpToNewest] = React.useState<boolean>(false)
  const scrollviewMarginTop = React.useRef<number>(0)
  const [forceRerender, setForceRerender] = React.useState<boolean>(true)

  const colors = useThemeColors()

  const opacity = useSharedValue(0)
  const animationOpacityStyle = useAnimatedStyle(() => ({
    opacity: withTiming(opacity.value, {
      duration: DEFAULT_OPACITY_TRANSITION_TIME,
      easing: Easing.inOut(Easing.quad),
    }),
  }))

  React.useEffect(() => {
    if (initialLoadCompleted) {
      opacity.value = 1
    }
  }, [opacity, initialLoadCompleted])

  const renderedPostsArray = postIds.map((postId) => {
    if (postsRecord[postId]) {
      return (
        <PlanetChatPost
          key={postId}
          post={postsRecord[postId]}
          userReactions={userReactions[postId]}
          maxReplyDepth={maxReplyDepth}
          threadNode={null}
          firstLoadTime={firstLoadTime}
          onReply={onReply}
        />
      )
    }
    return null
  })

  const chatRoomColorStyles = {
    backgroundColor: chatColors.background,
  }
  const chatRoomContainerStyles = {
    borderColor: chatColors.borderColor,
    marginTop: scrollviewMarginTop.current,
  }
  const styles = themedStyles[theme]

  return (
    <>
      <View style={styles.showLoadingOverlay}>
        <AnimateFadeIn>
          {updating ? (
            <View style={styles.showLoadingContainer}>
              <Loading size="small" />
            </View>
          ) : null}
        </AnimateFadeIn>
      </View>

      <ScrollView
        ref={scrollViewRef}
        style={[styles.container, chatRoomContainerStyles]}
        contentContainerStyle={[styles.containerContent, chatRoomColorStyles]}
        onScroll={(event) => {
          if (contextMenuShowing) {
            dispatch(contextMenuActions.hideContextMenu())
          }
          contentHeight.current = event.nativeEvent.contentSize.height
          scrollY.current = event.nativeEvent.contentOffset.y
          // If scrolled position is within 10px of the bottom, alwaysShowNewest
          if (
            event.nativeEvent.contentSize.height -
              (event.nativeEvent.contentOffset.y +
                event.nativeEvent.layoutMeasurement.height) <
            10
          ) {
            alwaysShowNewest.current = true
          } else {
            alwaysShowNewest.current = false
          }
          if (
            event.nativeEvent.contentOffset.y <
              Math.max(300, containerHeight.current || 600 / 2) &&
            onLoadMore
          ) {
            onLoadMore()
          }

          // show or hide Jump to Newest
          if (
            initialLoadCompleted &&
            !showJumpToNewest &&
            contentHeight.current -
              (scrollY.current + containerHeight.current) >
              TRIGGER_SHOW_JUMP_TO_NEWEST
          ) {
            setShowJumpToNewest(true)
          } else if (
            initialLoadCompleted &&
            showJumpToNewest &&
            contentHeight.current -
              (scrollY.current + containerHeight.current) <
              TRIGGER_SHOW_JUMP_TO_NEWEST / 2
          ) {
            setShowJumpToNewest(false)
          }
        }}
        scrollEventThrottle={25}
        onLayout={(nativeEvent) => {
          if (contextMenuShowing) {
            dispatch(contextMenuActions.hideContextMenu())
          }
          containerHeight.current = nativeEvent.nativeEvent.layout.height

          if (contentHeight.current) {
            if (
              contentHeight.current <
              containerHeight.current + scrollviewMarginTop.current
            ) {
              const newScrollviewMarginTop =
                containerHeight.current +
                scrollviewMarginTop.current -
                contentHeight.current
              if (
                Math.abs(scrollviewMarginTop.current - newScrollviewMarginTop) >
                1
              ) {
                // only update scroll view margin top if change is larger than 1px
                scrollviewMarginTop.current = newScrollviewMarginTop
                setForceRerender(!forceRerender)
              }
            } else if (
              contentHeight.current >=
                containerHeight.current + scrollviewMarginTop.current &&
              scrollviewMarginTop.current !== 0
            ) {
              scrollviewMarginTop.current = 0
              setForceRerender(!forceRerender)
            }
          }
          if (
            !initialLoadCompleted &&
            containerHeight.current &&
            contentHeight.current
          ) {
            if (
              contentHeight.current < containerHeight.current + 10 &&
              onLoadMore
            ) {
              onLoadMore()
            } else {
              setInitialLoadCompleted(true)
            }
          }
        }}
      >
        <Animated.View
          style={[styles.postsView, animationOpacityStyle]}
          onLayout={(event) => {
            if (contextMenuShowing) {
              dispatch(contextMenuActions.hideContextMenu())
            }
            if (alwaysShowNewest.current === true || !initialLoadCompleted) {
              scrollViewRef.current?.scrollTo({
                y: Math.max(
                  event.nativeEvent.layout.height,
                  containerHeight.current || 0
                ),
                animated: false,
              })
            } else if (scrollY.current === 0) {
              // when scrolled to the top , the default behavior is to not anchor to
              // the content - jump to the right spot instead
              scrollViewRef.current?.scrollTo({
                y: event.nativeEvent.layout.height - contentHeight.current,
                animated: false,
              })
              // scrollY.current =
              //   event.nativeEvent.layout.height - contentHeight.current
            }
            contentHeight.current =
              event.nativeEvent.layout.height + CONTAINER_PADDING_BOTTOM

            if (containerHeight.current) {
              if (
                contentHeight.current <
                containerHeight.current + scrollviewMarginTop.current
              ) {
                const newScrollviewMarginTop =
                  containerHeight.current +
                  scrollviewMarginTop.current -
                  contentHeight.current
                if (
                  Math.abs(
                    scrollviewMarginTop.current - newScrollviewMarginTop
                  ) > 1
                ) {
                  // only update scroll view margin top if change is larger than 1px
                  scrollviewMarginTop.current = newScrollviewMarginTop
                  setForceRerender(!forceRerender)
                }
              } else if (
                contentHeight.current >=
                  containerHeight.current + scrollviewMarginTop.current &&
                scrollviewMarginTop.current !== 0
              ) {
                scrollviewMarginTop.current = 0
                setForceRerender(!forceRerender)
              }
            }

            if (
              !initialLoadCompleted &&
              containerHeight.current &&
              contentHeight.current
            ) {
              if (
                contentHeight.current <= containerHeight.current + 10 &&
                onLoadMore
              ) {
                onLoadMore()
              } else if (contentHeight.current > containerHeight.current) {
                alwaysShowNewest.current = true
                setInitialLoadCompleted(true)
              }
            }
          }}
        >
          <AnimateDownFadeIn>
            <PlanetForumPostTypingStatus lastTime={typingStatusLastTime} />
          </AnimateDownFadeIn>
          {renderedPostsArray.length > 0 ? (
            renderedPostsArray
          ) : (
            <Text>Say something here...</Text>
          )}
        </Animated.View>
      </ScrollView>
      <AnimateFadeIn>
        {showJumpToNewest ? (
          <View style={styles.jumpToNewestContainer}>
            <Button
              onPress={async () => {
                scrollViewRef.current?.scrollToEnd()
              }}
            >
              <View
                style={[
                  styles.jumpToNewestBubble,
                  {
                    backgroundColor:
                      colors.button.primary.container.backgroundColor,
                  },
                ]}
              >
                <Text weight="bold" style={styles.jumpToNewestText}>
                  ⇣
                </Text>
              </View>
            </Button>
          </View>
        ) : null}
      </AnimateFadeIn>
    </>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    container: {},
    containerContent: {
      justifyContent: 'flex-end',
      paddingBottom: CONTAINER_PADDING_BOTTOM,
    },
    jumpToNewestBubble: {
      alignItems: 'center',
      borderRadius: 50,
      height: 50,
      justifyContent: 'center',
      width: 50,
    },
    jumpToNewestContainer: { bottom: 10, position: 'absolute', right: 10 },
    jumpToNewestText: { color: '#FFFFFF', fontSize: 30 },
    postsView: { flexDirection: 'column-reverse' },
    showLoadingContainer: { left: 0, position: 'absolute', right: 0, top: 10 },
    showLoadingOverlay: { zIndex: 1 },
  }),
  multiverse2022: StyleSheet.create({
    container: {
      borderRadius: 16,
      borderWidth: 1,
    },
    containerContent: {
      justifyContent: 'flex-end',
      paddingBottom: CONTAINER_PADDING_BOTTOM,
    },
    jumpToNewestBubble: {
      alignItems: 'center',
      borderRadius: 50,
      height: 50,
      justifyContent: 'center',
      width: 50,
    },
    jumpToNewestContainer: { bottom: 10, position: 'absolute', right: 10 },
    jumpToNewestText: { color: '#FFFFFF', fontSize: 30 },
    postsView: { flexDirection: 'column-reverse' },
    showLoadingContainer: { left: 0, position: 'absolute', right: 0, top: 10 },
    showLoadingOverlay: { zIndex: 1 },
  }),
}
