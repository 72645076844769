/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  Balance,
  BalancesCollection,
  collectionPath as balancesCollectionPath,
} from '@mv/api/lib/src/schema/accounts/balances'
import {
  Account,
  LocationsDoc,
  accountPath,
  locationsDocPath,
  convertEthereumAddressToDisplayAddress,
} from '@mv/api/lib/src/schema/accounts'
import {
  UserProfileVisible,
  userProfileVisiblePath,
} from '@mv/api/lib/src/schema/accounts/userProfile'
import { balance } from '@mv/api/lib/src/types'
import { geoip } from '@mv/api'
import { logger, stackdriver } from '../logger'
import {
  db,
  doc,
  collection,
  getDoc,
  onSnapshot,
  Timestamp,
  QuerySnapshot,
  DocumentReference,
} from '../firebase/firestore'
import { setUserId, analytics } from '../firebase/analytics'
import { makeCallableFunction } from '../firebase/functions'

import { RootState } from './state'
import { User, UserClaims, initialState } from './userTypes'

const zeroBalances = {
  AI: {
    tokenId: 'AI',
    timestamp: Timestamp.fromMillis(Date.now()),
    approximate: {
      available: 0,
      total: 0,
    },
    _: balance.zero(),
  },
}

const thunks = {
  login: createAsyncThunk<void, User>(
    'user/login',
    async (user, { dispatch }) => {
      setUserId(analytics(), user.fuid)
      stackdriver?.setUser(user.fuid)

      // subscribe to balances
      const balanceCollectionRef = collection(
        db,
        balancesCollectionPath(user.fuid)
      )
      const unsubscribeBalances = onSnapshot(
        balanceCollectionRef,
        (snapshot) => {
          const querySnapshot = snapshot as QuerySnapshot<Balance>
          if (querySnapshot.empty) {
            dispatch(userSlice.actions._updateBalances(zeroBalances))
          } else {
            const balances: BalancesCollection = {}
            querySnapshot.forEach((result) => {
              const { id } = result
              balances[id] = result.data()
            })
            dispatch(userSlice.actions._updateBalances(balances))
          }
        }
      )

      dispatch(
        userSlice.actions._loginUser({
          ...user,
          unsubscribeBalances,
        })
      )

      await dispatch(thunks.callAccountEnsure())

      await Promise.allSettled([
        dispatch(thunks.loadUserClaims()).catch((error) => logger.error(error)),
        dispatch(thunks.loadUserAccount()).catch((error) =>
          logger.error(error)
        ),
        dispatch(thunks.loadUserProfile()).catch((error) =>
          logger.error(error)
        ),
        dispatch(thunks.callAccountAnalyticsAndLoadUserLocations()).catch(
          (error) => logger.error(error)
        ),
      ])
    }
  ),
  logout: createAsyncThunk<void, void>(
    'user/logout',
    async (_args, { dispatch, getState }) => {
      const userState = (getState() as RootState).user
      if (userState.user?.unsubscribeBalances) {
        userState.user.unsubscribeBalances()
      }
      if (userState.user?.locationsTimeout) {
        clearTimeout(userState.user.locationsTimeout)
      }
      dispatch(userSlice.actions._logout())
    }
  ),
  loadUserClaims: createAsyncThunk<void, void>(
    'user/loadUserClaims',
    async (_args, { dispatch, getState }) => {
      const userState = (getState() as RootState).user
      userState.user?.firebaseUser
        .getIdTokenResult(true)
        .then((idTokenResult) => {
          dispatch(
            userSlice.actions._setUserClaims(idTokenResult.claims as UserClaims)
          )
        })
        .catch((e) =>
          logger.warn('Error encountered when retrieving user id token', e)
        )
    }
  ),
  loadUserAccount: createAsyncThunk<void, void>(
    'user/loadUserAccount',
    async (_args, { dispatch, getState }) => {
      const userState = (getState() as RootState).user
      if (userState.user) {
        const accountDocRef = doc(
          db,
          accountPath(userState.user.fuid)
        ) as DocumentReference<Account>
        const accountSnapshot = await getDoc(accountDocRef)
        const account = accountSnapshot.data()
        if (account) {
          dispatch(userSlice.actions._setUserAccount(account))
        }
      }
    }
  ),
  loadUserProfile: createAsyncThunk<void, void>(
    'user/loadUserProfile',
    async (_args, { dispatch, getState }) => {
      const userState = (getState() as RootState).user
      if (userState.user) {
        const userProfileDocRef = doc(
          db,
          userProfileVisiblePath(userState.user.fuid)
        ) as DocumentReference<UserProfileVisible>
        const userProfileSnapshot = await getDoc(userProfileDocRef)
        const userProfile = userProfileSnapshot.data()
        if (userProfile) {
          dispatch(userSlice.actions._setUserProfile(userProfile))
        }
      }
    }
  ),
  callAccountEnsure: createAsyncThunk<void, void>(
    'user/callAccountEnsure',
    async (_args, { getState }) => {
      const userState = (getState() as RootState).user
      if (userState.user) {
        const id = userState.user.fuid
        const accountEnsureFunction = makeCallableFunction('account-ensure')
        await accountEnsureFunction({ id }).catch((error) => {
          logger.error(`account ensure failed for ${id}`, error)
        })
      }
    }
  ),
  callAccountAnalyticsAndLoadUserLocations: createAsyncThunk<void, void>(
    'user/callAccountAnalyticsAndLoadUserLocations',
    async (_args, { dispatch, getState }) => {
      const userState = (getState() as RootState).user
      if (userState.user) {
        const id = userState.user.fuid
        const analyticsLocation = makeCallableFunction('account-analytics')
        await analyticsLocation({ id }).catch((error) => {
          logger.error(`analytics failed for ${id}`, error)
        })
        await dispatch(thunks.loadUserLocations())
      }
    }
  ),
  loadUserLocations: createAsyncThunk<void, void>(
    'user/loadUserLocations',
    async (_args, { dispatch, getState }) => {
      const userState = (getState() as RootState).user
      const userLocationsDocRef = doc(
        db,
        locationsDocPath(`${userState.user?.fuid}`)
      ) as DocumentReference<LocationsDoc>
      const userLocationsDoc = await getDoc(userLocationsDocRef)
      if (userState.user?.locationsTimeout) {
        clearTimeout(userState.user.locationsTimeout)
      }
      const timeoutID = setTimeout(async () => {
        await dispatch(thunks.loadUserLocations())
      }, 300000)
      dispatch(
        userSlice.actions._setUserLocations({
          locations: userLocationsDoc.data() ?? { locations: {} },
          timeoutID,
        })
      )
    }
  ),
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState(),
  reducers: {
    _loginUser(state, action: PayloadAction<User>) {
      state.ready = true
      state.loggedIn = true
      state.user = action.payload
    },
    _logout(state) {
      state.ready = true
      state.loggedIn = false
      state.user = null
    },
    _updateBalances(state, action: PayloadAction<BalancesCollection>) {
      if (state.user) {
        state.user.balances = action.payload
      }
    },
    _setUserClaims(state, action: PayloadAction<UserClaims>) {
      if (state.user) {
        state.user.claims = action.payload
      }
    },
    _setUserAccount(state, action: PayloadAction<Account>) {
      if (state.user) {
        state.user.account = {
          ...action.payload,
          address: convertEthereumAddressToDisplayAddress(
            action.payload.address ?? ''
          ),
        }
        state.geoAllowed = geoip.isLocationAllowed(
          state.user.locations,
          state.user.account
        )
      }
    },
    _setUserProfile(state, action: PayloadAction<UserProfileVisible>) {
      if (state.user) {
        state.user.profile = action.payload
      }
    },
    _setUserLocations(
      state,
      action: PayloadAction<{
        locations: LocationsDoc | undefined
        timeoutID: NodeJS.Timeout
      }>
    ) {
      if (state.user) {
        state.user.locations = action.payload.locations
        state.user.locationsTimeout = action.payload.timeoutID
        state.geoAllowed = geoip.isLocationAllowed(
          state.user.locations,
          state.user.account
        )
      }
    },
  },
})

export const userActions = {
  ...userSlice.actions,
  ...thunks,
}
