import * as React from 'react'
import { StyleSheet } from 'react-native'
import * as staking from '@mv/api/lib/src/schema/staking'
import { token } from '@mv/api/lib/src/types'
import { View } from '..'
import { SubmitButton } from '../form'
import { useDispatch } from '../../state/store'
import { modalsActions } from '../../state/modalsSlice'
import { useIsMounted } from '../../hooks/useIsMounted'
import { RootState } from '../../state/state'
import { FunctionsError, makeCallableFunction } from '../../firebase/functions'
import { logEvent, analytics } from '../../firebase/analytics'
import { logger } from '../../logger'
import { humanizeBalance } from '../../helpers'

const MINUTE = 60 * 1000

type Props = {
  user: RootState['user']
  userAiAvailableAmount: token.Units | undefined
  userStake: staking.Stake | null | undefined
  stakingStatistics: staking.StakeStatistics | null | undefined
}
export function MyWalletStakingActionButtons({
  user,
  userAiAvailableAmount,
  userStake,
  stakingStatistics,
}: Props): JSX.Element {
  const dispatch = useDispatch()
  const isMounted = useIsMounted()

  const [rewardDisabled, setRewardDisabled] = React.useState(true)
  const [checkRewardTime, setCheckRewardTime] = React.useState<number>(0)

  React.useEffect(() => {
    if (isMounted.current) {
      if (userStake) {
        if (userStake.amount.units === '0') {
          setRewardDisabled(true)
        } else {
          const now = Date.now()
          const lastRewardTimeInMs = userStake.lastRewardTime.toMillis()
          const timeSinceLastReward = now - lastRewardTimeInMs
          if (timeSinceLastReward > 1 * MINUTE) {
            setRewardDisabled(false)
          } else {
            setRewardDisabled(true)
            setTimeout(() => {
              if (isMounted.current) {
                setCheckRewardTime(now)
              }
            }, 1 * MINUTE - timeSinceLastReward + 100)
          }
        }
      } else {
        setRewardDisabled(true)
      }
    }
  }, [isMounted, userStake, checkRewardTime])

  if (!userStake || !stakingStatistics) {
    return <></>
  }

  return (
    <View>
      <View style={styles.buttonRow}>
        <SubmitButton
          onPress={async () => {
            dispatch(
              modalsActions.showStakeModal({
                availableAI: userAiAvailableAmount || '0',
                earlyUnstakingFeeInBasisPoints:
                  stakingStatistics.earlyUnstakingFeeInBasisPoints,
                earlyUnstakingPeriod: stakingStatistics.earlyUnstakingPeriod,
              })
            )
            return false
          }}
          label="Stake AI"
          limitWidth
          containerStyle={styles.button}
          disabled={userAiAvailableAmount === '0' || !userAiAvailableAmount}
        />
        <SubmitButton
          onPress={async () => {
            dispatch(
              modalsActions.showUnstakeModal({
                stakedAI: userStake.amount.units,
                earlyUnstakingFeeInBasisPoints:
                  stakingStatistics.earlyUnstakingFeeInBasisPoints,
                earlyUnstakingPeriod: stakingStatistics.earlyUnstakingPeriod,
              })
            )
            return false
          }}
          label="Unstake AI"
          limitWidth
          containerStyle={styles.button}
          disabled={userStake.amount.units === '0'}
        />
        <SubmitButton
          onPress={async () => {
            const result = await submitReward(user)
            if (result) {
              return () => {
                dispatch(
                  modalsActions.showGenericModal({
                    title: 'Multiverse Staking',
                    messages: [
                      `Your staking reward of ${humanizeBalance(
                        result
                      )} Tachyons has been collected.`,
                    ],
                  })
                )
                logEvent(analytics(), 'reward', {
                  // amount: validAmount,
                  staker: user.user?.fuid,
                })
              }
            }
            return false
          }}
          label="Collect Staking Rewards"
          limitWidth
          disabled={userStake.amount.units === '0' || rewardDisabled}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    marginBottom: 16,
    marginRight: 16,
  },
  buttonRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})

async function submitReward(user: RootState['user']): Promise<string | false> {
  if (!user.user) {
    return false
  }

  const reward = makeCallableFunction('staking-staking')
  try {
    const response = await reward({
      id: user.user.fuid,
      action: 'reward',
    })
    return response.data.amount.units
  } catch (_e) {
    const e = _e as FunctionsError

    switch (e.code) {
      default:
        logger.error('stake error', e)
    }
    return false
  }
}
