import * as React from 'react'
import { StyleSheet } from 'react-native'
import { ReactionType, ForumRole, Post } from '@mv/api/lib/src/schema/forums'
import { ThreadedPost } from './planetForumTypes'
import { View, Text } from '..'
import { DisplayAddress } from '../DisplayAddress'
import { LinkButton } from '../LinkButton'
import { logger } from '../../logger'
import { TimeAgo } from '../TimeAgo'
import { useSelector } from '../../state/store'
import { useAdmin } from '../../hooks/useAdmin'
import { useThemeColors } from '../../constants/colors'
import { PlanetForumPostActions } from './PlanetForumPostActions'
import { PlanetForumPostText } from './PlanetForumPostText'
import {
  PlanetForumPostTypingStatus,
  Props as PlanetForumPostTypingStatusProps,
} from './PlanetForumPostTypingStatus'
import { tempPlanetColorFromHexString } from './RenderPlanet'
import { AnimateDownFadeIn } from '../animations/AnimateDownFadeIn'
import { isAddressEqual } from '../../helpers'

const MAX_REPLY_DEPTH = 12
const REPLY_DEPTH_COLLAPSE = 2 // Will start showing levels 0 to REPLY_DEPTH_COLLAPSE

type PlanetForumPostProps = {
  post: ThreadedPost
  userReactions?: Partial<Record<ReactionType, true>>
  maxReplyDepth?: number
  threadNode?: React.ReactNode
  firstLoadTime: Date
  positionInThread: number
}
export function PlanetForumPost({
  post,
  userReactions,
  maxReplyDepth = MAX_REPLY_DEPTH,
  threadNode,
  firstLoadTime,
  positionInThread,
}: PlanetForumPostProps): JSX.Element | null {
  const user = useSelector((state) => state.user)
  const colors = useThemeColors()
  const isPlanetAdmin = useAdmin('planetAdmin')
  const isPlanetForumsAdmin = useAdmin('planetForumsAdmin')
  const [typingStatusLastTime, setTypingStatusLastTime] =
    React.useState<PlanetForumPostTypingStatusProps['lastTime']>(null)
  const [showThreadNode, setShowThreadNode] = React.useState<boolean>(
    !threadNode || post.replyDepth < REPLY_DEPTH_COLLAPSE
  )

  React.useEffect(() => {
    let typingStatusLastTimeValue: PlanetForumPostTypingStatusProps['lastTime']
    if (!post.lastReplyTypingAddress || !post.lastReplyTypingTime) {
      typingStatusLastTimeValue = null
    } else if (
      !isAddressEqual(post.lastReplyTypingAddress, user.user?.account?.address)
    ) {
      typingStatusLastTimeValue = post.lastReplyTypingTime?.toDate()
    }
    setTypingStatusLastTime(typingStatusLastTimeValue)
  }, [
    user.user?.account?.address,
    post.lastReplyTypingAddress,
    post.lastReplyTypingTime,
  ])

  const deletedColorStyle = { color: colors.medium }
  const authorColor = userColor(post)
  const boxColorStyle = { backgroundColor: authorColor }
  const addressColorStyle = { color: authorColor }
  const showReplyButton = post.replyDepth < maxReplyDepth
  const showDeleteButton =
    isAddressEqual(post.authorAddress, user.user?.account?.address) ||
    isPlanetAdmin ||
    isPlanetForumsAdmin

  try {
    let postContentNode: React.ReactNode
    switch (post.bodyType) {
      case 'text':
        postContentNode = (
          <>
            <PlanetForumPostText
              testID="planetFormPostText"
              text={post._.body.text}
            />
            <PlanetForumPostActions
              post={post}
              userReactions={userReactions}
              showReplyButton={showReplyButton}
              showDeleteButton={showDeleteButton}
              showReactionButtons
            />
          </>
        )
        break
      case 'deleted':
        postContentNode = (
          <Text style={[styles.deleted, deletedColorStyle]}>
            {'Deleted by '}
            <DisplayAddress
              address={post._.body.deleted.deleterAddress}
              textStyle={deletedColorStyle}
            />{' '}
            <TimeAgo
              style={[styles.deleted, deletedColorStyle]}
              date={post._.body.deleted.deletionTime.toDate()}
            />
          </Text>
        )
        break
      default:
        return null
    }

    // Prepare admin badge - show only one role based on priority order
    let adminRoleString = ''
    let adminRoleForDisplay: ForumRole | undefined
    const adminRolePriorityOrder: Record<ForumRole, number> = {
      founder: 1,
      multiverseAdmin: 2,
      forumModerator: 3,
      topicModerator: 4,
    }
    post.authorRoles.forEach((role) => {
      if (!adminRoleForDisplay) {
        adminRoleForDisplay = role
      } else if (
        adminRolePriorityOrder[adminRoleForDisplay] >
        adminRolePriorityOrder[role]
      ) {
        adminRoleForDisplay = role
      }
    })
    switch (adminRoleForDisplay) {
      case 'founder':
        adminRoleString = 'Founder'
        break
      case 'multiverseAdmin':
        adminRoleString = 'Multiverse Admin'
        break
      case 'forumModerator':
        adminRoleString = 'Forum Moderator'
        break
      case 'topicModerator':
        adminRoleString = 'Moderator'
        break
      default:
        adminRoleString = ''
    }
    const adminBadge =
      adminRoleString === null ? null : (
        <Text testID="adminBadge" weight="semiBold" style={styles.admin}>
          {adminRoleString}
        </Text>
      )

    return (
      <AnimateDownFadeIn
        animate={post.postTime.toMillis() > firstLoadTime.getTime()}
      >
        <View style={styles.container}>
          {post.replyDepth > 0 && (
            <View style={styles.leftChannel}>
              <View style={[styles.threadHead, boxColorStyle]} />
              <View style={[styles.threadLine]} />
            </View>
          )}
          <View
            style={[
              styles.containerMain,
              positionInThread > 0 ? styles.containerMainNotFirst : undefined,
            ]}
          >
            <Text weight="extraLight" style={styles.postedBy}>
              <DisplayAddress
                address={post.authorAddress}
                textWeight="semiBold"
                textStyle={addressColorStyle}
              />
              {adminBadge}
              <Text weight="extraLight" style={styles.postedByRight}>
                {'posted '}
              </Text>
              <TimeAgo
                textWeight="extraLight"
                style={styles.postedBy}
                date={post.postTime.toDate()}
              />
            </Text>
            <View>
              <View style={styles.content}>{postContentNode}</View>
              <View style={threadNode ? styles.threadNode : undefined}>
                {!showThreadNode ? (
                  <View style={styles.showReplies}>
                    <LinkButton
                      testID="forumPostActionsShowRepliesButton"
                      label="Show replies..."
                      labelStyle={styles.showRepliesText}
                      onPress={() => {
                        setShowThreadNode(true)
                      }}
                    />
                  </View>
                ) : null}
                <AnimateDownFadeIn
                  animate={post.replyDepth >= REPLY_DEPTH_COLLAPSE}
                >
                  {!showThreadNode ? null : threadNode}
                </AnimateDownFadeIn>
                <PlanetForumPostTypingStatus
                  lastTime={typingStatusLastTime}
                  style={!threadNode ? styles.threadNode : undefined}
                />
              </View>
            </View>
          </View>
        </View>
      </AnimateDownFadeIn>
    )
  } catch (e) {
    logger.error(`Encountered bad data rendering post ${post.postId}`, e)
  }
  return null
}

const styles = StyleSheet.create({
  admin: {
    fontSize: 11,
    paddingLeft: 8,
  },
  container: {
    flexDirection: 'row',
  },
  containerMain: {
    flex: 1,
  },
  containerMainNotFirst: {
    marginTop: 24,
  },
  content: {
    marginTop: 8,
  },
  deleted: {
    fontStyle: 'italic',
    paddingBottom: 4,
  },
  leftChannel: { alignItems: 'center' },
  postedBy: {
    fontSize: 11,
  },
  postedByRight: {
    fontSize: 11,
    paddingLeft: 8,
  },
  showReplies: {
    marginTop: -12,
  },
  showRepliesText: {
    fontSize: 12,
  },
  threadHead: {},
  threadLine: {
    borderColor: '#E0E0E0',
    borderLeftWidth: 1,
    flex: 1,
    marginRight: 24,
  },
  threadNode: {
    marginBottom: 0,
    marginTop: 24,
  },
})

function userColor(post: Post): string {
  return tempPlanetColorFromHexString(post.authorAddress || '')
}
