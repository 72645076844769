import {
  ColorSchemeName,
  useColorScheme as _useColorScheme,
} from 'react-native'

type Theme = {
  theme: 'hadron' | 'multiverse2022'
  colorScheme: NonNullable<ColorSchemeName>
}

export function useTheme(): Theme {
  return {
    // theme: 'hadron',
    theme: 'multiverse2022',
    // The useColorScheme value is always either light or dark, but the built-in
    // type suggests that it can be null. This will not happen in practice, so this
    // makes it a bit easier to work with.
    colorScheme: _useColorScheme() as NonNullable<ColorSchemeName>,
  }
}
