/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import {
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
  TouchableOpacity,
} from 'react-native'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
} from 'react-native-reanimated'
import { View } from './View'
import { Text } from './Text'
import { VLogo } from './svg/logos/VLogo'
import { useTheme } from '../hooks/useTheme'

type Props = {
  heading?: string
  containerStyle?: StyleProp<ViewStyle>
  headingStyle?: StyleProp<TextStyle>
  expandable?: boolean
  startExpanded?: boolean
  children?: React.ReactNode
  noMargin?: boolean
}
export function Section({
  heading,
  containerStyle,
  headingStyle,
  expandable,
  startExpanded,
  children,
  noMargin = false,
}: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = themedStyles[theme]

  if (expandable) {
    return (
      <ExpandableSection
        heading={heading}
        containerStyle={containerStyle}
        headingStyle={headingStyle}
        startExpanded={!!startExpanded}
      >
        {children}
      </ExpandableSection>
    )
  }
  return (
    <View
      style={[
        styles.container,
        noMargin ? undefined : styles.containerMargin,
        containerStyle,
      ]}
    >
      {!!heading && (
        <Text
          weight={theme === 'hadron' ? 'semiBold' : 'bold'}
          preset="paragraph"
          style={[styles.heading, headingStyle]}
        >
          {heading}
        </Text>
      )}
      <View style={styles.containerChildren}>{children}</View>
    </View>
  )
}
Section.defaultProps = {
  heading: undefined,
  containerStyle: undefined,
  headingStyle: undefined,
  expandable: false,
  startExpanded: true,
  children: undefined,
}

const themedStyles = {
  hadron: StyleSheet.create({
    animatedHeadingText: {
      flex: 1,
    },
    animatedHeadingView: {
      flexDirection: 'row',
    },
    animatedMarkerContainer: {
      flex: 1,
      justifyContent: 'center',
    },
    animatedView: {
      overflow: 'hidden',
    },
    container: {
      flexDirection: 'column',
    },
    containerChildren: {},
    containerHeading: {},
    containerMargin: {
      marginBottom: 20,
    },
    heading: {
      fontSize: 18,
      lineHeight: 18 * 2,
    },
  }),
  multiverse2022: StyleSheet.create({
    animatedHeadingText: {
      flex: 1,
    },
    animatedHeadingView: {
      flexDirection: 'row',
    },
    animatedMarkerContainer: {
      flex: 1,
      justifyContent: 'center',
    },
    animatedView: {
      overflow: 'hidden',
    },
    container: {
      flexDirection: 'column',
    },
    containerChildren: {},
    containerHeading: {},
    containerMargin: {
      marginBottom: 16,
    },
    heading: {},
  }),
}

type ExpandableSectionProps = {
  heading?: string
  containerStyle?: StyleProp<ViewStyle>
  headingStyle?: StyleProp<TextStyle>
  startExpanded: boolean
  children?: React.ReactNode
}
function ExpandableSection({
  heading,
  containerStyle,
  headingStyle,
  startExpanded,
  children,
}: ExpandableSectionProps): JSX.Element {
  const { theme } = useTheme()
  const [expanded, setExpanded] = React.useState(startExpanded)
  const [expandedHeight, setExpandedHeight] = React.useState(0)
  const animatedState = useSharedValue(startExpanded ? 1 : 0)

  const styles = themedStyles[theme]

  React.useEffect(() => {
    if (expandedHeight !== null) {
      if (expanded) {
        animatedState.value = withSpring(1)
      } else {
        animatedState.value = withSpring(0, {
          overshootClamping: true,
        })
      }
    }
  }, [animatedState, expandedHeight, expanded])

  const animatedViewStyle = useAnimatedStyle(() => {
    if (!expandedHeight) {
      return {
        height: undefined,
      }
    }
    const animatedHeight = animatedState.value * expandedHeight
    return {
      height: animatedHeight,
    }
  }, [expandedHeight])

  const animatedMarkerStyle = useAnimatedStyle(() => ({
    transform: [{ rotateZ: `${(1 - animatedState.value) * 90}deg` }],
  }))

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.containerHeading}>
        <TouchableOpacity
          onPress={() => {
            setExpanded(!expanded)
          }}
          activeOpacity={0.6}
        >
          <View style={styles.animatedHeadingView}>
            <Text
              weight="semiBold"
              style={[styles.animatedHeadingText, styles.heading, headingStyle]}
            >
              {heading || 'Click to expand'}
            </Text>
            <Animated.View style={animatedMarkerStyle}>
              <View style={styles.animatedMarkerContainer}>
                <VLogo height={12} />
              </View>
            </Animated.View>
          </View>
        </TouchableOpacity>
      </View>
      <Animated.View style={[styles.animatedView, animatedViewStyle]}>
        <View
          style={styles.containerChildren}
          onLayout={(event) => {
            setExpandedHeight(event.nativeEvent.layout.height)
          }}
        >
          {children}
        </View>
      </Animated.View>
    </View>
  )
}
ExpandableSection.defaultProps = {
  heading: undefined,
  containerStyle: undefined,
  headingStyle: undefined,
  children: undefined,
}
