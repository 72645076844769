/* eslint-disable react-native/no-unused-styles */
import * as React from 'react'
import { StyleSheet } from 'react-native'
import Clipboard from 'expo-clipboard'
import { View, Text } from '..'
import { Button } from '../Button'
import { useThemeColors } from '../../constants/colors'
import { useSelector } from '../../state/store'
import { LoggedInNavBalancesDisplay } from './LoggedInNavBalancesDisplay'
import { useTheme } from '../../hooks/useTheme'

export function LoggedInNavDisplay(): JSX.Element | null {
  const user = useSelector((state) => state.user)
  const navColor = useThemeColors().nav
  const { theme } = useTheme()

  if (!user) {
    return null
  }

  const containerColorStyles = {
    borderBottomColor: navColor.horizontalRule,
  }
  const textColorStyles = {
    color: navColor.infoText,
  }

  const styles = themedStyles[theme]

  if (theme === 'hadron') {
    return (
      <View style={[styles.container, containerColorStyles]}>
        <View style={styles.loggedInView}>
          <Text weight="bold" style={[styles.text, textColorStyles]}>
            Logged in as:
          </Text>
          {user.user?.firebaseUser.uid === user.user?.fuid && (
            <Text
              weight="semiBold"
              style={[styles.email, textColorStyles]}
              numberOfLines={1}
            >
              {user.user?.firebaseUser.email}
            </Text>
          )}
          <Button
            onPress={async () => {
              if (user.user?.account?.address)
                Clipboard.setString(user.user?.account?.address)
            }}
          >
            <View style={styles.addressView}>
              <Text
                weight="semiBold"
                numberOfLines={1}
                style={[styles.address, textColorStyles]}
              >
                {user.user?.account?.address || ' '}
              </Text>
              {!!user.user?.account?.address && (
                <Text weight="semiBold" style={[styles.copy, textColorStyles]}>
                  ⧉
                </Text>
              )}
            </View>
          </Button>
        </View>
        <LoggedInNavBalancesDisplay
          balances={user.user?.balances}
          labelStyle={[styles.text, textColorStyles]}
          weight="bold"
        />
      </View>
    )
  }

  const spaceStyle = {
    marginTop: 8,
  }

  return (
    <View style={[styles.container, containerColorStyles]}>
      <View style={styles.loggedInView}>
        <Text preset="paragraphSmall" weight="bold" style={textColorStyles}>
          Logged in as:
        </Text>
        {user.user?.firebaseUser.uid === user.user?.fuid && (
          <Text
            preset="paragraphSmall"
            style={[spaceStyle, textColorStyles]}
            numberOfLines={1}
          >
            {user.user?.firebaseUser.email}
          </Text>
        )}
        <Button
          onPress={async () => {
            if (user.user?.account?.address)
              Clipboard.setString(user.user?.account?.address)
          }}
        >
          <View style={styles.addressView}>
            <Text
              preset="paragraphSmall"
              numberOfLines={1}
              style={[spaceStyle, textColorStyles]}
            >
              {user.user?.account?.address || ' '}
            </Text>
            {!!user.user?.account?.address && (
              <Text
                preset="paragraphSmall"
                style={[spaceStyle, textColorStyles]}
              >
                ⧉
              </Text>
            )}
          </View>
        </Button>
      </View>
      <LoggedInNavBalancesDisplay
        preset="paragraphSmall"
        titleWeight="bold"
        balances={user.user?.balances}
        labelStyle={textColorStyles}
        verticalSpaceBetween={8}
      />
    </View>
  )
}

const themedStyles = {
  hadron: StyleSheet.create({
    address: {
      fontSize: 11,
      lineHeight: 1.5 * 14,
    },
    addressView: {
      flexDirection: 'row',
    },
    container: {
      borderBottomWidth: 1,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
    },
    copy: {
      fontSize: 16,
      lineHeight: 1.5 * 14,
    },
    email: {
      fontSize: 13,
      lineHeight: 1.5 * 14,
    },
    loggedInView: { marginBottom: 10 },
    text: {
      fontSize: 14,
      lineHeight: 1.5 * 14,
    },
  }),
  multiverse2022: StyleSheet.create({
    address: {
      fontSize: 11,
      lineHeight: 1.5 * 14,
    },
    addressView: {
      flexDirection: 'row',
    },
    container: {
      borderBottomWidth: 0,
      paddingBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
    },
    copy: {
      fontSize: 16,
      lineHeight: 1.5 * 14,
    },
    email: {
      fontSize: 13,
      lineHeight: 1.5 * 14,
    },
    loggedInView: { marginBottom: 32 },
    text: {
      fontSize: 14,
      lineHeight: 1.5 * 14,
    },
  }),
}
