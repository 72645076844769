import * as React from 'react'
import { StyleSheet } from 'react-native'
import { unrollSchedulesToArray } from '@mv/api/lib/src/schema/accounts/locks'
import { token } from '@mv/api/lib/src/types'
import { Schedule } from './types'
import { View } from '..'
import { Table } from '../table'
import { humanizeBalance } from '../../helpers'
import { LinkButton } from '../LinkButton'
import { useThemeColors } from '../../constants/colors'

const DEFAULT_LIMIT = 5

type Props = {
  schedules: Schedule[]
}

type ScheduleEntry = {
  key: string
  timestampString: string
  timestamp: Date
  amount: token.Units
}

export function MyWalletBalanceLockedSchedule({
  schedules,
}: Props): JSX.Element | null {
  const [limit, setLimit] = React.useState<number | undefined>(DEFAULT_LIMIT)
  const [tableData, setTableData] = React.useState<Array<ScheduleEntry>>([])

  const lockedTokenColors = useThemeColors().lockedTokens
  const scheduleColorStyle = {
    color: lockedTokenColors.scheduleColor,
  }
  const scheduleHeadingColorStyle = {
    color: lockedTokenColors.scheduleHeadingColor,
  }

  React.useEffect(() => {
    setTableData(extractTableDataFromSchedules(schedules))
  }, [schedules])

  if (tableData.length === 0) {
    return null
  }

  return (
    <View style={[styles.container]}>
      <Table
        cellTextStyle={[styles.cellText, scheduleColorStyle]}
        headingTextWeight="semiBold"
        headingTextStyle={[styles.heading, scheduleHeadingColorStyle]}
        data={tableData}
        columns={['timestampString', 'amount']}
        limit={limit}
        columnOptions={{
          timestampString: { name: 'Unlock Date' },
          timestamp: { hideHeading: true },
          amount: { name: 'Amount' },
        }}
        spaceBetweenColumns={40}
        containerStyle={styles.tableContainer}
        rowVerticalPadding={8}
        rowHorizontalPadding={0}
      />
      {tableData.length > DEFAULT_LIMIT && limit && (
        <View style={styles.showAllButtonView}>
          <LinkButton
            onPress={() => {
              setLimit(undefined)
            }}
            label="View all"
            labelStyle={styles.showAllButtonText}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  cellText: {
    fontSize: 12,
  },
  container: {
    flex: 1,
    marginBottom: 16,
    marginTop: 16,
  },
  heading: {
    fontSize: 11,
    textTransform: 'uppercase',
  },
  showAllButtonText: {
    fontSize: 12,
  },
  showAllButtonView: {
    flexDirection: 'column',
  },
  tableContainer: {
    // justifyContent: 'space-between',
  },
})

function extractTableDataFromSchedules(
  schedules: Schedule[]
): Array<ScheduleEntry> {
  const unlockEvents = unrollSchedulesToArray(schedules)
  const scheduleData = unlockEvents.map((event) => ({
    key: event.key,
    timestampString: event.unlockTimestamp.toLocaleString(undefined, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short',
    }),
    timestamp: event.unlockTimestamp,
    amount: `${humanizeBalance(event.unlockAmount.toString(), 5)} AI`,
  }))
  return scheduleData
}
