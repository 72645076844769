import {
  addressDisplayPrefixReplacement,
  convertEthereumAddressToDisplayAddress,
} from '@mv/api/lib/src/schema/accounts'

/**
 * When provided with an amount string of token unit amounts, this returns a
 * rounded string with `decimalPlaces` decimal digits. If the amount is less
 * than 1.0, then `decimalPlaces` number of significant figures are provided.
 */
export function humanizeBalance(
  amount: string | undefined,
  decimalPlaces = 5,
  decimalPosition = 18,
  minimumDecimalPlacesToDisplay = 0
): string {
  let humanized = humanizeBalanceToDecimalPlaces(
    amount,
    decimalPlaces,
    decimalPosition
  )

  if (minimumDecimalPlacesToDisplay > 0) {
    const decimalPointIndex = humanized.indexOf('.')
    if (decimalPointIndex === -1) {
      // whole number
      humanized += `.${'0'.repeat(minimumDecimalPlacesToDisplay)}`
    } else {
      const decimalPlacesNeeded = Math.max(
        minimumDecimalPlacesToDisplay -
          (humanized.length - (decimalPointIndex + 1)),
        0
      )
      humanized += `${'0'.repeat(decimalPlacesNeeded)}`
    }
  }
  return humanized
}

function humanizeBalanceToDecimalPlaces(
  amount: string | undefined,
  decimalPlaces = 5,
  decimalPosition = 18
): string {
  const amountBigInt = BigInt(amount ?? '0')
  const amountString = amountBigInt.toString()
  const decimalZeroes = '0'.repeat(decimalPosition)
  if (amountBigInt >= BigInt(`1${decimalZeroes}`)) {
    // round to decimalPlaces
    const unroundedValue =
      amountBigInt /
      BigInt(`1${'0'.repeat(Math.max(decimalPosition - decimalPlaces - 1, 0))}`)
    const roundedValue =
      decimalPlaces < decimalPosition
        ? (unroundedValue + BigInt('5')) / BigInt('10')
        : unroundedValue
    const roundedAmount =
      roundedValue * BigInt(`1${'0'.repeat(decimalPosition - decimalPlaces)}`)
    const roundedString = roundedAmount.toString()
    return `${insertThousandsDelimiter(
      roundedString.slice(0, roundedString.length - 18)
    )}.${roundedString.slice(-18)}`.replace(/\.?[0]+$/, '')
  }

  // amount is less than 1, so try to preserve decimalPlaces number of sigfigs instead
  if (decimalPlaces >= amountString.length) {
    // precision is more than the value, no need to round
    const amountPlus1 = (
      BigInt(`1${decimalZeroes}`) + BigInt(amountString)
    ).toString()
    return amountPlus1.replace(/^1/, '0.').replace(/\.?[0]+$/, '')
  }
  const unroundedValue =
    amountBigInt /
    BigInt(`1${'0'.repeat(amountString.length - decimalPlaces - 1)}`)
  const roundedValue =
    decimalPlaces < decimalPosition
      ? (unroundedValue + BigInt('5')) / BigInt('10')
      : unroundedValue
  const roundedAmount =
    roundedValue * BigInt(`1${'0'.repeat(amountString.length - decimalPlaces)}`)
  const roundedAmountPlus1 = (
    roundedAmount + BigInt(`1${decimalZeroes}`)
  ).toString()
  return `${(
    roundedAmount / BigInt(`1${decimalZeroes}`)
  ).toString()}.${roundedAmountPlus1.toString().slice(-18)}`.replace(
    /\.?[0]+$/,
    ''
  )
}

/**
 * Shortens the address provided by displaying a set number of pre and post
 * characters
 */
export function shortAddress(
  address: string,
  first = 5,
  last = 5,
  useMultiverseAddress = true
): string {
  const updatedAddress = useMultiverseAddress
    ? convertEthereumAddressToDisplayAddress(address)
    : address
  const prefix = updatedAddress.substr(0, 2)
  if (prefix !== addressDisplayPrefixReplacement && prefix !== '0x') {
    return updatedAddress
  }
  if (first + last >= updatedAddress.length - 2) {
    return updatedAddress
  }
  return `${prefix}${updatedAddress.substr(2, first)}...${updatedAddress.substr(
    updatedAddress.length - last,
    last
  )}`
}

function insertThousandsDelimiter(numStr: string, delimiter = ','): string {
  if (numStr.length < 4) {
    return numStr
  }
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)
}

/**
 * Returns `true` if the two addresses provided are the same when compared
 * without their prefixes. If either address is `undefined`, `isAddressEqual`
 * always returns `false`
 */
export function isAddressEqual(
  addr1: string | undefined,
  addr2: string | undefined
): boolean {
  if (addr1 === undefined || addr2 === undefined) return false

  function removePrefix(addr: string): string {
    if (addr.startsWith('Mv') || addr.startsWith('0x')) {
      return addr.slice(2)
    }
    return addr
  }
  return removePrefix(addr1) === removePrefix(addr2)
}
