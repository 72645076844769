import * as React from 'react'
import { Image, StyleSheet } from 'react-native'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import {
  draftCreateNewPlanetPath,
  draftEditPlanetPath,
  DraftPlanetDoc,
  Planet,
} from '@mv/api/lib/src/schema/planets'
import { MAX_SYMBOL_LENGTH } from '@mv/api/lib/src/requests/planet'
import { planet } from '@mv/api/lib/src/types'
import { fields } from '@mv/firestore/lib/src/fields'
import { logger } from '../../logger'
import {
  PlanetsStackNavigationProps,
  PlanetsStackParamList,
} from '../../navigation/types'
import { Section, View } from '..'
import { LinkButton } from '../LinkButton'
import * as Form from '../form'
import { RootState } from '../../state/state'
import { useSelector } from '../../state/store'
import {
  db,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  onSnapshot,
  DocumentReference,
  DocumentData,
} from '../../firebase/firestore'
import { makeCallableFunction, FunctionsError } from '../../firebase/functions'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../../constants/messages'
import { NoRefresh } from '../NoRefresh'
import { useAdmin } from '../../hooks/useAdmin'
import { useEarlyAccess } from '../../hooks/useEarlyAccess'
import { Loading } from '../Loading'
import { Text } from '../Text'

// const MIN_TEXT_AREA_HEIGHT = 81
const MIN_TEXT_AREA_HEIGHT = 300

export type PlanetFormFields = {
  planetName: string
  symbol: string
  planetaryStage: string
  governance: string
  founderName: string
  brief: string
  description: string
  benefits: string
  accountability: string
  cardImageUrl: string
}

type FormError = {
  planetName?: string
  symbol?: string
  planetaryStage?: string
  governance?: string
  founderName?: string
  brief?: string
  description?: string
  benefits?: string
  accountability?: string
  cardImageUrl?: string
  generic?: string
}
type Props = {
  planet?: Planet
}
export function CreateOrEditPlanetForm({
  planet: planetProp,
}: Props): JSX.Element {
  const navigation = useNavigation<PlanetsStackNavigationProps>()
  const route = useRoute<RouteProp<PlanetsStackParamList, 'PlanetsCreate'>>()
  const user = useSelector((state) => state.user)
  const isAdmin = useAdmin('planetAdmin')
  const hasSaveDraftAccess = useEarlyAccess('saveDraftPlanet')

  // If this is a draft of a new planet (not published), then planetId will be undefined and planetDraftId should be set
  // TODO: pass in planetDraftId when editing of draft new planets is written

  const [fuid, setFuid] = React.useState(user.user?.fuid)
  const [planetDraftId, setPlanetDraftId] = React.useState<string | null>(
    route.params?.draftId || null
  )
  const [planetName, setPlanetName] = React.useState(planetProp?.name || '')
  const [symbol, setSymbol] = React.useState(planetProp?.symbol || '')
  const [planetaryStage, setPlanetaryStage] = React.useState(
    planetProp?._.details.displayOverrides?.stage || ''
  )
  const [governance, setGovernance] = React.useState(
    planetProp?._.details.displayOverrides?.governance || ''
  )
  const [founderName, setFounderName] = React.useState(
    planetProp?._.details.displayOverrides?.founderName || ''
  )
  const [brief, setBrief] = React.useState(planetProp?._.details.brief || '')
  const [description, setDescription] = React.useState(
    planetProp?._.details.description || ''
  )
  const [descriptionHeight /* , setDescriptionHeight */] =
    React.useState(MIN_TEXT_AREA_HEIGHT)
  const [benefits, setBenefits] = React.useState(
    planetProp?._.details.benefits || ''
  )
  const [benefitsHeight /* , setBenefitsHeight */] =
    React.useState(MIN_TEXT_AREA_HEIGHT)
  const [accountability, setAccountability] = React.useState(
    planetProp?._.details.accountability || ''
  )
  const [accountabilityHeight /* , setAccountabilityHeight */] =
    React.useState(MIN_TEXT_AREA_HEIGHT)

  const [cardImageUrl, setCardImageUrl] = React.useState(
    planetProp?._.details.images?.card?.uri || ''
  )

  const [formError, setFormError] = React.useState({} as FormError)

  const [draftPlanetDoc, setDraftPlanetDoc] =
    React.useState<DraftPlanetDoc | null>(null)
  const [draftLoadedVersion, setDraftLoadedVersion] = React.useState(0)
  const [initialPlanetDraftLoaded, setInitialPlanetDraftLoaded] =
    React.useState(false)

  const isMounted = React.useRef(true)

  React.useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  React.useEffect(() => {
    setFuid(user.user?.fuid)
  }, [user.user])

  // Subscribe to drafts for existing planet
  React.useEffect(() => {
    if (planetProp?.id && fuid) {
      const draftDocRef = doc(db, draftEditPlanetPath(fuid, planetProp.id))
      return onSnapshot(draftDocRef, (snap) => {
        if (snap.exists()) {
          const draftDoc = snap.data() as DraftPlanetDoc
          setDraftPlanetDoc(draftDoc)
          return
        }
        setDraftPlanetDoc(null)
      })
    }
    return () => {}
  }, [planetProp?.id, fuid, draftLoadedVersion])

  // In the case of a draft create planet, perform initial load from draft if it exists
  React.useEffect(() => {
    if (planetDraftId && !initialPlanetDraftLoaded && fuid) {
      const draftDocRef = doc(db, draftCreateNewPlanetPath(fuid, planetDraftId))
      getDoc(draftDocRef)
        .then((snapshot) => {
          if (isMounted.current) {
            setInitialPlanetDraftLoaded(true)
            const draft = (snapshot.data() as DraftPlanetDoc) || null
            if (!draft) {
              navigation.setParams({ draftId: undefined })
              return
            }
            setDraftPlanetDoc(draft)
            loadDraft(draft)
          }
        })
        .catch((e) => {
          logger.warn('Something went wrong loading draft planet', e)
        })
    }
  }, [planetDraftId, initialPlanetDraftLoaded, navigation, fuid])

  // Subscribe to drafts for new planet
  React.useEffect(() => {
    if (planetDraftId && initialPlanetDraftLoaded && fuid) {
      const draftDocRef = doc(db, draftCreateNewPlanetPath(fuid, planetDraftId))
      return onSnapshot(draftDocRef, (snap) => {
        if (snap.exists()) {
          setDraftPlanetDoc(snap.data() as DraftPlanetDoc)
        } else {
          setDraftPlanetDoc(null)
        }
      })
    }
    return () => {}
  }, [planetDraftId, initialPlanetDraftLoaded, fuid])

  const planetFormFields: PlanetFormFields = {
    planetName,
    symbol,
    planetaryStage,
    governance,
    founderName,
    brief,
    description,
    benefits,
    accountability,
    cardImageUrl,
  }

  function loadDraft(draft: DraftPlanetDoc | null) {
    if (!draft) {
      // impossible since loadDraft is only called if draftPlanetDoc exists
      return
    }
    try {
      const draftFormFields = JSON.parse(draft.planetJSON) as PlanetFormFields
      setPlanetName(draftFormFields.planetName)
      setSymbol(draftFormFields.symbol)
      setPlanetaryStage(draftFormFields.planetaryStage)
      setGovernance(draftFormFields.governance)
      setFounderName(draftFormFields.founderName)
      setBrief(draftFormFields.brief)
      setDescription(draftFormFields.description)
      setBenefits(draftFormFields.benefits)
      setAccountability(draftFormFields.accountability)
      setCardImageUrl(draftFormFields.cardImageUrl)
      setDraftLoadedVersion(draft.version)
    } catch (_e) {
      setFormError({
        generic: ERROR_OOPS,
      })
    }
  }

  if (planetDraftId && !initialPlanetDraftLoaded) {
    return <Loading />
  }

  const cardImagePreviewUrl =
    cardImageUrl || planetProp?._.details.images?.card?.uri || ''

  return (
    <View style={styles.form}>
      <NoRefresh>
        {hasSaveDraftAccess &&
          !!draftPlanetDoc &&
          draftPlanetDoc.version > draftLoadedVersion && (
            <LinkButton
              label="You have previously saved draft changes. Click here to load your draft."
              onPress={() => {
                loadDraft(draftPlanetDoc)
              }}
              labelStyle={styles.loadDraftMessage}
            />
          )}
        <Form.Item>
          <Form.Label>Planet Name</Form.Label>
          <Form.TextInput
            value={planetName}
            onChangeText={(textValue) => {
              setPlanetName(textValue)
            }}
          />
          <Form.Error error={formError.planetName} />
        </Form.Item>
        <Form.Item>
          <Form.Label>Symbol</Form.Label>
          <Form.TextInput
            value={symbol}
            onChangeText={(textValue) => {
              setSymbol(textValue.substring(0, MAX_SYMBOL_LENGTH).toUpperCase())
            }}
          />
          <Form.Error error={formError.symbol} />
        </Form.Item>
        {isAdmin && (
          <Form.Item>
            <Form.Label>Planetary Stage</Form.Label>
            <Form.TextInput
              value={planetaryStage}
              onChangeText={(textValue) => {
                setPlanetaryStage(textValue)
              }}
              placeholder="Space Dust, Moon, Rocky Planet, Gas Giant"
            />
            <Form.Error error={formError.planetaryStage} />
          </Form.Item>
        )}
        <Form.Item>
          <Form.Label>Governance</Form.Label>
          <Form.TextInput
            value={governance}
            onChangeText={(textValue) => {
              setGovernance(textValue)
            }}
            placeholder="Stake Proportionate, One Staker One Vote, Total Dictatorship"
          />
          <Form.Error error={formError.governance} />
        </Form.Item>
        <Form.Item>
          <Form.Label>Founder Name or Group Override</Form.Label>
          <Form.TextInput
            value={founderName}
            onChangeText={(textValue) => {
              setFounderName(textValue)
            }}
          />
          <Form.Error error={formError.founderName} />
        </Form.Item>
        <Form.Item>
          <Form.Label>Brief Descripton</Form.Label>
          <Form.TextInput
            value={brief}
            onChangeText={(textValue) => {
              setBrief(textValue)
            }}
            placeholder="One or two lines to describe the planet goal"
          />
          <Form.Error error={formError.brief} />
        </Form.Item>
        <Form.Item>
          <Form.Label>Full Descripton</Form.Label>
          <Form.TextInput
            multiline
            numberOfLines={10}
            value={description}
            onChangeText={(textValue) => {
              setDescription(textValue)
            }}
            // onContentSizeChange={(e) =>
            //   setDescriptionHeight(e.nativeEvent.contentSize.height)
            // }
            style={[styles.textArea, { height: descriptionHeight }]}
            placeholder="Full description of the project"
          />
          <Form.Error error={formError.description} />
        </Form.Item>
        <Form.Item>
          <Form.Label>Staker Benefits</Form.Label>
          <Form.TextInput
            multiline
            numberOfLines={10}
            value={benefits}
            onChangeText={(textValue) => {
              setBenefits(textValue)
            }}
            // onContentSizeChange={(e) =>
            //   setBenefitsHeight(e.nativeEvent.contentSize.height)
            // }
            style={[styles.textArea, { height: benefitsHeight }]}
            placeholder="Describe the benefits stakers will have, if any"
          />
          <Form.Error error={formError.benefits} />
        </Form.Item>
        <Form.Item>
          <Form.Label>Project Accountability</Form.Label>
          <Form.TextInput
            multiline
            numberOfLines={10}
            value={accountability}
            onChangeText={(textValue) => {
              setAccountability(textValue)
            }}
            // onContentSizeChange={(e) =>
            //   setAccountabilityHeight(e.nativeEvent.contentSize.height)
            // }
            style={[styles.textArea, { height: accountabilityHeight }]}
            placeholder="Describe the processes and procedures that will provide accountability for your project"
          />
          <Form.Error error={formError.accountability} />
        </Form.Item>
        <Section heading="Planet Image(s)">
          <View style={styles.renderOptionsView}>
            <View style={styles.renderOptionsForm}>
              <Form.Item>
                <Form.Label>Image URL (image should be 640x640)</Form.Label>
                <Form.TextInput
                  placeholder=""
                  value={cardImageUrl}
                  onChangeText={(textValue) => {
                    setCardImageUrl(textValue)
                  }}
                />
                <Form.Error error={formError.cardImageUrl} />
              </Form.Item>
              {cardImagePreviewUrl ? (
                <Image
                  source={{ uri: cardImagePreviewUrl }}
                  style={styles.cardImagePreview}
                />
              ) : (
                <View style={styles.cardImagePreview}>
                  <Text>MISSING CARD IMAGE!</Text>
                </View>
              )}
            </View>
          </View>
        </Section>
        {hasSaveDraftAccess && (
          <Form.SubmitButton
            label="Save Draft"
            onPress={async () => {
              setFormError({})
              const draftIdResult = await saveDraft(
                planetProp?.id,
                planetDraftId,
                planetFormFields,
                user,
                setFormError,
                setDraftLoadedVersion
              )
              if (draftIdResult) {
                setPlanetDraftId(draftIdResult)
              }
              return false
            }}
          />
        )}
        <Form.SubmitButton
          label="Save"
          onPress={async () => {
            setFormError({})
            const result = await submitCreateOrEditPlanet(
              planetProp?.id,
              planetDraftId,
              planetFormFields,
              user,
              setFormError
            )
            if (!result) {
              return false
            }
            return () => {
              navigation.replace('PlanetsShow', {
                planetLocator: result.planetId,
              })
            }
          }}
        />
        <Form.Error error={formError.generic} />
        {planetProp && (
          <Form.Alternative.Row>
            <Form.Alternative.Link
              label="Cancel Edit"
              onPress={() => {
                if (navigation.canGoBack()) {
                  navigation.goBack()
                } else {
                  navigation.navigate('PlanetsShow', {
                    planetLocator: planetProp.symbol,
                  })
                }
              }}
            />
          </Form.Alternative.Row>
        )}
      </NoRefresh>
    </View>
  )
}
CreateOrEditPlanetForm.defaultProps = {
  planet: undefined,
}

const styles = StyleSheet.create({
  cardImagePreview: {
    alignItems: 'center',
    borderRadius: 16,
    borderWidth: 1,
    height: 160,
    justifyContent: 'center',
    textAlign: 'center',
    width: 160,
  },
  form: {
    maxWidth: 800,
  },
  loadDraftMessage: { marginBottom: 20 },
  renderOptionsForm: {
    flex: 1,
  },
  renderOptionsView: {
    flexDirection: 'row',
  },
  textArea: {
    minHeight: MIN_TEXT_AREA_HEIGHT,
  },
})

type SubmitCreatePlanetResult = null | {
  planetId: planet.Id
}
async function submitCreateOrEditPlanet(
  planetId: string | undefined,
  draftId: string | null,
  planetFormFields: PlanetFormFields,
  user: RootState['user'],
  setFormError: React.Dispatch<React.SetStateAction<FormError>>
): Promise<SubmitCreatePlanetResult> {
  if (!user.user) {
    setFormError({
      generic: ERROR_USER_NOT_LOGGED_IN,
    })
    return null
  }

  const {
    planetName,
    symbol,
    planetaryStage,
    governance,
    founderName,
    brief,
    description,
    benefits,
    accountability,
    cardImageUrl,
  } = planetFormFields

  if (!symbol) {
    setFormError({
      symbol: 'A planet symbol must be provided',
    })
    return null
  }

  const createOrEditPlanet = makeCallableFunction('planet-createOrEdit')
  try {
    const response = await createOrEditPlanet({
      id: user.user.fuid,
      planetId,
      planetData: {
        name: planetName,
        symbol,
        governance,
        founderName,
        brief,
        description,
        benefits,
        accountability,
      },
      images: {
        card: cardImageUrl,
      },
      adminData: {
        stage: planetaryStage,
      },
    })
    const responseData = response.data
    if (responseData && responseData.success && responseData.planetId) {
      if (planetId) {
        // existing planet
        await deleteDoc(doc(db, draftEditPlanetPath(user.user.fuid, planetId)))
      } else if (draftId) {
        // draft of new planet exists
        await deleteDoc(doc(db, draftEditPlanetPath(user.user.fuid, draftId)))
      }
      return {
        planetId: responseData.planetId,
      }
    }
    throw new Error(
      'Unexpected response received after createPlanet call concluded'
    )
  } catch (_e) {
    const e = _e as FunctionsError
    const errors: FormError = {}
    logger.error('createOrEditPlanet error', e)
    switch (e.code) {
      case 'invalid-argument':
      case 'already-exists':
      case 'failed-precondition':
        if (/symbol/i.test(e.message)) {
          errors.symbol = e.message
        } else {
          errors.generic = ERROR_OOPS
        }
        break
      default:
        errors.generic = ERROR_OOPS
    }
    setFormError(errors)
    return null
  }
}

async function saveDraft(
  planetId: string | undefined,
  draftId: string | null,
  planetFormFields: PlanetFormFields,
  user: RootState['user'],
  setFormError: React.Dispatch<React.SetStateAction<FormError>>,
  setDraftLoadedVersion: React.Dispatch<React.SetStateAction<number>>
): Promise<string | false> {
  if (!user.user) {
    setFormError({ generic: ERROR_USER_NOT_LOGGED_IN })
    return false
  }

  let draftPlanetDocRef: DocumentReference<DocumentData>
  let returnValue: string | false
  if (planetId) {
    // This is a draft of an existing planet
    draftPlanetDocRef = doc(db, draftEditPlanetPath(user.user.fuid, planetId))

    const existingDraftSnapshot = await getDoc(draftPlanetDocRef)
    if (existingDraftSnapshot.exists()) {
      // this is an update to the draft
      const draftPlanetDoc: Partial<DraftPlanetDoc> = {
        lastUpdateTime: fields.serverTimestamp(),
        planetJSON: JSON.stringify(planetFormFields),
        version: fields.increment(1) as unknown as number,
      }
      await setDoc(draftPlanetDocRef, draftPlanetDoc, { merge: true }).catch(
        () => {
          setFormError({
            generic: ERROR_OOPS,
          })
        }
      )
      returnValue = false
    } else {
      // create a new draft
      const draftPlanetDoc: DraftPlanetDoc = {
        id: planetId,
        lastUpdateTime: fields.serverTimestamp(),
        creationTime: fields.serverTimestamp(),
        planetJSON: JSON.stringify(planetFormFields),
        version: 1,
      }
      await setDoc(draftPlanetDocRef, draftPlanetDoc).catch(() => {
        setFormError({
          generic: ERROR_OOPS,
        })
      })
      returnValue = false
    }
  } else if (draftId) {
    // A draft of this brand new planet already exists - update

    draftPlanetDocRef = doc(
      db,
      draftCreateNewPlanetPath(user.user.fuid, draftId)
    )
    const draftPlanetDoc: Partial<DraftPlanetDoc> = {
      lastUpdateTime: fields.serverTimestamp(),
      planetJSON: JSON.stringify(planetFormFields),
      version: fields.increment(1),
    }
    await setDoc(draftPlanetDocRef, draftPlanetDoc, { merge: true }).catch(
      () => {
        setFormError({
          generic: ERROR_OOPS,
        })
      }
    )
    returnValue = draftId
  } else {
    // Create a new draft for a brand new planet

    const newDraftId = `${new Date().getTime().toString(36)}${Math.floor(
      Math.random() * 1000
    ).toString(36)}`

    const newDraftPlanetDoc: DraftPlanetDoc = {
      id: newDraftId,
      lastUpdateTime: fields.serverTimestamp(),
      creationTime: fields.serverTimestamp(),
      planetJSON: JSON.stringify(planetFormFields),
      version: 1,
    }
    draftPlanetDocRef = doc(
      db,
      draftCreateNewPlanetPath(user.user.fuid, newDraftId)
    )
    await setDoc(draftPlanetDocRef, newDraftPlanetDoc).catch(() => {
      setFormError({
        generic: ERROR_OOPS,
      })
    })
    returnValue = newDraftId
  }

  const latestDraftPlanetDocSnapshot = await getDoc(draftPlanetDocRef)
  const draftPlanetDoc = latestDraftPlanetDocSnapshot.data() as DraftPlanetDoc
  setDraftLoadedVersion(draftPlanetDoc.version)

  return returnValue
}
