import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { View, Text } from '..'
import { AnimateDownFadeIn } from '../animations/AnimateDownFadeIn'
import { AnimatePulse } from '../animations/AnimatePulse'

const IS_CONSIDERED_RECENT = 5 * 1000
const SHOW_STATUS_DURATION = 6 * 1000

export type Props = {
  /**
   * `lastTime` should be the Date of the last typing time unless
   * * the current user is the replier (then `lastTime` should be `undefined`)
   * * the lastReplyTypingTime value is missing (then `lastTime` should be
   *   `null`)
   *
   * This allows PlanetForumPostTypingStatus to show the typing status
   * uninterrupted if multiple replies are in progress are typing and one of
   * them is the current user
   */
  lastTime: Date | undefined | null
  style?: StyleProp<ViewStyle>
}

export function PlanetForumPostTypingStatus({
  lastTime,
  style = undefined,
}: Props): JSX.Element | null {
  const isMounted = React.useRef(true)
  const [lastSeenTime, setLastSeenTime] = React.useState(lastTime)
  const [show, setShow] = React.useState(false)
  const [recheckTick, setRecheckTick] = React.useState(false)
  const timerRef = React.useRef<NodeJS.Timeout | null>(null)

  React.useEffect(
    () => () => {
      isMounted.current = false
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    },
    []
  )

  React.useEffect(() => {
    if (lastTime !== undefined) {
      setLastSeenTime(lastTime)
    }
  }, [lastTime])

  React.useEffect(() => {
    if (
      lastSeenTime &&
      new Date().getTime() - lastSeenTime.getTime() < IS_CONSIDERED_RECENT
    ) {
      setShow(true)
      if (!timerRef.current) {
        timerRef.current = setTimeout(() => {
          if (isMounted.current) {
            timerRef.current = null
            setRecheckTick(!recheckTick)
          }
        }, SHOW_STATUS_DURATION)
      }
    } else {
      setShow(false)
    }
  }, [lastSeenTime, recheckTick])

  return (
    <AnimateDownFadeIn>
      {show ? (
        <AnimatePulse>
          <View testID="typingStatus" style={[styles.container, style]}>
            <View style={styles.innerContainer}>
              <Text style={styles.text}>Someone is typing...</Text>
            </View>
          </View>
        </AnimatePulse>
      ) : null}
    </AnimateDownFadeIn>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 16,
  },
  innerContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
  },
  text: {
    fontSize: 12,
  },
})
