import * as React from 'react'
import { buildActions } from '../state/buildSlice'
import { useDispatch } from '../state/store'

type Props = {
  children: React.ReactNode
  disabled?: boolean
}

export function NoRefresh({ children, disabled }: Props): JSX.Element {
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!disabled) {
      dispatch(buildActions.setNoRefresh(true))

      return () => {
        dispatch(buildActions.setNoRefresh(false))
      }
    }
    return () => {}
  }, [dispatch, disabled])
  return <>{children}</>
}
