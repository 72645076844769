import * as React from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import Constants from 'expo-constants'
import { ConstantsExtra } from '../../types/appConfig'
import {
  PlanetTabProps,
  PlanetTabParamList,
  PlanetTabRoute,
  DrawerNavigationRoute,
  HomeworldTabParamList,
} from '../../navigation/types'
import { Tabs as GenericTabs } from '../Tabs'
import { useEarlyAccess } from '../../hooks/useEarlyAccess'
import { useScreenMargins } from '../../hooks/useScreenMargins'
import { TextPreset } from '../Text'
import { useFeatureHomeworldChat } from '../../hooks/useFeatureHomeworldChat'

const featuresConfig = Constants.manifest.extra
  ?.features as ConstantsExtra['features']
const planetsChatAndForumEnabled = featuresConfig.enablePlanetChatAndForums

type TabDescription<ParamList> = {
  name: string
  screen: keyof ParamList
}
const allTabs: TabDescription<PlanetTabParamList>[] = planetsChatAndForumEnabled
  ? [
      { name: 'Overview', screen: 'PlanetTabOverview' },
      { name: 'Topics', screen: 'PlanetTabChat' },
      { name: 'Forums', screen: 'PlanetTabForums' },
      { name: 'Pledging', screen: 'PlanetTabPledge' },
    ]
  : [
      { name: 'Overview', screen: 'PlanetTabOverview' },
      { name: 'Pledging', screen: 'PlanetTabPledge' },
    ]

const filteredTabs = allTabs.filter((t) => t.screen !== 'PlanetTabForums')

const homeworldTabsWithChat: TabDescription<PlanetTabParamList>[] = [
  { name: 'Topics', screen: 'PlanetTabChat' },
  { name: 'Announcements', screen: 'PlanetTabOverview' },
]
const homeworldTabs: TabDescription<PlanetTabParamList>[] = [
  { name: 'Announcements', screen: 'PlanetTabOverview' },
]

export function Tabs(): JSX.Element {
  const navigation = useNavigation<
    PlanetTabProps | StackNavigationProp<HomeworldTabParamList>
  >()
  const route = useRoute<PlanetTabRoute | DrawerNavigationRoute>()
  const { marginLeft, marginRight } = useScreenMargins().style
  const marginStyle = {
    marginLeft,
    marginRight,
  }

  const hasEarlyAccess = useEarlyAccess('planetForums')
  const homeworldChatEnabled = useFeatureHomeworldChat()

  const planetLocator = route.params?.planetLocator || undefined
  const isHomeworld = !planetLocator || planetLocator === 'AI'

  let tabs = hasEarlyAccess ? allTabs : filteredTabs
  let textPreset: TextPreset = 'textLink'
  if (isHomeworld) {
    tabs = homeworldChatEnabled ? homeworldTabsWithChat : homeworldTabs
    textPreset = 'largeTextLink'
  }

  const tabDescriptionArray = tabs.map((tab) => ({
    name: tab.name,
    onPress: () => {
      if (planetLocator) {
        const planetNavigation = navigation as PlanetTabProps
        planetNavigation.navigate(tab.screen, { planetLocator })
      } else {
        const homeworldNavigation =
          navigation as StackNavigationProp<HomeworldTabParamList>
        homeworldNavigation.navigate(tab.screen)
      }
    },
    isActive: tab.screen === route.name,
  }))
  return (
    <GenericTabs
      textPreset={textPreset}
      style={marginStyle}
      tabs={tabDescriptionArray}
    />
  )
}
