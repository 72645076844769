import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Section } from '../../..'
import { TabView } from '../../../TabView'
import { MyWalletBalance } from '../../MyWalletBalance'
import { MyWalletSetupMetaMask } from '../../MyWalletSetupMetaMask'
import { MyWalletTransferIn } from '../../MyWalletTransferIn'
import { MyWalletTransferOut } from '../../MyWalletTransferOut'
import { MyWalletRecentTransactions } from '../../MyWalletRecentTransactions'

export function OverviewTab(): JSX.Element {
  return (
    <TabView>
      <MyWalletBalance />
      <Section
        containerStyle={styles.section}
        heading="Integrating your MetaMask Wallet with Multiverse"
      >
        <MyWalletSetupMetaMask />
      </Section>
      <Section
        containerStyle={styles.section}
        heading="Depositing funds into your Multiverse Wallet"
      >
        <MyWalletTransferIn />
      </Section>
      <Section
        containerStyle={styles.section}
        heading="Withdrawing funds from your Multiverse Wallet"
      >
        <MyWalletTransferOut />
      </Section>
      <Section heading="Recent transactions">
        <MyWalletRecentTransactions />
      </Section>
    </TabView>
  )
}

const styles = StyleSheet.create({
  section: {
    maxWidth: 700,
  },
})
