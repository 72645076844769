import * as React from 'react'
import { View, StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { useThemeColors } from '../constants/colors'
import { Button } from './Button'

type Props = {
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  onPress?: () => void
  onPressIn?: () => void
  onPressOut?: () => void
  activeOpacity?: number
  label?: string
  disabled?: boolean
  children?: React.ReactNode
}
export function MenuButton({
  style,
  labelStyle,
  onPress,
  onPressIn,
  onPressOut,
  activeOpacity,
  label,
  disabled = false,
  children,
}: Props): JSX.Element {
  const [isPressed, setIsPressed] = React.useState(false)
  const color = useThemeColors().button
  const colorStyles = disabled ? color.disabled.container : color.menu.container
  const labelColorStyles = color.menu.label
  return (
    <View style={styles.shadow1}>
      <View style={isPressed ? undefined : styles.shadow2}>
        <View style={isPressed ? undefined : styles.shadow3}>
          <Button
            style={[styles.container, colorStyles, style]}
            labelStyle={[styles.label, labelColorStyles, labelStyle]}
            label={label}
            onPress={async () => {
              if (onPress) onPress()
            }}
            onPressIn={() => {
              if (onPressIn) onPressIn()
              setIsPressed(true)
            }}
            onPressOut={() => {
              if (onPressOut) onPressOut()
              setIsPressed(false)
            }}
            activeOpacity={activeOpacity || 1}
            disabled={disabled}
          >
            {children}
          </Button>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
  },
  label: {
    fontSize: 16,
    letterSpacing: 0.25,
    lineHeight: 16 * 1.5,
  },
  shadow1: {
    marginRight: 8,
    shadowColor: 'rgba(0,0,0,0.2)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 3,
  },
  shadow2: {
    shadowColor: 'rgba(0,0,0,0.14)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 1,
  },
  shadow3: {
    shadowColor: 'rgba(0,0,0,0.12)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 1,
  },
})
