import * as React from 'react'
import { ThreadedPosts, UserReactionLookup } from './planetForumTypes'
import { PlanetForumPost } from './PlanetForumPost'

type Props = {
  posts: ThreadedPosts
  userReactions?: UserReactionLookup
  maxReplyDepth?: number
  firstLoadTime: Date
}
export function PlanetForumThreadedPosts({
  posts,
  userReactions,
  maxReplyDepth,
  firstLoadTime,
}: Props): JSX.Element | null {
  const rendered: JSX.Element[] = []

  if (posts.size === 0) return null

  let index = 0
  posts.forEach((post) => {
    const threadNode =
      post.thread.size === 0 ? null : (
        <PlanetForumThreadedPosts
          posts={post.thread}
          userReactions={userReactions}
          firstLoadTime={firstLoadTime}
        />
      )
    rendered.push(
      <PlanetForumPost
        key={post.postId}
        post={post}
        userReactions={
          userReactions ? userReactions[post.postId] || {} : undefined
        }
        maxReplyDepth={maxReplyDepth}
        threadNode={threadNode}
        firstLoadTime={firstLoadTime}
        positionInThread={index}
      />
    )
    index += 1
  })

  return <>{rendered}</>
}
