/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Notification, initialState } from './notificationsTypes'

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialState(),
  reducers: {
    addNotification(state, action: PayloadAction<Notification>) {
      const notification = action.payload
      if (notification.id) {
        state.notifications = state.notifications.filter(
          (n) => n.id !== notification.id
        )
      } else {
        notification.id =
          new Date().getTime() * 1000 + Math.floor(Math.random() * 1000)
      }
      state.notifications.push(notification)
    },
    clearNotification(state, action: PayloadAction<Notification['id']>) {
      const nowTime = new Date().getTime()

      // remove cleared notifications older than 1 minute
      state.notifications = state.notifications.filter(
        (n) =>
          !(
            n.clearedTimestamp &&
            nowTime - n.clearedTimestamp.getTime() > 60 * 1000
          )
      )

      // clear notifications that match the id
      state.notifications = state.notifications.map((n) =>
        n.id === action.payload ? { ...n, clearedTimestamp: new Date() } : n
      )
    },
  },
})

export const notificationsActions = notificationsSlice.actions
