import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Post, ReactionType } from '@mv/api/lib/src/schema/forums'
import { View } from '..'
import { Button } from '../Button'
import * as Form from '../form'
import { PlanetForumPostActionsDeleteButton } from './PlanetForumPostActionsDeleteButton'
import { PlanetForumPostActionsReactionButtons } from './PlanetForumPostActionsReactionButtons'
import { useThemeColors } from '../../constants/colors'

type FormError = {
  action?: string
}
type Props = {
  post: Post
  userReactions?: Partial<Record<ReactionType, true>>
  showReplyButton: boolean
  showDeleteButton: boolean
  showReactionButtons: boolean
  onReply?: () => void
}
export function PlanetChatPostActions({
  post,
  userReactions,
  showReplyButton,
  showDeleteButton,
  showReactionButtons,
  onReply = undefined,
}: Props): JSX.Element {
  const colors = useThemeColors()
  const [showPostForm, setShowPostForm] = React.useState(false)
  const [formError, setFormError] = React.useState<FormError>({})

  const activeColorStyle = {
    // color: colors.button.primary.container.backgroundColor,
    color: colors.chat.actionButton.activeColor,
  }
  const inactiveColorStyle = {
    color: colors.chat.actionButton.color,
  }

  const buttonColorStyle = showPostForm ? activeColorStyle : inactiveColorStyle
  return (
    <View style={styles.container}>
      <View style={styles.actions}>
        {showDeleteButton && (
          <PlanetForumPostActionsDeleteButton
            post={post}
            style={styles.actionsButton}
            labelStyle={[styles.actionsButtonLabel, buttonColorStyle]}
            handleError={(errorMessage: string | null) => {
              if (errorMessage === null) {
                setFormError({})
              } else {
                setFormError({ action: errorMessage })
              }
            }}
          />
        )}
        {showReplyButton && (
          <Button
            testID="forumPostActionsReplyButton"
            label="reply"
            labelStyle={[styles.actionsButtonLabel, buttonColorStyle]}
            labelWeight="extraLight"
            style={styles.actionsButton}
            onPress={async () => {
              if (onReply) {
                onReply()
              } else {
                setShowPostForm(!showPostForm)
              }
            }}
          />
        )}
        {showReactionButtons && (
          <PlanetForumPostActionsReactionButtons
            post={post}
            userReactions={userReactions}
            buttonStyle={styles.reactionButton}
          />
        )}
      </View>
      <Form.Error
        error={formError.action}
        style={styles.errorView}
        textStyle={styles.errorText}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  actions: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  actionsButton: {
    marginLeft: 4,
    marginRight: 4,
  },
  actionsButtonLabel: {
    fontSize: 12,
  },
  container: {},
  errorText: { fontSize: 10 },
  errorView: {
    alignSelf: 'flex-end',
  },
  reactionButton: {
    marginLeft: 8,
    marginRight: 0,
  },
})
