import { useFonts as useExpoFonts } from 'expo-font'
import { useTheme } from './useTheme'

export function useFonts(): ReturnType<typeof useExpoFonts> {
  const { theme } = useTheme()
  return useExpoFonts(
    theme === 'hadron'
      ? {
          InterThin: require('../assets/fonts/Inter-Thin.otf'),
          InterExtraLight: require('../assets/fonts/Inter-ExtraLight.otf'),
          InterLight: require('../assets/fonts/Inter-Light.otf'),
          InterNormal: require('../assets/fonts/Inter-Regular.otf'),
          InterMedium: require('../assets/fonts/Inter-Medium.otf'),
          InterSemiBold: require('../assets/fonts/Inter-SemiBold.otf'),
          InterBold: require('../assets/fonts/Inter-Bold.otf'),
        }
      : {
          PlayfairDisplayThin: require('../assets/fonts/PlayfairDisplay-Regular.ttf'),
          PlayfairDisplayExtraLight: require('../assets/fonts/PlayfairDisplay-Regular.ttf'),
          PlayfairDisplayLight: require('../assets/fonts/PlayfairDisplay-Regular.ttf'),
          PlayfairDisplayNormal: require('../assets/fonts/PlayfairDisplay-Regular.ttf'),
          PlayfairDisplayMedium: require('../assets/fonts/PlayfairDisplay-Medium.ttf'),
          PlayfairDisplaySemiBold: require('../assets/fonts/PlayfairDisplay-SemiBold.ttf'),
          PlayfairDisplayBold: require('../assets/fonts/PlayfairDisplay-Bold.ttf'),
          FraktionMonoThin: require('../assets/fonts/FraktionMono-Light.otf'),
          FraktionMonoExtraLight: require('../assets/fonts/FraktionMono-Light.otf'),
          FraktionMonoLight: require('../assets/fonts/FraktionMono-Light.otf'),
          FraktionMonoNormal: require('../assets/fonts/FraktionMono-Regular.otf'),
          FraktionMonoMedium: require('../assets/fonts/FraktionMono-Medium.otf'),
          FraktionMonoSemiBold: require('../assets/fonts/FraktionMono-Medium.otf'),
          FraktionMonoBold: require('../assets/fonts/FraktionMono-Bold.otf'),
        }
  )
}
