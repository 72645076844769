import * as React from 'react'
import { StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { View } from '..'
import { useThemeColors } from '../../constants/colors'
import { testIdToNativeId } from '../../helpers/testId'
import { useTheme } from '../../hooks/useTheme'
import { modalsActions } from '../../state/modalsSlice'
import { useDispatch, useSelector } from '../../state/store'
import { Button, ButtonRefType } from '../Button'
import { Loading } from '../Loading'

export type SubmitButtonRefType = ButtonRefType

type Props = {
  testID?: string
  containerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  sublabelStyle?: StyleProp<TextStyle>
  onPress?: () => Promise<(() => void) | false>
  onPressIn?: () => void
  onPressOut?: () => void
  activeOpacity?: number
  label?: string
  sublabel?: string
  disabled?: boolean
  requireGeoAllow?: boolean
  limitWidth?: boolean
}

export const SubmitButton = React.forwardRef<SubmitButtonRefType, Props>(
  (
    {
      testID = undefined,
      containerStyle = undefined,
      style = undefined,
      labelStyle = undefined,
      sublabelStyle = undefined,
      onPress = undefined,
      onPressIn = undefined,
      onPressOut = undefined,
      activeOpacity = undefined,
      label = undefined,
      sublabel = undefined,
      disabled = false,
      requireGeoAllow = false,
      limitWidth = false,
    }: Props,
    ref
  ): JSX.Element => {
    const dispatch = useDispatch()
    const { geoAllowed } = useSelector((state) => ({
      geoAllowed: state.user.geoAllowed,
    }))
    const { theme } = useTheme()

    const [isPressed, setIsPressed] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const isMounted = React.useRef(true)
    React.useEffect(
      () => () => {
        isMounted.current = false
      },
      []
    )

    const disabledOrLoading = disabled || loading

    const styles = themedStyles[theme]
    const color = useThemeColors().button

    let colorStyles: typeof color.disabled.container | undefined =
      color.disabled.container
    if (!disabledOrLoading) {
      colorStyles = isPressed
        ? color.primary.containerPressed
        : color.primary.container
    }

    const labelColorStyles = color.primary.label

    const kycMessage =
      'This feature has been disabled for all residents from excluded regions in accordance with our Terms of Service. To re-enable disabled features, please contact us at kyc@multiverse.ai.'

    if (theme === 'hadron') {
      return (
        <View
          nativeID={testIdToNativeId(testID, 'form-submit')}
          style={[
            limitWidth ? undefined : styles.containerOuterFullWidth,
            containerStyle,
          ]}
        >
          <View style={styles.shadow1}>
            <View style={isPressed ? undefined : styles.shadow2}>
              <View style={isPressed ? undefined : styles.shadow3}>
                <Button
                  testID={testID}
                  ref={ref}
                  style={[styles.container, colorStyles, style]}
                  labelStyle={[styles.label, labelColorStyles, labelStyle]}
                  sublabelStyle={[
                    styles.sublabel,
                    labelColorStyles,
                    sublabelStyle,
                  ]}
                  labelWeight="bold"
                  label={label}
                  sublabel={sublabel}
                  sublabelWeight="semiBold"
                  onPress={async () => {
                    if (requireGeoAllow && !geoAllowed) {
                      dispatch(
                        modalsActions.showGenericModal({
                          messages: [kycMessage],
                          addCloseLink: true,
                        })
                      )
                      return
                    }
                    if (onPress) {
                      if (isMounted.current) {
                        setLoading(true)
                      }
                      const onSuccessCallback = await onPress()
                      if (isMounted.current) {
                        setLoading(false)
                      }
                      if (onSuccessCallback) {
                        onSuccessCallback()
                      }
                    }
                  }}
                  onPressIn={() => {
                    if (onPressIn) onPressIn()
                    setIsPressed(true)
                  }}
                  onPressOut={() => {
                    if (onPressOut) onPressOut()
                    setIsPressed(false)
                  }}
                  activeOpacity={activeOpacity || 1}
                  disabled={disabledOrLoading}
                />
                {loading && <Loading size="small" style={styles.loading} />}
              </View>
            </View>
          </View>
        </View>
      )
    }

    return (
      <View
        nativeID={testIdToNativeId(testID, 'form-submit')}
        style={[
          styles.containerOuter,
          limitWidth ? undefined : styles.containerOuterFullWidth,
          containerStyle,
        ]}
      >
        <Button
          testID={testID}
          ref={ref}
          style={[styles.container, colorStyles, style]}
          labelStyle={[styles.label, labelColorStyles, labelStyle]}
          sublabelStyle={[styles.sublabel, labelColorStyles, sublabelStyle]}
          labelPreset="paragraph"
          labelWeight="bold"
          label={label}
          sublabelPreset="paragraphXSmall"
          sublabel={sublabel}
          sublabelWeight="bold"
          onPress={async () => {
            if (requireGeoAllow && !geoAllowed) {
              dispatch(
                modalsActions.showGenericModal({
                  messages: [kycMessage],
                  addCloseLink: true,
                })
              )
              return
            }
            if (onPress) {
              if (isMounted.current) {
                setLoading(true)
              }
              const onSuccessCallback = await onPress()
              if (isMounted.current) {
                setLoading(false)
              }
              if (onSuccessCallback) {
                onSuccessCallback()
              }
            }
          }}
          onPressIn={() => {
            if (onPressIn) onPressIn()
            setIsPressed(true)
          }}
          onPressOut={() => {
            if (onPressOut) onPressOut()
            setIsPressed(false)
          }}
          activeOpacity={activeOpacity || 1}
          disabled={disabledOrLoading}
        />
        {loading && <Loading size="small" style={styles.loading} />}
      </View>
    )
  }
)
SubmitButton.defaultProps = {
  testID: undefined,
  containerStyle: undefined,
  style: undefined,
  labelStyle: undefined,
  sublabelStyle: undefined,
  onPress: undefined,
  onPressIn: undefined,
  onPressOut: undefined,
  activeOpacity: undefined,
  label: undefined,
  sublabel: undefined,
  disabled: false,
  requireGeoAllow: false,
  limitWidth: false,
}

const themedStyles = {
  hadron: StyleSheet.create({
    container: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      paddingBottom: 12,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 12,
    },
    containerOuter: {
      flex: 1,
      marginBottom: 4,
      maxWidth: 280,
    },
    containerOuterFullWidth: {
      maxWidth: undefined,
    },
    label: {
      fontSize: 14,
      letterSpacing: 1.25,
      lineHeight: 14 * 1.25,
      textTransform: 'uppercase',
    },
    loading: {
      bottom: 0,
      position: 'absolute',
      right: -30,
      top: 0,
    },
    pressed: {},
    shadow1: {
      shadowColor: 'rgba(0,0,0,0.2)',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 4,
    },
    shadow2: {
      shadowColor: 'rgba(0,0,0,0.14)',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowRadius: 5,
    },
    shadow3: {
      shadowColor: 'rgba(0,0,0,0.12)',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 10,
    },
    sublabel: {
      fontSize: 10,
      letterSpacing: 1,
      lineHeight: 10 * 1.25,
      marginTop: 4,
      textTransform: 'uppercase',
    },
  }),
  multiverse2022: StyleSheet.create({
    container: {
      alignItems: 'center',
      borderRadius: 8,
      flex: 1,
      justifyContent: 'center',
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
    },
    containerOuter: {
      marginBottom: 4,
      maxWidth: 280,
    },
    containerOuterFullWidth: {
      maxWidth: undefined,
    },
    label: {
      textAlign: 'center',
    },
    loading: {
      bottom: 0,
      position: 'absolute',
      right: -30,
      top: 0,
    },
    pressed: {
      backgroundColor: 'rgba(255, 0, 0, 0.5)',
      opacity: 0.5,
    },
    shadow1: {},
    shadow2: {},
    shadow3: {},
    sublabel: {
      marginTop: 4,
    },
  }),
}
