import * as React from 'react'
import { StyleSheet } from 'react-native'
import { planet } from '@mv/api/lib/src/types'
import { TopicId, PostId } from '@mv/api/lib/src/schema/forums'
import { FunctionsError, makeCallableFunction } from '../../firebase/functions'
import { RootState } from '../../state/state'
import { useSelector } from '../../state/store'
import * as Form from '../form'
import { NoRefresh } from '../NoRefresh'
import { logger } from '../../logger'
import { ERROR_OOPS, ERROR_USER_NOT_LOGGED_IN } from '../../constants/messages'
import { usePrevious } from '../../hooks/usePrevious'

const STATUS_TRIGGER_TIME = 5 * 1000 // 5 seconds

type FormError = {
  generic?: string
}

type Props = {
  planetId: planet.Id
  topicId: TopicId
  replyTo?: PostId
  reset?: () => void
}
export function PlanetForumPostForm({
  planetId,
  topicId,
  replyTo = undefined,
  reset,
}: Props): JSX.Element | null {
  const user = useSelector((state) => state.user)

  const [postText, setPostText] = React.useState('')
  const prevPostText = usePrevious(postText)
  const [formError, setFormError] = React.useState<FormError>({})

  const statusPendingRef = React.useRef(false)
  const lastStatusUpdateTimeRef = React.useRef(0)
  const submitRef = React.useRef<Form.SubmitButtonRefType>(null)

  React.useEffect(() => {
    if (
      !statusPendingRef.current &&
      new Date().getTime() - lastStatusUpdateTimeRef.current >
        STATUS_TRIGGER_TIME &&
      postText &&
      prevPostText !== postText
    ) {
      statusPendingRef.current = true
      lastStatusUpdateTimeRef.current = new Date().getTime()
      markAsTyping(planetId, topicId, replyTo, user)
        .then(() => {
          statusPendingRef.current = false
        })
        .catch((e) => {
          logger.warn(e)
        })
    }
  }, [planetId, topicId, replyTo, user, postText, prevPostText])

  const multiline = !replyTo
  return (
    <NoRefresh disabled={!postText}>
      <Form.Item style={styles.formRow}>
        <Form.TextInput
          testID="forumPostFormTextInput"
          multiline={multiline}
          numberOfLines={multiline ? 3 : 1}
          style={styles.textInput}
          placeholder={replyTo ? 'Reply...' : 'Start another discussion...'}
          value={postText}
          onChangeText={async (textValue) => {
            setPostText(textValue)
          }}
          onKeyPress={(event) => {
            switch (event.nativeEvent.key) {
              case 'Enter':
                if (
                  !multiline &&
                  postText.trim() &&
                  submitRef.current?.onPress
                ) {
                  submitRef.current.onPress().catch((e) => {
                    logger.warn('unexpected promise rejection on submit', e)
                  })
                }
                break
              default:
            }
          }}
        />
        <Form.SubmitButton
          testID="forumPostFormSubmitButton"
          ref={submitRef}
          label="Post"
          labelStyle={styles.submitLabel}
          style={styles.submit}
          containerStyle={styles.submitContainer}
          onPress={async () => {
            setFormError({})
            const success = await submitPost(
              planetId,
              topicId,
              replyTo,
              postText,
              user,
              setFormError
            )
            if (success) {
              setPostText('')
              if (reset) reset()
            }
            return false
          }}
        />
      </Form.Item>
      <Form.Error error={formError.generic} />
    </NoRefresh>
  )
}

const styles = StyleSheet.create({
  formRow: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  submit: {
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
  },
  submitContainer: { marginLeft: 8 },
  submitLabel: {
    fontSize: 12,
  },
  textInput: {
    borderColor: '#E0E0E0',
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
    fontSize: 14,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 6,
  },
})

async function submitPost(
  planetId: planet.Id,
  topicId: TopicId,
  replyTo: PostId | undefined,
  postText: string,
  user: RootState['user'],
  setFormError: React.Dispatch<React.SetStateAction<FormError>>
): Promise<boolean> {
  if (!user.user || !user.user.account) {
    setFormError({
      generic: ERROR_USER_NOT_LOGGED_IN,
    })
    return false
  }

  const createPost = makeCallableFunction('forums-createPost')
  try {
    await createPost({
      id: user.user.fuid,
      planetId,
      topicId,
      replyTo,
      referencedPostId: undefined,
      body: {
        bodyType: 'text',
        _: {
          body: {
            text: postText,
          },
        },
      },
    })
    return true
  } catch (e) {
    const error = e as FunctionsError
    const errors: FormError = {}
    logger.error('Failed to create planet forum post', error)
    errors.generic = ERROR_OOPS
    setFormError(errors)
    return false
  }
}

async function markAsTyping(
  planetId: planet.Id,
  topicId: TopicId,
  replyTo: PostId | undefined,
  user: RootState['user']
): Promise<boolean> {
  if (!(user.user && user.user.account && topicId && replyTo)) {
    return false
  }

  const createPost = makeCallableFunction('forums-createPost')
  try {
    await createPost({
      id: user.user.fuid,
      planetId,
      topicId,
      replyTo,
      referencedPostId: undefined,
      body: undefined,
    })
    return true
  } catch (e) {
    const error = e as FunctionsError
    logger.error('Failed to update post with user typing status', error)
    return false
  }
}
